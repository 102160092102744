import {  Card,  Form, message,  Spin } from 'antd';
import React, { useState } from 'react';
import { GridApi } from 'ag-grid-community';
import { IAppComponentProps } from '../../../../components';
import { useDispatch } from 'react-redux';
import { updateMasterDataUpdated } from '../../entitlement_maintenance/redux/IStoredEntitlementsSlice';
import { MASTER_DATA_BUTTONS_TOP, MASTER_DATA_FROM_INPUT_FIELDS, MASTER_DATA_TABLE_GRID } from './EditFormData';
import { useGetTableDataQuery, useGetTableMetaDataQuery } from './queries.generated';
import { convertRawDataToObject } from './CustomRenderers';


type CustomFormProps = {
    tableName: string
    recordKey: string
} &  IAppComponentProps

const GenericEditForm: React.FC<CustomFormProps> = (props) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [gridApi, setGridApi] = useState<GridApi>(undefined);
    const [loadedDataList, setLoadedDataList] = useState([]);
    const [loadedMetaDataList, setLoadedMetaDataList] = useState([]);
    const [newDataList, setNewDataList] = useState([]);
    const [deletedDataList, setDeletedDataList] = useState([]);
    const [updatedDataList, setUpdatedDataList] = useState([]);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [updateMode, setUpdateMode] = useState<boolean>(false);

    //TODO Update this with the GraphQL generated query name
    const { data: loadedData, loading: dataLoading, error: dataLoadError, refetch: dataRefresh } = useGetTableDataQuery({
        variables: { tableNames: [props.tableName] }
    });

    const { data: loadedMetaData, loading: metaDataLoading, error: metaDataLoadError, refetch: metaDataRefresh } = useGetTableMetaDataQuery({
        variables: { tableNames: [props.tableName] }
    });

    if (dataLoadError && !dataLoading && !refreshLoading && loadedDataList.length === 0)
    {
        console.error(dataLoadError);
    }
    if (metaDataLoadError && !metaDataLoading && !refreshLoading && loadedMetaDataList.length === 0)
    {
        console.error(metaDataLoadError);
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(`event : ${e} `);
        const { value } = e.target;
        //check if Record already exists in the list
        const exists = loadedDataList.find((record) => record[props.recordKey] === value);
        //If it exists and you are not in update mode then raise duplicate error message
        if (exists && !updateMode) {
            message.error('Record Id already exists');
        }
        //Apply any custom logic for form fields here if required
        //e.g. Here we create a phrase Id string based on the record Id
        form.setFieldsValue({ phraseId: `${props.recordKey}-`.concat(form.getFieldValue(props.recordKey)).toLowerCase() });
        //e.g. make sure record Id string is always uppercase, this is just an example
        form.setFieldsValue({ [props.recordKey]: form.getFieldValue(props.recordKey)?.toUpperCase() });
    };

    const cancelUpdate = () => {
        setUpdateMode(false);
        form.resetFields();
    };

    const addRecord = () => {
        //TODO replace recordId with your record identification
        const exists = loadedDataList.find((record) => record.recordId === form.getFieldValue(props.recordKey));

        if (!exists) {
            //insert new record, add any additional fields required for your data, in this instance we keep track of where the record are used so we initialise the volume to 0 as its new
            const newRecord = { ...form.getFieldsValue(), insertUser : props.currentUser.username, updateUser: props.currentUser.username, updateAction: 'insert', volume: 0 };
            setNewDataList([...newDataList,newRecord]);
            setLoadedDataList([...loadedDataList, newRecord]);
            //Clear all input fields on form
            form.resetFields();
            //make sure other components knows the data was updated
            dispatch(updateMasterDataUpdated(true));
            
        } else if (updateMode) {
            
            const updatedRecord = { ...form.getFieldsValue(), recordId: exists.recordId,insertUser: exists.insertUser,insertTime: exists.insertTime, updateTime: exists.updateTime,updateUser: props.currentUser.username,updateAction : 'update',volume: exists.volume};
            setUpdatedDataList([...updatedDataList,updatedRecord]);
             const rowData = convertRawDataToObject(props.tableName, loadedData);
            //TODO Make sure the  item.businessAreaCode matches your unique way to identify record
            setLoadedDataList(
                rowData.map((item) =>
                    item[props.recordKey] === exists[props.recordKey]
                        ? updatedRecord
                        : item
                )
            );
            form.resetFields();
            setUpdateMode(false);
            dispatch(updateMasterDataUpdated(true));
        } else {
            message.error('Record already exists');
        }
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const onDeleteLine = (params) => {
        //TODO add this if you require to protect the delete if used somewhere else
        if (params.data.volume > 0) {
            //TODO customize the message as appropriate
            message.error(
                `Record was used ${params.data?.volume} times in somewhere else Rules and cannot be delete`
            );
            return;
        }
        params.api.applyTransaction({ remove: [params.data] });
        //TODO replace recordId with your identifier
        const newList = loadedDataList.filter(
            (record) => record[props.recordKey] !== params.data[props.recordKey]
        );
        setLoadedDataList(newList);
        //If not insert then also add to deleted list
        if (params.data.updateAction !== 'insert') {
            //Create record to add to deleted list
            setDeletedDataList([
                ...deletedDataList,
                { ...params.data, updateAction: 'delete' },
            ]);
            dispatch(updateMasterDataUpdated(true));
        }
    };


    const onEditLine = (params) => {
        //TODO Insert all field data on the form as appropriate
        form.setFieldsValue(params.data);
        if (params.data.updateAction !== 'insert') {
            setUpdateMode(true);
        }
        console.log(`update mode: ${updateMode}`);
    };

    const refreshData = () => {
        //TODO Call the method to refresh the data from the GraphQL generated data
        setRefreshLoading(true);
        //Make sure grid is cleared before refreshing the data
        setLoadedDataList(null);
        dataRefresh().then(({ data }) => {
            const rowData = convertRawDataToObject(props.tableName, loadedData);
            setLoadedDataList(rowData);
            if (gridApi) {
                gridApi.setRowData(rowData);
            }
            metaDataRefresh().then(() => {
                setLoadedMetaDataList(loadedMetaData.getTableMetaData);
                setRefreshLoading(false);
            }
            ).catch(() => {
                setRefreshLoading(false);
            });
            
        }).catch(() => {
            setRefreshLoading(false);
            });
    };

    const saveGroup = () => {
            //save all Groups
            //call mutation for save
            const toBeUpdatedRecords = [...newDataList, ...updatedDataList, ...deletedDataList];
            if (toBeUpdatedRecords && toBeUpdatedRecords.length > 0) {
                //TODO replace UpdateRecordsMutation with your mutation
                // UpdateRecordsMutation({
                //     variables: { inputFieldCollection: toBeUpdatedRecords } as UpdateRecordsMutationVariables,
                // })
                //     .then((response) => {
                //         //find the records in responds in the entitlementTypes and update the list
                //         const updatedRecords = response.data.updateRecords;
                //         const updatedList = loadedDataList.map((record) => {
                //             const updatedRecord = updatedRecords.find((updated) => updated.recordId === record.recordId);
                //             return updatedRecord ? { ...updatedRecord, updateAction: null } : record;
                //         });
                //         setLoadedDataList(updatedList);
                //         setNewDataList([]);
                //         setUpdatedDataList([]);
                //         setDeletedDataList([]);
                //         dispatch(updateMasterDataUpdated(false));
                //         //make sure other components knows specific record data was updated if required
                //         //dispatch(setBusinessAreaUpdated(true));
    
                //         message.success(
                //             getI18n(
                //                 'business-area-master-data-edit-success-update-message',
                //                 'Updated was successful',
                //                 props.intl
                //             )
                //         );
                //     })
                //     .catch((error) => {
                //         console.dir(error);
                //     });
            } else {
                message.error('Nothing to update');
            }
        };



    //TODO Uncomment this when query exists
    //TODO make sure these variable match the variables on the data query above 
    //TODO and also make sure the loadedData.getRecordList represents your data structure from graphQL query
    React.useEffect(() => {
        //TODO Uncomment this is you require loading flag propagation
        //props.onLoading(dataLoading);
        if (!dataLoading && loadedData) {
            //convert raw table, row and column data into a list of records
            const rowData = convertRawDataToObject(props.tableName, loadedData);
            setLoadedDataList(rowData);
        }
        if (!metaDataLoading && loadedMetaData) {
            setLoadedMetaDataList(loadedMetaData.getTableMetaData);
        }
    }, [dataLoading, loadedData, metaDataLoading, loadedMetaData]);

    return (
        //TODO add all the boolean variable required for spinner as per example below
        // <Spin spinning={ updateLoading}>
        <Spin spinning={ dataLoading|| refreshLoading || metaDataLoading}>
        <Card>
            <Form form={form} layout="vertical" onFinish={addRecord}>
                {MASTER_DATA_BUTTONS_TOP(props.intl, saveGroup, refreshData)}
                {MASTER_DATA_FROM_INPUT_FIELDS({
                    props,
                    submit: () => form.submit(),
                    form,
                    handleInputChange,
                    handleSelect: null,
                    updateMode,
                    cancelUpdate,
                    loadedMetaDataList
                })}
            </Form>
            {MASTER_DATA_TABLE_GRID(loadedDataList, onDeleteLine, onEditLine, onGridReady, props, loadedMetaDataList)}
        </Card>
        </Spin>
    );
};

export default GenericEditForm;