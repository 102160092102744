import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {
  Alert,
  Badge, Button,
  Col,
  ConfigProvider, Dropdown,
  Form,
  Menu,
  Modal,
  notification,
  Row,
  Space,
  Spin,
  Tabs, Tooltip,
  Typography
} from "antd";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { PageContainer } from "@ant-design/pro-layout";
import styles from "../../../styles/scss/portal.scss";
import { useIntl } from "react-intl";
import { useLazyQuery } from "@apollo/client";
import enUS from "antd/lib/locale/en_US";
import { getI18n, isExternalUser } from "../../../utils/Utils";
import DraftDocumentSearchForm from "./DraftDocumentSearchForm";
import { FailureDownloadDocsResp, IAppComponentProps } from "../../../components";
import dayjs from 'dayjs';
import { getDraftDocument } from "./DraftDocumentService";
import { MYDOCS_TABLE_HEADERS_DRAFT } from "./draftDocumentUtils";
import { AgGridReact } from "ag-grid-react";
import { GridApi } from "ag-grid-community";
import { isDraftDocumentLoading, setTotalDraftDocument } from "../IStoredDraftDocumentLoadingSlice";
import { useAppDispatch, useAppSelector } from "../../../main/hooks";
import deDe from "antd/lib/locale/de_DE";
import {DOCUMENT_USER_ENTITLEMENT_RESULT_BY_USER_ID} from "../../../main/queries";
import {getAgGridLocalization, PersonalizeGridView} from "../../info-portal/components/grid/GridUtil";
import utc from 'dayjs/plugin/utc';
import {
  getDocumentUserEntitlementResultByUserId
} from "../../../main/__generated__/getDocumentUserEntitlementResultByUserId";
import {MYLISTDOCS_TABLE_HEADERS} from "../../documents/ListDocumentsUtils";
import {
  DownloadOutlined,
  DownOutlined,
  EyeOutlined,
  ReloadOutlined,
  SaveOutlined,
  UserOutlined
} from "@ant-design/icons";
import moduleStyles from "../../documents/styling/document.module.css";

dayjs.extend(utc);

const DraftDocuments: FunctionComponent<IAppComponentProps> = (props) => {
  const dispatch = useAppDispatch();
  const currentUser = props.currentUser;
  const [_form] = Form.useForm();
  const [docGridApi, setDocGridApi] = useState<GridApi>(undefined);
  const [docData, setDocData] = useState(undefined);
  const [_docCount, setDocCount] = useState(0);
  const [_formDataValues, setFormDataValues] = useState(undefined);
  const [failureDocuments, _setFailureDocuments] = useState<FailureDownloadDocsResp[]>(undefined);
  const [partnerNos, setPartnerNos] = useState<string[]>(undefined);
  const [_flag,setFlag] = useState<boolean>(false);


  const { TabPane } = Tabs;
  const sizes: React.CSSProperties = {
    height: "100%",
    width: "98%"
  };

  const intl = useIntl();

  const gridView = useRef<PersonalizeGridView>(
      new PersonalizeGridView(
          'ecap_draft_list_ag_grid_personalized_view',
          MYDOCS_TABLE_HEADERS_DRAFT(props.intl,props.distinctEntitlements , props.currentUser)));

  const { loading, total } = useAppSelector((state) => state.draftDocumentLoading);
  const [getUserEntitlement, {}] = useLazyQuery<getDocumentUserEntitlementResultByUserId>(
      DOCUMENT_USER_ENTITLEMENT_RESULT_BY_USER_ID,
      {
        variables: {
          userId: props.currentUser?.uid,
          entitlement: 'display_dd@ecap',
          partnerName: String,
          authentication: localStorage.getItem('id_token'),
        },
        fetchPolicy: 'network-only',
      },
  );


  const onDocGridReady = (params) => {
    setDocGridApi(params.api);
    gridView.current.initPersonalizedView(params.api);
  };

  const onExport = () => {

    const selectedRows = docGridApi.getSelectedRows();
    console.dir(selectedRows);

    if (selectedRows.length === 0) {
      //console.log("Error")
      Modal.warning({
        title: intl.formatMessage({
          id: "Warning-message",
          defaultMessage: "Export Document(s)"
        }),
        content: intl.formatMessage({
          id: "Warning-export-message",
          defaultMessage: "You have not selected any items to be exported. Please select an item first."
        })
      });
    } else {
      docGridApi.exportDataAsExcel({
        onlySelectedAllPages: false,
        onlySelected: true,
        fileName: "Draft_Document_Export"
      });
    }
  };

  const reload = ()=>setFlag(prevState=>!prevState);

  const onSave = (_: any) => {
    gridView.current.savePersonalizedView();
    reload();
    notification.info({ message: intl.formatMessage({ id: 'report-result-preferences-saved' }) });
  };

  const onApplyPerson = (_: any) => {
    gridView.current.applyPersonalizedView();
  };

  const onReset = (_: any) => {
    docGridApi.setFilterModel(null);
    gridView.current.reset();
  };

  const downIcon = () => <DownOutlined />;

  const menu = (
      <Menu>
        <Menu.Item key="3" disabled={!gridView.current.hasPersonalizedView()}>
          <UserOutlined onClick={onApplyPerson} />
          <a data-testid={'info-portal-personal'} onClick={onApplyPerson}>
            {intl.formatMessage({ id: 'info-portal-personal', defaultMessage: 'Personal' })}
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="4">
          <SaveOutlined onClick={onSave} />

          <a data-testid={'info-portal-save'} onClick={onSave}>
            {intl.formatMessage({ id: 'info-portal-save', defaultMessage: 'Save' })}
          </a>
        </Menu.Item>
      </Menu>
  );


  const onSearch = (values) => {
    dispatch(isDraftDocumentLoading(true));
    setFormDataValues(values);

    const draftListInput = {

      documentDateFrom: values.documentDateFrom ? dayjs(values.documentDateFrom).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"),
      documentDateTo: values.documentDateTo ? dayjs(values.documentDateTo).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"),
      //21427181
      bmwCompanyId: values.bmwCompanyId ? parseInt(values.bmwCompanyId) : undefined,
      documentNumbers: values.documentNumbers,
      documentTypeId: values.documentTypeId ? parseInt(values.documentTypeId) : undefined,
      downloaded: values.downloaded ? parseInt(values.downloaded) : undefined,
      formatId: values.formatId ? parseInt(values.formatId) : undefined,
      partnerId: values.partnerId ? parseInt(values.partnerId) : undefined,
      processCodeId: values.processCodeId ? parseInt(values.processCodeId) : undefined,
      statusId: values.statusId ? parseInt(values.statusId) : undefined,
      myDocuments: values.myDocuments,
      partnerNoList: values.partnerNoList ? values.partnerNoList : isExternalUser(props.distinctEntitlements) && !values.partnerNo ? partnerNos : undefined
    };
    localStorage.setItem("draftListInput", JSON.stringify(draftListInput));
    Promise.resolve(getDraftDocument(currentUser, draftListInput, intl))
      .then((data) => {
        setDocCount(data.DraftDocumentList.length);
        dispatch(setTotalDraftDocument(data?.DraftDocumentList.length));
        setDocData(data.DraftDocumentList);
        dispatch(isDraftDocumentLoading(false));
      })
      .catch((err) => {
        dispatch(isDraftDocumentLoading(false));
        console.error(err);
      });
  };

  useEffect(() => {
    const draftDocumentInput = {
      documentDateFrom: dayjs().startOf("d").format("YYYY-MM-DD"),
      documentDateTo: dayjs().endOf("d").format("YYYY-MM-DD")
    };
    if (isExternalUser(props.distinctEntitlements)) {
      dispatch(isDraftDocumentLoading(true));
      const partnerNos: string[] = [];
      getUserEntitlement().then(response => {
        return new Promise<string[]>((resolve) => {
          const userEntitlement = response.data?.getDocumentUserEntitlementResultByUserId;
          userEntitlement.partners.map(item => {
            partnerNos.push(item.partnerNo);
          });
          setPartnerNos(partnerNos);
          resolve(partnerNos);
        });
      })
        .then(partnerNoList => {
          onSearch({ ...draftDocumentInput, partnerNoList });
        });
    } else {
      onSearch(draftDocumentInput);
    }
  }, [props.currentUser]);

  return (
    <>
      <ConfigProvider locale={localStorage.getItem("locale") === "en" ? enUS : deDe}>
        <PageContainer content={""} extraContent={""} className={styles.users} style={{ background: "white" }}
                       title={false}>

          <Spin spinning={loading} size={"large"}>

            <div style={{ ...sizes, minHeight: "200px" }} className="pl15 ag-theme-balham">
              <Col md={24} sm={12} xs={24}>
                <Tabs defaultActiveKey={"1"} className="users-tabs">
                  <TabPane tab={getI18n("Document-title-draft", "Draft Documents", intl)} key="1">
                    <Typography.Title level={5} className={"mb10"}>
                      {getI18n("Document-title-draft-message", "", intl)}
                    </Typography.Title>
                    <DraftDocumentSearchForm  onSearch={onSearch} currentUser={currentUser}
                                             intl={props.intl} distinctEntitlements={props.distinctEntitlements}
                                             />

                    {failureDocuments &&
                    <Row gutter={24}>
                      <Col span={24} className="mb5 mt5">
                        {failureDocuments.map((x, i) =>
                          <Alert key={i} message={`${x.errorMessage}`} type="error" showIcon={true} closable={true} />)}
                      </Col>
                    </Row>}

                    <Col md={24} sm={12} xs={24}>
                      <Tabs defaultActiveKey={"1"}>
                        <TabPane tab={getI18n("Document-title-draft", "Draft Documents", intl)} key="1">
                          <div style={{marginBottom:"10px"}}>
                            <label style={{marginRight:"5px"}}>{getI18n('welcome-No_documents', ' Number Of Documents ', intl)}</label>
                            <Space>
                              <Badge
                                  className="site-badge-count-109"
                                  count={total}
                                  style={{backgroundColor: '#52c41a'}}
                                  title={'Number of Documents'}
                                  overflowCount={999999}
                              />
                            </Space>
                          </div>

                          <div style={{marginBottom:"15px"}}>
                            <div style={{display:"inline-block"}}>
                            <Dropdown.Button
                                style={{marginRight:"5px"}}
                                data-testid={'view-result-customization'}
                                overlay={menu} trigger={['click']}
                                icon={downIcon()} size={'middle'}>
                              <EyeOutlined />
                              {intl.formatMessage({
                                id: 'info-portal-view',
                                defaultMessage: 'View',
                              })}
                            </Dropdown.Button>
                            </div>

                            <Tooltip
                                title={intl.formatMessage({ id: 'reset-filter' })}>
                              <Button
                                  style={{marginRight:"5px"}}
                                  data-testid={'view-result-customization-reset'}
                                  onClick={onReset} size={'middle'}
                                  icon={<ReloadOutlined
                                      className={moduleStyles.simpleFlipRefresh} />}>
                                {intl.formatMessage({ id: 'info-portal-reset' })}
                              </Button>
                            </Tooltip>

                            <Tooltip
                                title={intl.formatMessage({ id: 'export-to-excel' })}>
                              <Button data-testid={'result-export'}
                                      onClick={onExport}
                                      icon={<DownloadOutlined />}
                                      size={'middle'}>
                                {intl.formatMessage({ id: 'export' })}
                              </Button>
                            </Tooltip>
                          </div>

                          <div style={{flex: "auto", height: "500px"}}>
                            <AgGridReact
                                defaultColDef={{
                                  enableValue: true,
                                  sortable: true,
                                  resizable: true

                                }}
                                api={docGridApi}
                                columnDefs={gridView.current.getView()}
                                rowData={docData}
                                sideBar={false}
                                rowClass="agGridRows"
                                localeText={getAgGridLocalization(intl)}
                                suppressMenuHide={true}
                                debug={true}
                                onGridSizeChanged={() => docGridApi.sizeColumnsToFit()}
                                onGridReady={onDocGridReady}
                                rowSelection={"multiple"}
                                pagination={true}
                                paginationPageSize={15}
                                suppressRowClickSelection={true}
                                enableBrowserTooltips={true}
                                data-testid="documents-test-id"/>
                          </div>
                        </TabPane>
                      </Tabs>
                    </Col>
                  </TabPane>
                </Tabs>
              </Col>
            </div>
          </Spin>


        </PageContainer>
      </ConfigProvider>

    </>
  );
};
export default DraftDocuments;

function parseBoolean(myDocuments: any) {
  throw new Error("Function not implemented.");
}

