import { gql } from "@apollo/client";

export const SELECT_PROCESSCODE_QUERY = gql`
    query listProcessCodes($select: String!, $username: String!) {
        listProcessCodes(select: $select, username: $username) {
            id
            processCode
            processCodeName
            phraseId

        }
    }
`;
export const SELECT_STATUS_QUERY = gql`
    query getDocumentStatus {
        getDocumentStatus {
            id
            documentStatus
            phraseId
            insertTime
            isFinalStatus
            updateTime
        }
    }
`;
export const SELECT_FORMAT_QUERY = gql`
    query getDocumentFormat {
        getDocumentFormat {
            id
            documentFormat
            insertTime
            insertUser
            updateUser
        }
    }
`;
export const SELECT_ALL_DOCTYPE_QUERY = gql`
    query getAllDocumentTypes {
        getAllDocumentTypes {
            id
            documentDescription
            documentType
            insertTime
            insertUser
            updateTime
            updateUser
            phraseId
            serviceProviderId
            serviceProviderName
        }
    }
`;


export const SELECT_FILTERED_DOCTYPE_QUERY = gql`
    query getFilteredDocumentTypes($entitlement: String!) {
        getFilteredDocumentTypes(entitlement : $entitlement) {
            id
            documentDescription
            documentType
            insertTime
            insertUser
            updateTime
            updateUser
            phraseId
            serviceProviderId
            serviceProviderName
        }
    }
`;

export const SELECT_PROVIDER_DOCTYPE_QUERY = gql`
    query getProviderDocumentType($serviceProviderId: Float!) {
        getProviderDocumentType(serviceProviderId : $serviceProviderId) {
            id
            documentDescription
            documentType
            insertTime
            insertUser
            updateTime
            updateUser
            phraseId
            serviceProviderId
            serviceProviderName
        }
    }
`;

export const MY_DOCS_DATA = gql`
    query getDocumentList($documentInput:DocumentInput!){
        getPartnerDocuments(documentInput:$documentInput) {
            amount
            azContainerName
            azDownloadFileName
            azItemPathName
            azStorageType
            blobId
            companyCode
            companyName
            currency
            dateReceived
            documentId
            downloadLink
            downloaded
            insertTs
            blobId
            partnerName
            partnerno
            processCode
            quantity
            reference
            systemCode
            updatetime
            area
        }
    }
`;

export const MY_DOCS_COUNT = gql`
    query getDocCount{
        getDocumentsCount{
            count
        }
    }
`;


export const DOWNLOAD_DOCUMENT = gql`
    query downloadDocument($documentDownloadInput: DocumentDownloadInput!){
        downloadDocument(documentDownloadInput: $documentDownloadInput){
            failureDocuments{
                documentId
                documentName
                documentLink
                documentPath
            }
            successDocuments{
                documentId
                documentName
                documentLink
                documentPath
            }
            zipFileData
            encodedZipFileData
            errorMessage
        }
    }
`;


export const DOWNLOAD_DOCUMENT_TLOG_INFO_ECAP = gql`
    query downloadDocumentEcapTLogInfo($documentDownloadTLogInfoInput: [DocumentDownloadTLogInfoInput!]!){
        downloadDocumentEcapTLogInfo(documentDownloadTLogInfoInput: $documentDownloadTLogInfoInput){
            failureDocuments{
                id
                blobId
                messageId
                errorMessage

            }
            successDocuments{
                id
                messageId
                blobId

            }
            zipFileData
            encodedZipFileData
            errorMessage
        }
    }
`;

export const DOWNLOAD_DOCUMENT_ECAP = gql`
    query downloadDocumentEcap($documentDownloadInputs: [DocumentDownloadInputs!]!){
        downloadDocumentEcap(documentDownloadInputs: $documentDownloadInputs){
            failureDocuments{
                id
                blobId
                messageId
                errorMessage

            }
            successDocuments{
                id
                messageId
                blobId

            }
            zipFileData
            encodedZipFileData
            errorMessage
        }
    }
`;
export const DOWNLOAD_MULTIPLE_DOCUMENT = gql`
    query downloadMultiDocument($documentDownloadInputs: [DocumentDownloadInput!]!){
        downloadMultipleDocument(documentDownloadInputs: $documentDownloadInputs){
            failureDocuments{
                documentId
                documentName
                documentLink
                documentPath
                errorMessage
            }
            successDocuments{
                documentId
                documentName
                documentLink
                documentPath
            }
            zipFileData
            encodedZipFileData
            errorMessage
        }
    }
`;

export const MY_DOCS_LIST_DATA = gql`
    query getPartnerDocsList($docListInput:DocListInput!){
        getPartnerDocsList(docListInput:$docListInput) {
            id
            blobId
            messageId
            bmwCompany
            country
            currency
            documentDate
            documentNumber
            documentType
            phraseId
            downloaded
            format
            grossAmount
            partnerName
            partnerNumber
            processCode
            receptionDate
            sdiNumber
            status
            taxAmount
            vinNumber
            statusPhraseId
            fileName
            attachment
            shipPartnerNo
            externalDocumentNumber
        }
    }
`;

export const MY_FSM_DOCS_LIST_DATA = gql`
    query getFsmPartnerDocsList($docListInput:DocListInput!){
        getFsmPartnerDocsList(docListInput:$docListInput) {
            id
            blobId
            fileName
            messageId
            bmwCompany
            country
            currency
            documentDate
            documentNumber
            documentType
            phraseId
            downloaded
            format
            grossAmount
            partnerName
            partnerNumber
            processCode
            receptionDate
            sdiNumber
            status
            taxAmount
            vinNumber
            statusPhraseId
            errorMessage
            attachment
            shipPartnerNo
            externalDocumentNumber
        }
    }
`;

export const TECH_MISSING_INVOICES_DOCS_LIST_DATA = gql`
    query getMissingInvoicesDocsList($docListInput:DocListInput!){
        getMissingInvoicesDocsList(docListInput:$docListInput) {
            ecDocumentId
            messageId
            partnerNo
            companyPartnerId
            correctCompanyPartnerId
            documentNumber
            street1
            street2
            city
            countryCode
            postalCode
            region
            regionCode
            companyVatCountryCode
            companyVatCode
            companyVatCodeFromCompanyVatCode
            companyVatCountryCodeFromCompanyVatCode
            insertUser
            insertTime
            companyNameFromPartnerCompanySystem
            companyCode
            companyCodeFromPartnerCompanySystem
            category
            partnerAddressTypeFromPartnerAddress
            partnerCityFromPartnerAddress
            partnerCountryCodeFromPartnerAddress
            partnerName
            partnerNameFromPartnerCompanySystem
            partnerNoFromPartnerCompanySystem
            partnerPostalCodeFromPartnerAddress
            partnerRegionFromPartnerAddress
            partnerRegionCodeFromPartnerAddress
            partnerStreet1FromPartnerAddress
            partnerStreet2FromPartnerAddress
            partnerVatCode
            partnerVatCodeFromPartnerVatCode
            partnerVatCountryCodeFromPartnerVatCode
            partnerVatCountryCode
            processingStatusFlag
            updateTime
            partnerNoIssue
            partnerNameIssue
            companyCodeIssue
            partnerAddressIssue
            countryCodeAddressIssue
            partnerVatCodeIssue
            partnerVatCountryCodeIssue
            companyPartnerIdIssue
            companyVatCodeIssue
            attachmentPending
            logInfoId
            fileName
            blobId
            discrepancies
        }
    }
`;

export const TECH_MISSING_INVOICES_DOCS_LIST_DATA_GROUPED = gql`
    query getMissingInvoicesDocsListGrouped($docListInput:DocListInput!){
        getMissingInvoicesDocsListGrouped(docListInput:$docListInput) {
            rowNo
            processingStatusFlag
            updateTime
        }
    }
`;
