import React, {FunctionComponent, useEffect, useMemo, useRef, useState} from 'react'; //useContext removed as it not being used
import { IAppComponentProps } from '../../../components';
import { Col, ConfigProvider, Row, Spin, Tabs, Typography, Tooltip, Modal } from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import enUS from 'antd/lib/locale/en_US';
import { useIntl } from 'react-intl';
import { QUERY_GET_ALL_BANNER_DATA } from '../../survey/queries';
import { useLazyQuery } from '@apollo/client';
import { IntlShape } from 'react-intl/src/types';
import RenderIfEntitled from '../../../components/EntitlementComponents';
import { DeleteOutlined, PlusSquareOutlined, EditOutlined } from '@ant-design/icons';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { deleteBannerData } from '../NotificationService';
import AddBannerModal from './AddBannerModal';
import 'ag-grid-community/styles/ag-grid.css';
import { getI18n } from '../../../utils/Utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { entitlementExists } from '../../../main/entitlementService';
import {ColDef} from "ag-grid-community/dist/lib/entities/colDef";

const initialState = {
    bannerShowStart: '',
    bannerShowEnd: '',
    bannerHeadingDe: '',
    bannerHeadingEn: '',
    bannerMessageDe: '',
    bannerMessageEn: '',
    startTime: '',
    endTime: '',
    messageType: '',
};

type BannerRowOptionParams = IAppComponentProps & ICellRendererParams;



const BannerManagement: FunctionComponent<IAppComponentProps> = (props) => {
    const { TabPane } = Tabs;
    const { Paragraph } = Typography;
    const intl = useIntl();
    const gridRef = useRef<AgGridReact>(null);
    const [_gridApi, setGridApi] = useState<GridApi>(undefined);
    const [bannerTableData, setBannerTableData] = useState([]);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [adding, setAdding] = useState<Boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const dateTimeFormat = 'YYYY-MM-DD HH:mm';

    dayjs.extend(utc);

    const [getAllBanners] = useLazyQuery(QUERY_GET_ALL_BANNER_DATA, { fetchPolicy: 'network-only' });

    const bannerHeaderParagraphLine1 = getI18n(
        'banner-message-headerText-line1',
        'Banner Show Start and End is the period for which the banner should show. If you add dates to your message, add it with {start_time} and {end_time} varaibles and',
        intl
    );
    const bannerHeaderParagraphLine2 = getI18n(
        'banner-message-headerText-line2',
        'add the actual times in the Start and End time column, this is to display time in international time. You can add html in the En and De Banner Messages and Headings.',
        intl
    );
    const bannerHeaderParagraphLine3 = getI18n(
        'banner-message-headerText-line3',
        'The color of the Banner Message will depend on the Mesage Type chosen: success = Green, error = Red, warning = Yellow, Info = Blue.',
        intl
    );

    const headerTitle = getI18n('banner-management-title', 'Banner Management', intl);

    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    //Add Banner
    const addBanner = () => {
        setAdding(true);
        setIsModalVisible(true);
    };

    //Edit Banner
    const editBanner = (gridSelectedRow:any) => {
        setSelectedBanner({...gridSelectedRow, __typename:undefined});
        setIsModalVisible(true);
    };

    //Delete Banner
    const deleteBanner = async (gridSelectedRow:any) => {
        if (gridSelectedRow.bannerId) {
            setLoading(true);
            deleteBannerData(gridSelectedRow.bannerId)
                .then((_) => {
                    return getAllBanners().then((response) => {
                        if (response.data != null) {
                            const banners = response.data.getAllBanners.map((banner) => {
                                return {
                                    ...banner,
                                    bannerShowStart: dayjs
                                        .utc(banner.bannerShowStart)
                                        .local()
                                        .format(dateTimeFormat),
                                    bannerShowEnd: dayjs.utc(banner.bannerShowEnd).local().format(dateTimeFormat),
                                    startTime: banner.startTime
                                        ? dayjs.utc(banner.startTime).local().format(dateTimeFormat)
                                        : null,
                                    endTime: banner.endTime
                                        ? dayjs.utc(banner.endTime).local().format(dateTimeFormat)
                                        : null,
                                };
                            });
                            setBannerTableData(banners);
                        }
                    });
                })
                .finally(() => setLoading(false));
        }
    };

    const refreshGrid = () => {
        setAdding(false);
        setLoading(true);
        getAllBanners().then((response) => {
            if (response.data != null) {
                const banners = response.data.getAllBanners.map((banner) => {
                    return {
                        ...banner,
                        bannerShowStart: dayjs.utc(banner.bannerShowStart).local().format(dateTimeFormat),
                        bannerShowEnd: dayjs.utc(banner.bannerShowEnd).local().format(dateTimeFormat),
                        startTime: banner.startTime ? dayjs.utc(banner.startTime).local().format(dateTimeFormat) : null,
                        endTime: banner.endTime ? dayjs.utc(banner.endTime).local().format(dateTimeFormat) : null,
                    };
                });
                setBannerTableData(banners);
                setLoading(false);
            }
        });
    };

    const BannerRowOption = useMemo(()=>(props: BannerRowOptionParams) => {
        return <div>
            <Tooltip title={getI18n("editBannerTooltipTitle", "Edit Banner", intl)}
                     key={"editBanner"}>
                <EditOutlined
                    style={{ color: "#444444", marginRight: "5px" }}
                    onClick={_=>editBanner(props.data)}
                />
            </Tooltip>

            <Tooltip title={getI18n("removeBannerTooltipTitle", "Delete Banner", intl)}
                     key={"removeBanner"}>
                <DeleteOutlined
                    style={{ color: "#444444" }}
                    onClick={_ => deleteBanner(props.data)}
                />
            </Tooltip>
        </div>
    }, [bannerTableData]);

    const BANNER_MANAGEMENT_TABLE_HEADERS = (intl: IntlShape) => {
        let columns: ColDef[] = [
            {
                headerName: getI18n('banner-management-table-header-banner-show-start', 'Banner Show Start', intl),
                field: 'bannerShowStart',
                colId: 'bannerhowStart',
            },
            {
                headerName: getI18n('banner-management-table-header-banner-show-end', 'Banner Show End', intl),
                field: 'bannerShowEnd',
                colId: 'bannerShowEnd',
            },
            {
                headerName: getI18n(
                    'banner-management-table-header-banner-heading-english',
                    'Banner Heading English',
                    intl
                ),
                field: 'bannerHeadingEn',
                colId: 'bannerHeadingEn',
            },
            {
                headerName: getI18n(
                    'banner-management-table-header-banner-heading-german',
                    'Banner Heading German',
                    intl
                ),
                field: 'bannerHeadingDe',
                colId: 'bannerHeadingDe',
            },
            {
                headerName: getI18n(
                    'banner-management-table-header-banner-message-english',
                    'Banner Message English',
                    intl
                ),
                field: 'bannerMessageEn',
                colId: 'bannerMessageEn',
            },
            {
                headerName: getI18n(
                    'banner-management-table-header-banner-message-german',
                    'Banner Message German',
                    intl
                ),
                field: 'bannerMessageDe',
                colId: 'bannerMessageDe',
            },
            {
                headerName: getI18n('banner-management-table-header-message-type', 'Message Type', intl),
                field: 'messageType',
                colId: 'messageType',
            },
        ];

        if (entitlementExists('technicaldashboard@systemadmin', props.distinctEntitlements)) {
            columns = [{
                headerName: intl.formatMessage({
                    id: "document-draft-grid-header-Options",
                    defaultMessage: "Options"
                }),
                field: "draftDocumentRowOptions",
                colId: "draftDocumentRowOptions",
                cellRenderer: BannerRowOption,
                width: 100,
                lockPosition:true
            },...columns];
        }

        return columns;
    };

    useEffect(() => {
        setLoading(true);
        getAllBanners().then((response) => {
            if (response.data != null) {
                const banners = response.data.getAllBanners.map((banner) => {
                    return {
                        ...banner,
                        bannerShowStart: dayjs.utc(banner.bannerShowStart).local().format(dateTimeFormat),
                        bannerShowEnd: dayjs.utc(banner.bannerShowEnd).local().format(dateTimeFormat),
                        startTime: banner.startTime ? dayjs.utc(banner.startTime).local().format(dateTimeFormat) : null,
                        endTime: banner.endTime ? dayjs.utc(banner.endTime).local().format(dateTimeFormat) : null,
                    };
                });
                setBannerTableData(banners);
                setLoading(false);
            }
        });
    }, []);

    const cancelModal = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Spin spinning={loading} size={'large'}>
                <ConfigProvider locale={enUS}>
                    <PageContainer
                        title={false}
                        content={''}
                        extraContent={''}
                    >
                        {/* <div style={{ ...sizes, minHeight: '200px' }} className="pl15 ag-theme-balham"> */}
                        <div className="pl15 ag-theme-balham">
                            <Col md={24} sm={12} xs={24}>
                                <Tabs defaultActiveKey={'1'}>
                                    <TabPane tab={headerTitle} key="1" id="survey-List">
                                        <Typography.Title level={5} className={'mb10'}>
                                            <Paragraph>
                                                <Row>{bannerHeaderParagraphLine1}</Row>
                                                <Row>{bannerHeaderParagraphLine2}</Row>
                                                <Row>{bannerHeaderParagraphLine3}</Row>
                                            </Paragraph>
                                        </Typography.Title>
                                        <Col md={24} sm={12} xs={24}>
                                            <Row style={{ marginTop: '40px' }}>
                                                <RenderIfEntitled
                                                    entitlementToCheck="technicaldashboard@systemadmin"
                                                    intl={intl}
                                                    distinctEntitlements={props.distinctEntitlements}
                                                >
                                                    <Tooltip
                                                        title={getI18n('addBannerTooltipTitle', 'Add Banner', intl)}
                                                        key={'addBanner'}
                                                    >
                                                        <PlusSquareOutlined
                                                            style={{
                                                                fontSize: '25px',
                                                                color: '#444444',
                                                                marginRight: '15px',
                                                            }}
                                                            onClick={addBanner}
                                                        />
                                                    </Tooltip>
                                                </RenderIfEntitled>
                                            </Row>

                                            <Row gutter={24}>
                                                <Col md={24} sm={12} xs={24}>
                                                    <div style={{ flex: 'auto', height: '500px', paddingTop: '10px' }}>
                                                        <AgGridReact
                                                            ref={gridRef}
                                                            defaultColDef={{
                                                                enableValue: true,
                                                                sortable: true,
                                                                resizable: true,
                                                            }}
                                                            rowData={bannerTableData}
                                                            columnDefs={BANNER_MANAGEMENT_TABLE_HEADERS(props.intl)}
                                                            sideBar={false}
                                                            rowClass="agGridRows"
                                                            suppressMenuHide={true}
                                                            debug={false}
                                                            pagination={true}
                                                            paginationPageSize={15}
                                                            suppressRowClickSelection={true}
                                                            onGridReady={onGridReady}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </div>
                        <AddBannerModal
                            isModalVisible={isModalVisible}
                            bannerValues={adding ? initialState : selectedBanner}
                            handleCancel={cancelModal}
                            key={'addBannerModalKey'}
                            afterClose={refreshGrid}
                            currentUser={props.currentUser}
                            intl={intl}
                            distinctEntitlements={props.distinctEntitlements}
                            adding={adding}
                        />
                    </PageContainer>
                </ConfigProvider>
            </Spin>
        </>
    );
};

export default BannerManagement;
