import React, { useEffect, useState } from 'react';
import {
    FieldValueCache,
    InfoPortalAgGridColumnDef,
    InfoPortalColumnDef,
    InfoPortalGridService,
    InfoPortalReport,
    InfoPortalSearchProperties,
    IValidator,
    LabelLocation,
    OnChangeListener,
    ReportViewConfig,
    Validator,
} from '../../../InfoPortalInterfaces';
import { Card, Checkbox, Form, FormInstance, Radio } from 'antd';
import styles from '../../styling/infoportal.module.css';
import { ExecutionDateFromToSelection } from '../../FromToDateSelection';
import { RuleSet } from '../../../../e-cap/util/RuleResultCache';
import { IntlShape } from 'react-intl/src/types';
import { retrieveKPIUsage } from '../../../services/ReportSearch';
import { IAppComponentProps, PortalUser } from '../../../../../components';
import {
    convertFromSpecifiedDateFormatToUserDefined, convertToUserSpecifiedNumericFormat,
    getDateFormatOrDefault, numericComparatorUsingUserPreferences
} from "../../../../users/UserFormatPreferenceServices";
import dayjs, {Dayjs} from "dayjs";
import Keybinding from '../../../../../components/Keybinding';

function validatator(form: FormInstance<any>): Validator {
    const validator: IValidator = {
        fetchRule(fieldName: string): RuleSet {
            const result = {
                required: false,
                picture: '',
                readOnly: false,
                hidden: false,
                formatMessage: null,
                infoMessage: null,
            };

            if (fieldName === 'executionDate') {
                result.required = true;
            }

            return result;
        },
    };
    return new Validator(validator);
}

type CodeTranslationID = {
    code:string,
    translationID:string
};

type ReportType = {
    codeTranslationList:CodeTranslationID[],
    initialValuesCache: FieldValueCache,
    form: FormInstance<any>,
    groupName:string,
} & IAppComponentProps;

function ReportSelection({codeTranslationList,intl,initialValuesCache,form,groupName}:ReportType) {

    useEffect(()=> {
        let allSelected = true;
        codeTranslationList.forEach(codeTranslation=> {
            const inputFieldName = `${codeTranslation.code.toLowerCase()}Selection`;
            const initialInputValue:boolean = initialValuesCache.getValueOrDefault(inputFieldName,true);
            allSelected = allSelected && initialInputValue;
            const initialValue = {};
            initialValue[inputFieldName] = initialInputValue;
            form.setFieldsValue(initialValue);
        });
        const updateInitial = {};
        updateInitial[groupName] = allSelected;
        form.setFieldsValue(updateInitial);
    },[]);

    const onCheckBoxChange = (_)=> {
        let allSelected = true;
        codeTranslationList.forEach(codeTranslation=> {
            const inputFieldName = `${codeTranslation.code.toLowerCase()}Selection`;
            const selectedValue:boolean = form.getFieldValue(inputFieldName);
            allSelected = allSelected && selectedValue;
        });
        const updateInitial = {};
        updateInitial[groupName] = allSelected;
        form.setFieldsValue(updateInitial);
    }

    return <div>
        {codeTranslationList.map(codeTranslation=> {
            const name = `${codeTranslation.code.toLowerCase()}Selection`;
            return <Form.Item data-testid={name} name={name}
                              key={`${codeTranslation.code}-selection`}
                              valuePropName="checked">
                <Checkbox key={`${codeTranslation.code}-checkbox`} onChange={e=>onCheckBoxChange(e)}>
                    {`${codeTranslation.code} - ${intl.formatMessage({id:codeTranslation.translationID})}`}
                </Checkbox>
            </Form.Item>;
        })}
    </div>
}

function SimpleSearch({
                          currentUser,
                          intl,
                          reportEntitlement,
                          distinctEntitlements,
                          initialization,
                          form,
                          initialValuesCache,
                          rerenderDependentFields
                      }: InfoPortalSearchProperties) {
    const [vertical, _] = useState<boolean>(false);
    const validator = validatator(form);

    const onChangeUpdate:OnChangeListener<Dayjs> = {
        performAction(_:Dayjs) {
            form.resetFields(['period']);
        }
    }

    const onPeriodChange = (period: string) => {
        let start:Dayjs = null;
        let end:Dayjs = null;

        if (period === 'ytd') {
            end = dayjs();
            start = dayjs().startOf('year');
        } else if (period === 'cm') {
            end = dayjs();
            start = dayjs().startOf('month');
        } else if (period === 'pm') {
            end = dayjs().subtract(1, 'months').endOf('month');
            start = dayjs().subtract(1, 'months').startOf('month');
        } else if (period === 'l3m') {
            end = dayjs();
            start =  dayjs().subtract(3, 'months').startOf('month');
        }

        form.setFieldsValue({executionDate:[start,end]});
    };

    const onValueChange = (e:any,codeTranslationList:{code:string,translationID}[]) =>{
        codeTranslationList.forEach(codeTranslation=> {
            const inputFieldName = `${codeTranslation.code.toLowerCase()}Selection`;
            const initialValue = {};
            initialValue[inputFieldName] = e.target.checked;
            form.setFieldsValue(initialValue);
        });
    };

    const AG_CODE_TRANSLATIONS = [
        {code:'SR3',translationID:'report-type-sr3-label'} ,
        {code:'SR1',translationID:'report-type-sr1-label'} ,
        {code:'SR2',translationID:'report-type-sr2-label'} ,
        {code:'SR4',translationID:'report-type-sr4-label'} ,
        {code:'SR5',translationID:'report-type-sr5-label'} ,
    ];
    const MEXICO_CODE_TRANSLATIONS = [
        {code: 'LY1', translationID: 'report-type-ly1-label'},
        {code: 'LY2', translationID: 'report-type-ly2-label'},
        {code: 'LY4', translationID: 'report-type-ly4-label'},
        {code: 'LY5', translationID: 'report-type-ly5-label'},
        {code: 'LY6', translationID: 'report-type-ly6-label'},
        {code: 'LY7', translationID: 'report-type-ly7-label'},
    ];
    const CHINA_CODE_TRANSLATIONS = [
        {code:'CZ1',translationID:'report-type-cz1-label'} ,
        {code:'CZ2',translationID:'report-type-cz2-label'} ,
        {code:'CZ3',translationID:'report-type-cz3-label'} ,
    ];
    const NSC_CODE_TRANSLATIONS = [
        {code:'NR1',translationID:'report-type-sr1-label'} ,
        {code:'NR3',translationID:'report-type-nr3-label'} ,
        {code:'NR4',translationID:'report-type-sr4-label'} ,
        {code:'NR5',translationID:'report-type-sr5-label'} ,
    ];

    return <Card size={"small"} style={{backgroundColor: "#f1f3f5"}}>
        <div className={styles.kpiSearchContainer}>

            <div className={styles.singleColumnGrid}>
                <ExecutionDateFromToSelection
                    validator={validator}
                    onFromDateChange={onChangeUpdate}
                    onToDateChange={onChangeUpdate}
                    dateFormatter={getDateFormatOrDefault(currentUser)}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    initialValue={[dayjs(),dayjs()]}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}/>


                <div className={styles.kpiDateOptions} style={{marginTop:"35px"}}>
                    <Form.Item name={'period'}>
                        <Radio.Group style={{width:"100%"}}>
                            <Radio.Button style={{display:"none"}} value="na">{intl.formatMessage({id:'kpi-period-selection-ytd'})}</Radio.Button>
                            <Radio.Button data-testid='kpi-ytd' id='kpi-ytd' onClick={_=>onPeriodChange('ytd')} value="ytd">{intl.formatMessage({id:'kpi-period-selection-ytd'})}</Radio.Button>
                            <Radio.Button data-testid='kpi-cm'  id='kpi-cm'onClick={_=>onPeriodChange('cm')} value="cm">{intl.formatMessage({id:'kpi-period-selection-current-month'})}</Radio.Button>
                            <Radio.Button data-testid='kpi-pm'  id='kpi-pm' onClick={_=>onPeriodChange('pm')} value="pm">{intl.formatMessage({id:'kpi-period-selection-previous-month'})}</Radio.Button>
                            <Radio.Button data-testid='kpi-l3m'  id='kpi-l3m' onClick={_=>onPeriodChange('l3m')} value="l3m">{intl.formatMessage({id:'kpi-period-selection-lst-3-months'})}</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </div>

            </div>

            <div className={styles.singleColumnGrid}>
                &nbsp;
            </div>

            <div className={styles.kpiReportUsageContainer}>
                <div>
                    <div>
                        <Form.Item data-testid={'agReport'} name={'agReport'} valuePropName="checked">
                            <Checkbox style={{fontWeight:"bold"}} onChange={val=>onValueChange(val,AG_CODE_TRANSLATIONS)}>
                                {intl.formatMessage({id:'account-payable-bmw-ag-tab'})}
                            </Checkbox>
                        </Form.Item>
                    </div>
                    <ReportSelection
                        intl={intl}
                        initialValuesCache={initialValuesCache}
                        form={form}
                        groupName={'agReport'}
                        codeTranslationList={AG_CODE_TRANSLATIONS}/>
                </div>
                <div>
                    <div>
                        <Form.Item data-testid={'mexicoReport'} name={'mexicoReport'} valuePropName="checked">
                            <Checkbox style={{fontWeight:"bold"}} onChange={val=>onValueChange(val,MEXICO_CODE_TRANSLATIONS)}>
                                {intl.formatMessage({id:'account-payable-bmw-mexico-tab'})}
                            </Checkbox>
                        </Form.Item>
                    </div>
                    <ReportSelection
                        intl={intl}
                        initialValuesCache={initialValuesCache}
                        form={form}
                        groupName={'mexicoReport'}
                        codeTranslationList={MEXICO_CODE_TRANSLATIONS}/>

                </div>
                <div>
                    <div>
                        <Form.Item data-testid={'chinaReport'} name={'chinaReport'} valuePropName="checked">
                            <Checkbox style={{fontWeight:"bold"}} onChange={val=>onValueChange(val,CHINA_CODE_TRANSLATIONS)}>
                                {intl.formatMessage({id:'account-payable-bmw-china-tab'})}
                            </Checkbox>
                        </Form.Item>
                    </div>
                    <ReportSelection
                        intl={intl}
                        initialValuesCache={initialValuesCache}
                        form={form}
                        groupName={'chinaReport'}
                        codeTranslationList={CHINA_CODE_TRANSLATIONS}/>
                </div>
                <div>
                    <div>
                        <Form.Item data-testid={'nscReport'} name={'nscReport'} valuePropName="checked">
                            <Checkbox style={{fontWeight:"bold"}} onChange={val=>onValueChange(val,NSC_CODE_TRANSLATIONS)}>
                                {intl.formatMessage({id:'account-payable-bmw-nsc-tab'})}
                            </Checkbox>
                        </Form.Item>
                    </div>
                    <ReportSelection
                        intl={intl}
                        initialValuesCache={initialValuesCache}
                        form={form}
                        groupName={'nscReport'}
                        codeTranslationList={NSC_CODE_TRANSLATIONS}/>
                </div>
            </div>

        </div>
        <Keybinding target={this}
                    onKey={ (e) => { if(e.key === 'Enter'){form.submit()} }} type='keyup'/>
    </Card>;
}

export class KPIColumnDefinitions implements InfoPortalAgGridColumnDef {
    getLineItemColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
        return [{field: "partnerNo", headerName: intl.formatMessage({"id": "grid-heading-partnerNumber"})},
            {
                field: "count",
                headerName: intl.formatMessage({"id": "grid-heading-numberOfTransactions"}),
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.count,0),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {
                field: "dayOfYear",
                headerName: intl.formatMessage({"id": "grid-heading-executionDate"}),
                valueFormatter:params=>convertFromSpecifiedDateFormatToUserDefined(user,params.data.dayOfYear,"YYYY-MM-DD")
            }
        ];
    }

    getDefaultColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
        return [
            {field: "report", headerName: intl.formatMessage({"id": "grid-heading-report"}), width:400},
            {
                field: "count",
                headerName: intl.formatMessage({"id": "grid-heading-sum-of-transactions"}),
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.count,0),
                comparator:numericComparatorUsingUserPreferences(user)
            },
        ];
    }

    getShortViewColumns(): string[] {
        return [];
    }
}

export class KPIReport implements InfoPortalReport {
    private _config = new KPIColumnDefinitions();

    getColumnsDefinition(): InfoPortalAgGridColumnDef {
        return this._config;
    }

    performSearch(form: FormInstance, currentUser: any, distinctEntitlements: string[],intl:IntlShape): Promise<InfoPortalGridService> {
        return retrieveKPIUsage(form, intl).then(result =>
            new InfoPortalGridService(
                new ReportViewConfig("KP1", this._config), result, distinctEntitlements));
    }

    hasExpertSearch(): boolean {
        return false;
    }

    presearch(form: FormInstance<any>, intl: IntlShape): { result: "continue" | "abort-search"; description?: string, title?:string } {
        const formFields = form.getFieldsValue();

        const matcher = /^[a-z]{2}[0-9](Selection)$/
        const reports = [];
        for (const key in formFields) {
            if (matcher.test(key)) {
                if (formFields[key]) {
                    reports.push(key.substring(0,3));
                }
            }
        }

        if (reports.length === 0) {
            return {
                result:"abort-search",
                description:intl.formatMessage({id:'kpi-presearch-please-select-one'}),
                title:intl.formatMessage({id:'Download Error'})
            }
        }

        return { result: "continue" };
    }

    renderExpertSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache
                       }: InfoPortalSearchProperties): JSX.Element {
        return undefined;
    }

    renderSimpleSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element {
        return <SimpleSearch initialization={initialization}
                             currentUser={currentUser}
                             intl={intl}
                             form={form}
                             distinctEntitlements={distinctEntitlements}
                             rerenderDependentFields={rerenderDependentFields}
                             initialValuesCache={initialValuesCache}
                             reportEntitlement={this.getEntitlement()}/>;
    }


    getEntitlement(): string {
        return "display_kpi@reports";
    }

    getReportName(): string {
        return "KP1";
    }
}