import React, {useEffect, useState} from "react";
import {
    DropDownSelectionMode,
    ExternalStateUpdateCallback,
    InfoPortalAgGridColumnDef,
    InfoPortalColumnDef,
    InfoPortalGridService,
    InfoPortalReport,
    InfoPortalSearchProperties,
    IValidator,
    LabelLocation,
    OnChangeListener,
    ReportViewConfig,
    stringToDayjs_yyyy_MM_dd, utcDateToFormatTo_yyyy_MM_dd_string,
    Validator
} from "../../../InfoPortalInterfaces";
import {Card, FormInstance, Typography} from "antd";
import {RuleSet} from "../../../../e-cap/util/RuleResultCache";
import {IntlShape} from "react-intl/src/types";
import {retrieveLY7Report} from "../../../services/ReportSearch";
import styles from "../../styling/infoportal.module.css";
import {CompanySelection, descriptionFilter, PartnerSelection, StatusSelection} from "../../InfoPortalDropdowns";
import {PurchaseOrderNumber} from "../../InputWithOperator";
import {PostingDateOfDeliveryFromToSelection} from "../../FromToDateSelection";
import {PortalUser} from "../../../../../components";
import {
    convertToUserSpecifiedNumericFormat, dateComparatorUsingUserPreferences,
    getDateFormatOrDefault, numericComparatorUsingUserPreferences
} from "../../../../users/UserFormatPreferenceServices";
import {
    filterParamAmountFormattedAsStringUsingPreferences,
    filterParamWithUserDateFormatComparator
} from "../../grid/GridUtil";
import Keybinding from '../../../../../components/Keybinding';



function validatator(form:FormInstance<any>):Validator {
    const validator:IValidator = {
        fetchRule(fieldName: string): RuleSet {
            const result = {required: false, picture: '', readOnly: false, hidden: false, formatMessage:null, infoMessage:null};
            if (fieldName === "company") {
                result.required = true;
            }
            if (fieldName === "partner") {
                result.required = true;
            }
            if (fieldName === "status") {
                result.required = true;
            }

            return result;
        }
    };
    return new Validator(validator);
}

function FreightSimpleSearch({currentUser, intl, distinctEntitlements, reportEntitlement,initialization, form, initialValuesCache,rerenderDependentFields}: InfoPortalSearchProperties) {


    const [vertical, setVertical] = useState<boolean>(false);
    const validator = validatator(form);
    const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

    const { Text } = Typography;

    const companySelectionChange: OnChangeListener<string[]> = {
        performAction(companyIds: string[]) {
            const newVal = form.getFieldValue("partner") || [];
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    };

    useEffect(() => {
        if (rerenderDependentFields) {
            const companyIds = form.getFieldValue("company");
            const newVal = form.getFieldValue("partner");
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    });

    return <Card size={"small"} style={{backgroundColor: "#f1f3f5"}}>
        <div className={styles.selfBilledSimpleSearchContainer} style={{marginLeft: "10px"}}>
            <div className={styles.singleColumnGrid}>
                <CompanySelection
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    currentUser={currentUser}
                    initialValue={initialValuesCache?.getValue("company")}
                    intl={intl}
                    validator={validator}
                    filterOptions={descriptionFilter}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}
                    onSelectionChange={companySelectionChange}
                    companyEntitlement={reportEntitlement}/>
                <PartnerSelection
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    currentUser={currentUser} intl={intl}
                    validator={validator}
                    initialValue={initialValuesCache?.getValue("partner")}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}
                    filterOptions={descriptionFilter}
                    companyIds={initialValuesCache?.getValue("company")}
                    externalStateUpdater={partnerSelectionUpdate}
                    partnerEntitlement={reportEntitlement}/>
                <PurchaseOrderNumber
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialInputValue={initialValuesCache?.getValue("purchaseOrderNumber")}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("purchaseOrderNumberOperator", "CP")}
                    initialization={initialization} form={form} />
            </div>

            <div className={styles.singleColumnGrid}>

                <PostingDateOfDeliveryFromToSelection
                    validator={validator}
                    dateFormatter={getDateFormatOrDefault(currentUser)}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    initialValue={initialValuesCache?.getValue("postingDeliveryDate", stringToDayjs_yyyy_MM_dd)}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />

                <StatusSelection
                    initialValue={initialValuesCache?.getValue("status")}
                    displayColon={true}
                    selectionMode={DropDownSelectionMode.SINGLE}
                    currentUser={currentUser}
                    intl={intl}
                    validator={validator}
                    reportName={"ly7"}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />

            </div>
        </div>

        <div style={{marginLeft: "10px"}}>
            <div style={{marginTop:"30px"}}>
                <ul>
                    <li><Text>{intl.formatMessage({id:'ly7-extra-info-1'})}</Text></li>
                    <li><Text>{intl.formatMessage({id:'ly7-extra-info-2'})}</Text></li>
                    <li><Text>{intl.formatMessage({id:'ly7-extra-info-3'})}</Text></li>
                </ul>
            </div>
            <div>&nbsp;</div>
        </div>
        <Keybinding target={this}
                    onKey={ (e) => { if(e.key === 'Enter'){form.submit()} }} type='keyup'/>
    </Card>;
}

export class LY7AgGridColumnDef implements InfoPortalAgGridColumnDef {
    getLineItemColumnDefinitions(intl: IntlShape, user:PortalUser): InfoPortalColumnDef[] {
        return [];
    }

    getDefaultColumnDefinitions(intl: IntlShape, user:PortalUser,distinctEntitlements:string[]): InfoPortalColumnDef[] {
        return [
            {field: "compcode", headerName: intl.formatMessage({"id": "grid-heading-company"})},
            {field: "partnerno", headerName: intl.formatMessage({"id": "grid-heading-partnerNumber"})},
            {field: "ponumber", headerName: `${intl.formatMessage({"id": "grid-heading-purchaseOrderNumber"})} *`},
            { field: "itemstatus", headerName: intl.formatMessage({ "id": "grid-heading-status" }) },
            {field: "fsdnumber", headerName: intl.formatMessage({"id": "fsd-number"})},
            {field: "grnumber", headerName: intl.formatMessage({"id": "grid-heading-grscDocumentNumber"})},
            {
                field: "deliverypostdate",
                headerName: intl.formatMessage({"id": "info-portal-posting-delivery-date-from"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.deliverypostdate, getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {
                field: "netamount",
                headerName: `${intl.formatMessage({"id": "total-netamount-of-grsc"})}`,
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.netamount),
                filter: "agTextColumnFilter",
                filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {field: "currency", headerName: `${intl.formatMessage({"id": "grid-heading-currency"})} *`},
            {field: "extinvoice", headerName: intl.formatMessage({"id": "grid-heading-partnerInvoiceNumber"})},
            {field: "bmwdocno", headerName: intl.formatMessage({"id": "grid-heading-bmwInvoiceNumber"})},
            {
                field: "documentdate",
                headerName: intl.formatMessage({"id": "grid-heading-documentDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.documentdate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
        ]
    }

    getShortViewColumns(): string[] {
        return [
            'compcode',
            'partnerno',
            'ponumber',
            'itemstatus',
            'fsdnumber',
            'grnumber',
            'deliverypostdate',
            'netamount',
            'currency'
        ]
    }
}

export class LY7Report implements InfoPortalReport {
    private _config = new LY7AgGridColumnDef();

    getColumnsDefinition(): InfoPortalAgGridColumnDef {
        return this._config;
    }

    performSearch(form:FormInstance,currentUser:any, distinctEntitlements:string[]):Promise<InfoPortalGridService> {
        return retrieveLY7Report(form).then(result=>
            new InfoPortalGridService(
                new ReportViewConfig('LY7',this._config), result, distinctEntitlements));
    }

    hasExpertSearch(): boolean {
        return false;
    }

    renderExpertSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache
                       }: InfoPortalSearchProperties): JSX.Element {
        return null;
    }

    renderSimpleSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element {
        return <FreightSimpleSearch initialization={initialization}
                                     currentUser={currentUser}
                                     intl={intl}
                                     form={form}
                                     distinctEntitlements={distinctEntitlements}
                                     rerenderDependentFields={rerenderDependentFields}
                                     initialValuesCache={initialValuesCache} reportEntitlement={this.getEntitlement()}/>;
    }

    getEntitlement(): string {
        return "display_ly@reports";
    }

    getReportName(): string {
        return "LY7";
    }
}