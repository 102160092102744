import React, {useEffect, useState} from "react";
import {
  DropDownSelectionMode,
  ExternalStateUpdateCallback,
  InfoPortalAgGridColumnDef,
  InfoPortalColumnDef,
  InfoPortalGridService,
  InfoPortalReport,
  InfoPortalSearchProperties,
  IValidator,
  LabelLocation,
  OnChangeListener,
  ReportViewConfig,
  stringToDayjs_yyyy_MM_dd, timestampComparator, utcDateToFormatTo_HHmm_string,
  utcDateToFormatTo_yyyy_MM_dd_string,
  Validator
} from "../../../InfoPortalInterfaces";
import {BMWInvoiceNumber} from "../../InputWithOperator";
import {Card, FormInstance} from "antd";
import {CompanySelection, descriptionFilter, PartnerSelection} from "../../InfoPortalDropdowns";
import styles from "../../styling/infoportal.module.css";
import {RejectionDateFromToSelection} from "../../FromToDateSelection";
import {RuleSet} from "../../../../e-cap/util/RuleResultCache";
import {IntlShape} from "react-intl/src/types";
import {retrieveDownloadedRejectionLetters} from "../../../services/ReportSearch";
import {PortalUser} from "../../../../../components";
import {
  dateComparatorUsingUserPreferences,
  getDateFormatOrDefault
} from "../../../../users/UserFormatPreferenceServices";
import Keybinding from '../../../../../components/Keybinding';

function validatator(form: FormInstance<any>): Validator {
  const validator: IValidator = {
    fetchRule(fieldName: string): RuleSet {
      const result = {
        required: false,
        picture: "",
        readOnly: false,
        hidden: false,
        formatMessage: null,
        infoMessage: null
      };
      if (fieldName === "company") {
        result.required = true;
      }
      if (fieldName === "partner") {
        result.required = true;
      }
      return result;
    }
  };
  return new Validator(validator);
}

function SimpleSearch({
                                     currentUser,
                                     intl,
                                     reportEntitlement,
                                     distinctEntitlements,
                                     initialization,
                                     form,
                                     initialValuesCache,
                                     rerenderDependentFields
                                   }: InfoPortalSearchProperties) {
  const [vertical, _] = useState<boolean>(false);
  const validator = validatator(form);
  const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

  const companySelectionChange: OnChangeListener<string[]> = {
    performAction(val: string[]) {
      const newVal = form.getFieldValue("partner") || [];
      partnerSelectionUpdate.invokeCallBack({ companyIds: val, newVal });
    }
  };

  useEffect(() => {
    if (rerenderDependentFields) {
      const companyIds = form.getFieldValue("company");
      const newVal = form.getFieldValue("partner");
      partnerSelectionUpdate.invokeCallBack({ companyIds, newVal });
    }
  });

  return <Card size={"small"} style={{ backgroundColor: "#f1f3f5" }}>
    <div className={styles.selfBilledSimpleSearchContainer}>
      <div className={styles.singleColumnGrid}>
        <CompanySelection
          selectionMode={DropDownSelectionMode.MULTIPLE}
          currentUser={currentUser}
          initialValue={initialValuesCache?.getValue("company")}
          intl={intl}
          validator={validator}
          distinctEntitlements={distinctEntitlements}
          filterOptions={descriptionFilter}
          initialization={initialization} form={form}
          onSelectionChange={companySelectionChange}  companyEntitlement={reportEntitlement}/>
        <PartnerSelection
          selectionMode={DropDownSelectionMode.MULTIPLE}
          currentUser={currentUser} intl={intl}
          partnerEntitlement={reportEntitlement}
          validator={validator}
          initialValue={initialValuesCache?.getValue("partner")}
          distinctEntitlements={distinctEntitlements}
          initialization={initialization} form={form}
          filterOptions={descriptionFilter}
          companyIds={initialValuesCache?.getValue("company")}
          externalStateUpdater={partnerSelectionUpdate} />
      </div>

      <div className={styles.singleColumnGrid}>
        <BMWInvoiceNumber
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            initialInputValue={initialValuesCache?.getValue("bmwInvoiceNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("bmwInvoiceNumberOperator", "CP")}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            operatorFilterPredicate={(dropDownValue)=>dropDownValue.id === 'CP'}
            initialization={initialization} form={form}/>

        <RejectionDateFromToSelection
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            dateFormatter={getDateFormatOrDefault(currentUser)}
            initialValue={initialValuesCache?.getValue("rejectionDate", stringToDayjs_yyyy_MM_dd)}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form}/>
      </div>
    </div>
    <Keybinding target={this}
                onKey={ (e) => { if(e.key === 'Enter'){form.submit()} }} type='keyup'/>
  </Card>;
}

export class RejectionLetterAgGridColumnDef implements InfoPortalAgGridColumnDef {
  getLineItemColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
    return [];
  }

  getDefaultColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
    return [
      { field: "companyCodeName", headerName: intl.formatMessage({ "id": "grid-heading-company" }) },
      { field: "partnerNo", headerName: intl.formatMessage({ "id": "grid-heading-partnerNumber" }) },
      { field: "documentNumber", headerName: intl.formatMessage({ "id": "grid-heading-bmwInvoiceNumber" }) },
      { field: "partnerInvoice",
        hide:true,
        headerName: intl.formatMessage({ "id": "partner-invoice-number" })
      },
      {
        field: "insertDate",
        headerName: intl.formatMessage({ "id": "grid-heading-rejectionDate" }) ,
        valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.insertDate,getDateFormatOrDefault(user)),
        comparator:dateComparatorUsingUserPreferences(user)
      },
      {
        field: "username",
        headerName: intl.formatMessage({ "id": "survey-management-grid-header-userName" }),
        valueFormatter: params => `${params.data.firstname} ${params.data.lastname}`
      },
      {
        field: "downloadDate",
        headerName: intl.formatMessage({ "id": "grid-heading-rejectionLetterDownloadDate" }),
        valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.downloadDate,getDateFormatOrDefault(user)),
        comparator:dateComparatorUsingUserPreferences(user)
      },
      {
        field: "downloadTime",
        headerName: intl.formatMessage({ "id": "grid-heading-rejectionLetterDownloadTime" }),
        valueFormatter: params => utcDateToFormatTo_HHmm_string(params.data.downloadDate),
        comparator:timestampComparator()
      },
    ];
  }

  getShortViewColumns(): string[] {
    return [];
  }
}

export class RejectionLetterTrackingReport implements InfoPortalReport {
  private _config = new RejectionLetterAgGridColumnDef();

  getColumnsDefinition(): InfoPortalAgGridColumnDef {
    return this._config;
  }

  performSearch(form: FormInstance, currentUser: any, distinctEntitlements: string[]): Promise<InfoPortalGridService> {
    return retrieveDownloadedRejectionLetters(form).then(result =>
        new InfoPortalGridService(
            new ReportViewConfig("RL1", this._config), result, distinctEntitlements));
  }

  hasExpertSearch(): boolean {
    return false;
  }

  renderExpertSearch({
                       currentUser,
                       intl,
                       distinctEntitlements,
                       initialization,
                       form,
                       initialValuesCache
                     }: InfoPortalSearchProperties): JSX.Element {
    return undefined;
  }

  renderSimpleSearch({
                       currentUser,
                       intl,
                       distinctEntitlements,
                       initialization,
                       form,
                       initialValuesCache,
                       rerenderDependentFields
                     }: InfoPortalSearchProperties): JSX.Element {
    return <SimpleSearch initialization={initialization}
                                      currentUser={currentUser}
                                      intl={intl}
                                      form={form}
                                      distinctEntitlements={distinctEntitlements}
                                      rerenderDependentFields={rerenderDependentFields}
                                      initialValuesCache={initialValuesCache}
                                      reportEntitlement={this.getEntitlement()}/>;
  }


  getEntitlement(): string {
    return "display_sr@reports";
  }

  getReportName(): string {
    return "RL1";
  }
}