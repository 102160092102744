import { FormInstance } from "antd/lib/form/hooks/useForm";
import { getDocumentRecord, getDocumentRecord_getDocumentRecord_document, getDocumentRecord_getDocumentRecord_documentTemplate } from "./__generated__/getDocumentRecord";
import { omitDeep, readNestedFormValue } from "../../utils/Utils";
import { DocumentRecordInput } from "../../../__generated__/globalTypes";
import { IStoredDocumentRecord } from "./IStoredDocumentRecord";
import {CompanyCodeInfo} from "./bmw-company/BMWCompanyHeader";

const extractAndFormatDate = (form: FormInstance, dateField: string, dateFormat?: string) => {
  const nestedDate = readNestedFormValue(form, dateField);
  if (nestedDate) {
    if (dateFormat) {
      return nestedDate.format(dateFormat);
    }

    return `${nestedDate}`;
  }

  return null;
};


export const buildDocument = (values, form: FormInstance, documentRecord: getDocumentRecord, document: IStoredDocumentRecord, templateMode: boolean, systemCode) => {
  console.log("document state : ", document);
  console.dir(form.getFieldsValue());
  const formDocument: getDocumentRecord_getDocumentRecord_document = omitDeep(values.document, (_, prop) =>
    ["__typename"].includes(prop)
  );
  const formShippingInformation = omitDeep(values.shippingInformation, (_, prop) =>
    ["__typename"].includes(prop)
  );
  const formSupplierDetails = omitDeep(values.supplierDetails, (_, prop) =>
    [
      "__typename",
      "supplierName",
      "supplierStreet1",
      "supplierPostalCode",
      "supplierCountry",
      "supplierCity",
      "supplierRegionCode"
    ].includes(prop)
  );
  //I have no idea why assigning number to number results in string, so i had to hack it to number
  formDocument.bmwBusinessAreaId = Number(document.bmwBusinessAreaId);
  formDocument.bmwBusinessUnitId = Number(document.bmwBusinessUnitId);
  formDocument.documentTypeId = Number(document.documentType);

  if (document.legalFormTypeId !== undefined) {
    formSupplierDetails.legalFormTypeId = Number(document.legalFormTypeId);
  }

  formSupplierDetails.fiscalRepresentativeVatId = formSupplierDetails.fiscalRepresentativeVatId;

  formDocument.documentDate = extractAndFormatDate(form, "document.documentDate", "YYYY-MM-DD");
  formDocument.deliveryDate = extractAndFormatDate(form, "document.deliveryDate", "YYYY-MM-DD");
  formDocument.exchangeRateDate = extractAndFormatDate(form, "document.exchangeRateDate", "YYYY-MM-DD");

  const newDocumentRecord: DocumentRecordInput = {
    document: { ...formDocument },
    shippingInformation: { ...formShippingInformation },
    supplierDetails: { ...formSupplierDetails }
  };

  //get values from state
  newDocumentRecord.document.companyPartnerId = document.companyPartnerId;
  newDocumentRecord.document.companyVatCodeId = document.companyVatCodeId;
  newDocumentRecord.document.partnerVatCodeId = document.partnerVatCodeId;
  newDocumentRecord.document.companyCode = document.companyCode;
  newDocumentRecord.document.bmwGroupPaymentRequest = document.bmwGroupPaymentRequest;
  newDocumentRecord.document.bmwIsRetailOutlet = document.bmwIsRetailOutlet;
  newDocumentRecord.lineItems = omitDeep(document.lineItems, (_, prop) => ["__typename"].includes(prop));
  //VAT/TAX refactor
  newDocumentRecord.document.bmwCompanyCity = document.bmwCompanyCity;
  newDocumentRecord.document.bmwCompanyCountryCode = document.bmwCompanyCountryCode;
  newDocumentRecord.document.bmwCompanyPostalCode = document.bmwCompanyPostalCode;
  newDocumentRecord.document.bmwCompanyStreet1 = document.bmwCompanyStreet1;
  newDocumentRecord.document.bmwCompanyStreet2 = document.bmwCompanyStreet2;
  newDocumentRecord.document.bmwCompanyTaxId = document.bmwCompanyTaxId;
  newDocumentRecord.document.isOrderNumberNotRequired = document.isOrderNumberNotRequired;

  newDocumentRecord.supplierDetails.partnerName = document.partnerName;
  newDocumentRecord.supplierDetails.partnerNo = document.partnerNo;
  
  newDocumentRecord.supplierDetails.partnerTaxId = document.partnerTaxId;
  newDocumentRecord.supplierDetails.partnerVatCode = document.partnerVatCode;
  newDocumentRecord.supplierDetails.partnerVatCountryCode = document.partnerVatCountryCode;
  newDocumentRecord.supplierDetails.city = document.partnerAddressCity;
  newDocumentRecord.supplierDetails.countryCode = document.supplierCountryCode;
  newDocumentRecord.supplierDetails.postalCode = document.partnerAddressPostalCode;
  newDocumentRecord.supplierDetails.regionCode = document.partnerAddressRegionCode;
  newDocumentRecord.supplierDetails.street1 = document.partnerAddressStreet1;
  newDocumentRecord.supplierDetails.street2 = document.partnerAddressStreet2;
  newDocumentRecord.supplierDetails.companyCode = document.companyCode;
  newDocumentRecord.supplierDetails.companyName = document.companyName;
  newDocumentRecord.supplierDetails.companyVatCode = document.companyVatCode;
  newDocumentRecord.supplierDetails.companyVatCountryCode = document.companyVatCountryCode;
  //For now I default system_code as I don't know where we going to get the actual system code from
  newDocumentRecord.supplierDetails.systemCode = systemCode;
  //
  //this should be present on an existing record (edit mode)
  newDocumentRecord.document.id = document.documentId;
  newDocumentRecord.document.documentStatusId = documentRecord?.getDocumentRecord.document.documentStatusId;
  newDocumentRecord.shippingInformation.ecDocumentShippingInfoId =
    documentRecord?.getDocumentRecord.shippingInformation.ecDocumentShippingInfoId;
  newDocumentRecord.supplierDetails.documentPartnerProfileId =
    documentRecord?.getDocumentRecord.supplierDetails.documentPartnerProfileId;
  //Set values from state
  newDocumentRecord.shippingInformation.shipFromCountryCode = document.shipFromCountryCode;
  newDocumentRecord.shippingInformation.shipToCountryCode = document.shipToCountryCode;
  newDocumentRecord.supplierDetails.fiscalRepresentativeAddressCountryCode = document.fiscalCountryCode;
  newDocumentRecord.shippingInformation.shipFromRegionCode = values.shippingInformation?.shipFromRegionCode;
  newDocumentRecord.shippingInformation.shipToRegionCode = values.shippingInformation?.shipToRegionCode;
  newDocumentRecord.shippingInformation.shipToStreet = values.shippingInformation?.shipToStreet;
  newDocumentRecord.shippingInformation.shipFromStreet = values.shippingInformation?.shipFromStreet;
  newDocumentRecord.shippingInformation.shipFromPostalCode = values.shippingInformation?.shipFromPostalCode;
  newDocumentRecord.shippingInformation.shipToPostalCode = values.shippingInformation?.shipToPostalCode;
  newDocumentRecord.shippingInformation.shipFromName = values.shippingInformation?.shipFromName;
  newDocumentRecord.shippingInformation.shipToName = values.shippingInformation?.shipToName;
  newDocumentRecord.supplierDetails.fiscalRepresentativeAddressRegionCode = document.fiscalRegionCode;
  newDocumentRecord.supplierDetails.hasFiscalRepresentative = document.fiscalRepresentativeChecked;
  newDocumentRecord.supplierDetails.inLiquidation = document.inLiquidationChecked;
  //
  const appendZ = (date: string) => (date ? `${date}Z` : date);
  newDocumentRecord.document.earlyPaymentDiscountDate = appendZ(
    extractAndFormatDate(form, "document.earlyPaymentDiscountDate", "YYYY-MM-DDTHH:mm:ss.SSS")
  );
  newDocumentRecord.document.earlyPaymentDiscountDueDate = appendZ(
    extractAndFormatDate(form, "document.earlyPaymentDiscountDueDate", "YYYY-MM-DDTHH:mm:ss.SSS")
  );


  if (templateMode)
  {
    console.log("Saving document record as template");
    newDocumentRecord.document.isTemplateDocument = true;
    console.log(`Document template Id: ${documentRecord?.getDocumentRecord?.documentTemplate?.id}`);
    const documentTemplate: getDocumentRecord_getDocumentRecord_documentTemplate= form.getFieldValue("documentTemplate");
    documentTemplate.id = documentRecord?.getDocumentRecord?.documentTemplate?.id;
    console.dir(documentTemplate);
    newDocumentRecord.documentTemplate = documentTemplate;
  }

  console.log("Document record to save");
  console.dir(newDocumentRecord);


  //format the output....
  newDocumentRecord.document.documentNumber =
      newDocumentRecord.document.documentNumber?.replaceAll(/[\t\r\n]/g,' ').trim();
  return newDocumentRecord;
};

export const initFields = (form: FormInstance,
                           listOfCompanyCodes: CompanyCodeInfo[],
                           documentRecord: getDocumentRecord) => {

  if (documentRecord) {
    if (listOfCompanyCodes.length === 1) {
      form.setFieldsValue({
        companyCode: listOfCompanyCodes
          .at(0)
          .companyCode.concat(" - ".concat(listOfCompanyCodes.at(0).companyName))
      });
    }

    //Set BMW contact details
    form.setFieldsValue({
      companyContactName: documentRecord?.getDocumentRecord?.document?.bmwContactName
    });
    form.setFieldsValue({
      companyContactDepartment: documentRecord?.getDocumentRecord?.document?.bmwContactDepartment
    });
    form.setFieldsValue({
      companyContactTelephone: documentRecord?.getDocumentRecord?.document?.bmwContactTelephone
    });
    form.setFieldsValue({
      companyContactEmail: documentRecord?.getDocumentRecord?.document?.bmwContactEmail
    });
    //
  }
};