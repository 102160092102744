import React, {useState } from "react";
import { Input, Form, Modal, Row, Button, Alert, Card, DatePicker, Spin, message } from "antd";
import { useIntl } from "react-intl";
import { getI18n } from "../../../utils/Utils";
import { editNotificationData } from "../NotificationService";
import dayjs from 'dayjs';

type EditNotificationModalProps = {
    isModalVisible: any;
    handleCancel: any;
    notificationValues: any;
    afterClose: any;
}

type FormData = {
    notificationId: number,
    activeFromDate: string,
    activeToDate: string,
    entitlements: string[],
    linkText: string,
    linkAddress: string,
}

const EditNotificationModal: React.FunctionComponent<EditNotificationModalProps> = (props) => {
    const [form] = Form.useForm()
    const intl = useIntl();
    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData>(props.notificationValues);
    const [alertMessage, setAlertMessage] = useState<string>("Please enter all required fields");
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const validateForm = () => {
        if ((formData === null || formData === undefined) || formData?.activeFromDate === undefined  || formData?.activeToDate === "" ||
       formData?.entitlements === undefined || formData?.linkText === "") { 
          setShowAlert(true)  
          return true
      }
  }

  const handleChange = (event) => {
    setFormData(prevFormData => {
        return { ...prevFormData, [event.target.id]: event.target.value }
    })
}

const handleSelectChange = (val) => {
    setFormData(prevFormData => {
        return { ...prevFormData, "messageType": val }
    })
}
const handleActiveFromDateChange = (val) => {

    setFormData(prevFormData => {
        return { ...prevFormData, "activeFromDate": val?.format('YYYY-MM-DD HH:mmZ') }
    })

}

const handleActiveToDateChange = (val) => {

    setFormData(prevFormData => {
        return { ...prevFormData, "activeToDate": val?.format('YYYY-MM-DD HH:mmZ') }
    })
}

const handleSubmit = () => {
    const formDataWithNotificationId = formData;
    formDataWithNotificationId.notificationId = props.notificationValues.notificationId;       
        Promise.resolve(editNotificationData(formDataWithNotificationId))
            .then(() => {
                message.success(getI18n(
                    "notification-EditDataSuccess",
                    "Notification Edit Successfully", intl
                ));
                form.resetFields();
                props.afterClose();
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                message.error(getI18n(
                    "notification-EditDataFailure",
                    "Notification Edit Failed", intl
                ));
                form.resetFields();
                props.handleCancel();
                setLoading(false);
            });
    setFormData(null);
    form.resetFields();
    props.handleCancel();
}

const handleCancelInModal = () => {
    setFormData(null);
    setShowAlert(false)
    props.handleCancel();
     }
    
    return (
        <>
        <Spin spinning={loading} size="large" />
            <Modal
                title={getI18n("edit-notification-modal-title-edit-notification", "Edit Notification", intl)}
                afterClose={props.afterClose}
                onCancel={() => handleCancelInModal()}
                open={props.isModalVisible}
                width={'900px'}
                mask={true}
                footer={[
                    <Button key="back"
                        onClick={handleCancelInModal}
                    >
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary"
                        onClick={handleSubmit}
                    >Submit
                    </Button>
                ]}
            >
                <Form size={"middle"} layout={"vertical"} form={form} >
                    <Row gutter={24} style={{ alignContent: "flex-end", marginTop: "50" }} />
                    <Card
                        title={getI18n("edit-notification-modal-title-edit-notification-details", "Edit Notification Details", intl) }>
                        <Row gutter={24}>
                            <Form.Item
                                name={"activeFromDate"}
                                key={"activeFromDate"}
                                label={getI18n("notification-management-modal-active-from-date", "Active From Date", intl)}
                                required={true}
                            >
                                <DatePicker
                                    showTime={{ format: 'HH:mm' }}
                                    format={"YYYY-MM-DD HH:mm"}
                                    placeholder={getI18n("notification-management-modal-active-from-date", "Active From Date", intl)}
                                    allowClear={true}
                                    data-testid={"notificationActiveFromDate"}
                                    id={"notificationActiveFromDate"}
                                    // style={{ width: "30%", marginLeft: 40 }}
                                    onChange={handleActiveFromDateChange}
                                    defaultValue={dayjs(props.notificationValues?.activeFromDate)}
                                />
                            </Form.Item>
                            <Form.Item
                                name={"activeToDate"}
                                key={"activeToDate"}
                                label={getI18n("notification-management-modal-active-to-date", "Active To Date", intl)}
                                required={true}
                            >
                                <DatePicker
                                    showTime={{ format: 'HH:mm' }}
                                    format={"YYYY-MM-DD HH:mm"}
                                    placeholder={getI18n("notification-management-modal-active-to-date", "Active To Date", intl)}
                                    allowClear={true}
                                    data-testid={"notificationActiveToDate"}
                                    id={"notificationActiveToDate"}
                                    onChange={handleActiveToDateChange}
                                    defaultValue={dayjs(props.notificationValues?.activeToDate)}
                                />
                            </Form.Item>
                        </Row>
                    </Card>
                    <Card title={getI18n("banner-management-modal-edit-banner-message", "Link Text", intl)}>
                        <Row>
                            <Form.Item
                                name={"linkText"}
                                key={"linkText"}
                                label={getI18n("banner-management-add-modal-label-banner-heading-english", "Link Text", intl)}
                            >
                                <Input
                                    placeholder={getI18n("banner-management-modal-banner-banner-heading-english", "Link Text", intl)}
                                    id={'linkText'}
                                    onChange={handleChange}
                                    style={{ width: 300, height: 40 }} allowClear={true} bordered={true}
                                    defaultValue={props.notificationValues?.linkText}
                                />
                            </Form.Item>
                            <Form.Item
                                name={"linkAddress"}
                                key={"linkAddress"}
                                label={getI18n("banner-management-add-modal-label-banner-heading-german", "Link Address", intl)}
                            >
                                <Input
                                    placeholder={getI18n("banner-management-modal-banner-banner-heading-german", "Link Address", intl)}
                                    id={'linkAddress'}
                                    onChange={handleChange}
                                    style={{ width: 300, height: 40 }} allowClear={true}
                                    defaultValue={props.notificationValues?.linkAddress}
                                />
                            </Form.Item>
                        </Row>
                    </Card>
                </Form>
                {showAlert && <Alert message={alertMessage} type={"error"} showIcon={true} closable={true} />}

            </Modal >
        </>
    )
}

export default EditNotificationModal;