import { IAppComponentProps } from "../../components";
import React, { useEffect, useState } from "react";
import { Button, Col, Collapse, DatePicker, Form, Modal, Row, Select, Tooltip } from "antd";
import {
  PortalFormItem
} from "../../components/FormUtils";
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import styles from '../info-portal/components/styling/infoportal.module.css';
import { ProcessCode } from "../../components/ProcessCode";
import { Format } from "../../components/Format";
import { DocumentType } from "../../components/DocumentTypes";
import { FsmDocumentType } from "../../components/FsmDocumentTypes";
import { useIntl } from "react-intl";
import {getI18n, getMinDate, isExternalUser} from "../../utils/Utils";
import { useLocation } from "react-router-dom";
import {filteredEntitlementLookupForSelf} from "../../main/entitlementService";
import { getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners } from "../../main/__generated__/getSelfEntitlementResultByFilter";
import { useAppDispatch, useAppSelector  } from "../../main/hooks";
import { useLazyQuery } from "@apollo/client";

import {DOCUMENT_USER_ENTITLEMENT_RESULT_BY_USER_ID} from "../../main/queries";

import _ from "lodash";
import dayjs from "dayjs";
import {getDateFormatOrDefault} from "../users/UserFormatPreferenceServices";
import {updateDocumentOverviewFilters} from "../e-cap/IStoredDocumentOverviewSlice";
import {DocumentOverviewBmwCompanyCode} from "../../components/DocumentOverviewCompanyCode";
import {
  getDocumentUserEntitlementResultByUserId
} from "../../main/__generated__/getDocumentUserEntitlementResultByUserId";

import documentOverviewStyles from './styling/document.module.css';

type MyListDocumentsSearchFormProps = {
  onSearch: any;
  onDisplay: any;
  isFsm: boolean;
  gridRef: any;
} & IAppComponentProps;
const Overview_Entitlement = "overview@documents";
const Fsm_Report_Entitlement  = "fsm_overview@documents"
const MyListDocumentsSearchForm: React.FunctionComponent<MyListDocumentsSearchFormProps> = (props) => {

  const [form] = Form.useForm();
  const location = useLocation();
  const isFromCaptureDocument = location.state && location.state.isFromCaptureDocument;
  const { Panel } = Collapse;
  const dispatch = useAppDispatch();
  const [selectOptions, setSelectOption] = useState<getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]>([]);
  const [loading, isLoading] = useState<boolean>(false);
  const [selectedDate, handleDateChange] = useState<Date | null | undefined>(null);
  const intl = useIntl();
  const docOverviewFiltersState = useAppSelector((state) => state.documentOverview);

  const [partnerSelectedValue, setPartnerSelectedValue] = useState(isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.partnerNoList : null);

  const [documentDateFromSelectedValue, setDocumentDateFromSelectedValue] = useState(isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.documentDateFrom: null);
  const [documentDateToSelectedValue, setDocumentDateToSelectedValue] = useState(isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.documentDateTo: null);
  const [documentNumberSelectedValue, setDocumentNumberSelectedValue] = useState(isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.documentNumbers: null);
  const [attachmentSelectedValue, setAttachmentSelectedValue] = useState(isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.attachment: null);

  const removeDuplicated = (data: getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]) => Array.from(
      data
          .reduce(
              (acc, item) => (
                  item && item["partnerId"] && acc.set(item["partnerId"], item),
                      acc
              ),
              new Map()
          )
          .values());

  const searchPartner = (partnerName) => {
    Promise.resolve(!props.isFsm ? filteredEntitlementLookupForSelf(Overview_Entitlement, partnerName, 1, 200) :
        filteredEntitlementLookupForSelf(Fsm_Report_Entitlement, partnerName, 1, 200))
        // externalPartnersLookupByEntitlement(Fsm_Report_Entitlement, partnerName, 1, 50))
        .then((response) => {
          const searchOption = removeDuplicated(response.getSelfEntitlementResultByFilter.partners);
          setSelectOption(searchOption);
          isLoading(false);
        })
        .catch((err) => {
          console.error(err);
          isLoading(false);
        });
  };

  const findSelectedPartner = (partnerId) => {
    const partnerToBeReturned = selectOptions.filter((item) => {
      if (item.partnerNo === partnerId) {
        return item;
      }
    });

    return partnerToBeReturned ||
        {
          __typename: "EntitlementPartner",
          partnerId: undefined,
          partnerInDB: undefined,
          partnerName: undefined,
          partnerNo: undefined,
          partnerOu: undefined,
          entitlements: [],
          companies: [],
          category: undefined
        }
  };

  const handleSearch = _.debounce(function (value: string) {
    isLoading(true);
    searchPartner(value);
  }, 1000)

  const [getUserEntitlement, {}] = useLazyQuery<getDocumentUserEntitlementResultByUserId>(
      DOCUMENT_USER_ENTITLEMENT_RESULT_BY_USER_ID,
      {
        variables: {
          userId: props.currentUser?.uid,
          entitlement: Overview_Entitlement,
          partnerName: String,
          authentication: localStorage.getItem('id_token'),
        },
        fetchPolicy: 'network-only',
      },
  );

  const checkPartnerEntitlement = () => {
    if (isExternalUser(props.distinctEntitlements)) {
      isLoading(true);
      getUserEntitlement().then(response => {
        const userEntitlement = response.data?.getDocumentUserEntitlementResultByUserId;
        if (userEntitlement?.partners?.length === 1) {
          form.setFieldsValue({ partnerNoList: [userEntitlement.partners[0]?.partnerNo]});
        }
        if (userEntitlement?.partners?.length >= 1) {
          setSelectOption(userEntitlement?.partners);
        }
      }).finally(()=> {
        isLoading(false);
      });
    }
  };


  useEffect(() => {
    if(props.currentUser?.uid) {
      checkPartnerEntitlement();
    }
  }, []);

  const onSearch = () => {
    props.onSearch(form.getFieldsValue());
    dispatch(updateDocumentOverviewFilters((form.getFieldsValue())));
  };


  useEffect(() => {
    if (isFromCaptureDocument && (docOverviewFiltersState.documentOverviewFilters.partnerNoList?.length)) {

      const partnerNoList = [...(docOverviewFiltersState.documentOverviewFilters.partnerNoList || [])];

      Promise.all(partnerNoList.map(partnerNo=> {
        if (!props.isFsm) {
          return filteredEntitlementLookupForSelf(Overview_Entitlement, partnerNo, 1, 200)
        }
        return filteredEntitlementLookupForSelf(Fsm_Report_Entitlement, partnerNo, 1, 200)
      })).then(responses=> {
        const searchOption =
            removeDuplicated(
                responses.filter(item=>item.getSelfEntitlementResultByFilter?.partners?.length)
                    .flatMap(item=>item.getSelfEntitlementResultByFilter.partners));
        setSelectOption(searchOption);
      });
    }

    form.setFieldsValue({
      partnerNoList: isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.partnerNoList : null,
      documentDateFrom: isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.documentDateFrom : null,
      documentDateTo: isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.documentDateTo : null,
      documentNumbers : isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.documentNumbers : null,
      externalDocumentNumbers : isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.externalDocumentNumbers : null,
      attachment: isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.attachment : null,
      bmwCompanyIdList: isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.bmwCompanyIdList : null,
      formatId: isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.formatId : null,
      processCodeId: isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.processCodeId : null,
      vinNumber: isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.vinNumber : null,
    });
  }, [form, isFromCaptureDocument]);

  const onClear = () => {
    Modal.confirm({
      title: intl.formatMessage({
        id: "e-cap-draft",
        defaultMessage: "Reset Input Data"
      }),
      content: intl.formatMessage({
        id: "e-cap-message",
        defaultMessage: "Input Data will be lost. Do you want to continue?"
      }),
      okText: intl.formatMessage({ id: "e-cap-capture-document-cancel-confirm-modal-yes", defaultMessage: "YES" }),
      cancelText: intl.formatMessage({ id: "e-cap-capture-document-cancel-confirm-modal-no", defaultMessage: "NO" }),
      onOk: () => {
        form.resetFields();
        setDocumentNumberSelectedValue(null);
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const fromDateMinimumDate = ()=> {
    return dayjs().subtract(5, "years");
  };

  const toDateMinimumDate = ()=> {
    const receptionFromDate = form.getFieldValue('receptionDateFrom');
    if (receptionFromDate) {
      return receptionFromDate;
    }

    return dayjs().subtract(5, "years");
  };

  const toDateMaximumDate = ()=> {
    const receptionFromDate = form.getFieldValue('receptionDateFrom');
    if (receptionFromDate) {
      return getMinDate(dayjs(), receptionFromDate.add(3, "months"));
    }

    return dayjs().subtract(5, "years").add(3, "months");
  };

  const handleReceptionDateFrom = (newReceptionDateFrom:dayjs.Dayjs) => {
    if (newReceptionDateFrom) {
      const receptionToDate = form.getFieldValue('receptionDateTo');
      if (receptionToDate) {
        if (newReceptionDateFrom.isBefore(receptionToDate.subtract(3, "months")) ||
            newReceptionDateFrom.isAfter(receptionToDate)) {
          form.setFieldsValue({receptionDateTo: newReceptionDateFrom.clone()});
        }
      }
      else {
        form.setFieldsValue({receptionDateTo: newReceptionDateFrom.clone()});
      }
    }
    else {
      form.setFieldsValue({receptionDateTo: undefined});
    }
  }

  return (
          <Form
              size={'middle'}
              layout={'vertical'}
              form={form}
              onFinish={onSearch}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              initialValues={
                  isFromCaptureDocument
                      ? {
                          receptionDateFrom: dayjs(docOverviewFiltersState.documentOverviewFilters.receptionDateFrom),
                          receptionDateTo: dayjs(docOverviewFiltersState.documentOverviewFilters.receptionDateTo),
                      }
                      : {receptionDateFrom: dayjs(), receptionDateTo: dayjs()}
              }
              className={'mt100'}
          >
              <div className={documentOverviewStyles.documentOverviewFourComponents}>
                  <div>
                      <label>{getI18n('welcome-From Reception Date', 'From Reception Date', intl)}</label>
                      <Form.Item
                          name={'receptionDateFrom'}
                          key={'receptionDateFrom'}
                          rules={[
                              {
                                  required: true,
                                  message: intl.formatMessage({
                                      id: 'e-cap-capture-document-select-reception-date-from',
                                      defaultMessage: 'Please Select Reception Date From',
                                  }),
                              },
                          ]}
                      >
                          <DatePicker
                              defaultValue={
                                  isFromCaptureDocument
                                      ? dayjs(docOverviewFiltersState.documentOverviewFilters.receptionDateFrom)
                                      : dayjs()
                              }
                              className="mb20 mt8"
                              format={getDateFormatOrDefault(props.currentUser)}
                              placeholder={getI18n('welcome-From Reception Date', 'From Reception Date', intl)}
                              style={{width: 350}}
                              allowClear={true}
                              data-testid={'receptionDateFrom'}
                              onKeyDown={(e) => {
                                  e.preventDefault();
                              }}
                              onChange={handleReceptionDateFrom}
                              disabledDate={(current) =>
                                  current.isBefore(fromDateMinimumDate()) || current.isAfter(dayjs())
                              }
                          />
                      </Form.Item>
                  </div>
                  <div>
                      <label>{getI18n('welcome-To Reception Date', 'To Reception Date', intl)}</label>
                      <Form.Item
                          name={'receptionDateTo'}
                          key={'receptionDateTo'}
                          rules={[
                              {
                                  required: true,
                                  message: getI18n(
                                      'e-cap-capture-document-select-reception-date-to',
                                      'Please Select Reception Date To',
                                      intl
                                  ),
                              },
                          ]}
                      >
                          <DatePicker
                              defaultValue={
                                  isFromCaptureDocument
                                      ? dayjs(docOverviewFiltersState.documentOverviewFilters.receptionDateTo)
                                      : dayjs()
                              }
                              className="mb20 mt8"
                              format={getDateFormatOrDefault(props.currentUser)}
                              placeholder={getI18n('welcome-To Reception Date', 'To Reception Date', intl)}
                              style={{width: 350}}
                              allowClear={true}
                              data-testid={'receptionDateTo'}
                              onKeyDown={(e) => {
                                  e.preventDefault();
                              }}
                              disabledDate={(current) =>
                                  current.isAfter(toDateMaximumDate()) || current.isBefore(toDateMinimumDate())
                              }
                          />
                      </Form.Item>
                  </div>

                  <div>
                      <Form.Item label={getI18n('partner-overview', 'Partner', intl)} name="partnerNoList">
                          <Select
                              size={'middle'}
                              showSearch={true}
                              mode={'multiple'}
                              value={partnerSelectedValue}
                              defaultValue={
                                  isFromCaptureDocument
                                      ? docOverviewFiltersState.documentOverviewFilters.partnerNoList
                                      : null
                              }
                              placeholder={getI18n(
                                  'document-overview-search-message',
                                  'Enter partner number or partner name',
                                  intl
                              )}
                              onSearch={handleSearch}
                              onChange={(value) => {
                                  setPartnerSelectedValue(value);
                              }}
                              defaultActiveFirstOption={true}
                              filterOption={false}
                              allowClear={true}
                              notFoundContent={null}
                              loading={loading}
                          >
                              {selectOptions &&
                                  selectOptions.map((item) => (
                                      <Select.Option
                                          key={`${item.partnerId}_${item.partnerNo}`}
                                          value={item.partnerNo}
                                          data-testid={`partnerNo_${item.partnerNo}`}
                                      >
                                          {item.partnerNo} - {item.partnerName}
                                      </Select.Option>
                                  ))}
                          </Select>
                      </Form.Item>
                  </div>
                  <div>
                      {!props.isFsm ? (
                          <DocumentType currentUser={props.currentUser} intl={props.intl} distinctEntitlements={[]}/>
                      ) : (
                          <FsmDocumentType
                              currentUser={props.currentUser}
                              intl={props.intl}
                              distinctEntitlements={[]}
                          />
                      )}
                  </div>
              </div>

              <br/>

              <div className={documentOverviewStyles.documentOverviewFourComponents}>
                  <div>
                      <label>{getI18n('welcome-To-document-from-date', 'From Document Date', intl)}</label>
                      <Form.Item name={'documentDateFrom'} key={'documentDateFrom'}>
                          <DatePicker
                              className="mb20 mt8"
                              value={dayjs(documentDateFromSelectedValue)}
                              defaultValue={
                                  isFromCaptureDocument
                                      ? dayjs(docOverviewFiltersState.documentOverviewFilters.documentDateFrom)
                                      : null
                              }
                              format={getDateFormatOrDefault(props.currentUser)}
                              placeholder={getI18n('welcome-To-document-from-date', 'From Document Date', intl)}
                              style={{width: 350}}
                              allowClear={true}
                              data-testid={'documentDateFrom'}
                              onKeyDown={(e) => {
                                  e.preventDefault();
                              }}
                              disabledDate={(current) => current.isAfter(dayjs().subtract(0, 'day'))}
                              onChange={(newValue) => handleDateChange(newValue?.toDate())}
                          />
                      </Form.Item>
                  </div>
                  <div>
                      <label> {getI18n('welcome-To-document-To-date', 'To Document Date', intl)}</label>
                      <Form.Item name={'documentDateTo'} key={'documentDateTo'}>
                          <DatePicker
                              className="mb20 mt8"
                              value={dayjs(documentDateToSelectedValue)}
                              defaultValue={
                                  isFromCaptureDocument
                                      ? dayjs(docOverviewFiltersState.documentOverviewFilters.documentDateTo)
                                      : null
                              }
                              format={getDateFormatOrDefault(props.currentUser)}
                              placeholder={getI18n('welcome-To-document-To-date', 'To Document Date', intl)}
                              style={{width: 350}}
                              allowClear={true}
                              data-testid={'documentDateTo'}
                              onKeyDown={(e) => {
                                  e.preventDefault();
                              }}
                              disabledDate={(current) => current.isAfter(dayjs().subtract(0, 'day'))}
                          />
                      </Form.Item>
                  </div>
                  <div>
                      <PortalFormItem
                          label={getI18n('welcome-To-document-number', 'Document Numbers', intl)}
                          name={'documentNumbers'}
                          value={documentNumberSelectedValue}
                          onChange={(value) => setDocumentNumberSelectedValue(value)}
                          placeHolder={getI18n(
                              'welcome-To-document-number-placeholder',
                              'Enter Document Numbers, separate then with commas',
                              intl
                          )}
                      />
                  </div>
                  <div>
                      <Form.Item name={'attachment'} label={getI18n('welcome-To-attachment', 'Attachment', intl)}>
                          <Select
                              showSearch={true}
                              allowClear={true}
                              value={attachmentSelectedValue}
                              onChange={(value) => {
                                  setAttachmentSelectedValue(value);
                              }}
                              placeholder={getI18n('welcome-To-attachment-placeholder', 'Attachment Available', intl)}
                          >
                              <Select.Option key={'kYesOption'} value="1">
                                  {getI18n('general-edp-yes', 'YES', intl)}
                              </Select.Option>
                              <Select.Option key={'kNoOption'} value="0">
                                  {getI18n('general-edp-no', 'No', intl)}
                              </Select.Option>
                          </Select>
                      </Form.Item>
                  </div>
                  <div>
                      <PortalFormItem
                          label={getI18n('welcome-To-exsternal-document-number', 'External Document Numbers', intl)}
                          name={'externalDocumentNumbers'}
                          placeHolder={getI18n(
                              'welcome-To-external-document-number-placeholder',
                              'Enter external Document Numbers, separate then with commas',
                              intl
                          )}
                      />
                  </div>
              </div>

              <br/>

              <div style={{width:"102%", marginLeft:"-17px"}}>
                  <Collapse defaultActiveKey={isFromCaptureDocument ? '1' : ''}>
                      <Panel
                          key={1}
                          id="Panel1"
                          header={getI18n('welcome-more-selection', 'More Selections', intl)}
                          style={{marginTop: 'auto'}}
                      >
                          <div className={documentOverviewStyles.documentOverviewFourComponents}>
                              <div>
                                  <DocumentOverviewBmwCompanyCode
                                      currentUser={props.currentUser}
                                      intl={props.intl}
                                      distinctEntitlements={props.distinctEntitlements}
                                      isFsm={props.isFsm}
                                      style={{width:350}}
                                  />
                              </div>
                              <div>
                                  {props.isFsm ? (
                                      <ProcessCode
                                          currentUser={props.currentUser}
                                          intl={props.intl}
                                          distinctEntitlements={props.distinctEntitlements}
                                          select={'FSMPROCESSCODE'}
                                          style={{width:350}}
                                      />
                                  ) : (
                                      <ProcessCode
                                          currentUser={props.currentUser}
                                          intl={props.intl}
                                          distinctEntitlements={props.distinctEntitlements}
                                          select={'PROCESSCODE'}
                                          style={{width:350}}
                                      />
                                  )}
                              </div>
                              <div>
                                  <Format
                                      currentUser={props.currentUser}
                                      intl={props.intl}
                                      distinctEntitlements={props.distinctEntitlements}
                                  />
                              </div>
                              <div>
                                  <PortalFormItem
                                      label={getI18n('welcome-vin-number', 'VIN Number', intl)}
                                      name={'vinNumber'}
                                      placeHolder={getI18n('welcome-vin-number', 'VIN Number', intl)}
                                      colSpan={4}
                                      infoOutlined={getI18n(
                                          'document-overview-vin-number-tooltip',
                                          'When using the VIN number search field, try to search by using the long 17 digit VIN number, and if not successful, also try searching using the last 7 digits of the VIN number.',
                                          intl
                                      )}
                                  />
                              </div>

                          </div>
                      </Panel>
                  </Collapse>
              </div>

                  <div style={{margin: '20px 0px 10px 0px'}}>
                      <Tooltip title={intl.formatMessage({id: 'info-portal-search', defaultMessage: 'Search'})}>
                          <Button
                              style={{marginRight: '10px'}}
                              data-testid="info-portal-search-button"
                              type="primary"
                              htmlType="submit"
                              icon={<SearchOutlined/>}
                              size={'large'}
                          />
                      </Tooltip>

                      <Tooltip title={intl.formatMessage({id: 'do-clear', defaultMessage: 'Clear'})}>
                          <Button
                              data-testid="info-portal-clear"
                              className={styles.simpleFlipRefresh}
                              type="primary"
                              icon={<ReloadOutlined/>}
                              onClick={onClear}
                              size={'large'}
                          />
                      </Tooltip>
                  </div>
          </Form>
);
};

export default MyListDocumentsSearchForm;
