import { Spin, Tabs } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { IAppComponentProps } from '../../../components';
import { getI18n } from '../../../utils/Utils';
import MasterDataEcapTabComponent from './ECAP/Forms/MasterDataEcapTabComponent';
import MasterDataFreightTabComponent from './Freight/MasterDataFreightTabComponent';
import RenderIfEntitled from 'src/components/EntitlementComponents';
import MasterDataInfoPortalTabComponent from './InfoPortal/MasterDataInfoPortalTabComponent';
import MasterDataSurveyTabComponent from './Survey/MasterDataSurveyTabComponent';


const MasterDataMain: React.FunctionComponent<IAppComponentProps> = (props) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    const onLoading = (loading) => {
        setLoading(loading);
    };

    const tabItems = [
        {
            defaultActiveKey: '1',
            className:"users-tabs ml20",
            key: '1',
            label: getI18n('master-data-dashboard-ecap-tab','ECAP',props.intl),
            children:   
                <MasterDataEcapTabComponent
                    system={'ECAP'}
                    intl={intl}
                    currentUser={props.currentUser}
                    distinctEntitlements={props.distinctEntitlements}
                />,
        },
        {
            className:"users-tabs ml20",
            key: '2',
            label: getI18n('master-data-dashboard-info-portal-tab','Info Portal',props.intl),
            children:   
                <MasterDataInfoPortalTabComponent
                    intl={intl}
                    currentUser={props.currentUser}
                    distinctEntitlements={props.distinctEntitlements}
                />,
        },
        {
            className:"users-tabs ml20",
            key: '3',
            label: getI18n('master-data-dashboard-freight-tab','Freight',props.intl),
            children:   
                <MasterDataFreightTabComponent
                    intl={intl}
                    currentUser={props.currentUser}
                    distinctEntitlements={props.distinctEntitlements}
                />,
        },
        {
            className:"users-tabs ml20",
            key: '4',
            label: getI18n('master-data-dashboard-survey-tab','Survey',props.intl),
            children:   
                <MasterDataSurveyTabComponent
                    intl={intl}
                    currentUser={props.currentUser}
                    distinctEntitlements={props.distinctEntitlements}
                />,
        },
        
    ];

    return (
        props?.currentUser ?
            // <RenderIfEntitled
            //     entitlementToCheck={VIEW_ENTITLEMENT}
            //     distinctEntitlements={props.distinctEntitlements}
            //     intl={props.intl}
            // >
            
                <Spin size="large" spinning={loading} >
                    <h1>Master Data Management</h1>
                    <Tabs defaultActiveKey="1" items={tabItems} />
                </Spin>
            // </RenderIfEntitled>
            :
            <></>
    );
};

export default MasterDataMain;
