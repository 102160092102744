import {
  DropDownSelectionMode,
  ExternalStateUpdateCallback,
  InfoPortalSearchProperties, IValidator, LabelLocation,
  OnChangeListener, stringToDayjs_yyyy_MM_dd, Validator
} from "../../../InfoPortalInterfaces";
import React, { useEffect, useRef } from "react";
import { Card, Col, FormInstance, Row } from "antd";
import {
  BusinessAreaListSelection,
  CompanySelection,
  descriptionFilter, InvoiceStatusSelection,
  PartnerSelection, PlantInfoSelection,
  SettlementTypeSelection, StatusSelection
} from "../../InfoPortalDropdowns";
import {
  BMWInvoiceNumber,
  DeliveryNoteNumber, GRSCDocumentNumber,
  PartnerInvoiceNumber,
  PurchaseOrderNumber, SPABOrderNumber
} from "../../InputWithOperator";
import {
  DeliveryNoteFromToSelection, DocumentDateFromToSelection,
  GRSCDateFromToSelection,
  PurchaseOrderFromToSelection
} from "../../FromToDateSelection";
import { RuleSet } from "../../../../e-cap/util/RuleResultCache";
import {getDateFormatOrDefault} from "../../../../users/UserFormatPreferenceServices";
import Keybinding from '../../../../../components/Keybinding';


function sr2ReportExportModeFormValidator(form: FormInstance<any>): Validator {
  const validator: IValidator = {
    fetchRule(fieldName: string): RuleSet {
      const result = {
        required: false,
        picture: "",
        readOnly: false,
        hidden: false,
        formatMessage: null,
        infoMessage: null
      };

      if (fieldName === "keyDate") {
        result.required = true;
      }
      if (fieldName === "company") {
        result.required = true;
      }
      if (fieldName === "partner") {
        result.required = true;
      }
      if (fieldName === "status") {
        result.required = true;
      }
      return result;
    }
  };
  return new Validator(validator);
}


const GoodsReceiptsServiceConfirmationsExpectSearch = ({
                                                         currentUser,
                                                         intl,
                                                         reportEntitlement,
                                                         distinctEntitlements,
                                                         initialization,
                                                         form,
                                                         initialValuesCache,
                                                         rerenderDependentFields
                                                       }: InfoPortalSearchProperties) => {
  const vertical = useRef<boolean>(false);

  const validator = sr2ReportExportModeFormValidator(form);
  const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

  const companySelectionChange: OnChangeListener<string[]> = {
    performAction(companyIds: string[]) {
      const newVal = form.getFieldValue("partner") || [];
      partnerSelectionUpdate.invokeCallBack({ companyIds, newVal });
    }
  };

  const isLoading = useRef<boolean>(true);

  useEffect(() => {
    if (rerenderDependentFields) {
      const companyIds = form.getFieldValue("company");
      const newVal = form.getFieldValue("partner");
      partnerSelectionUpdate.invokeCallBack({ companyIds, newVal });
    }
  });

  return <>

    <Card size={"small"} style={{ backgroundColor: "#f1f3f5" }}>
      <Row gutter={24}>
        <Col span={7}>
          <CompanySelection
            selectionMode={DropDownSelectionMode.MULTIPLE}
            labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={true}
            currentUser={currentUser}
            initialValue={initialValuesCache?.getValue("company")}
            intl={intl}
            validator={validator}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form}
            onSelectionChange={companySelectionChange}
            filterOptions={descriptionFilter}
            companyEntitlement={reportEntitlement}/>
          <PartnerSelection
            selectionMode={DropDownSelectionMode.MULTIPLE}
            currentUser={currentUser} intl={intl}
            initialValue={initialValuesCache?.getValue("partner")}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form}
            validator={validator}
            labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={true}
            companyIds={initialValuesCache?.getValue("company")}
            externalStateUpdater={partnerSelectionUpdate}
            filterOptions={descriptionFilter}
            partnerEntitlement={reportEntitlement}/>

          <PartnerInvoiceNumber
            currentUser={currentUser}
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            initialInputValue={initialValuesCache?.getValue("partnerInvoiceNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("partnerInvoiceOperator", "CP")}
            intl={intl}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization}
            form={form} />
          <SettlementTypeSelection
            currentUser={currentUser}
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            initialValue={initialValuesCache?.getValue("settlementType")}
            intl={intl}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization}
            form={form} />
          <DeliveryNoteNumber
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            initialInputValue={initialValuesCache?.getValue("deliveryNoteNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("deliveryNoteNumberOperators", "CP")}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} />
          <DeliveryNoteFromToSelection
            validator={validator}
            dateFormatter={getDateFormatOrDefault(currentUser)}
            initialValue={initialValuesCache?.getValue("deliveryNoteDate", stringToDayjs_yyyy_MM_dd)}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} />

        </Col>
        <Col span={8}>
          <StatusSelection
            initialValue={initialValuesCache?.getValue("status")}
            displayColon={true}
            currentUser={currentUser}
            intl={intl}
            validator={validator}
            reportName={"SR2"}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} />

          <BusinessAreaListSelection
            initialValue={initialValuesCache?.getValue("businessArea")}
            displayColon={true}
            selectionMode={DropDownSelectionMode.MULTIPLE}
            currentUser={currentUser}
            intl={intl}
            validator={validator}
            reportName={"SR2"}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} />
          <BMWInvoiceNumber
            currentUser={currentUser}
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            initialInputValue={initialValuesCache?.getValue("partnerInvoiceNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("partnerInvoiceOperator", "CP")}
            intl={intl}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization}
            form={form} />
          <PurchaseOrderNumber
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialInputValue={initialValuesCache?.getValue("purchaseOrderNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("purchaseOrderNumberOperator", "CP")}
            initialization={initialization} form={form} />
          <PurchaseOrderFromToSelection
            validator={validator}
            dateFormatter={getDateFormatOrDefault(currentUser)}
            initialValue={initialValuesCache?.getValue("purchaseOrderDate", stringToDayjs_yyyy_MM_dd)}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} />
        </Col>


        <Col span={8}>
          <GRSCDocumentNumber
            currentUser={currentUser}
            validator={validator}
            labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            initialInputValue={initialValuesCache?.getValue("grScDocumentNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("grScDocumentNumberOperator", "CP")}
            intl={intl}
            distinctEntitlements={distinctEntitlements}
            initialization={initialization}
            form={form} />
          <GRSCDateFromToSelection
            validator={validator}
            dateFormatter={getDateFormatOrDefault(currentUser)}
            initialValue={initialValuesCache?.getValue("grScDate", stringToDayjs_yyyy_MM_dd)}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} />
          <PlantInfoSelection
            selectionMode={DropDownSelectionMode.MULTIPLE}
            initialValue={initialValuesCache?.getValue("plantInfo")}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} reportName={"sr1"} />
          <SPABOrderNumber
            initialInputValue={initialValuesCache?.getValue("spabOrderNumber")}
            initialSelectedOperator={initialValuesCache?.getValueOrDefault("spabOrderNumberOperator", "CP")}
            currentUser={currentUser}
            validator={validator}
            intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} />
          <DocumentDateFromToSelection
            validator={validator}
            labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
            displayColon={!vertical}
            dateFormatter={getDateFormatOrDefault(currentUser)}
            initialValue={initialValuesCache?.getValue("documentDate", stringToDayjs_yyyy_MM_dd)}
            currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
            initialization={initialization} form={form} />

        </Col>
      </Row>
      <Keybinding target={this}
                  onKey={ (e) => { if(e.key === 'Enter'){form.submit()} }} type='keyup'/>s
    </Card>

  </>;
};

export default GoodsReceiptsServiceConfirmationsExpectSearch;