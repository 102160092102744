import { gql } from "@apollo/client";

export const UPDATE_SURVEY_CONNECTIVITY_AS2 = gql`
    mutation updateSurveyConnectivityAs2($groupId: String!, $surveyAgreementConnectivityAs2: SurveyAgreementConnectivityAs2!) {
        updateSurveyConnectivityAs2(groupId: $groupId, surveyAgreementConnectivityAs2: $surveyAgreementConnectivityAs2)
        {
            response
        }

    }
`

export const UPDATE_SURVEY_CONTACT = gql`
    mutation updateSurveyContact($groupId: String!, $surveyContact: SurveyContactInput!) {
        updateSurveyContact(groupId: $groupId, surveyContact: $surveyContact)
        {
         
            surveyContactId
            surveyId
            contactTypeId
            contactFirstName
            contactLastName
            contactNumber
            contactEmailAddres
            contactComment
            insertUser
            insertTime
            updateUser
            updateTime
            groupId
        }

    }
`

export const UPDATE_SURVEY_CONNECTIVITY_SMTP = gql`
    mutation updateSurveyConnectivitySmtp($groupId: String!, $surveyConnectivitySMTP: SurveyConnectivitySMTPInput!) {
        updateSurveyConnectivitySmtp(groupId: $groupId, surveyConnectivitySMTP: $surveyConnectivitySMTP)
        {         
            surveyConnectivityId
            surveyId
            environment
            partnerInboundMessageSubject
            partnerInboundMessageTo
            partnerInboundMessageCC
            partnerInboundMessageDataFileInEmailBody
            partnerInboundAttachmentFilenameRequirements
            partnerInboundEmailBodyText
            partnerInboundMaximumEmailSizeMegaBytes
            partnerOutboundMessageFrom
            partnerOutboundMessageSubject
            partnerOutboundMessageDataFileInEmailBody
            partnerInboundMessageSubjectComment
            partnerInboundMessageToComment
            partnerInboundMessageCCComment
            partnerInboundMessageDataFileInEmailBodyComment
            partnerInboundAttachmentFilenameRequirementsComment
            partnerInboundEmailBodyTextComment
            partnerInboundMaximumEmailSizeMegaBytesComment
            partnerOutboundMessageFromComment
            partnerOutboundMessageSubjectComment
            partnerOutboundMessageDataFileInEmailBodyComment
            additionalInfo
            insertUser
            insertTime
            updateUser
            updateTime
            groupId
        }

    }
`

export const UPDATE_SURVEY_CONNECTIVITY_OFTP2 = gql`
    mutation updateSurveyConnectivityOftp2($groupId: String!, $surveyConnectivityOFTP2: SurveyConnectivityOFTP2Input!) {
        updateSurveyConnectivityOftp2(groupId: $groupId, surveyConnectivityOFTP2: $surveyConnectivityOFTP2)
        {         
            surveyConnectivityId
            surveyId
            environment
            fileExchangeMode
            serverAddress
            serverPort
            sSIDCode
            passwordAvailable
            useTLS
            useCryptographicAuthentication
            certificateAvailable
            providerPushSFIDDestination
            providerPushSFIDDSNVFN
            providerPushAdvancedCompression
            providerPushDataFormat
            providerPushFixedLength
            providerPushEnryptOrSign
            providerPushEnryptionAlgorithm
            providerPushEERP
            partnerPullSFIDDSNVFN
            partnerPullDataFormat
            partnerPullFixedLength
            partnerPullAdvancedCompression
            partnerPullEnryptOrSign
            partnerPullEnryptionAlgorithm
            partnerPullEERP
            providerPullFrequency
            providerPullEnrypted
            providerPullSigning
            partnerPushFileTypes
            partnerPushNonEDIOriginator
            partnerPushNonEDIRecipient
            partnerPushNonEDIAPRF
            partnerPushVFN
            providerPushSFIDDestinationComment
            providerPushSFIDDSNVFNComment
            providerPushAdvancedCompressionComment
            providerPushDataFormatComment
            providerPushFixedLengthComment
            providerPushEnryptOrSignComment
            providerPushEnryptionAlgorithmComment
            providerPushSigningAlgorithm
            providerPushSigningAlgorithmComment
            providerPushEERPComment
            partnerPullSFIDDSNVFNComment
            partnerPullDataFormatComment
            partnerPullFixedLengthComment
            partnerPullAdvancedCompressionComment
            partnerPullEnryptOrSignComment
            partnerPullEnryptionAlgorithmComment
            partnerPullEERPComment
            providerPullFrequencyComment
            providerPullEnryptedComment
            providerPullSigningComment
            partnerPushFileTypesComment
            partnerPushNonEDIOriginatorComment
            partnerPushNonEDIRecipientComment
            partnerPushNonEDIAPRFComment
            partnerPushVFNComment
            additionalInfo
            insertUser
            insertTime
            updateUser
            updateTime
            certificateAvailableComment
            fileExchangeModeComment
            serverAddressComment
            serverPortComment
            sSIDCodeComment
            passwordAvailableComment
            useTLSComment
            useCryptographicAuthenticationComment
            groupId
        }

    }
`

export const UPLOAD_BLOB_FILE = gql`
    mutation uploadBlobFile($blobFile: BlobFileInput!) {
        uploadBlobFile(blobFile: $blobFile)
        {         
            blobId            
            downloadLink
            fileName
            mimeType
            requiresAuthentication
            rolesAllowed
            insertUser
            insertTime
            updateUser
            updateTime
        }

    }
`

export const SAVE_SURVEY_CONNECTIVITY_TESTFILE = gql`
    mutation saveConnectivityTestFile($groupId: String!, $testFile: SurveyConnectivityTestFileInput!) {
        saveConnectivityTestFile(groupId: $groupId, testFile:$testFile)
        {         
            rowId
            processCode
            processCodeName
            documentFormat
            currentVersionNumber
            processCodeId
            documentFormatId
            testFileId
            surveyId
            groupId
            versionNumber
            fileBlobId
            fileStatus
            fileError
            submittedForValidation
            validationReference
            validationStatus
            validationPassed
            validationResultBlobId
            surveyValidationStatusDescr
            validationStatusPhraseId
            insertTime
            subList {
                rowId
                processCode
                processCodeName
                documentFormat
                currentVersionNumber
                processCodeId
                documentFormatId
                testFileId
                surveyId
                groupId
                versionNumber
                fileBlobId
                fileStatus
                fileError
                submittedForValidation
                validationReference
                validationStatus
                validationPassed
                validationResultBlobId
                surveyValidationStatusDescr
                validationStatusPhraseId
                insertTime
            }
        }

    }
`

export const UPDATE_NOTIFICATION_READ_DATA = gql`
     mutation updateNotificationReadData($authentication: String!, $notificationInput: [NotificationInput!]!) {
        updateNotificationReadData(authentication:$authentication ,notificationInput:$notificationInput)
        {
            response
        }

    }
`;

export const ADD_BANNER_DATA = gql`
     mutation addBannerData($authentication: String!, $addBannerInput: AddBannerInput!) {
        addBannerData(authentication:$authentication, addBannerInput:$addBannerInput)
        {
            response
        }

    }
`;

export const EDIT_BANNER_DATA = gql`
     mutation editBannerData($authentication: String!, $bannerEditRequest: BannerEditRequest!) {
        editBannerData(authentication:$authentication, bannerEditRequest:$bannerEditRequest)
        {
            response
        }

    }
`;

export const DELETE_BANNER_DATA = gql`
     mutation deleteBannerData($authentication: String!, $bannerId: Float!) {
        deleteBannerData(authentication:$authentication, bannerId:$bannerId)
        {
            response
        }

    }
`;

export const CREATE_SURVEY = gql`
    mutation createSurvey($surveyCreateRequests: [SurveyCreateRequest!]!) {
        createSurvey(surveyCreateRequests: $surveyCreateRequests)
        {
            success
            surveyId
            errorText
        }

    }
`;

export const UPDATE_PEPPOL_DETAILS = gql`
    mutation saveConnectivityPeppolDetailsForSurvey($groupId: String!, $peppolDetails: SurveyConnectivityPeppolInput!) {
        saveConnectivityPeppolDetailsForSurvey(groupId: $groupId, peppolDetails: $peppolDetails)
        {
            environment
            groupId
            insertTime
            insertUser
            receivingPeppolId
            receivingPeppolIdComment
            sendingPeppolId
            sendingPeppolIdComment
            surveyConnectivityId
            surveyID
            updateTime
            updateUser
        }

    }
`;

export const CREATE_NOTIFICATION_TEMPLATE_DATA = gql`
     mutation createNotificationTemplateData($authentication: String!, $notificationTemplateInput: [NotificationTemplateInput!]!) {
        createNotificationTemplateData(authentication:$authentication ,notificationTemplateInput:$notificationTemplateInput)
        {
            response
        }

    }
`;

export const ADD_NOTIFICATION_DATA = gql`
     mutation addNotificationData($authentication: String!, $addNotificationInput: [AddNotificationInput!]!) {
        addNotificationData(authentication:$authentication ,addNotificationInput:$addNotificationInput)
        {
            response
        }

    }
`;

export const EDIT_NOTIFICATION_DATA = gql`
   mutation editNotificationData($authentication: String!, $notificationEditRequest: NotificationEditRequest!) {
        editNotificationData(authentication:$authentication, notificationEditRequest:$notificationEditRequest)
        {
            response
        }
    }
`;


