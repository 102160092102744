import React from 'react';
import { IAppComponentProps } from 'src/components';

const MasterDataFreightTabComponent: React.FC<IAppComponentProps> = (props) => {
    return (
        <div>
            <h1>Master Data Freight Tab</h1>
            <p>This is the Master Data Freight Tab component.</p>
        </div>
    );
};

export default MasterDataFreightTabComponent;