import React, { useState } from 'react';
import { Tabs } from 'antd';
import MaintainTableTabComponent from '../MaintainTableTabComponent';
import { EditTabInputProps } from '../../Common/types';
import GenericEditForm from '../../Common/GenericEditForm';
import BusinessAreaAuditViewForm from './BusinessAreaAuditViewForm';

const MasterDataEcapTabComponent: React.FC<EditTabInputProps> = (props) => {

    const tabItems = [
        {
            key: '1',
            label: 'Business Areas',
            children: <MaintainTableTabComponent intl={props.intl} system={props.system} tableTabName='Business Area' editForm={<GenericEditForm intl={props.intl} currentUser={props.currentUser} distinctEntitlements={props.distinctEntitlements} tableName='t_md_business_area' recordKey='businessAreaCode'/>} auditForm={<BusinessAreaAuditViewForm  intl={props.intl} currentUser={props.currentUser} distinctEntitlements={props.distinctEntitlements}/>}/>,
        },
        {
            key: '2',
            label: 'Business Units',
            children: <MaintainTableTabComponent intl={props.intl} system={props.system} tableTabName='Business Unit' editForm={<GenericEditForm intl={props.intl} currentUser={props.currentUser} distinctEntitlements={props.distinctEntitlements} tableName='t_md_business_unit' recordKey='businessUnitCode'/>} auditForm={<BusinessAreaAuditViewForm intl={props.intl} currentUser={props.currentUser} distinctEntitlements={props.distinctEntitlements}/>}/>,
        },
        {
            key: '3',
            label: 'Table 3',
            children: <MaintainTableTabComponent intl={props.intl} system={props.system} tableTabName='table 3' editForm={<>edit table 3 ECAP</>} auditForm={<BusinessAreaAuditViewForm intl={props.intl} currentUser={props.currentUser} distinctEntitlements={props.distinctEntitlements}/>}/>,
        },
    ];

    return (
        <div>
            <h1>Master Data ECAP Tab</h1>
            <Tabs defaultActiveKey="1" items={tabItems} />
        </div>
    );
};

export default MasterDataEcapTabComponent;