import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {IAppComponentProps} from "../../../components";
import enUS from "antd/lib/locale/en_US";
import deDe from "antd/lib/locale/de_DE";
import {ConfigProvider, Spin, Tabs} from "antd";
import {BMWAGTab, BMWChinaTab, BMWCORTab, BMWMexico, BMWNSCTab, InfoPortalTab} from "./tabs/InfoPortalTab";
import {InfoPortalTabConfig, Initialization} from "../InfoPortalInterfaces";
import {v4 as uuidv4} from 'uuid';
import {entitlementExists} from "../../../main/entitlementService";
import {ProPageHeader} from "@ant-design/pro-layout";

type AllTabsState = {
    isExpertMode: boolean;
    reportName: string;
}
const AccountPayableReports: FunctionComponent<IAppComponentProps> = (props) => {

    const [selectedTab, setSelectedTab] = useState<string>("item-1")
    const [allTabStates, setAllTabStates] = useState<{ [key: string]: AllTabsState }>({});

    const generalTab = useRef<InfoPortalTabConfig>(new BMWAGTab());
    const nscTab = useRef<InfoPortalTabConfig>(new BMWNSCTab());
    const mexicoTab = useRef<InfoPortalTabConfig>(new BMWMexico());
    const chinaTab = useRef<InfoPortalTabConfig>(new BMWChinaTab());
    const corTab = useRef<InfoPortalTabConfig>(new BMWCORTab());

    const [initState, setState] = useState<any>({});

    const isLoading = () => Object.keys(initState).map(key => initState[key] === true).reduce((prev, curr) => prev || curr, false);

    const updateInitState =
        (property: string, state: boolean) => setState(currState => {
            const newState = {...currState};
            newState[property] = state;
            return newState;
        });

    const stateInit: Initialization = {
        beginInitialize(): string {
            const randomProp = uuidv4();
            updateInitState(randomProp, true);
            return randomProp;
        }, finishInitialize(initializationId: string): void {
            updateInitState(initializationId, false);
        }
    };

    const onTabSelectionChange = (key: string) => {
        console.debug(`${selectedTab}->${key}`);
        setSelectedTab(key);
    };

    const reportSearchChange = (isExpert: boolean, reportName: string) => {
        const newState = {...allTabStates};
        newState[selectedTab] = {isExpertMode:isExpert, reportName}
        setAllTabStates(newState)
    };

    type ReportTab = {
        shouldRender: boolean;
        component: JSX.Element;
    }

    const tabs: ReportTab[] = [
        {
            shouldRender: entitlementExists(generalTab.current.getEntitlement(), props.distinctEntitlements),
            component: <Tabs.TabPane
                tab={props.intl.formatMessage({
                    'id': 'account-payable-bmw-ag-tab',
                    'defaultMessage': "BMW AG"
                })} key={`${generalTab.current.getName()}`}>
                <InfoPortalTab
                    intl={props.intl}
                    distinctEntitlements={props.distinctEntitlements}
                    currentUser={props.currentUser}
                    initialization={stateInit}
                    infoPortalTab={generalTab.current}
                    onReportAndSearchTypeChange={reportSearchChange}/>
            </Tabs.TabPane>
        },
        {
            shouldRender: entitlementExists(mexicoTab.current.getEntitlement(), props.distinctEntitlements),
            component: <Tabs.TabPane tab={props.intl.formatMessage({
                'id': 'account-payable-bmw-mexico-tab',
                'defaultMessage': "BMW Mexico"
            })} key={`${mexicoTab.current.getName()}`}>
                <InfoPortalTab
                    intl={props.intl}
                    distinctEntitlements={props.distinctEntitlements}
                    currentUser={props.currentUser}
                    initialization={stateInit}
                    infoPortalTab={mexicoTab.current}
                    onReportAndSearchTypeChange={reportSearchChange}/>
            </Tabs.TabPane>
        },
        {
            shouldRender: entitlementExists("display_cz@reports", props.distinctEntitlements),
            component: <Tabs.TabPane tab={props.intl.formatMessage({
                "id": "account-payable-bmw-china-tab",
                "defaultMessage": "BMW China"
            })} key={`${chinaTab.current.getName()}`}>
                <InfoPortalTab
                    intl={props.intl}
                    distinctEntitlements={props.distinctEntitlements}
                    currentUser={props.currentUser}
                    initialization={stateInit}
                    infoPortalTab={chinaTab.current}
                    onReportAndSearchTypeChange={reportSearchChange}/>
            </Tabs.TabPane>
        },
        {
            shouldRender: entitlementExists("display_nr@reports", props.distinctEntitlements),
            component: <Tabs.TabPane tab={props.intl.formatMessage({
                "id": "account-payable-bmw-nsc-tab",
                "defaultMessage": "BMW NSC's"
            })} key={`${nscTab.current.getName()}`}>
                <InfoPortalTab
                    intl={props.intl}
                    distinctEntitlements={props.distinctEntitlements}
                    currentUser={props.currentUser}
                    initialization={stateInit}
                    infoPortalTab={nscTab.current}
                    onReportAndSearchTypeChange={reportSearchChange}/>
            </Tabs.TabPane>
        },
        {
            shouldRender: entitlementExists("display_coa@reports", props.distinctEntitlements),
            component: <Tabs.TabPane tab={props.intl.formatMessage({
                "id": "account-payable-bmw-cor-tab",
                "defaultMessage": "Confirmation of Arrival"
            })} key={`${corTab.current.getName()}`}>
                <InfoPortalTab
                    intl={props.intl}
                    distinctEntitlements={props.distinctEntitlements}
                    currentUser={props.currentUser}
                    initialization={stateInit}
                    infoPortalTab={corTab.current}
                    onReportAndSearchTypeChange={reportSearchChange}/>
            </Tabs.TabPane>
        }
    ]

    useEffect(() => {
        const initialState:{[key: string]: AllTabsState} = {};
        tabs.forEach((item, idx) => {
            initialState[`item-${idx + 1}`] = {isExpertMode: false, reportName: ''};
        })
        setAllTabStates(initialState)
    }, []);

    const displayHeading = () => {
        if (allTabStates[selectedTab]) {
            if (!allTabStates[selectedTab]?.isExpertMode) {
                return props.intl.formatMessage({id: 'account-payable-basic-title'});
            }
            return props.intl.formatMessage({id: 'info-portal-expert-search-heading'}).replace('{}', props.intl.formatMessage({id:allTabStates[selectedTab].reportName}));
        }

        return props.intl.formatMessage({id: 'account-payable-basic-title'});

    };

    useEffect(()=> {
        function checkIfContentEditable(node: HTMLElement | null) {
            if (node === null) {
                return false;
            }

            if (node.getAttribute('contenteditable') === 'true') {
                return true;
            }

            return checkIfContentEditable(node.parentElement);
        }

        const TARGETS_BLACKLIST = ['textarea', 'input', 'select'];

        const enterKeyEvent =   (e: KeyboardEvent) => {

                const target = e.target as HTMLElement | null;

                if (target) {
                    let canDispatch = true;

                    if (
                        TARGETS_BLACKLIST.indexOf(target.tagName.toLowerCase()) > -1
                    ) {
                        if (target.tagName.toLowerCase() === 'input' && !target.classList.contains('ant-radio-input')) {
                            canDispatch = false;
                        }
                    }

                    if (checkIfContentEditable(target)) {
                        canDispatch = false;
                    }

                    if (canDispatch && e.key === 'Enter') {
                        const selectorString = `.ant-tabs-tabpane-active [data-testid="info-portal-search-button"]`;
                        const searchButton = document.querySelector(selectorString);
                        if (searchButton) {
                            console.debug('Button found', searchButton);
                            // @ts-ignore
                            searchButton.click();
                        }
                    }
                }
            };

        console.log('Registering key up event');
        const target = document;
        target.addEventListener('keyup', enterKeyEvent);
        return () => {
            console.log('Unregistering key up event');
            target.removeEventListener('keyup', enterKeyEvent);
        };
    },[]);

    return <>
        <ConfigProvider locale={localStorage.getItem("locale") === "en" ? enUS : deDe}>
            <Spin size={"large"} spinning={isLoading()}>
                <div id={'accounts-payable-screen'}>
                    <ProPageHeader
                        prefixedClassName="site-page-header"
                        title={displayHeading()}
                    />
                    <Tabs onChange={onTabSelectionChange}>
                        {
                            tabs.filter(theTab => theTab.shouldRender).map(theTab => theTab.component)
                        }
                    </Tabs>
                </div>
            </Spin>
        </ConfigProvider>
    </>
};

export default AccountPayableReports;