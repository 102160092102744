import React, { FunctionComponent, useEffect, useState } from 'react';
import { IAppComponentProps } from '../components';
import { Alert } from 'antd';
import {
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_BANNER_DETAILS } from '../pages/survey/queries';
import { getCurrentLocale } from '../utils';
import dayjs, { Dayjs } from 'dayjs';
import { convertFromSpecifiedDateFormatToUserDefined } from '../pages/users/UserFormatPreferenceServices';
import { IPortalUser } from '../__generated__/types';
import {interpretConfluenceMarkup, removeScriptsFromText} from '../utils/Utils';

export function modifyBannerTextForDisplay(text: string, start: Dayjs, end: Dayjs, user: IPortalUser) {
    if (!text) {
        return text;
    }

    const replaceDates = (toBeModified: string, placeHolder: string, date: Dayjs) => {
        if (toBeModified.indexOf(placeHolder) === -1) {
            return toBeModified;
        }

        const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

        const localDate = date?.local();
        const localDateInYYYY_MM_DD = dayjs(localDate).format(dateTimeFormat).substring(0, 10);
        const localTimeHourMinutes = dayjs(localDate).format(dateTimeFormat).substring(11, 16);

        const convertLocalDateToUserPreferenceFormat = convertFromSpecifiedDateFormatToUserDefined(
            user,
            localDateInYYYY_MM_DD,
            'YYYY-MM-DD'
        );
        return toBeModified.replace(placeHolder,`${convertLocalDateToUserPreferenceFormat} ${localTimeHourMinutes}`);
    };

    let message = removeScriptsFromText(text);
    message = replaceDates(message, '{start_time}', start);
    message = replaceDates(message, '{end_time}', end);

    return  interpretConfluenceMarkup(message);
}

const Banner: FunctionComponent<IAppComponentProps> = (props) => {
  const user = props.currentUser;
  const [bannerExistsForCurrentTime, setBannerExistsForCurrentTime] = useState(false);
  const [messageAlertVisible, setMessageAlertVisible] = useState(true);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [banners, setBanners] = useState([]);
  const [isPaused, setIsPaused] = useState(false);

  const [getBannerDetails] = useLazyQuery(QUERY_GET_BANNER_DETAILS,
    { fetchPolicy: "network-only" }
  );

  useEffect(() => {
    getBannerDetails().then(response => {
      if (!response?.data && !response?.data?.getBannerDetailsData) {
        setBannerExistsForCurrentTime(false);
      } else {
          const localeLanguage = getCurrentLocale();
          const bannersData = response.data?.getBannerDetailsData;

          const processedBanners = bannersData.map(banner => {
          const startTimeFromDB = dayjs.utc(banner?.startTime)?.local();
          const endTimeFromDB = dayjs.utc(banner?.endTime)?.local();

          const bannerMessageAddIntlTime =
              localeLanguage === "en"?  modifyBannerTextForDisplay(banner?.bannerMessageEn, startTimeFromDB, endTimeFromDB, user):
                  modifyBannerTextForDisplay( banner?.bannerMessageDe, startTimeFromDB, endTimeFromDB, user);

          const bannerHeading =  localeLanguage === "en"? banner?.bannerHeadingEn:banner?.bannerHeadingDe;

          return {
            message: bannerMessageAddIntlTime,
            heading: bannerHeading,
            messageType: banner?.messageType
          };
        });

        setBanners(processedBanners);
        setBannerExistsForCurrentTime(processedBanners?.length > 0);
      }
    });
  }, [getBannerDetails, user]);

  useEffect(() => {
    if (banners.length > 1 && !isPaused) {
      const interval = setInterval(() => {
        setCurrentBannerIndex(prevIndex => (prevIndex + 1) % banners?.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [banners, isPaused]);

  const currentBanner = banners[currentBannerIndex];

  const messageAlertToggle = () => {
    setMessageAlertVisible(prev=>!prev);
  }

  const togglePausePlay = () => {
    setIsPaused(!isPaused);
  };

  return (
      <div>
          {bannerExistsForCurrentTime && messageAlertVisible === true ? (
              <Alert
                  description={<div dangerouslySetInnerHTML={{ __html: currentBanner?.message }} />}
                  type={currentBanner?.messageType}
                  style={{
                      marginBottom: '10',
                      textAlign: 'center',
                      alignItems: 'center',
                      height: '62px'
                  }}
                  action={
                      <div>
                          <div style={{display:"inline", marginRight:"10px"}}>
                              {isPaused ? (
                                  <PlayCircleOutlined
                                      style={{ fontSize: '16px', cursor: 'pointer', color: '#000' }}
                                      onClick={togglePausePlay}
                                  />
                              ) : (
                                  <PauseCircleOutlined
                                      style={{ fontSize: '16px', cursor: 'pointer', color: '#000' }}
                                      onClick={togglePausePlay}
                                  />
                              )}
                          </div>

                          <CloseCircleOutlined
                              style={{
                                  fontSize: '16px'
                              }}
                              onClick={messageAlertToggle}
                          />
                      </div>
                  }
              />
          ) : (
              messageAlertVisible === false && (
                  <Alert
                      message={<div dangerouslySetInnerHTML={{ __html: currentBanner?.heading }} />}
                      type={currentBanner?.messageType}
                      style={{
                          fontFamily: 'BMW Group Condensed',
                          textAlign: 'right',
                          alignItems: 'center',
                      }}
                      action={
                          <div>
                              <div style={{display: "inline", marginRight: "10px"}}>
                                  {isPaused ? (
                                      <PlayCircleOutlined
                                          style={{fontSize: '16px', cursor: 'pointer', color: '#000'}}
                                          onClick={togglePausePlay}
                                      />
                                  ) : (
                                      <PauseCircleOutlined
                                          style={{fontSize: '16px', cursor: 'pointer', color: '#000'}}
                                          onClick={togglePausePlay}
                                      />
                                  )}
                              </div>

                              <ExclamationCircleOutlined
                                  style={{
                                      fontSize: '16px'
                                  }}
                                  onClick={messageAlertToggle}
                              />
                          </div>
                      }
                  />
              )
          )}
      </div>
  );
}
export default Banner;