import React, { useEffect, useState } from 'react';
import {
    downloadDocumentByBlobIdAndApplicationType,
    downloadRejectionLetterDocumentUsingUrl,
    DropDownSelectionMode,
    ExternalStateUpdateCallback,
    InfoPortalAgGridColumnDef,
    InfoPortalColumnDef,
    InfoPortalGridService,
    InfoPortalReport,
    InfoPortalSearchProperties,
    IValidator,
    LabelLocation,
    OnChangeListener,
    ReportViewConfig,
    stringToDayjs_yyyy_MM_dd,
    utcDateToFormatTo_yyyy_MM_dd_string,
    Validator,
} from '../../../InfoPortalInterfaces';
import {
    BMWInvoiceNumber,
    ConfirmationNumber,
    DeliveryNoteNumber,
    GrossAmount,
    PartnerInvoiceNumber,
    PurchaseOrderNumber,
    SPABOrderNumber,
} from '../../InputWithOperator';
import { Card, FormInstance, Modal, Spin, Tooltip } from 'antd';
import {
    BusinessAreaListSelection,
    CompanySelection,
    CurrencySelection,
    descriptionFilter,
    InvoiceDirectionSelection,
    InvoiceProcessSelection,
    InvoiceStatusSelection,
    PartnerSelection,
    PlantInfoSelection,
} from '../../InfoPortalDropdowns';
import styles from '../../styling/infoportal.module.css';
import { DeliveryNoteFromToSelection, DocumentDateFromToSelection } from '../../FromToDateSelection';
import { RuleSet } from '../../../../e-cap/util/RuleResultCache';
import { IntlShape } from 'react-intl/src/types';
import { retrieveSR3Report } from '../../../services/ReportSearch';
import { FileOutlined } from '@ant-design/icons';
import { saveAs } from 'save-as';
import {
    convertToUserSpecifiedNumericFormat,
    dateComparatorUsingUserPreferences,
    getDateFormatOrDefault,
    numericComparatorUsingUserPreferences,
} from '../../../../users/UserFormatPreferenceServices';
import { PortalUser } from '../../../../../components';

import { Buffer } from 'buffer';
import {
    filterParamAmountFormattedAsStringUsingPreferences,
    filterParamWithUserDateFormatComparator
} from "../../grid/GridUtil";
import Keybinding from '../../../../../components/Keybinding';

function validatator(form: FormInstance<any>): Validator {
    const validator: IValidator = {
        fetchRule(fieldName: string): RuleSet {
            const result = {
                required: false,
                picture: '',
                readOnly: false,
                hidden: false,
                formatMessage: null,
                infoMessage: null,
            };
            if (fieldName === 'company' || fieldName === 'partner' || fieldName === 'documentDate') {
                result.required = true;
            }
            return result;
        },
    };
    return new Validator(validator);
}

function RejectionLetterDownload(props: any, distinctEntitlements: string[], intl: IntlShape): JSX.Element {
    const [loading, setLoading] = useState<boolean>(false);

    function getApplication(uri: string) {
        if (uri) {
            const lastForwardSlashIndex = uri.lastIndexOf("/");
            if (lastForwardSlashIndex > 0) {
                const result = uri.substring(lastForwardSlashIndex + 1);
                if (/[\d]+/.test(result)) {
                    return 'ip'
                }
                return result;
            }
        }
        return null;
    }

    function getBlobId(uri: string) {
        if (uri) {
            const lastForwardSlashIndex = uri.lastIndexOf("/");
            if (lastForwardSlashIndex > 0) {
                const downloadIndex = uri.indexOf("download/");
                if (downloadIndex + 9 < lastForwardSlashIndex) {
                    const blobId = uri.substring(downloadIndex + 9, lastForwardSlashIndex);
                    if (/[\d]+/.test(blobId)) {
                        return parseInt(blobId);
                    }
                }
                else {
                    const blobId = uri.substring(lastForwardSlashIndex+1);
                    if (/[\d]+/.test(blobId)) {
                        return parseInt(blobId);
                    }
                }
            }

            return null;
        }
    }

    function downloadBlob(blobId: number, application:string) {
        setLoading(true);
        Promise.resolve(downloadDocumentByBlobIdAndApplicationType(blobId, application))
            .then((data) => {
                const mimeType = data.value.mimeType;
                const decodedData = Buffer.from(data.value.encodedFileData, 'base64');
                const blob = new Blob([decodedData], {type: mimeType});
                saveAs(blob, data.value.fileName);
            }).catch(error => {
            console.error(error);
            Modal.warning({
                title: intl.formatMessage({id: "Download Error",}),
                content: intl.formatMessage({
                    id: "rejection-letter-download-failed",
                })
            });
        }).finally(() => setLoading(false));
    }

    function downloadBlobByUrl(companyCode:string, partnerNumber:string, bmwInvoiceNumber:string, partnerInvoiceNumber:string, url:string) {
        setLoading(true);

        Promise.resolve(downloadRejectionLetterDocumentUsingUrl(companyCode, partnerNumber, bmwInvoiceNumber, partnerInvoiceNumber, url))
            .then((data) => {
                const mimeType = data.value.mimeType;
                const decodedData = Buffer.from(data.value.encodedFileData, 'base64');
                const blob = new Blob([decodedData], {type: mimeType});
                saveAs(blob, data.value.fileName);
            }).catch(error => {
            console.error(error);
            Modal.warning({
                title: intl.formatMessage({id: "Download Error",}),
                content: intl.formatMessage({
                    id: "rejection-letter-download-failed",
                })
            });
        }).finally(() => setLoading(false));
    }

    const blobId = getBlobId(props.data.rejectionletteruri || props.valueFormatted);
    const application = getApplication(props.data.rejectionletteruri || props.valueFormatted);

    if (blobId && application) {
        return <Spin data-testid={loading? `sr3_downloading_${blobId}`:`sr3_finished_downloading_${blobId}`} size={"large"} spinning={loading}>
            <Tooltip
                style={{textTransform:"capitalize"}}
                title={`${intl.formatMessage({id:'welcome-download-button'})}`}>
                <FileOutlined data-testid={`sr3_rejection_letter_${blobId}`} style={{cursor: "pointer"}} onClick={_ => downloadBlob(blobId, application)}/>
            </Tooltip>
        </Spin>
    }

    if (props.data.xSuiteRejectionUrl) {
        return <Spin data-testid={loading? `sr3_downloading_${props.data.bmwdocno}`:`sr3_finished_downloading_${props.data.bmwdocno}`} size={"large"} spinning={loading}>
            <Tooltip
                style={{textTransform:"capitalize"}}
                title={`${intl.formatMessage({id:'welcome-download-button'})}`}>
                <FileOutlined data-testid={`sr3_rejection_letter_${props.data.bmwdocno}`} style={{cursor: "pointer"}} onClick={_ => downloadBlobByUrl(props.data.compcode, props.data.partnerno,props.data.bmwdocno ,props.data.partnerinvno, props.data.xSuiteRejectionUrl)}/>
            </Tooltip>
        </Spin>
    }

    return <></>;
}

function SelfBilledInvoiceSimpleSearch({
                                           currentUser,
                                           intl,
                                           reportEntitlement,
                                           distinctEntitlements,
                                           initialization,
                                           form,
                                           initialValuesCache,
                                           rerenderDependentFields
                                       }: InfoPortalSearchProperties) {
    const [vertical, setVertical] = useState<boolean>(false);

    const validator = validatator(form);
    const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

    const companySelectionChange: OnChangeListener<string[]> = {
        performAction(companyIds: string[]) {
            const newVal = form.getFieldValue("partner") || [];
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    };

    useEffect(() => {
        if (rerenderDependentFields) {
            const companyIds = form.getFieldValue("company");
            const newVal = form.getFieldValue("partner");
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    });

    return <>
        <Card size={"small"} style={{backgroundColor: "#f1f3f5"}}>
            <div className={styles.selfBilledSimpleSearchContainer}>
                <div className={styles.singleColumnGrid}>
                    <CompanySelection
                        selectionMode={DropDownSelectionMode.MULTIPLE}
                        currentUser={currentUser}
                        initialValue={initialValuesCache?.getValue("company")}
                        intl={intl}
                        validator={validator}
                        displayColon={!vertical}
                        distinctEntitlements={distinctEntitlements}
                        initialization={initialization} form={form}
                        filterOptions={descriptionFilter}
                        onSelectionChange={companySelectionChange}
                        companyEntitlement={reportEntitlement}/>
                    <PartnerSelection
                        selectionMode={DropDownSelectionMode.MULTIPLE}
                        currentUser={currentUser} intl={intl}
                        validator={validator}
                        labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                        displayColon={!vertical}
                        initialValue={initialValuesCache?.getValue("partner")}
                        distinctEntitlements={distinctEntitlements}
                        initialization={initialization} form={form}
                        filterOptions={descriptionFilter}
                        companyIds={initialValuesCache?.getValue("company")}
                        externalStateUpdater={partnerSelectionUpdate}
                        partnerEntitlement={reportEntitlement}/>
                    <PartnerInvoiceNumber
                        currentUser={currentUser}
                        validator={validator}
                        labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                        displayColon={!vertical}
                        initialInputValue={initialValuesCache?.getValue("partnerInvoiceNumber")}
                        initialSelectedOperator={initialValuesCache?.getValueOrDefault("partnerInvoiceOperator", "CP")}
                        intl={intl}
                        distinctEntitlements={distinctEntitlements}
                        initialization={initialization}
                        form={form}/>
                    <InvoiceStatusSelection
                        currentUser={currentUser}
                        selectionMode={DropDownSelectionMode.MULTIPLE}
                        intl={intl}
                        validator={validator}
                        displayColon={!vertical}
                        initialValue={initialValuesCache?.getValue("invoiceStatus")}
                        distinctEntitlements={distinctEntitlements}
                        initialization={initialization}
                        form={form} reportName={"sr3"}/>
                    <DocumentDateFromToSelection
                        validator={validator}
                        labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                        displayColon={!vertical}
                        dateFormatter={getDateFormatOrDefault(currentUser)}
                        initialValue={initialValuesCache?.getValue("documentDate", stringToDayjs_yyyy_MM_dd)}
                        currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                        initialization={initialization} form={form}/>
                </div>

                <div className={styles.singleColumnGrid}>
                    <BusinessAreaListSelection
                        validator={validator}
                        displayColon={!vertical}
                        selectionMode={DropDownSelectionMode.MULTIPLE}
                        filterOptions={descriptionFilter}
                        labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                        initialValue={initialValuesCache?.getValue("businessAreaCode")}
                        currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                        initialization={initialization} form={form}/>
                    <BMWInvoiceNumber
                        validator={validator}
                        labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                        displayColon={!vertical}
                        initialInputValue={initialValuesCache?.getValue("bmwInvoiceNumber")}
                        initialSelectedOperator={initialValuesCache?.getValueOrDefault("bmwInvoiceNumberOperator", "CP")}
                        currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                        initialization={initialization} form={form}/>
                    <PurchaseOrderNumber
                        validator={validator}
                        labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                        displayColon={!vertical}
                        currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                        initialInputValue={initialValuesCache?.getValue("purchaseOrderNumber")}
                        initialSelectedOperator={initialValuesCache?.getValueOrDefault("purchaseOrderNumberOperator", "CP")}
                        initialization={initialization} form={form}/>
                    <DeliveryNoteNumber
                        validator={validator}
                        labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                        displayColon={!vertical}
                        initialInputValue={initialValuesCache?.getValue("deliveryNoteNumber")}
                        initialSelectedOperator={initialValuesCache?.getValueOrDefault("deliveryNoteNumberOperators", "CP")}
                        currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                        initialization={initialization} form={form}/>
                </div>
            </div>
            <Keybinding target={this}
                        onKey={ (e) => { if(e.key === 'Enter'){form.submit()} }} type='keyup'/>
        </Card>
        {/*<Button onClick={toggleMode} style={{marginTop:"10px"}}>*/}
        {/*    Toggle on left*/}
        {/*</Button>*/}
    </>;
}

function SelfBilledInvoiceExpertSearch({
                                           currentUser,
                                           intl,
                                           reportEntitlement,
                                           distinctEntitlements,
                                           initialization,
                                           form,
                                           initialValuesCache,
                                           rerenderDependentFields
                                       }: InfoPortalSearchProperties) {
    const [vertical, setVertical] = useState<boolean>(false);
    const validator = validatator(form);
    const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

    const companySelectionChange: OnChangeListener<string[]> = {
        performAction(companyIds: string[]) {
            const newVal = form.getFieldValue("partner") || [];
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    };

    useEffect(() => {
        if (rerenderDependentFields) {
            const companyIds = form.getFieldValue("company");
            const newVal = form.getFieldValue("partner");
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    });

    return <Card size={"small"} style={{backgroundColor: "#f1f3f5"}}>
        <div className={styles.selfBilledExpertSearchContainer} style={{marginLeft: "10px"}}>
            <div className={styles.singleColumnGrid}>
                <CompanySelection
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    currentUser={currentUser}
                    initialValue={initialValuesCache?.getValue("company")}
                    intl={intl}
                    validator={validator}
                    filterOptions={descriptionFilter}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}
                    onSelectionChange={companySelectionChange}
                    companyEntitlement={reportEntitlement}/>
                <PartnerSelection
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    currentUser={currentUser} intl={intl}
                    validator={validator}
                    initialValue={initialValuesCache?.getValue("partner")}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}
                    filterOptions={descriptionFilter}
                    companyIds={initialValuesCache?.getValue("company")}
                    externalStateUpdater={partnerSelectionUpdate}
                    partnerEntitlement={reportEntitlement}/>
                <PartnerInvoiceNumber
                    currentUser={currentUser}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    validator={validator}
                    initialInputValue={initialValuesCache?.getValue("partnerInvoiceNumber")}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("partnerInvoiceOperator", "CP")}
                    intl={intl}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization}
                    form={form}/>
                <InvoiceStatusSelection
                    currentUser={currentUser}
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    intl={intl}
                    validator={validator}
                    initialValue={initialValuesCache?.getValue("invoiceStatus")}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization}
                    form={form} reportName={"sr3"}/>
                <DocumentDateFromToSelection
                    validator={validator}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    dateFormatter={getDateFormatOrDefault(currentUser)}
                    initialValue={initialValuesCache?.getValue("documentDate", stringToDayjs_yyyy_MM_dd)}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}/>

                <GrossAmount
                    initialInputValue={initialValuesCache?.getValue("grossAmount")}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    isNumeric={true}
                    numDecimals={2}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("grossAmountOperator", "EQ")}
                    currentUser={currentUser}
                    validator={validator}
                    intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}/>

                <CurrencySelection
                    initialValue={initialValuesCache?.getValue("currency")}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    currentUser={currentUser}
                    validator={validator}
                    intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}/>
            </div>

            <div className={styles.singleColumnGrid}>

                <BusinessAreaListSelection
                    initialValue={initialValuesCache?.getValue("businessAreaCode")}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    filterOptions={descriptionFilter}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization}
                    validator={validator}
                    form={form}/>
                <BMWInvoiceNumber
                    initialInputValue={initialValuesCache?.getValue("bmwInvoiceNumber")}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("bmwInvoiceNumberOperator", "CP")}
                    currentUser={currentUser}
                    validator={validator}
                    intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}/>

                <PurchaseOrderNumber
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    initialInputValue={initialValuesCache?.getValue("purchaseOrderNumber")}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("purchaseOrderNumberOperator", "CP")}
                    initialization={initialization}
                    validator={validator}
                    form={form}/>

                <DeliveryNoteNumber
                    initialInputValue={initialValuesCache?.getValue("deliveryNoteNumber")}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("deliveryNoteNumberOperators", "CP")}
                    currentUser={currentUser}
                    validator={validator}
                    intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}/>

                <DeliveryNoteFromToSelection
                    validator={validator}
                    dateFormatter={getDateFormatOrDefault(currentUser)}
                    initialValue={initialValuesCache?.getValue("deliveryNoteDate", stringToDayjs_yyyy_MM_dd)}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    initialization={initialization} form={form}/>

                <PlantInfoSelection
                    initialValue={initialValuesCache?.getValue("plantInfo")}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization}
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    filterOptions={descriptionFilter}
                    validator={validator}
                    form={form} reportName={"sr3"}/>
            </div>

            <div className={styles.singleColumnGrid}>

                <SPABOrderNumber
                    initialInputValue={initialValuesCache?.getValue("spabOrderNumber")}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("spabOrderNumberOperator", "CP")}
                    currentUser={currentUser}
                    validator={validator}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}/>

                <InvoiceProcessSelection
                    initialValue={initialValuesCache?.getValue("invoiceProcess")}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization}
                    validator={validator}
                    form={form}/>

                <ConfirmationNumber
                    initialInputValue={initialValuesCache?.getValue("confirmationNumber")}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("confirmationNumberOperator", "CP")}
                    currentUser={currentUser}
                    validator={validator}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}/>

                <InvoiceDirectionSelection
                    initialValue={initialValuesCache?.getValue("invoiceDirection")}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization}
                    validator={validator}
                    form={form}/>
            </div>
        </div>
        <Keybinding target={this}
                    onKey={ (e) => { if(e.key === 'Enter'){form.submit()} }} type='keyup'/>
    </Card>;
}

export class SR3AgGridColumnDef implements InfoPortalAgGridColumnDef {
    getDefaultColumnDefinitions(intl: IntlShape, user:PortalUser,distinctEntitlements: string[]): InfoPortalColumnDef[] {
        return [
            {field: "compcode", headerName: intl.formatMessage({"id": "grid-heading-company"})},
            {field: "busarea", headerName: intl.formatMessage({"id": "grid-heading-businessArea"})},
            {field: "partnerno", headerName: intl.formatMessage({"id": "grid-heading-partnerNumber"})},
            {field: "partnerinvno", headerName: intl.formatMessage({"id": "grid-heading-partnerInvoiceNumber"})},
            {field: "bmwdocno", headerName: intl.formatMessage({"id": "grid-heading-bmwInvoiceNumber"})},
            {
                field: "docdate",
                headerName: intl.formatMessage({"id": "grid-heading-documentDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.docdate, getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {
                field: "gross",
                headerName: intl.formatMessage({"id": "grid-heading-grossAmount"}),
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.gross),
                filter: "agTextColumnFilter",
                filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {field: "doccurrency", headerName: intl.formatMessage({"id": "grid-heading-currency"})},
            {field: "invdirection", headerName: intl.formatMessage({"id": "grid-heading-invoiceDirection"})},
            {field: "invstat", headerName: intl.formatMessage({"id": "grid-heading-invoiceStatus"})},
            {
                field: "rejectionletteruri",
                headerName: intl.formatMessage({"id": "grid-heading-rejectionLetteravailablefor6months"}),
                cellRenderer: (props: any) => RejectionLetterDownload(props, distinctEntitlements, intl),
                exportFormatter: params => params.data.rejectionLetterFileName || `IP_RL_${params.data.compcode.substring(0,2)}_${params.data.partnerno}_${params.data.bmwdocno || params.data.partnerinvno}.pdf` || ''
            },
            {field: "invstatcode", headerName: intl.formatMessage({"id": "grid-heading-invoiceStatusCode"})},
            {field: "invprocess", headerName: intl.formatMessage({"id": "grid-heading-invoicingProcess"})},
            {field: "cumulativeno", headerName: intl.formatMessage({"id": "grid-heading-cumulativeNumber"})},
            {
                field: "invreceiptdate",
                headerName: intl.formatMessage({"id": "grid-heading-invoiceReceiptDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.invreceiptdate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {field: "paymentnumber", headerName: intl.formatMessage({"id": "grid-heading-paymentAdviceNumber"})},
            {
                field: "paymentdate",
                headerName: intl.formatMessage({"id": "grid-heading-paymentDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.paymentdate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {
                field: "net",
                headerName: intl.formatMessage({"id": "grid-heading-netAmount"}),
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.net),
                filter: "agTextColumnFilter",
                filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {field: "paymentterm", headerName: intl.formatMessage({"id": "grid-heading-paymentTerms"})},
            {
                field: "duedate",
                headerName: intl.formatMessage({"id": "grid-heading-dueDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.duedate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {
                field: "basedate",
                headerName: intl.formatMessage({"id": "grid-heading-baseDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.basedate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {field: "dunnlevel", headerName: intl.formatMessage({"id": "grid-heading-dunningLevel"})},
            {
                field: "taxamount",
                headerName: intl.formatMessage({"id": "grid-heading-taxAmount"}),
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.taxamount),
                filter: "agTextColumnFilter",
                filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {field: "purchasename", headerName: intl.formatMessage({"id": "grid-heading-purchasingname"})},
            {field: "purchasemail", headerName: intl.formatMessage({"id": "grid-heading-purchasingemail"})},
            {
                field: "recipientname",
                headerName: intl.formatMessage({"id": "grid-heading-recipientofgoodsservicesname"})
            },
            {
                field: "recipientofgoodsservicesemail",
                headerName: intl.formatMessage({"id": "grid-heading-recipientofgoodsservicesemail"})
            },
            {field: "contactcomplaint", headerName: intl.formatMessage({"id": "grid-heading-contactforInquiries"})},
            {field: "doctype", headerName: intl.formatMessage({"id": "grid-heading-documentType"})},
            {field: "purchaseorderno", headerName: intl.formatMessage({"id": "grid-heading-purchaseOrderNumber"})},
            {field: "deliverynoteno", headerName: intl.formatMessage({"id": "grid-heading-deliveryNoteNumber"})}
        ];
    }

    getShortViewColumns(): string[] {
        return [
            "compcode",
            "partnerno",
            "partnerinvno",
            "bmwdocno",
            "docdate",
            "gross",
            "doccurrency",
            "invdirection",
            "invstat",
            "rejectionletteruri",
            "duedate",
            "purchaseorderno",
            "deliverynoteno"
        ];
    }

    getLineItemColumnDefinitions(intl: IntlShape, user:PortalUser): InfoPortalColumnDef[] {
        return [
            {field: "plantname", headerName: intl.formatMessage({"id": "grid-heading-plant"})},
            {field: "purchaseorderno", headerName: intl.formatMessage({"id": "grid-heading-purchaseOrderNumber"})},
            {field: "deliverynoteno", headerName: intl.formatMessage({"id": "grid-heading-deliveryNoteNumber"})},
            {
                field: "deliverynotedate",
                headerName: intl.formatMessage({"id": "grid-heading-deliveryNoteDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.deliverynotedate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator:dateComparatorUsingUserPreferences(user)
            },
            {field: "spaborderno", headerName: intl.formatMessage({"id": "grid-heading-spabOrderNumber"})},
            {field: "confirmationno", headerName: intl.formatMessage({"id": "grid-heading-confirmationNumber"})},
            {
                field: "receiptdate",
                headerName: intl.formatMessage({"id": "grid-heading-grscDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.receiptdate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator:dateComparatorUsingUserPreferences(user)
            },
            {field: "purchasename", headerName: intl.formatMessage({"id": "grid-heading-purchasingname"})},
            {field: "purchasemail", headerName: intl.formatMessage({"id": "grid-heading-purchasingemail"})},
            {
                field: "recipientname",
                headerName: intl.formatMessage({"id": "grid-heading-recipientofgoodsservicesname"})
            },
            {
                field: "recipientmail",
                headerName: intl.formatMessage({"id": "grid-heading-recipientofgoodsservicesemail"})
            },
            {field: "materialdocno", headerName: intl.formatMessage({"id": "grid-heading-grscDocumentNumber"})}
        ];
    }
}

export class SR3Report implements InfoPortalReport {
    private _config = new SR3AgGridColumnDef();

    getColumnsDefinition(): InfoPortalAgGridColumnDef {
        return this._config;
    }


    performSearch(form: FormInstance, currentUser: any, distinctEntitlements: string[]): Promise<InfoPortalGridService> {
        return retrieveSR3Report(form).then(result =>
            new InfoPortalGridService(
                new ReportViewConfig("SR3", this._config), result, distinctEntitlements));
    }

    hasExpertSearch(): boolean {
        return true;
    }

    renderExpertSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element {
        return <SelfBilledInvoiceExpertSearch initialization={initialization}
                                              currentUser={currentUser}
                                              intl={intl}
                                              form={form}
                                              distinctEntitlements={distinctEntitlements}
                                              rerenderDependentFields={rerenderDependentFields}
                                              initialValuesCache={initialValuesCache}
                                              reportEntitlement={this.getEntitlement()}/>;
    }

    renderSimpleSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element {
        return <SelfBilledInvoiceSimpleSearch initialization={initialization}
                                              currentUser={currentUser}
                                              intl={intl}
                                              form={form}
                                              distinctEntitlements={distinctEntitlements}
                                              rerenderDependentFields={rerenderDependentFields}
                                              initialValuesCache={initialValuesCache}
                                              reportEntitlement={this.getEntitlement()}/>;
    }

    getEntitlement(): string {
        return "display_sr@reports";
    }

    getReportName(): string {
        return "SR3";
    }
}