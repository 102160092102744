import React, { useState, useEffect, useRef, useCallback } from "react";
import { IAppComponentProps } from "../../../components";
import { Col, ConfigProvider, Form, Row, Spin, Typography, Tooltip } from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import { useIntl } from "react-intl";
import { getI18n } from "../../../utils/Utils";
import { EditOutlined } from "@ant-design/icons";
import { AgGridReact } from 'ag-grid-react';
import { GridApi } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-grid.css';
import { IntlShape } from "react-intl/src/types";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useLazyQuery } from "@apollo/client";
import { QUERY_GET_ALL_NOTIFICATIONS_DATA } from "../../survey/queries";
import enUS from 'antd/lib/locale/en_US';
import EditNotificationModal from "./EditNotificationModal";

const EditNotifications: React.FunctionComponent<IAppComponentProps> = (props) => {
  const { Paragraph } = Typography;
  const intl = useIntl();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const gridRef = useRef<AgGridReact>(null);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [gridApi, setGridApi] = useState<GridApi>(undefined);
  const [notificationTableData, setNotificationTableData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dateTimeFormat = "YYYY-MM-DD HH:mm";

  dayjs.extend(utc);

  const [getAllNotificationsInTable] = useLazyQuery(QUERY_GET_ALL_NOTIFICATIONS_DATA,
    { fetchPolicy: "network-only" }
  );

  const EditNotificationShowMoreLine1 = getI18n(
    'edit-notification-show-more-line1',
    'Change the End time to the past to remove a notification, for deleting a notification, we have to use sql for now' , intl
  );

  const onRowSelect = useCallback(() => {
    const selectedRowInTable = gridRef?.current.api?.getSelectedRows()[0];
    let selectedRow = null;
    if (selectedRowInTable !== undefined) {
      const { __typename, ...selectedRowInTheTable } = selectedRowInTable;
      selectedRow = selectedRowInTheTable;
    } else { selectedRow = null };
    console.log(`selectedRow :${selectedRow}`)
    setSelectedNotification(selectedRow)
  }, []);


  const editNotification = () => {
    setIsModalVisible(true);
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const cancelModal = () => {
    setIsModalVisible(false);
  };

  const NOTIFICATION_MANAGEMENT_TABLE_HEADERS = (intl: IntlShape) => [

    {
      headerName: getI18n("notification-grid-header-select", "Select", intl),
      checkboxSelection: true,
      colId: "select",
      filter: true
    },


    {
      headerName:getI18n("notification-management-table-header-notification-activeFromDate", "Active From Date", intl),
      field: "activeFromDate",
      colId: "activeFromDate"
    },
    {
      headerName: getI18n("notification-management-table-header-activeToDate", "Active To Date", intl ),
      field: "activeToDate",
      colId: "activeToDate"
    },
    {
      headerName: getI18n("notification-management-table-header-entitlements", "Entitlements", intl ),
      field: "entitlements",
      colId: "entitlements",
    },
    {
      headerName: getI18n("notification-management-table-header-linkText", "Link Text", intl ),
      field: "linkText",
      colId: "linkText"
    },
    {
      headerName: getI18n("notification-management-table-header-linkAddress", "Link Address", intl ),
      field: "linkAddress",
      colId: "linkAddress",
    },
  ];

  const refreshGrid = () => {
    setLoading(true);
    getAllNotificationsInTable().then(response => {
      if (response.data != null) {
        const notifications = response.data.getAllNotificationsInTable.map(notification => {
          return {...notification

          };
        });
        setNotificationTableData(notifications);
        setLoading(false);
      }
    });
  }



  useEffect(() => {
    setLoading(true);
    getAllNotificationsInTable().then(response => {
      if (response.data != null) {
        const notifications = response.data.getAllNotificationsInTable.map(notification => {
          return {...notification,
            activeFromDate: dayjs.utc(notification.activeFromDate).local().format(dateTimeFormat),
                      activeToDate: dayjs.utc(notification.activeToDate).local().format(dateTimeFormat),
          };
        });
        setNotificationTableData(notifications);
        setLoading(false);
      }
    }
    );
  }, [])

  return (<>      
        <ConfigProvider locale={enUS}>
        <Spin spinning={loading} size={'large'}>
          <PageContainer
            title={false}
            content={''}
            extraContent={''}
            style={{ background: 'white' }}
          >
            <div  className="pl15 ag-theme-balham">
              <Col>
          <Typography.Title level={5} className={"mb10"}>
            <Paragraph>
              <Row>{EditNotificationShowMoreLine1}</Row>
            </Paragraph>
          </Typography.Title>
            <Col md={24} sm={12} xs={24}>
              <Row>
                <Tooltip title={getI18n("editNotificationTooltipTitle", "Edit Notification", intl)}
                  key={"editNotification"}>
                  <EditOutlined
                    style={{ fontSize: "25px", color: "#444444" }}
                    onClick={editNotification}
                  />
                </Tooltip>
              </Row>

              <Row gutter={24}>
                <Col md={24} sm={12} xs={24} >
                <div style={{ flex: 'auto', height: '500px', paddingTop: "10px" }}>
                  {console.log(notificationTableData)}
                  <AgGridReact
                    ref={gridRef}
                    defaultColDef={{
                      enableValue: true,
                      sortable: true,
                      resizable: true,
                    }}
                    rowData={notificationTableData}
                    columnDefs={NOTIFICATION_MANAGEMENT_TABLE_HEADERS(intl)}
                    sideBar={false}
                    rowClass="agGridRows"
                    suppressMenuHide={true}
                    debug={false}
                    pagination={true}
                    rowSelection={"single"}
                    onSelectionChanged={onRowSelect}
                    paginationPageSize={15}
                    suppressRowClickSelection={true}
                    onGridReady={onGridReady}
                  />
                  </div>
                </Col>
              </Row>
            </Col>
            </Col>
            </div>
            <EditNotificationModal
              isModalVisible={isModalVisible}
              notificationValues={selectedNotification}
              handleCancel={cancelModal}
              key={'editNotificationModalKey'}
              afterClose={refreshGrid}
              />
          </PageContainer>
          </Spin>
        </ConfigProvider>
      
    </>
  )
}

export default EditNotifications;