import { saveAs } from "save-as";
import { renderInvoiceCellValue } from "../../../components/decorators/decorator";
import DraftDocumentRowOptions from "./DraftDocumentGridIconRenderer";
import { IntlShape } from "react-intl/src/types";
import {PortalUser} from "../../../components";
import {
  convertFromSpecifiedDateFormatToUserDefined,
  convertToUserSpecifiedNumericFormat
} from "../../users/UserFormatPreferenceServices";
import {ColDef} from "ag-grid-community/dist/lib/entities/colDef";


export const MYDOCS_TABLE_HEADERS_DRAFT = (intl: IntlShape, _distinctEntitlements: string[],user:PortalUser):ColDef[]  => [
  {
    headerName: intl.formatMessage({
      id: "document-draft-grid-header-Options",
      defaultMessage: "Options"
    }),
    field: "draftDocumentRowOptions",
    colId: "draftDocumentRowOptions",
    cellRenderer: DraftDocumentRowOptions,
    width: 150,
    lockPosition:true
  },
  {
    headerName: intl.formatMessage({
      id: "document-draft-grid-header-document-number",
      defaultMessage: "Document Number"
    }),
    field: "documentNumber",
    colId: "documentNumber",
    tooltipField:'documentNumber',
    headerTooltip: intl.formatMessage({
      id: "document-draft-grid-header-document-number",
      defaultMessage: "Document Number"
    }),
    filter: true,
    width: 210,
    checkboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerCheckboxSelection: true,
    autoHeight: true,
    lockPosition:true
  },
  {
    headerName: intl.formatMessage({
      id: "document-draft-grid-header-document-date",
      defaultMessage: "Document Date"
    }),
    field: 'documentDate',
    colId: 'documentDate',
    tooltipField:'documentDate',
    headerTooltip: intl.formatMessage({
      id: "document-draft-grid-header-document-date",
      defaultMessage: "Document Date"
    }),
    valueGetter: (params) => {
      if (params.data.documentDate) {
        return convertFromSpecifiedDateFormatToUserDefined(user, params.data.documentDate, "YYYY-MM-DD");
      }
      return "";
    },
    filter: true,
    width: 120
  },
  {
    headerName: intl.formatMessage({
      id: "document-draft-grid-header-document-type",
      defaultMessage: "Document Type"
    }),
    field: 'documentType',
    colId: 'documentType',
    tooltipField:'documentType',
    headerTooltip: intl.formatMessage({
      id: "document-draft-grid-header-document-type",
      defaultMessage: "Document Type"
    }),
    filter: true,
    width: 150
  },
 
  {
    headerName: intl.formatMessage({
      id: "document-draft-grid-header-BMW-Company",
      defaultMessage: "BMW Company"
    }),
    field: 'bmwCompany',
    colId: 'bmwCompany',
    tooltipField:'BmwCompany',
    headerTooltip: intl.formatMessage({
      id: "document-draft-grid-header-BMW-Company",
      defaultMessage: "BMW Company"
    }),
    filter: true,
    width: 100
  },
 
  {
    headerName: intl.formatMessage({
      id: "document-draft-grid-header-partner-number",
      defaultMessage: "Partner Number"
    }),
    field: 'partnerNumber',
    colId: 'partnerNumber',
    filter: true,
    width: 150,
    tooltipField: 'partnerNumber',
    headerTooltip: intl.formatMessage({
      id: "document-draft-grid-header-partner-number",
      defaultMessage: "Partner Number"
    }),
  },
  {
    headerName: intl.formatMessage({
      id: "document-draft-grid-header-partner-name",
      defaultMessage: "Partner Name"
    }),
    field: 'partnerName',
    colId: 'partnerName',
    filter: true,
    width: 160,
    headerTooltip: intl.formatMessage({
      id: "document-draft-grid-header-partner-name",
      defaultMessage: "Partner Name"
    }),
    cellRendererFramework: (params) => {
      console.log(`params.value: ${params.value}`);
      return params.value !== null && renderInvoiceCellValue(params.value) !== null  ? renderInvoiceCellValue(params.value):'';
    },
  },
  {
    headerName: intl.formatMessage({
      id: "document-draft-grid-header-currency",
      defaultMessage: "Currency"
    }),
    field: 'currency',
    colId: 'currency',
    tooltipField: 'currency',
    headerTooltip:intl.formatMessage({
      id: "document-draft-grid-header-currency",
      defaultMessage: "Currency"
    }),
    filter: true,
    width: 100
  },

  {
    headerName: intl.formatMessage({
      id: "document-draft-grid-header-gross-amount",
      defaultMessage: "Gross Amount"
    }),
    field: 'grossAmount',
    colId: 'grossAmount',
    valueFormatter: params => convertToUserSpecifiedNumericFormat(user,params.data.grossAmount),
    headerTooltip: intl.formatMessage({
      id: "document-draft-grid-header-gross-amount",
      defaultMessage: "Gross Amount"
    }),
    tooltipField: 'grossAmount',
    filter: true,
    type: 'rightAligned',
    width: 150
  },
  {
    headerName: intl.formatMessage({
      id: "document-draft-grid-header-tax-amount",
      defaultMessage: "Tax Amount"
    }),
    field: 'taxAmount',
    colId: 'taxAmount',
    valueFormatter: params => convertToUserSpecifiedNumericFormat(user,params.data.taxAmount),
    tooltipField: 'taxAmount',
    headerTooltip: intl.formatMessage({
      id: "document-draft-grid-header-tax-amount",
      defaultMessage: "Tax Amount"
    }),
    filter: true,
    type: 'rightAligned',
    width: 120
  },
  {
    headerName: intl.formatMessage({
      id: "document-draft-grid-header-last-update",
      defaultMessage: "Last Update"
    }),
    field: 'lastUpdate',
    colId: 'lastUpdate',
    tooltipField: 'lastUpdate',
    headerTooltip:intl.formatMessage({
      id: "document-draft-grid-header-last-update",
      defaultMessage: "Last Update"
    }),
    valueGetter: (params) => {
      if (params.data.lastUpdate) {
        return convertFromSpecifiedDateFormatToUserDefined(user, params.data.lastUpdate, "YYYY-MM-DD");
      }
      return "";
    },
    filter: true,
    width: 150
  },
  {
    headerName: intl.formatMessage({
      id: "document-draft-grid-header-tp-user",
      defaultMessage: "TP User"
    }),
    field: 'tpUser',
    colId: 'tpUser',
    tooltipField: 'tpUser',
    headerTooltip: intl.formatMessage({
      id: "document-draft-grid-header-tp-user",
      defaultMessage: "TP User"
    }),
    filter: true,
    width: 150
  },
];

export const base64toZipFile = (b64Data, filename) => {
  const sliceSize = 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: "application/zip" });
  saveAs(blob, filename);
};