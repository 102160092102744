import React from 'react';
import { IntlShape } from 'react-intl';
import { Col, Collapse, FormInstance, Row } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import { getAgGridLocalization } from '../../../info-portal/components/grid/GridUtil';
import { ButtonRendererComponents, GetYellowRowStyle } from './CustomRenderers';
import { IAppComponentProps } from '../../../../components';
import {
    AuditIdField,
    AuditIdGroupingField,
    BusinessAreaCodeField,
    BusinessAreaIdField,
    BusinessAreaNameField,
    CompanyCodeField,
    DeleteButton,
    EditButton,
    GridRowSaveButton as SaveButton,
    InsertTimeField,
    InsertUserField,
    PhraseIdField,
    RefreshButton,
    UpdateTimeField,
    UpdateUserField,
    SearchButton,
    ClearFiltersButton,
    AuditDateFromToSelectionField,
    BusinessAreasCodeFormField,
    BusinessAreaNameFormField,
    SubmitButton,
    CancelUpdateButton,
    CompanyCodeDropDownField,
    InsertUserFormField,
    UpdateUserFormField,
    TextInputFormField,
    BusinessAreaCodeDropDownField,
    agGridFilterTypes,
    GenericGridField,
    agGridCellRendererTypes,
} from './FieldDictionary';
import { TableColumnMetaData, TableMetaData } from 'src/__generated__/types';

//Add all field components here that needs to be on a grid
export const EDIT_TABLE_GRID_HEADERS = (
    intl: IntlShape,
    onDelete: any,
    onEdit: any,
    loadedMetaDataList: TableMetaData[]
) => {
    const components: any = [];
    components.push(EditButton(intl, onEdit));
    components.push(DeleteButton(intl, onDelete));
    loadedMetaDataList.forEach((metaData: TableMetaData) =>
        metaData.columnNames.map((column: TableColumnMetaData) => {
            if (column.columnName === 'Business_Area_Code') {
                components.push(
                    GenericGridField(
                        intl,
                        'businessAreaCode',
                        column.columnName,
                        agGridFilterTypes.agTextColumnFilter,
                        'Business Area Code'
                    )
                );
            } else if (column.columnName === 'Business_Area_Name') {
                components.push(
                    GenericGridField(
                        intl,
                        'businessAreaName',
                        column.columnName,
                        agGridFilterTypes.agTextColumnFilter,
                        'Business Area Name'
                    )
                );
            } else if (column.columnName === 'Company_Id') {
                components.push(
                    GenericGridField(
                        intl,
                        'companyCode',
                        'company_Code',
                        agGridFilterTypes.agTextColumnFilter,
                        'Company Code'
                    )
                );
                components.push(
                    GenericGridField(
                        intl,
                        'companyName',
                        'company_Name',
                        agGridFilterTypes.agTextColumnFilter,
                        'Business Name'
                    )
                );
            } else if (column.columnName === 'Phrase_Id') {
                components.push(
                    GenericGridField(
                        intl,
                        'phraseId',
                        column.columnName,
                        agGridFilterTypes.agTextColumnFilter,
                        'Phrase Id'
                    )
                );
            } else if (column.columnName === 'Insert_User') {
                components.push(
                    GenericGridField(
                        intl,
                        'insertUser',
                        column.columnName,
                        agGridFilterTypes.agTextColumnFilter,
                        'Insert User',
                        100,
                        agGridCellRendererTypes.uppercase
                    )
                );
            } else if (column.columnName === 'Insert_Time') {
                components.push(
                    GenericGridField(
                        intl,
                        'insertTime',
                        column.columnName,
                        agGridFilterTypes.agDateColumnFilter,
                        'Insert time'
                    )
                );
            } else if (column.columnName === 'Update_User') {
                components.push(
                    GenericGridField(
                        intl,
                        'updateUser',
                        column.columnName,
                        agGridFilterTypes.agTextColumnFilter,
                        'Update User',
                        100,
                        agGridCellRendererTypes.uppercase
                    )
                );
            } else if (column.columnName === 'Update_Time') {
                components.push(
                    GenericGridField(
                        intl,
                        'updateTime',
                        column.columnName,
                        agGridFilterTypes.agDateColumnFilter,
                        'Update time'
                    )
                );
            } else if (column.columnName === 'Business_Unit_Code') {
                components.push(
                    GenericGridField(
                        intl,
                        'businessUnitCode',
                        column.columnName,
                        agGridFilterTypes.agDateColumnFilter,
                        'Business Unit Code'
                    )
                );
            } else if (column.columnName === 'Business_Unit_Id') {
                components.push(
                    GenericGridField(
                        intl,
                        'businessUnitId',
                        column.columnName,
                        agGridFilterTypes.agDateColumnFilter,
                        'Business Unit Id'
                    )
                );
            } else if (column.columnName === 'Business_Unit_Name') {
                components.push(
                    GenericGridField(
                        intl,
                        'businessUnitName',
                        column.columnName,
                        agGridFilterTypes.agDateColumnFilter,
                        'Business Unit Name'
                    )
                );
            }
        })
    );
    return components;
};

export const AUDIT_TABLE_MASTER_HEADERS = (intl: IntlShape) => [
    AuditIdGroupingField(intl),
    BusinessAreaIdField(intl),
    CompanyCodeField(intl),
    BusinessAreaCodeField(intl),
    BusinessAreaNameField(intl),
    PhraseIdField(intl),
    InsertUserField(intl),
    InsertTimeField(intl),
    UpdateUserField(intl),
    UpdateTimeField(intl),
];

export const AUDIT_TABLE_DETAIL_HEADERS = (intl: IntlShape) => [
    AuditIdField(intl),
    BusinessAreaIdField(intl),
    CompanyCodeField(intl),
    BusinessAreaCodeField(intl),
    BusinessAreaNameField(intl),
    PhraseIdField(intl),
    InsertUserField(intl),
    InsertTimeField(intl),
    UpdateUserField(intl),
    UpdateTimeField(intl),
];

export function MASTER_DATA_BUTTONS_TOP(intl: IntlShape, saveData: any, refreshData: any) {
    return (
        <Row gutter={24} className={'pl20'}>
            {SaveButton(intl, saveData)},{RefreshButton(intl, refreshData)}
        </Row>
    );
}

export function MASTER_DATA_AUDIT_BUTTONS_TOP(
    intl: IntlShape,
    refreshData: any,
    handleFormSearch: any,
    clearFilters: any
) {
    return (
        <Row gutter={24} className={'pl20'}>
            {SearchButton(intl, handleFormSearch, 1)},{RefreshButton(intl, refreshData, 1)},
            {ClearFiltersButton(intl, clearFilters, 1)}
        </Row>
    );
}

export function AUDIT_DATA_SEARCH_FORM_FIELDS(
    props: IAppComponentProps,
    vertical: boolean,
    form: FormInstance<any>,
    onFieldChange: any,
    hidden: boolean
) {
    return (
        <>
            <Row gutter={24} className={'pl20'}>
                {AuditDateFromToSelectionField(props, form, vertical, 1)}
                {InsertUserFormField(props.intl, false, 4)}
                {UpdateUserFormField(props.intl, false, 4, onFieldChange)}
            </Row>

            <Collapse ghost={true}>
                <Collapse.Panel header="Advanced Search" key="1">
                    <Row gutter={24} className={'pl20'} hidden={hidden}>
                        {BusinessAreasCodeFormField(props.intl, false, 2, onFieldChange)}
                        {BusinessAreaNameFormField(props.intl, false, 2, onFieldChange)}
                        {CompanyCodeDropDownField(props, 2, onFieldChange)}
                    </Row>
                </Collapse.Panel>
            </Collapse>
        </>
    );
}

//Add all components here that needs to be on the input/update form
interface MasterDataInputFieldsProps {
    readonly props: IAppComponentProps;
    readonly submit: any;
    readonly form: FormInstance<any>;
    readonly handleInputChange?: any;
    readonly handleSelect?: any;
    readonly updateMode?: any;
    readonly cancelUpdate?: any;
    readonly loadedMetaDataList?: TableMetaData[];
}

export function MASTER_DATA_FROM_INPUT_FIELDS({
    props,
    submit,
    form,
    handleInputChange,
    handleSelect,
    updateMode,
    cancelUpdate,
    loadedMetaDataList,
}: MasterDataInputFieldsProps) {
    return (
        <Row gutter={24} className={'pl20'} style={{ backgroundColor: '#f0f2f5' }}>
            {loadedMetaDataList.map((metaData: TableMetaData) =>
                metaData.columnNames.map((column: TableColumnMetaData) => {
                    console.log(column.columnName);
                    if (column.columnName === 'Business_Area_Code') {
                        return (
                            <React.Fragment key={column.columnName}>
                                {TextInputFormField(
                                    props.intl,
                                    'businessAreaCode',
                                    'Business Area Code',
                                    updateMode,
                                    4,
                                    handleInputChange
                                )}
                            </React.Fragment>
                        );
                    }
                    if (column.columnName === 'Business_Area_Name') {
                        return (
                            <React.Fragment key={column.columnName}>
                                {TextInputFormField(
                                    props.intl,
                                    'businessAreaName',
                                    'Business Area Name',
                                    updateMode,
                                    4,
                                    handleInputChange
                                )}
                            </React.Fragment>
                        );
                    }
                    if (column.columnName === 'Business_Unit_Name') {
                        return (
                            <React.Fragment key={column.columnName}>
                                {TextInputFormField(
                                    props.intl,
                                    'businessUnitName',
                                    'Business Unit Name',
                                    updateMode,
                                    4,
                                    handleInputChange
                                )}
                            </React.Fragment>
                        );
                    }
                    if (column.columnName === 'Business_Unit_Code') {
                        return (
                            <React.Fragment key={column.columnName}>
                                {TextInputFormField(
                                    props.intl,
                                    'businessUnitCode',
                                    'Business Unit Code',
                                    updateMode,
                                    4,
                                    handleInputChange
                                )}
                            </React.Fragment>
                        );
                    }
                    if (column.columnName === 'Business_Area_Id') {
                        return (
                            <React.Fragment key={column.columnName}>
                                {BusinessAreaCodeDropDownField(props, form, 4)}
                            </React.Fragment>
                        );
                    }
                    if (column.columnName === 'Company_Id') {
                        return (
                            <React.Fragment key={column.columnName}>
                                {CompanyCodeDropDownField(props, 4, handleSelect)}
                            </React.Fragment>
                        );
                    }
                    if (column.columnName === 'Phrase_Id') {
                        return (
                            <React.Fragment key={column.columnName}>
                                {TextInputFormField(
                                    props.intl,
                                    'phraseId',
                                    'Phrase Id',
                                    updateMode,
                                    4,
                                    handleInputChange
                                )}
                            </React.Fragment>
                        );
                    }
                    if (column.columnName === 'Business_Unit_Code') {
                        return (
                            <React.Fragment key={column.columnName}>
                                {TextInputFormField(
                                    props.intl,
                                    'businessUnitCode',
                                    'Business Unit Code',
                                    updateMode,
                                    4,
                                    handleInputChange
                                )}
                            </React.Fragment>
                        );
                    }
                    if (column.columnName === 'Business_Unit_Id') {
                        return (
                            <React.Fragment key={column.columnName}>
                                {TextInputFormField(
                                    props.intl,
                                    'Business_Unit_Id',
                                    'Business_Unit_Id',
                                    false,
                                    4,
                                    handleInputChange
                                )}
                            </React.Fragment>
                        );
                    }
                    return null;
                })
            )}
            {SubmitButton(props.intl, submit, 1, updateMode)}
            {CancelUpdateButton(props.intl, cancelUpdate, 1, updateMode)}
        </Row>
    );
}

//TODO the type any[] with the loadedDataList should be replaced with the correct type
export function MASTER_DATA_TABLE_GRID(
    loadedDataList: any[],
    onDeleteLine: any,
    onEditLine: any,
    onGridReady: any,
    props: IAppComponentProps,
    loadedMetaDataList: TableMetaData[]
) {
    return (
        <Row gutter={24} className={'pl20'}>
            <Col span={22}>
                <div style={{ flex: 'auto', height: '500px', paddingTop: '10px' }} className="ag-theme-balham">
                    <AgGridReact
                        defaultColDef={{
                            enableValue: true,
                            sortable: true,
                            resizable: true,
                        }}
                        rowData={loadedDataList}
                        columnDefs={EDIT_TABLE_GRID_HEADERS(props.intl, onDeleteLine, onEditLine, loadedMetaDataList)}
                        localeText={getAgGridLocalization(props.intl)}
                        sideBar={false}
                        rowClass="agGridRows"
                        suppressMenuHide={true}
                        debug={false}
                        pagination={true}
                        paginationPageSize={15}
                        suppressRowClickSelection={true}
                        onGridReady={onGridReady}
                        rowSelection={'multiple'}
                        components={ButtonRendererComponents}
                        getRowStyle={GetYellowRowStyle}
                    />
                </div>
            </Col>
        </Row>
    );
}

export function MASTER_DATA_AUDIT_TABLE_GRID(
    masterGridView: any,
    masterGridApi: any,
    entitlementAuditReport: any[],
    onMasterGridReady: any,
    selectMasterRow: any,
    detailCellRendererParams: any,
    props: IAppComponentProps
) {
    return (
        <Row gutter={24}>
            <Col span={20} className="mb5">
                <div style={{ flex: 'auto', height: '500px', paddingTop: '0px' }} className="ag-theme-balham">
                    <AgGridReact
                        defaultColDef={{
                            enableValue: true,
                            sortable: true,
                            resizable: true,
                        }}
                        // columnDefs={ENTITLEMENT_RULES_TABLE_HEADERS()}
                        columnDefs={masterGridView.current.getView()}
                        rowData={entitlementAuditReport || []}
                        localeText={getAgGridLocalization(props.intl)}
                        sideBar={false}
                        api={masterGridApi}
                        rowClass="agGridRows"
                        rowSelection="single"
                        suppressMenuHide={true}
                        debug={false}
                        pagination={true}
                        paginationPageSize={15}
                        suppressRowClickSelection={true}
                        onGridReady={onMasterGridReady}
                        onRowSelected={selectMasterRow}
                        masterDetail={true}
                        detailCellRendererParams={detailCellRendererParams}
                    />
                </div>
            </Col>
        </Row>
    );
}
