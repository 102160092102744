import { act } from '@testing-library/react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer'; // Import enableMapSet
import { IStoreMasterDataFilters } from './IStoredMasterDataSchemas';
import { FormInstance } from 'antd';

// Enable the MapSet plugin
enableMapSet();

interface MasterDataState {
    masterDataSchema: IStoreMasterDataFilters;
}

const initialState: MasterDataState = {
    masterDataSchema: {
        formFieldFilter: new Map<string, FormInstance<any>>(),
    },
};

const masterDataSlice = createSlice({
    name: 'MasterDataFilters',
    initialState,
    reducers: {
        updateAuditFilters(state, action: PayloadAction<FormInstance<any>, string>) {
            console.log("Save form field filter");
            console.dir(action.payload.getFieldsValue());
            state.masterDataSchema.formFieldFilter.set(action.type, action.payload.getFieldsValue());
        }
    },
});

export const { updateAuditFilters } = masterDataSlice.actions;
export default masterDataSlice.reducer;
