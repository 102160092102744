import { gql } from '@apollo/client';

export const GET_ECAP_DOCUMENT_RECORD = gql`
    query getDocumentRecord($documentId: Float!, $mode: String!) {
        getDocumentRecord(documentId: $documentId, mode: $mode) {
            document {
                id
                documentTypeId
                documentStatusId
                companyPartnerId
                companyCode
                partnerVatCodeId
                bmwIsRetailOutlet
                bmwBusinessAreaId
                bmwBusinessUnitId
                bmwContactName
                bmwContactDepartment
                bmwContactDepartment
                bmwContactTelephone
                bmwContactTelephone
                bmwContactEmail
                currencyCodeId
                localCurrencyCodeId
                exchangeRate
                exchangeRateDate
                documentNumber
                documentDate
                orderNumber
                deliveryNumber
                deliveryDate
                documentFormatId
                processCodeId
                originalInvoiceNumber
                agreementReference
                headerText
                footerText
                grossAmount
                netAmount
                taxAmount
                taxableAmount
                localCurrencyGrossAmount
                localCurrencyTaxAmount
                localCurrencyTaxableAmount
                localCurrencyNetAmount
                paymentTerms
                bmwGroupPaymentRequest
                companyVatCodeId
                documentType
                documentTypePhraseId
                documentTypeDescription
                documentTypeDescriptionPhraseId
                documentStatus
                documentStatusPhraseId
                partnerVatCode
                partnerVatCountry
                partnerId
                partnerName
                companyName
                companyVatCode
                companyId
                bmwCompanyTaxId
                partnerNo
                earlyPaymentDiscountDate
                earlyPaymentDiscountDueDate
                earlyPaymentDescription
                isTemplateDocument
                bmwCompanyCity
                bmwCompanyCountryCode
                bmwCompanyPostalCode
                bmwCompanyStreet1
                bmwCompanyStreet2
                isOrderNumberNotRequired
            }
            shippingInformation {
                ecDocumentShippingInfoId
                shipFromCity
                shipFromContact
                shipFromCountryCode
                shipFromCountry
                shipFromCountryPhraseId
                shipFromDepartment
                shipFromPostalCode
                shipFromRegionCode
                shipFromRegion
                shipFromRegionPhraseId
                shipFromStreet
                shipToCity
                shipFromName
                shipToContact
                shipToCountryCode
                shipToCountry
                shipToCountryPhraseId
                shipToDepartment
                shipToPostalCode
                shipToRegionCode
                shipToRegion
                shipToRegionPhraseId
                shipToStreet
                shipToName
            }
            supplierDetails {
                documentPartnerProfileId
                bankAccountNumber
                bankBranchCode
                bankIban
                bankSwiftBicNumber
                placeOfRegistration
                managingDirector
                legalFormType
                legalFormTypeId
                liquidationRemark
                inLiquidation
                hasFiscalRepresentative
                chairmanOfSupervisoryBoard
                companyRegistrationNumber
                companyRegistryData
                contactDepartment
                contactEmail
                contactName
                contactTelephone
                fiscalRepresentativeAddressCity
                fiscalRepresentativeAddressPostalCode
                fiscalRepresentativeAddressCountryCode
                fiscalRepresentativeAddressCountry
                fiscalRepresentativeAddressCountryPhraseId
                fiscalRepresentativeAddressRegionCode
                fiscalRepresentativeAddressRegion
                fiscalRepresentativeAddressRegionPhraseId
                fiscalRepresentativeAddressStreet
                fiscalRepresentativeName
                fiscalRepresentativeVatId
                partnerVatCode
                partnerVatCountryCode
                companyVatCode
                companyVatCountryCode
                partnerNo
                partnerName
                systemCode
                street1
                street2
                postalCode
                regionCode
                city
                countryCode
                partnerTaxId
                companyCode
                companyName
            }
            lineItems {
                ecDocumentDetailId
                ecDocumentId
                lineNo
                deliveryDate
                deliveryNo
                quantity
                grossAmount
                itemDescription
                netAmount
                orderNumber
                taxAmount
                taxRate
                unitPrice
                unitQuantity
                unitOfMeasureFreeText
                bmwPartnerNumber
                unitOfMeasureId
                toolLocation
                toolSupplyingCountry
                srmReferenceNumber
                inventoryNumber
                taxExemptionTypeId
                taxLawReferenceId
                exemptionReason
            }
            agreementHistory {
                id
                agreementAcceptedDate
                companyPartnerId
            }
            documentTemplate {
                id
                templateName
            }
            masterDataRefreshed
            changedFields
        }
    }
`;

export const GET_PARTNER_VAT_INFO_BY_PARTNER_NO = gql`
    query getPartnerVATInfoByPartnerNo($partnerNo: String!, $companyCode: String!) {
        getPartnerVATInfoByPartnerNo(partnerNo: $partnerNo, companyCode: $companyCode) {
            partnerId
            partnerName
            taxId
            vatCodes {
                country
                partnerName
                partnerName
                vatCode
                partnerVatId
            }
        }
    }
`;

export const GET_COUNTRY_LIST = gql`
    query getCountryList {
        getCountryList {
            id
            country
            phraseId
            isEuMemberState
            defaultCurrencyCode {
                id
                currencyName
                currencySymbol
                decimalPlaces
            }
        }
    }
`;

export const GET_COUNTRY_INFO = gql`
    query getCountryInfo($countryId: String!) {
        getCountryInfo(countryId: $countryId) {
            id
            isEuMemberState
            country
            defaultCurrencyCode {
                currencyName
                currencySymbol
                decimalPlaces
                id
            }
        }
    }
`;

export const GET_REGION_LIST = gql`
    query getRegionList {
        getRegionList {
            id {
                countryCode
                regionCode
            }
            region
            phraseId
            countryCode {
                id
                isEuMemberState
                country
                defaultCurrencyCode {
                    currencyName
                    currencySymbol
                    decimalPlaces
                    id
                }
            }
        }
    }
`;

export const GET_REGION_INFO = gql`
    query getRegionInfo($regionId: String!) {
        getRegionInfo(regionId: $regionId) {
            id {
                countryCode
                regionCode
            }
            region
            phraseId
            countryCode {
                id
                isEuMemberState
                country
                defaultCurrencyCode {
                    currencyName
                    currencySymbol
                    decimalPlaces
                    id
                }
            }
        }
    }
`;

export const GET_CURRENCY_LIST = gql`
    query getCurrencyList {
        getCurrencyList {
            id
            currencyName
            phraseId
            currencySymbol
            decimalPlaces
        }
    }
`;
export const RETRIEVE_SUPPORTING_DOCUMENTS = gql`
    query AllSupporingDocumentsResponse($docId: String!) {
        retrieveSupportingDocuments(documentId: $docId) {
            attachmentId
            blobId
            blobPath
            fileName
            documentId
            attachmentType
            sizeInKB
        }
    }
`;

export const GET_BUSINESS_UNIT_LIST = gql`
    query getBusinessUnitList {
        getBusinessUnitList {
            id
            businessUnitCode
            businessUnitName
            insertTime
            insertUser
            updateTime
            updateUser
            phraseId
            businessArea {
                id
                businessAreaCode
                businessAreaName
                insertTime
                insertUser
                updateTime
                updateUser
                phraseId
                company {
                    companyId
                    companyCode
                    companyName
                }
            }
        }
    }
`;

export const GET_BUSINESS_AREA_LIST = gql`
    query getBusinessAreaList {
        getBusinessAreaList {
            id
            businessAreaCode
            businessAreaName
            insertTime
            insertUser
            updateTime
            updateUser
            phraseId
            company {
                companyId
                companyCode
                companyName
            }
        }
    }
`;

//Deprecated service as we now get the data based on partner number and company code
export const ALL_COMPANY_INFO_BY_PARTNER_ID = gql`
    query allCompanyInfoByCompanyPartnerId($companyCode: String!, $partnerId: Float!) {
        allCompanyInfoByCompanyPartnerId(companyCode: $companyCode, partnerId: $partnerId) {
            companyId
            companyInfoId
            ceo
            chairman
            companyNameAddon
            companyNameShort
            contactEmail
            email
            fax
            fullAddress
            management
            phone
            registerCourt
            registerCourtShort
            taxId
            website
            companyCode
            companyName
            partnerId
            partnerNo
            partnerName
            systemId
            systemCode
            companyPartnerId
        }
    }
`;

export const GET_COMPANY_INFO_BY_PARTNER_NO = gql`
    query getCompanyInfoByCompanyPartnerNo($companyCode: String!, $partnerNo: String!) {
        getCompanyInfoByCompanyPartnerNo(companyCode: $companyCode, partnerNo: $partnerNo) {
            companyId
            companyInfoId
            ceo
            chairman
            companyNameAddon
            companyNameShort
            contactEmail
            email
            fax
            fullAddress
            management
            phone
            registerCourt
            registerCourtShort
            taxId
            website
            companyCode
            companyName
            partnerId
            partnerNo
            partnerName
            systemId
            systemCode
            companyPartnerId
        }
    }
`;

export const GET_COMPANY_INFO_BY_PARTNER_NO_CATEGORY = gql`
    query getCompanyInfoByCompanyPartnerNoCategory($companyCode: String!, $partnerNo: String!,$systemCode: String!, $category: String!) {
        getCompanyInfoByCompanyPartnerNoCategory(companyCode: $companyCode, partnerNo: $partnerNo,systemCode: $systemCode, category: $category) {
            companyId
            companyInfoId
            ceo
            chairman
            companyNameAddon
            companyNameShort
            contactEmail
            email
            fax
            fullAddress
            management
            phone
            registerCourt
            registerCourtShort
            taxId
            website
            companyCode
            companyName
            partnerId
            partnerNo
            partnerName
            systemId
            systemCode
            companyPartnerId
        }
    }
`;




export const GET_COMPANY_ADDRESS_DETAILS = gql`
    query getCompanyAddressDetailsByCompanyId($companyId: Float!, $addressType: String!) {
        getCompanyAddressDetailsByCompanyId(companyId: $companyId, addressType: $addressType) {
            addressType
            AddressTypeCode
            addressTypePhraseId
            city
            companyAddressId
            country
            countryCode
            poBox
            postalCode
            street1
            street2
        }
    }
`;

// Deprecated service (20230918), we now lookup details based on Partner no and company code
export const GET_PARTNER_COMPANY_RELATIONSHIP = gql`
    query getPartnerByCompanyPartnerId($companyPartnerId: Float!) {
        getPartnerByCompanyPartnerId(companyPartnerId: $companyPartnerId) {
            systemId
            systemCompanyId
            companyId
            companyPartnerId
            partnerId
            systemCode
            companyCode
            partnerNo
            partnerName
            category
            companyName
        }
    }
`;

export const GET_PARTNER_ADDRESS_DETAILS = gql`
    query getPartnerAddressByPartnerId($partnerId: Float!, $pageNumber: Float!, $pageSize: Float!) {
        getPartnerAddressByPartnerId(partnerId: $partnerId, pageNumber: $pageNumber, pageSize: $pageSize) {
            street1
            street2
            city
            countryCode
            country
            regionCode
            region
            postalcodePobox
            postalCode
            updateTime
            partnerNo
            partnerName
        }
    }
`;

export const GET_RULES_FOR_RULE_TYPE = gql`
    query getFilteredRulesWithRuleType($ruleType: String!) {
        getFilteredRulesWithRuleType(ruleType: $ruleType) {
            ruleId
            ruleTypeId
            ruleType
            stopRule
            priority
            ruleCategory
            definition
        }
    }
`;

export const MY_DOCS_DRAFT_DATA = gql`
    query DraftDocumentList($draftListInput:DraftListInput!){
        DraftDocumentList(draftListInput:$draftListInput) {
            
            id
            documentNumber
            documentDate
            documentType
            bmwCompany
            partnerNumber
            partnerName
            currency
            grossAmount
            taxAmount
            lastUpdate
            phraseId
            tpUser
        }
    }
`;

export const ALL_VAT_CODES_BY_COMPANY_ID = gql`
    query allVatCodesByCompanyId($companyId: Float!) {
        allVatCodesByCompanyId(companyId: $companyId) {
            company {
                companyId
                companyCode
                companyName
            }
            id
            vatCode
            countryCode {
                id
                country
                isEuMemberState
                defaultCurrencyCode {
                    id
                    currencyName
                    currencySymbol
                    decimalPlaces
                }
            }
        }
    }
`;


export const DEFAULT_COMPANY_TAX_ID = gql`
    query getCompanyDefaultTaxId($companyCode: String!, $companyVatCountryCode: String!) {
        getCompanyDefaultTaxId(companyCode: $companyCode , companyVatCountryCode: $companyVatCountryCode) {
            companyCode {
                companyId
                companyCode
                companyName
            }
            companyTaxDefaultId
            countryCode {
                id
                country
                phraseId
                isEuMemberState
                defaultCurrencyCode {
                    id
                    currencyName
                    phraseId
                    phraseId
                    currencySymbol
                    decimalPlaces
                }
            }
            taxId
        }
    }
`;
//

export const GET_TAX_REFERENCES = gql`
    query TaxLawReferenceAPI {
        getTaxLawReferences {
              id
              phraseId
              taxLawReference
              taxTypeCode
        }
        getTaxExemptions {
              id
              phraseId
              taxExemptionType
              taxLawReferenceId
        }
    }
`;

export const GET_UNIT_OF_MEASURES = gql`
    query UnitOfMeasureAPI {
        getAllUnitOfMeasureValues {
              decimalPlaces
              id
              unitOfMeasureAllowDecimal
              unitOfMeasureDescription
              unitOfMeasureSymbol
        }
    }
`;

//Deprecated, replaced with INVOICE_AGREEMENT_HISTORY_V2
export const INVOICE_AGREEMENT_HISTORY = gql`
    query GET_invoice_agreement_history($companyPartnerId : Float!) {
        getAgreementHistory(companyPartnerId : $companyPartnerId){
            agreementHistoryId
            agreementId
            agreementName
            agreementAcceptedByUserId
            agreementAcceptedDate
            agreementAcceptedUser
            agreementDescription
            blobId
        }
    }
`;

export const INVOICE_AGREEMENT_HISTORY_V2 = gql`
    query GET_invoice_agreement_history_V2($partnerNo : String!,$companyCode : String!,$systemCode : String!) {
        getAgreementHistoryV2(partnerNo: $partnerNo,companyCode: $companyCode, systemCode: $systemCode ){
            agreementHistoryId
            agreementId
            agreementName
            agreementAcceptedByUserId
            agreementAcceptedDate
            agreementAcceptedUser
            agreementDescription
            blobId
        }
    }
`;export const INVOICE_AGREEMENT_INFO = gql`
    query GET_invoice_agreement_info($companyCode : String!,$languageCode : String!,$moduleCode : String!,$category : String!) {
        getAgreementInfo(companyCode: $companyCode,languageCode: $languageCode, moduleCode: $moduleCode,category: $category ){
            agreementId
            agreementName          
            agreementDescription
            blobId
            languageCode
        }
    }
`;

export const DOWNLOAD_ECAP_DOCUMENT_BY_BLOB_ID = gql`
    query DownloadEcapDocumentAPI($blobId: Float!){
        downloadECapDocument(blobId : $blobId)
        {
            success
            value {
                encodedFileData
                fileName
                mimeType
            }
        }
    }
`;

