import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { getI18n } from '../../../utils/Utils';
import AddNotification from './AddNotification';
import { IAppComponentProps } from '../../../components/';
import { ConfigProvider, Spin, Tabs } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import deDe from 'antd/lib/locale/de_DE';
import { PageContainer } from '@ant-design/pro-layout';
import styles from '../../../styles/scss/portal.scss';
import NotificationsTemplate from './NotificationsTemplate';
import EditNotifications from './EditNotifications';

const NotificationManagement: React.FunctionComponent<IAppComponentProps> = (props) => {

    const intl = useIntl();
    const [loading, isLoading] = useState(false);
    const { TabPane } = Tabs;

    const tabItems = [

        {
            defaultActiveKey: '1',
            className:"users-tabs ml20",
            key: '1',
            label: getI18n('add-notification', 'Add Notification', intl),
            children: 
            <AddNotification intl={intl}
            />
        },
        {
            defaultActiveKey: '2',
            className:"users-tabs ml20",
            key: '2',
            label: getI18n('edit-notification', 'Edit Notification', intl),
            children: 
            <EditNotifications intl={intl}
            />
        },
        {
            defaultActiveKey: '3',
            className:"users-tabs ml20",
            key: '3',
            label: getI18n( 'create-notification-template-title', 'Create Notification Template', intl),
            children: 
            <NotificationsTemplate intl={intl}
            />,
        }
        ]

    return (
        <ConfigProvider locale={localStorage.getItem('locale') === 'en' ? enUS : deDe}>
            <PageContainer
                title={false}
                content={''}
                extraContent={''}
                className={styles.users}
                style={{ background: 'white' }}
            >
                <Spin spinning={loading} size={'large'}>

                    <Tabs defaultActiveKey='1' items={tabItems} />
                </Spin>
            </PageContainer>
        </ConfigProvider>
    );
}

export default NotificationManagement;