import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {IAppComponentProps, PortalUser} from '../../../../components';
import {GridViewType, InfoPortalColumnDef, InfoPortalGridService} from '../../InfoPortalInterfaces';
import {AgGridReact} from 'ag-grid-react';
import {ColumnApi, ColumnResizedEvent, ColumnVisibleEvent, GridApi, SortChangedEvent} from 'ag-grid-community';
import {ColDef, ColGroupDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {Button, Col, Dropdown, Menu, Modal, notification, Row, Spin, Tooltip} from 'antd';
import {
  BorderInnerOutlined,
  BorderVerticleOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  ReloadOutlined,
  SaveOutlined,
  UserOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';

import styles from '../styling/infoportal.module.css';
import {IntlShape} from 'react-intl/src/types';
import {entitlementExists} from '../../../../main/entitlementService';
import {CustomDateComponent, getAgGridLocalization, processCellForClipboard} from './GridUtil';
import {createInfoPortalReport, ExcelSheetColumn} from '../../../../utils/ExcelWorkBookUtil';
import {saveFileAs} from '../../../documents/DocumentUtils';
import dayjs from 'dayjs';
import {ColumnMovedEvent, RowDataUpdatedEvent} from 'ag-grid-community/dist/lib/events';
import {useAppSelector} from '../../../../main/hooks';
import {createRoot} from 'react-dom/client';


function makeid(length) {
  let result           = '';
  const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function infoPortalDetailCellRendererWrapper(
    infoService: InfoPortalGridService,
    intl: IntlShape,
    lineColumnDefinitions: React.MutableRefObject<ColDef<any>[] | ColGroupDef<any>[]>,
    refreshCallbacks:{id:string,callback:()=>void}[],
    pageSizeCallBacks:{id:string,callback:(page:any)=>void}[],
    lineItemPageSize: React.MutableRefObject<number>,
    registerGridApi?:(api:GridApi, columnApi:ColumnApi)=>void,
    components?:any) {

  const DetailCellRenderer = ({ data, node, api }) => {
    const [lineItems, _] = useState<any[]>(infoService.getLineItems(data));
    const [flag,setFlag] = useState<boolean>(false);
    const myId = useRef<string>(makeid(10));
    const reload = ()=> setFlag(val=>!val);

    const rowId = node.id;

    useEffect(() => {
      return () => {
        // the detail grid is automatically destroyed as it is a React component
        api.removeDetailGridInfo(rowId);
      };
    }, []);

    function onColumnVisibleFunction(event: ColumnVisibleEvent) {
      const lineItemColumns = event.api.getColumnDefs();

      const columnDefs = (lineItemColumns[0]['children'] || lineItemColumns)?.filter(item=>!item['hide']);

      if (!columnDefs) {
        console.log('undefined',event.api.getColumnDefs())
        return;
      }

      lineColumnDefinitions.current = columnDefs;

      for (const refreshCallback of refreshCallbacks) {
        if (refreshCallback.id !== myId.current) {
          refreshCallback.callback();
        }
      }
    }

    function onColumnMovedFunction(event: ColumnMovedEvent) {
      const lineItemColumns = event.api.getColumnDefs();
      if (!lineItemColumns.length) {
        return;
      }

      const columnDefs = (lineItemColumns[0]['children'] || lineItemColumns)?.filter(item=>!item['hide']);

      lineColumnDefinitions.current = columnDefs;

      for (const refreshCallback of refreshCallbacks) {
        if (refreshCallback.id !== myId.current) {
          refreshCallback.callback();
        }
      }
    }

    const colDefs = ()=> [
      {
        headerName: intl.formatMessage({ id: "e-cap-capture-document-lines-items-panel-title" }),
        children: lineColumnDefinitions.current.map(colDef=>{return {...colDef, tooltipField: colDef.tooltipValueGetter ? undefined:colDef.field}})
      }
    ];

    const pagerDropSizeDown = (pageSetter:(newPageNumber:any)=>void)=> {
      const lastPagerSummary = document.querySelector(`#${myId.current} .ag-paging-panel.ag-unselectable`);

      if (lastPagerSummary?.firstElementChild?.id !== 'pagerSize') {
        const insertedDiv = document.createElement("span");
        insertedDiv.setAttribute('id','pagerSize');
        lastPagerSummary.insertBefore(insertedDiv,lastPagerSummary.firstElementChild);

        const querySelector = createRoot(document.querySelector(`#${myId.current} #pagerSize`));
        if (querySelector) {
          querySelector.render(<>
            <select data-testid={`line-item-${rowId}-page-selector`} id={`selector-${myId.current}`} onChange={pageSetter} className={`${styles.pageSizeSelector}`}>
              <option data-testid={`line-item-${rowId}-page-option-50`} value={50} selected={lineItemPageSize.current ===50}>{`50 ${intl.formatMessage({id:'info-portal-records-per-page'})}`}</option>
              <option data-testid={`line-item-${rowId}-page-option-100`} value={100} selected={lineItemPageSize.current ===100}>{`100 ${intl.formatMessage({id:'info-portal-records-per-page'})}`}</option>
              <option data-testid={`line-item-${rowId}-page-option-200`} value={200} selected={lineItemPageSize.current ===200}>{`200 ${intl.formatMessage({id:'info-portal-records-per-page'})}`}</option>
              <option data-testid={`line-item-${rowId}-page-option-500`} value={500} selected={lineItemPageSize.current ===500}>{`500 ${intl.formatMessage({id:'info-portal-records-per-page'})}`}</option>
            </select>
          </>, querySelector);
        }
      }
    }

    const onGridReady = (params) => {
      const gridInfo = {
        id: node.id,
        api: params.api,
        columnApi: params.columnApi
      };

      registerGridApi?.(params.api, params.columnApi);

      api?.addDetailGridInfo(rowId, gridInfo);

      const pageNumSetter = (val:any)=> {
        lineItemPageSize.current = Number(val.target?.value)
        params.api!.paginationSetPageSize(lineItemPageSize.current);
      };

      pagerDropSizeDown(pageNumSetter);

      refreshCallbacks.push({id:myId.current,callback:reload});
      pageSizeCallBacks.push({id:myId.current,callback:pageNumSetter});
    };

    const isGridVisible = () => lineItems && lineItems.length > 0;

    return (
      <div data-testid={`line-item-${rowId}`}>
        <div hidden={isGridVisible()} style={{ width: "100%" }}>
          <p style={{ marginTop: 20, marginLeft: "30px" }}>{intl.formatMessage({ id: "no-line-items" })}</p>
        </div>
        <div id={myId.current} hidden={!isGridVisible()}
             style={{ height: isGridVisible() ? 300 : 50, padding: 20, marginBottom: 30 }}>
          <AgGridReact
              defaultColDef={{
                enableValue: true,
                sortable: true,
                resizable: true,
                autoHeight: true
              }}
            columnDefs={colDefs()}
            rowData={lineItems}
            localeText={getAgGridLocalization(intl)}
            onGridReady={onGridReady}
              onColumnMoved={onColumnMovedFunction}
              onColumnVisible={onColumnVisibleFunction}
            pagination={true}
            components={components}
            paginationPageSize={lineItemPageSize.current}
          />
        </div>
      </div>
    );
  };

  return DetailCellRenderer;
}

function supportsLineItems(lineItemColDefinitions: InfoPortalColumnDef[]): boolean {
  return (lineItemColDefinitions?.length || 0) > 0;
}

function SelectAndExpandHeader(props:any) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const gridApi = useRef<GridApi>(props.api);

  const toggleChecked = (_:any)=> {
    let newFlag = false;
    if (!checked) {
     newFlag = true;
    }

    setChecked(newFlag);
    gridApi.current.forEachNode(node=>node.setSelected(newFlag));
  }

  const toggleExpanded = (_:any)=> {
    let newFlag = false;
    if (!expanded) {
      newFlag = true;
    }

    setExpanded(newFlag);
    gridApi.current.forEachNode(node=>node.setExpanded(newFlag));
  }

  return <div>
    <div className={`"ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${checked? "ag-checked":""} role="presentation"`}>
      <input className="ag-input-field-input ag-checkbox-input" type="checkbox" id="ag-10-input"
             aria-label="Press Space to toggle all rows selection (unchecked)" onClick={toggleChecked}/>
    </div>
    <span>&nbsp;</span>
    <span>&nbsp;</span>
    <BorderInnerOutlined hidden={expanded} style={{fontWeight:"bold", fontSize:"larger", position:"absolute"}} onClick={toggleExpanded}/>
    <BorderVerticleOutlined hidden={!expanded} style={{fontWeight:"bold", fontSize:"larger", position:"absolute"}} onClick={toggleExpanded}/>
  </div>
}

function modifyColumnDefinitions(columnDefinitions: InfoPortalColumnDef[], supportsLineItems: boolean): (ColDef<any> | ColGroupDef<any>)[] {

  const firstColumn: any = {
    field: "firstColumn",
    headerName: " ",
    headerComponent: supportsLineItems? SelectAndExpandHeader:undefined,
    headerCheckboxSelectionFilteredOnly: supportsLineItems? undefined:true,
    headerCheckboxSelection: supportsLineItems? undefined:true,
    checkboxSelection: true,
    lockVisible: true,
    rowSelection: "multiple",
    lockPosition: true,
    width: supportsLineItems? "60px":"40px",
    suppressMovable: true
  };

  const hiddenLogSys: InfoPortalColumnDef = {
    field: "rowLogSys",
    headerName:'Row Log sys',
    hide: true,
    valueGetter: (params) => params.data['logsys']
  };

  if ((columnDefinitions?.length || 0) > 0 && supportsLineItems) {
    // @ts-ignore
    const newObject = { ...columnDefinitions[0] };
    newObject["cellRenderer"] = "agGroupCellRenderer";
    columnDefinitions[0] = newObject;
  }

  function convertToAgGridColumnDef(colDef: InfoPortalColumnDef): ColDef {
    return { ...colDef,
      filter: colDef.filter || true,
      tooltipField: colDef.tooltipValueGetter ? undefined:colDef.field};
  }

  return [firstColumn, ...columnDefinitions.map(convertToAgGridColumnDef), hiddenLogSys];
}


type GridButtons = {
  infoGridService: InfoPortalGridService,
  intl: IntlShape,
  lineColumnDefinitions: React.MutableRefObject<any>,
  columnDefinitions: React.MutableRefObject<any>
  distinctEntitlements: string[],
  viewUpdater: () => void,
  isFullScreen: boolean,
  toggleFullScreen: () => void,
  exportDataAsExcel: () => Promise<void>,
  generatePDF: () => Promise<boolean>,
  viewType:GridViewType
}

function InfoPortalGridButtons({
                                 infoGridService,
                                 intl,
                                 columnDefinitions,
                                 viewUpdater,
                                 viewType,
                                 exportDataAsExcel,
                                 generatePDF,
                                 distinctEntitlements
                               }: GridButtons) {
  const [_flag, setFlag] = useState<boolean>(false);
  const [isExporting,setIsExporting] = useState<boolean>(false);
  const rerender = () => setFlag(current => !current);

  const isPersonalDisabled = () => {
    return !infoGridService.hasPersonalizedView();
  };

  const onShort = () => {
    viewType.enableShortView();
    viewUpdater();
  };

  const onFull = () => {
    viewType.enableLongView();
    viewUpdater();
  };

  const onPersonal = () => {
    if (!isPersonalDisabled()) {
      viewType.enablePrivateView();
      viewUpdater();
    }
  };


  const onSave = () => {
    const result:  (ColDef | ColGroupDef)[] = columnDefinitions.current
      .filter((colDef, index) => index > 0 && !colDef["hide"])
        .map(item=> {
          let sortIndex = item['sortIndex'];
          if (!sortIndex && sortIndex!==0) {
            sortIndex = undefined;
          }
          return {
            colId:item['field'],
            width:item['width'] || undefined,
            sort:item['sort'] || undefined,
            sortIndex
          }
        });

    notification.info({ message: intl.formatMessage({ id: "report-result-preferences-saved" }) });
    infoGridService.savePersonalizedView(result);
    rerender();
  };

  const onReset = () => {
    if (viewType.isLongView) {
      viewUpdater();
    } else if (viewType.isShortView) {
      viewUpdater();
    } else if (infoGridService.hasPersonalizedView()) {
      viewUpdater();
    }
  };

  const createPDF = () => {
    setIsExporting(true);
    generatePDF()
        .finally(()=>setIsExporting(false));
  };


  const onDownload = () => {
    setIsExporting(true);
    exportDataAsExcel()
        .finally(()=>setIsExporting(false));
  };

  const menu = (
    <Menu>
      <Menu.Item key="0" hidden={!infoGridService.hasShortView()} style={{display:!infoGridService.hasShortView()?'none':'inline-block'}}>
        <ZoomOutOutlined onClick={onShort} />
        <a data-testid={'info-portal-short'} onClick={onShort}>
          {intl.formatMessage({ id: "info-portal-short", defaultMessage: "Short" })}
        </a>
      </Menu.Item>
      <Menu.Item key="1">
        <ZoomInOutlined onClick={onFull} />
        <a data-testid={'info-portal-full'} onClick={onFull}>
          {intl.formatMessage({ id: "info-portal-full", defaultMessage: "Full" })}
        </a>
      </Menu.Item>

      <Menu.Item key="3" disabled={isPersonalDisabled()}>
        <UserOutlined onClick={onPersonal} />
        <a data-testid={'info-portal-personal'} onClick={onPersonal}>
          {intl.formatMessage({ id: "info-portal-personal", defaultMessage: "Personal" })}
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="4">
        <SaveOutlined onClick={onSave} />

        <a data-testid={'info-portal-save'} onClick={onSave}>
          {intl.formatMessage({ id: "info-portal-save", defaultMessage: "Save" })}
        </a>
      </Menu.Item>
    </Menu>
  );

  const downIcon = () => <DownOutlined />;

  return <>
    <Spin size={"large"} spinning={isExporting}>
      <Row gutter={24}>
        <Col style={{paddingRight: "5px"}}>
          <Dropdown.Button data-testid={'view-result-customization'} overlay={menu} trigger={["click"]} icon={downIcon()} size={"middle"}>
            <EyeOutlined/>


            {intl.formatMessage({id: "info-portal-view", defaultMessage: "View"})}
          </Dropdown.Button>
        </Col>
        <Col style={{paddingRight: "5px", paddingLeft: "5px"}}>
          <Tooltip title={intl.formatMessage({id: "reset-grid"})}>
            <Button  data-testid={'view-result-customization-reset'} onClick={onReset} size={"middle"}
                    icon={<ReloadOutlined className={styles.simpleFlipRefresh}/>}>
              {intl.formatMessage({id: "info-portal-reset"})}
            </Button>
          </Tooltip>
        </Col>
        <Col style={{paddingRight: "5px", paddingLeft: "5px"}} hidden={!infoGridService.canGenerateDocument()}>
          <Tooltip title={intl.formatMessage({id: "create-pdf-tooltip"})}>
            <Button  data-testid={'view-result-customization-reset'} onClick={createPDF} size={"middle"}
                     icon={<EditOutlined />}>
              {intl.formatMessage({id: "create-pdf"})}
            </Button>
          </Tooltip>
        </Col>
        <Col style={{paddingRight: "5px", paddingLeft: "5px"}}>
          {entitlementExists("download@reports", distinctEntitlements) &&
              <Tooltip title={intl.formatMessage({id: "export-to-excel"})}>
                <Button data-testid={'result-export'} onClick={onDownload} icon={<DownloadOutlined/>} size={"middle"}>
                  {intl.formatMessage({id: "export"})}
                </Button>
              </Tooltip>
          }
        </Col>
      </Row>
    </Spin>
  </>;
}

function generatePDF(docGridApi:GridApi, _lineItemDefinitions:any[],intl:IntlShape, _user:PortalUser, infoGridService:InfoPortalGridService,_viewType:GridViewType):Promise<boolean> {
  const selectedRows = docGridApi.getSelectedRows().filter(item=>!item['documentCreated']);

  if (!selectedRows?.length) {
    Modal.warning({
      title: intl.formatMessage({id: "Download Warning",}),
      content: intl.formatMessage({id: "please-select-documents-to-generate-pdf"})
    });

    return Promise.resolve(false);
  }

  return infoGridService.generateDocument(selectedRows, docGridApi).then(response=> {
    Modal.info({
      title: intl.formatMessage({id: "Download Success",}),
      content: intl.formatMessage({id:"document-creation-was-successful"})
    });
    return true;
  });
}
function onExport(docGridApi:GridApi, lineItemDefinitions:any[],intl:IntlShape, user:PortalUser, infoGridService:InfoPortalGridService,viewType:GridViewType):Promise<void> {
  let selectedRows = docGridApi.getSelectedRows();
  if (!selectedRows?.length) {
    Modal.warning({
      title: intl.formatMessage({
        id: "Warning-message",
        defaultMessage: "Export Document(s)"
      }),
      content: intl.formatMessage({
        id: "Warning-export-message",
        defaultMessage: "You have not selected any items to be exported. Please select an item first."
     })
    });
    return Promise.resolve();
  }

  const visibleColumns = docGridApi.getColumnDefs().filter(item=>!item['hide']);

  const rowMetaData = infoGridService.getRowMetaData(intl, user, viewType);
  const lineRowMetaData = infoGridService.getLineRowMetaData(intl, user, viewType);

  const extractedFields:InfoPortalColumnDef[] = [];
  for (const visibleColumn of visibleColumns) {
    for (const columnDefinition of infoGridService.getColumnDefinitions(intl,user, viewType)) {
      if (columnDefinition.field === visibleColumn['field']) {
        extractedFields.push(columnDefinition);
      }
    }
  }

  const lineItemColumnDefinitions =
      lineItemDefinitions.map(item=> {
        return {...item,field:`lineItem.${item.field}`}
      });

  extractedFields.push(...lineItemColumnDefinitions);

  const dummyLineItemRow = lineItemColumnDefinitions.map(item=>{
    const newItem = {};
    newItem[item.field] = '';
  });

  const genericComparator = (a:any,b:any):number => {
    if (!a) {
      return -1;
    }
    if (!b) {
      return 1;
    }

    if (typeof a === 'string' && typeof b === 'string') {
      return a.localeCompare(b);
    } else if (typeof a === 'number' && typeof b === 'number') {
      return a - b;
    }

    return 0;
  }

  const inverseComparator = (comparator:(a:any,b:any)=>number):(a:any,b:any)=>number => {
    return (a:any,b:any)=>-1*comparator(a,b);
  }

  const sortData = (arr:any[])=> {
    let toReturn = arr;
    for (const visibleColumnMetaData of visibleColumns) {
      let comparator = visibleColumnMetaData['comparator'] || genericComparator;

      const sortDirection = visibleColumnMetaData['sort'];
      if (sortDirection) {
        console.log('Sorting',visibleColumnMetaData)
        if (sortDirection === 'desc') {
          comparator = inverseComparator(comparator);
        }

        const wrapperComparator = (a:any,b:any):number=> {
          return comparator(a[visibleColumnMetaData['field']], b[visibleColumnMetaData['field']]);
        }

        toReturn = arr.sort(wrapperComparator);
        break;
      }
    }

    return toReturn;
  }

  const modifySelectedRowValuesUsingValueGetter = (arr:any[])=> {
    const newArray = arr.map(item=> {
      return {...item}
    });

    for (const visibleColumnMetaData of visibleColumns) {
      const valueGetter = visibleColumnMetaData['valueGetter'];
      if (valueGetter) {
        for (const item of newArray) {
          const paramData = {
            data:{}
          }
          paramData.data = {...item};
          item[visibleColumnMetaData['field']] = valueGetter(paramData);
        }
      }
    }
    return newArray;
  }

  selectedRows = modifySelectedRowValuesUsingValueGetter(selectedRows);
  selectedRows = sortData(selectedRows);

  const extractedRowContent:any[] = [];
  for (const selectedRow of selectedRows) {
    const masterRecord = {};
    for (const extractedField of extractedFields) {
      masterRecord[extractedField.field] = selectedRow[extractedField.field];
      if (extractedField.valueFormatter) {
        masterRecord[extractedField.field] = extractedField.valueFormatter({data:selectedRow});
      }
      if (extractedField.exportFormatter) {
        masterRecord[extractedField.field] = extractedField.exportFormatter({data:selectedRow});
      }
    }

    const lineItemMapper = infoGridService.getLineItemMapper();
    const lineItemRows = lineItemMapper(selectedRow);

    if (lineItemRows?.length) {
      for (const lineItemRow of lineItemRows) {
        const copyOfMaster = {...masterRecord}
        Object.keys(lineItemRow).forEach(key=> {
          const derivedKey = `lineItem.${key}`;
          for (const extractedField of extractedFields) {
            if (derivedKey === extractedField.field) {
              copyOfMaster[extractedField.field] = lineItemRow[key];
              if (extractedField.valueFormatter) {
                copyOfMaster[extractedField.field] = extractedField.valueFormatter({data:lineItemRow});
              }
              if (extractedField.exportFormatter) {
                copyOfMaster[extractedField.field] = extractedField.exportFormatter({data:lineItemRow});
              }
              else if (extractedField.valueGetter) {
                copyOfMaster[extractedField.field] = extractedField.valueGetter({data:lineItemRow});
              }
              break;
            }
          }
        })
        extractedRowContent.push(copyOfMaster);
      }
    }
    else {
      Object.keys(dummyLineItemRow).forEach(key=> {
        masterRecord[key] = dummyLineItemRow[key];
      })
      extractedRowContent.push(masterRecord);
    }
  }

  function calculateWidth(field:string) {
      let metaData;
      if (field.startsWith('lineItem.')) {
        metaData = lineRowMetaData[field.substring(field.indexOf('.')+1)];
      }
      else {
        metaData = rowMetaData[field];
      }

      if (metaData) {
        return Math.max(17,Math.min(30,metaData.averageLength))
      }

      return 17;
  }

  function getAlignment(field:string) {
    let metaData;
    if (field.startsWith('lineItem.')) {
      metaData = lineRowMetaData[field.substring(field.indexOf('.')+1)];
    }
    else {
      metaData = rowMetaData[field];
    }

    if (metaData) {
      return metaData.align;
    }

    return 'left';
  }

  const excelColumns:ExcelSheetColumn[] = extractedFields.map(extractedField=> {
    return {
      header: extractedField.headerName,
      key:extractedField.field,
      width:Math.max(1.30*calculateWidth(extractedField.field), extractedField.headerName.length),
      horizontalAlignment:getAlignment(extractedField.field),
      color:'ffdadada'
    }
  });

  if (extractedRowContent.length > 0) {
    const fileName = `${dayjs().format('YYYYMMDDHHmmss')}_${infoGridService.getReportName()}.xlsx`;
    return createInfoPortalReport(infoGridService.getReportName(), excelColumns, extractedRowContent).then(result=> {
      saveFileAs(new Uint8Array(result), fileName, "application/vnd.ms-excel");
    }).catch(console.error);
  }

  return Promise.resolve();
};

function initViewType(infoGrid:InfoPortalGridService) {
  const viewType = new GridViewType();

  if (!infoGrid.hasPersonalizedView()) {
    infoGrid.initializeGridView(viewType);
  }
  else {
    viewType.enablePrivateView();
  }

  return viewType;
}

type InfoPortalDisplayGridProperties = {
  infoGridService: InfoPortalGridService
} & IAppComponentProps;

export function InfoPortalDisplayGrid({
                                        infoGridService,
                                        intl,
                                        currentUser,
                                        distinctEntitlements
                                      }: InfoPortalDisplayGridProperties) {
  const [docGridApi, setDocGridApi] = useState<GridApi>(undefined);
  const [isFullScreen, setFullScreen] = useState<boolean>(false);
  const viewType = useRef<GridViewType>(initViewType(infoGridService));
  const toggleFullscreenMode = () => setFullScreen(current => !current);
  const columnDefinitions = useRef<ColDef<any>[] | ColGroupDef<any>[]>(modifyColumnDefinitions(infoGridService.getColumnDefinitions(intl,currentUser,viewType.current), supportsLineItems(infoGridService.getLineItemColumnDefinitions(intl, currentUser))));
  const lineColumnDefinitions = useRef<ColDef<any>[] | ColGroupDef<any>[]>(infoGridService.getLineItemColumnDefinitions(intl,currentUser));
  const lineItemRefreshCallbacks = useRef<{id:string,callback:()=>void}[]>([]);
  const lineItemPageSizeCallbacks = useRef<{id:string,callback:(page:any)=>void}[]>([]);
  const pageSize = useRef<number>(50);
  const lineItemPageSize = useRef<number>(50);

  const { rerenderAfterLanguageChange } = useAppSelector((state) => state.globalStateReducer);

  const components = useMemo(() => ({ agDateInput: CustomDateComponent }), []);

  const detailCellRenderer = useMemo(() => {
    return infoPortalDetailCellRendererWrapper(
        infoGridService,
        intl,
        lineColumnDefinitions,
        lineItemRefreshCallbacks.current,
        lineItemPageSizeCallbacks.current,
        lineItemPageSize,
        null,
        components);
  }, [rerenderAfterLanguageChange]);

  CustomDateComponent.prototype.intl = intl;


  useEffect(()=>{
    columnDefinitions.current = modifyColumnDefinitions(infoGridService.getColumnDefinitions(intl,currentUser,viewType.current), supportsLineItems(infoGridService.getLineItemColumnDefinitions(intl, currentUser)));
  },[rerenderAfterLanguageChange])

  const gridRef = useRef();

  const pagerDropSizeDown = (pageSetter:(newPageNumber:any)=>void)=> {
    const pagerSummaryList = document.querySelectorAll(`.infoaggrid-${infoGridService.getReportName()} .ag-paging-panel.ag-unselectable`);

    if (pagerSummaryList && pagerSummaryList.length) {
      const lastPagerSummary = pagerSummaryList[pagerSummaryList.length-1];

      if (lastPagerSummary.firstElementChild.id !== 'pagerSize') {
        const insertedDiv = document.createElement("span");
        insertedDiv.setAttribute('id','pagerSize');
        lastPagerSummary.insertBefore(insertedDiv,lastPagerSummary.firstElementChild);

        const querySelector = createRoot(document.querySelector(`.infoaggrid-${infoGridService.getReportName()} #pagerSize`));
        if (querySelector) {
          querySelector.render(<>
            <select data-testid={`grid-main-page-selector`} onChange={pageSetter} className={`${styles.pageSizeSelector}`}>
              <option  data-testid={`grid-main-page-option-50`} value={50}>{`50 ${intl.formatMessage({id:'info-portal-records-per-page'})}`}</option>
              <option  data-testid={`grid-main-page-option-100`} value={100}>{`100 ${intl.formatMessage({id:'info-portal-records-per-page'})}`}</option>
              <option  data-testid={`grid-main-page-option-200`} value={200}>{`200 ${intl.formatMessage({id:'info-portal-records-per-page'})}`}</option>
              <option  data-testid={`grid-main-page-option-500`} value={500}>{`500 ${intl.formatMessage({id:'info-portal-records-per-page'})}`}</option>
            </select>
          </>, querySelector);
        }
      }
    }
  }

  const onDocGridReady = (params) => {
    setDocGridApi(params.api);
    infoGridService.gridAPI = params.api;
    infoGridService.columnAPI = params.columnApi;
    const pageNumSetter = (val:any)=> {
      pageSize.current = Number(val.target?.value)
      params.api!.paginationSetPageSize(pageSize.current);
    };

    pagerDropSizeDown(pageNumSetter);
  };


  const onReportViewUpdate = useCallback(() => {

    const newDefinitions = modifyColumnDefinitions(infoGridService.getColumnDefinitions(intl, currentUser, viewType.current), supportsLineItems(infoGridService.getLineItemColumnDefinitions(intl, currentUser)));
    columnDefinitions.current = newDefinitions;
    // @ts-ignore
    gridRef?.current?.api?.setColumnDefs(newDefinitions);
    // @ts-ignore
    gridRef?.current?.api?.setFilterModel(null);

    const lineItemColumnDefinitions = infoGridService.getLineItemColumnDefinitions(intl, currentUser);
    lineColumnDefinitions.current = lineItemColumnDefinitions;

    for (const refreshCallback of lineItemRefreshCallbacks.current) {
      refreshCallback.callback();
    }
  }, []);



  if (!infoGridService.hasRowData()) {
    return <div>
      {intl.formatMessage({ id: "no-results-found" })}
    </div>;
  }

  function onColumnMovedFunction(event: ColumnMovedEvent) {
    const columnDefs = event.api.getColumnDefs().filter(item=>!item['hide']);
    columnDefinitions.current = columnDefs;
  }

  function onColumnVisibleFunction(event: ColumnVisibleEvent) {
    const columnDefs = event.api.getColumnDefs().filter(item=>!item['hide']);
    columnDefinitions.current = columnDefs;
  }

  function onSortChangedFunction(event: SortChangedEvent) {
    const columnDefs = event.api.getColumnDefs().filter(item=>!item['hide']);
    columnDefinitions.current = columnDefs;
  }
  function onColumnResizedFunction(event: ColumnResizedEvent) {
    const columnDefs = event.api.getColumnDefs().filter(item=>!item['hide']);
    columnDefinitions.current = columnDefs;
  }

  function rowDataChanged(event: RowDataUpdatedEvent<any>) {
    event.api.refreshHeader();
  }

  function getRowClass(params) {
    return params.data?.positions?.length ? 'multiple-line-items-present': 'multiple-line-items-absent';
  }


  return <div>
    {isFullScreen && <div style={{ height: "400px" }}>&nbsp;</div>}
    <div className={`${styles.reportDataGrid} ${isFullScreen ? styles.reportGridFullscreen : "none"}`}>
      <div className={styles.reportDataGridButtons}>
        <InfoPortalGridButtons
          infoGridService={infoGridService}
          intl={intl}
          viewUpdater={onReportViewUpdate}
          columnDefinitions={columnDefinitions}
          isFullScreen={isFullScreen}
          exportDataAsExcel={()=>onExport(docGridApi,lineColumnDefinitions.current, intl,currentUser,infoGridService,viewType.current)}
          generatePDF={()=>generatePDF(docGridApi,lineColumnDefinitions.current, intl,currentUser,infoGridService,viewType.current)}
          toggleFullScreen={toggleFullscreenMode}
          distinctEntitlements={distinctEntitlements}
          viewType={viewType.current}
          lineColumnDefinitions={lineColumnDefinitions}/>
      </div>
      <div className={`pl15 ag-theme-balham infoaggrid-${infoGridService.getReportName()}`}>
        <div data-testid={docGridApi? 'report-grid-parent': 'report-grid-parent-loading'} id={"report-grid-parent"}
             style={{ height: "500px", flex: "auto", width: "95%", marginLeft: "20px", marginTop: "30px" }}>
          <AgGridReact
            defaultColDef={{
              enableValue: true,
              sortable: true,
              resizable: true,
              autoHeight: true,
            }}
            ref={gridRef}
            rowData={infoGridService.getRowData()}
            columnDefs={columnDefinitions.current}
            onColumnMoved={onColumnMovedFunction}
            onColumnResized={onColumnResizedFunction}
            onColumnVisible={onColumnVisibleFunction}
            onSortChanged={onSortChangedFunction}
            api={docGridApi}
            getRowClass={getRowClass}
            sideBar={false}
            rowClass="agGridRows"
            suppressMenuHide={true}
            debug={false}
            pagination={true}
            paginationPageSize={pageSize.current}
            onGridReady={onDocGridReady}
            rowSelection={"multiple"}
            masterDetail={true}
            processCellForClipboard={processCellForClipboard}
            localeText={getAgGridLocalization(intl)}
            components={components}
            detailCellRenderer={detailCellRenderer}
            onRowDataUpdated={rowDataChanged}
            suppressRowClickSelection={true}
            enableBrowserTooltips={true}
          />
        </div>
      </div>
    </div>
  </div>;
}