import React from 'react';
import { IAppComponentProps } from 'src/components';

const MasterDataSurveyTabComponent: React.FC<IAppComponentProps> = (props) => {
    return (
        <div>
            <h1>Master Data Survey Tab</h1>
            <p>This is the Master Data Survey Tab component.</p>
        </div>
    );
};

export default MasterDataSurveyTabComponent;