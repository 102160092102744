import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {Card, Col, ConfigProvider, notification, Row, Tabs, Typography} from 'antd';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {
    getDefinitionFormatChannelHelp,
    getFormats,
    SUPPLIER_DOC_TYPES,
    SURVEY_DOCUMENT_TYPE_TABLE_HEADERS,
} from './documentTypeUtils';
import ActionIconDocumentTypeCellRenderer from './ActionIconDocumentTypeCellRenderer';
import Paragraph from 'antd/es/typography/Paragraph';
import {PARTNER_TYPE, SELECTED_COMPANY_CODE, SELECTED_PARTNER_NO, SELECTED_SURVEY_ID} from '../../../constants';
import {getSurveyChannelBySurveyId} from './DocumentTypeService';
import enUS from 'antd/lib/locale/en_US';
import deDe from 'antd/lib/locale/de_DE';
import {setDocumentTypeViewMode} from '../../../utils';
import {GridApi} from 'ag-grid-community';
import Link from 'antd/lib/typography/Link';
import {getDefaultChannelFormat_getDefaultChannelFormat_format} from './__generated__/getDefaultChannelFormat';
import SurveySupplierDocumentTypeTab from './SurveySupplierDocumentTypeTab';
import SurveySupplierDocumentTypeBulkUpdateForm from './SurveySupplierDocumentTypeBulkUpdateForm';
import {getAgGridLocalization} from '../../info-portal/components/grid/GridUtil';
import {StateContainer} from '../utilities/StateContainer';
import {showErrorWithHeaderNotification} from '../../Notification';
import {useAppDispatch, useAppSelector} from '../../../main/hooks';
import {resetSurveyDataLoading, setSurveyDataLoading} from '../IStoredSurveySlice';
import {useInsertSurveyChannelsMutation} from './queries.generated';

type ISurveySuppliersDocumentTypeProps = {
    next?: any;
    prev?: any;
    onStatusUpdate?: any;
    surveyMasterData?: any;
    stateContainer?: StateContainer;
};
const SurveySuppliersDocumentType: React.FunctionComponent<ISurveySuppliersDocumentTypeProps> = (props) => {
    const dispatch = useAppDispatch();
    const {TabPane} = Tabs;
    const intl = useIntl();
    const [gridApi, setGridApi] = useState<GridApi>(undefined);
    const [surveyDocumentTypeMainVisible, setSurveyDocumentTypeMainVisible] = useState(
        'survey-document-type-main-visible'
    );
    const [surveyDocumentTypeMainDetailsVisible, setSurveyDocumentTypeMainDetailsVisible] = useState(
        'survey-document-type-main-details-hidden'
    );

    const [includedSurveyIds, setIncludedSurveyIds] = useState();
    const [selectedPartnerNumber, setSelectedPartnerNumber] = useState();
    const [selectedSurveyId, setSelectedSurveyId] = useState(undefined);
    const [selectedPartnerCompanyCode, setSelectedPartnerCompanyCode] = useState();
    const [processOutgoingChannelData, setProcessOutgoingChannelData] = useState([]);
    const [processPaymentAdviceChannelData, setProcessPaymentAdviceChannelData] = useState([]);
    const [processIncomingChannelData, setProcessIncomingChannelData] = useState([]);
    const [surveyChannels, setSurveyChannels] = useState([]);
    const [showEditPanel, isShowEditPanel] = useState(false);
    const [selectedSurvey, setSelectedSurvey] = useState<any>(null);
    const [outgoingFormatData, setOutgoingFormatData] =
        useState<getDefaultChannelFormat_getDefaultChannelFormat_format[]>();
    const [incomingFormatData, setIncomingFormatData] =
        useState<getDefaultChannelFormat_getDefaultChannelFormat_format[]>();
    const [padFormatData, setPadFormatData] = useState<getDefaultChannelFormat_getDefaultChannelFormat_format[]>();

    const [outgoingFormatOptions, setOutgoingFormatOptions] = useState<string[]>();
    const [paymentAdviceFormatOptions, setPaymentAdviceFormatOptions] = useState<string[]>();
    const [incomingDocumentsFormatOptions, setIncomingDocumentsFormatOptions] = useState<string[]>();
    const [summaryGridData, setSummaryGridData] = useState<any[]>([]);
    const [insertSurveyChannelsMutation, {}] = useInsertSurveyChannelsMutation();

    const surveyState = useAppSelector((state) => state.survey);

    const headerParagraphLine1 = intl.formatMessage({
        id: 'survey-eDocument-supplier-headerText-line1',
        defaultMessage:
            'The following eDocument variants are assigned to a standard format or channel for the partner data listed below. Please check and confirm whether you ',
    });

    const definitionsFormatChannel = intl.formatMessage({
        id: 'guidelines-and-testfiles',
        defaultMessage: 'Guidelines and testfiles',
    });

    const eDocumentTitle = intl.formatMessage({
        id: 'survey-eDocument-supplier-title',
        defaultMessage: 'eDocument Types',
    });

    const outgoingDocumentsTabTitle = intl.formatMessage({
        id: 'survey-eDocument-supplier-outgoingDocuments-tab-title',
        defaultMessage: 'Outgoing Documents',
    });

    const paymentAdvicesTabTitle = intl.formatMessage({
        id: 'survey-eDocument-supplier-paymentAdvices-tab-title',
        defaultMessage: 'Payment Advices',
    });

    const incomingDocumentsTabTitle = intl.formatMessage({
        id: 'survey-eDocument-supplier-incomingDocuments-tab-title',
        defaultMessage: 'Incoming Documents',
    });

    const fetchData = async () => {
        let count = 0;
        const defaultGridData = [];
        let updatedOutgoingFormat = undefined;
        let updatedOutgoingChannel = undefined;
        for await (const surveyChannels of props.surveyMasterData.map((item) =>
            getSurveyChannelBySurveyId(
                Number(item.surveySummaryRecord.surveyId),
                localStorage.getItem(PARTNER_TYPE),
                props.surveyMasterData[count].surveySummaryRecord.companyCode,
                intl
            )
        )) {
            if (Array.isArray(surveyChannels) && surveyChannels?.length > 0) {
                // setSurveyChannels(surveyChannels);


                const outgoingChannelFormat = surveyChannels?.filter(
                    (item) => item.processCode !== 'INC' && item.processCode !== 'PAD' && item.processCode !== 'ECAP'
                );

                if (outgoingChannelFormat?.length > 0 && outgoingChannelFormat?.length > 0) {
                    updatedOutgoingFormat = 'PDF';
                    updatedOutgoingChannel = 'PORTAL';

                    updatedOutgoingChannel =
                        new Set(outgoingChannelFormat.map((item) => item.updatedChannel))?.size === 1
                            ? outgoingChannelFormat[0]?.updatedChannel
                            : intl.formatMessage({id: 'miscellaneous'});
                    updatedOutgoingFormat =
                        new Set(outgoingChannelFormat.map((item) => item.updatedFormat))?.size === 1
                            ? outgoingChannelFormat[0]?.updatedFormat
                            : intl.formatMessage({id: 'miscellaneous'});
                }
                const updatedPaymentAdviceFormat =
                    surveyChannels?.filter((item) => item.processCode === 'PAD').length > 0
                        ? surveyChannels?.filter((item) => item.processCode === 'PAD')[0]?.updatedFormat
                        : 'PDF';
                const updatedPaymentAdviceChannel =
                    surveyChannels?.filter((item) => item.processCode === 'PAD').length > 0
                        ? surveyChannels?.filter((item) => item.processCode === 'PAD')[0]?.updatedChannel
                        : 'PORTAL';

                const updatedIncomingFormat =
                    surveyChannels?.filter((item) => item.processCode === 'INC').length > 0
                        ? surveyChannels?.filter((item) => item.processCode === 'INC')[0]?.updatedFormat
                        : 'ECAP';
                const updatedIncomingChannel =
                    surveyChannels?.filter((item) => item.processCode === 'INC').length > 0
                        ? surveyChannels?.filter((item) => item.processCode === 'INC')[0]?.updatedChannel
                        : 'PORTAL';

                defaultGridData.push({
                    partnerNo: props.surveyMasterData[count].surveySummaryRecord.partnerNo,
                    companyCode: props.surveyMasterData[count].surveySummaryRecord.companyCode,
                    surveyId: props.surveyMasterData[count].surveySummaryRecord.surveyId,
                    surveyChannels,
                    includedSurveyIds: props.surveyMasterData[count].surveySummaryRecord.includedSurveyIds,
                    outgoingFormat: updatedOutgoingFormat,
                    outgoingChannel: updatedOutgoingChannel,
                    paymentAdviceFormat: surveyChannels?.filter((item) => item.processCode === 'PAD')?.length > 0 ? updatedPaymentAdviceFormat : undefined,
                    paymentAdviceChannel: surveyChannels?.filter((item) => item.processCode === 'PAD')?.length > 0 ? updatedPaymentAdviceChannel : undefined,
                    incomingFormat: surveyChannels?.filter((item) => item.processCode === 'INC').length > 0 ? updatedIncomingFormat : undefined,
                    incomingChannel: surveyChannels?.filter((item) => item.processCode === 'INC').length > 0 ? updatedIncomingChannel : undefined,
                });
            } else {
                defaultGridData.push({
                    partnerNo: props.surveyMasterData[count].surveySummaryRecord.partnerNo,
                    companyCode: props.surveyMasterData[count].surveySummaryRecord.companyCode,
                    surveyId: props.surveyMasterData[count].surveySummaryRecord.surveyId,
                    includedSurveyIds: props.surveyMasterData[count].surveySummaryRecord.includedSurveyIds,
                    outgoingFormat: 'PDF',
                    outgoingChannel: 'PORTAL',
                    updatedOutgoingFormat: 'PDF',
                    updatedOutgoingChannel: 'PORTAL',
                    paymentAdviceFormat: 'PDF',
                    paymentAdviceChannel: 'PORTAL',
                    updatedPaymentAdviceFormat: 'PDF',
                    updatedPaymentAdviceChannel: 'PORTAL',
                    incomingFormat: 'ECAP',
                    incomingChannel: 'PORTAL',
                    updatedIncomingFormat: 'PDF',
                    updatedIncomingChannel: 'ECAP',
                });
            }
            count++;
        }
        // console.log("defaultGridData");
        // console.dir(defaultGridData);
        setSummaryGridData(defaultGridData);
        //isLoading(false);
        // }
    };

    useEffect(() => {
        if (props.surveyMasterData !== null && props.surveyMasterData !== undefined) {
            console.log('load data activated');
            dispatch(setSurveyDataLoading(true));
            fetchData()
                .then((_) => {
                    console.log('loading false');
                    dispatch(setSurveyDataLoading(false));
                    props.onStatusUpdate;
                })
                .catch(() => {
                    dispatch(resetSurveyDataLoading(false));
                })
                .finally(() => {
                    dispatch(resetSurveyDataLoading(false));
                });
        }
    }, [props.surveyMasterData]);

    const onSupplierDocumentTypeGridReady = (params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const selectRow = () => {
        const selectedRows = gridApi!.getSelectedRows();
        if (selectedRows.length) {
            setSelectedPartnerNumber(selectedRows[0]?.partnerNo);
            setSelectedPartnerCompanyCode(selectedRows[0]?.companyCode);
            setSelectedSurveyId(selectedRows[0]?.surveyId);
            setIncludedSurveyIds(selectedRows[0]?.includedSurveyIds);
            setSurveyChannels(selectedRows[0]?.surveyChannels);

            localStorage.setItem(SELECTED_PARTNER_NO, selectedRows[0]?.partnerNo);
            localStorage.setItem(SELECTED_COMPANY_CODE, selectedRows[0]?.companyCode);
            localStorage.setItem(SELECTED_SURVEY_ID, selectedRows[0]?.surveyId);

            if (showEditPanel) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                editChannel();
            }
        } else {
            isShowEditPanel(false);
        }
    };

    const showSurveyNotSelectedErrorMessage = () => {
        notification.error({
            message: intl.formatMessage({
                id: 'survey-eDocument-supplier-error-notification-header',
                defaultMessage: 'Error',
            }),
            description: intl.formatMessage({
                id: 'survey-eDocument-supplier-error-notification-partner-not-selected',
                defaultMessage: 'Please select a partner',
            }),
        });
    };

    const validateSelect = () => {
        if (
            localStorage.getItem(SELECTED_SURVEY_ID) === undefined ||
            localStorage.getItem(SELECTED_SURVEY_ID) === null ||
            localStorage.getItem(SELECTED_PARTNER_NO) === null ||
            localStorage.getItem(SELECTED_PARTNER_NO) === undefined
        ) {
            showSurveyNotSelectedErrorMessage();
            return;
        }

        if (
            localStorage.getItem(SELECTED_SURVEY_ID) !== undefined ||
            localStorage.getItem(SELECTED_SURVEY_ID) !== null ||
            localStorage.getItem(SELECTED_PARTNER_NO) !== null ||
            localStorage.getItem(SELECTED_PARTNER_NO) !== undefined
        ) {
            setSurveyDocumentTypeMainVisible('survey-document-type-main-hidden');
            setSurveyDocumentTypeMainDetailsVisible('survey-document-type-main-details-visible');
        }
    };

    const editChannel = () => {
        if (gridApi.getSelectedRows().length === 0) {
            showSurveyNotSelectedErrorMessage();
            return;
        }
        if (gridApi.getSelectedRows().length === 1) {
            const selectedSurveyItem = gridApi.getSelectedRows()[0];
            setSelectedSurvey(selectedSurveyItem);
            dispatch(setSurveyDataLoading(true));
            isShowEditPanel(true);
            //isLoading(true);
            const outFormats = [];
            const padFormats = [];
            const inFormats = [];

            if (Array.isArray(selectedSurveyItem?.surveyChannels)) {
                const outgoingChannels = selectedSurveyItem?.surveyChannels?.filter(
                    (item) => item.processCode !== 'INC' && item.processCode !== 'PAD' && item.processCode !== 'ECAP'
                );
                const paymentAdviceChannels = selectedSurveyItem?.surveyChannels?.filter(
                    (item) => item.processCode === 'PAD'
                );
                const incomingDocumentsChannels = selectedSurveyItem?.surveyChannels?.filter(
                    (item) => item.processCode === 'INC' || item.processCode === 'ECAP'
                );

                setProcessOutgoingChannelData(outgoingChannels);
                setProcessPaymentAdviceChannelData(paymentAdviceChannels);
                setProcessIncomingChannelData(incomingDocumentsChannels);

                if (outgoingChannels?.length > 0) {
                    dispatch(setSurveyDataLoading(true));
                    Promise.resolve(
                        getFormats(
                            localStorage.getItem(PARTNER_TYPE),
                            localStorage.getItem(SELECTED_COMPANY_CODE),
                            outgoingChannels[0].processCode,
                            'OUT'
                        )
                    )
                        .then((data) => {
                            data.format.map((item) => {
                                outFormats.push(item.formatName);
                            });
                            setOutgoingFormatData(data?.format);
                            setOutgoingFormatOptions(outFormats);
                            dispatch(setSurveyDataLoading(false));
                        })
                        .catch((e) => {
                            console.error(e);
                            dispatch(resetSurveyDataLoading(false));
                        })
                        .finally(() => {
                            dispatch(setSurveyDataLoading(false));
                        });
                }

                if (paymentAdviceChannels?.length > 0) {
                    dispatch(setSurveyDataLoading(true));
                    Promise.resolve(
                        getFormats(
                            localStorage.getItem(PARTNER_TYPE),
                            localStorage.getItem(SELECTED_COMPANY_CODE),
                            'PAD',
                            'OUT'
                        )
                    )
                        .then((data) => {
                            data.format.map((item) => {
                                padFormats.push(item.formatName);
                            });
                            setPadFormatData(data?.format);
                            setPaymentAdviceFormatOptions(padFormats);
                            dispatch(setSurveyDataLoading(false));
                        })
                        .catch((e) => {
                            console.error(e);
                            dispatch(resetSurveyDataLoading(false));
                        })
                        .finally(() => {
                            dispatch(setSurveyDataLoading(false));
                        });
                }

                if (incomingDocumentsChannels?.length > 0) {
                    dispatch(setSurveyDataLoading(true));
                    Promise.resolve(
                        getFormats(
                            localStorage.getItem(PARTNER_TYPE),
                            localStorage.getItem(SELECTED_COMPANY_CODE),
                            'INC',
                            'IN'
                        )
                    )
                        .then((data) => {
                            data.format.map((item) => {
                                inFormats.push(item.formatName);
                            });
                            setIncomingFormatData(data?.format);
                            setIncomingDocumentsFormatOptions(inFormats);
                            dispatch(setSurveyDataLoading(false));
                        })
                        .catch((e) => {
                            console.error(e);
                            dispatch(resetSurveyDataLoading(false));
                        })
                        .finally(() => {
                            dispatch(setSurveyDataLoading(false));
                        });
                }
            }
        }
    };

    const saveChannel = (data) => {
        if (gridApi?.getSelectedRows().length === 0) {
            showSurveyNotSelectedErrorMessage();
        } else if (
            !data.incomingDocumentsUpdatedFormat &&
            !data.incomingDocumentsUpdatedChannel &&
            !data.outgoingUpdatedFormat &&
            !data.outgoingUpdatedChannel &&
            !data.paymentAdviceUpdatedFormat &&
            !data.paymentAdviceUpdatedChannel
        ) {
            showErrorWithHeaderNotification(
                intl.formatMessage({
                    id: 'survey-eDocument-supplier-expert-mode-channel-not-selected-header',
                    defaultMessage: 'Error',
                }),
                intl.formatMessage({
                    id: 'survey-eDocument-supplier-bulk-channel-not-selected-error',
                    defaultMessage: 'NO EDI Channel Format selected',
                })
            );
        } else {
            //isLoading(true);
            dispatch(setSurveyDataLoading(true));
            const inputData = [];
            if (data?.incomingDocumentsUpdatedFormat && data.incomingDocumentsUpdatedChannel) {
                const channelProvider = data.incomingDocumentsUpdatedChannel?.split('-');

                processIncomingChannelData
                    .filter((item) => item.processCode !== 'ECAP')
                    .forEach((processIncomingData) => {
                        inputData.push({
                            surveyId: Number(localStorage.getItem(SELECTED_SURVEY_ID)),
                            processCode: processIncomingData.processCode,
                            format: data.incomingDocumentsUpdatedFormat,
                            channel: channelProvider[0],
                            provider: channelProvider[1],
                            channelDefault: false,
                        });
                    });
            }
            if (data.outgoingUpdatedFormat && data.outgoingUpdatedChannel) {
                const channelProvider = data.outgoingUpdatedChannel?.split('-');

                processOutgoingChannelData
                    .filter((item) => item.processCode !== 'PAD')
                    .forEach((processOutGoingData) => {

                        /**
                         *  https://atc.bmwgroup.net/jira/browse/EINVV2-17671
                         *
                         *  when one performs a bulk edit on the following process codes:
                         *  FCN abd CDN should become => PDF SMTP provider EDI_PROV
                         *  this will then trigger the smtp screen on connectivity info where they need to enter their smtp details
                         */

                        let updatedChannel = channelProvider[0];
                        let updatedFormat = processOutGoingData.processCode === 'FCN' && data.outgoingUpdatedFormat === 'VDA4938' ? 'VDA4983' : data.outgoingUpdatedFormat;
                        let updatedProvider = channelProvider[1];

                        if (selectedPartnerCompanyCode === 'C5' && ['FCN', 'CDN'].includes(processOutGoingData.processCode)) {
                            updatedChannel = 'SMTP';
                            updatedFormat = 'PDF';
                            updatedProvider = 'EDI_PROV';
                        }

                        inputData.push({
                            surveyId: Number(localStorage.getItem(SELECTED_SURVEY_ID)),
                            processCode: processOutGoingData.processCode,
                            format: updatedFormat,
                            channel: updatedChannel,
                            provider: updatedProvider,
                            channelDefault: false,
                        });
                    });
            }
            if (data.paymentAdviceUpdatedFormat && data.paymentAdviceUpdatedChannel) {
                const channelProvider = data.paymentAdviceUpdatedChannel?.split('-');

                processPaymentAdviceChannelData.forEach((processPaymentAdvice) => {
                    inputData.push({
                        surveyId: Number(localStorage.getItem(SELECTED_SURVEY_ID)),
                        processCode: processPaymentAdvice.processCode,
                        format: data.paymentAdviceUpdatedFormat,
                        channel: channelProvider[0],
                        provider: channelProvider[1],
                        channelDefault: false,
                    });
                });
            }
            if (inputData.length > 0) {
                dispatch(setSurveyDataLoading(true));
                insertSurveyChannelsMutation({
                    variables: {
                        includedSurveyIds: gridApi?.getSelectedRows()[0].includedSurveyIds,
                        partnerType: localStorage.getItem(PARTNER_TYPE),
                        surveyChannels: inputData,
                    }
                }).then((_) => {
                    fetchData().then(() => {
                        props.onStatusUpdate();
                        notification.info({
                            message: intl.formatMessage({
                                id: 'survey-eDocument-supplier-success-notification-header',
                                defaultMessage: 'SUCCESSFULLY',
                            }),
                            description: intl.formatMessage({
                                id: 'survey-eDocument-customer-saved-channel-successfully',
                                defaultMessage: 'Format and Channels successfully saved',
                            }),
                        });
                        dispatch(setSurveyDataLoading(false));
                    });
                })
                    .catch(() => {
                        dispatch(resetSurveyDataLoading(false));
                    })
                    .finally(() => {
                        dispatch(setSurveyDataLoading(false));
                    });
            }
        }
    };
    const onEdit = () => {
        setDocumentTypeViewMode(false);
        validateSelect();
    };

    const onView = () => {
        setDocumentTypeViewMode(true);
        validateSelect();
    };

    const backToMain = async () => {
        await fetchData();
        setSurveyDocumentTypeMainVisible('survey-document-type-main-visible');
        setSurveyDocumentTypeMainDetailsVisible('survey-document-type-main-details-hidden');
        isShowEditPanel(false);
    };

    const toPaymentAdvice = () => {
        // setActiveTabKey("2")
    };

    const toIncomingDocuments = () => {
        //setActiveTabKey("3")
    };

    return (
        <>
            <ConfigProvider locale={localStorage.getItem('locale') === 'en' ? enUS : deDe}>
                {/* <Spin spinning={surveyState.survey.masterDataLoading} size={"large"}> */}
                {surveyDocumentTypeMainVisible === 'survey-document-type-main-visible' && (
                    <Card
                        title={eDocumentTitle}
                        size={'small'}
                        className={`pl15 ml-10 ${surveyDocumentTypeMainVisible}`}
                    >
                        <Typography>
                            <Paragraph>
                                {headerParagraphLine1.split('\\n').map((line, idx) => (
                                    <Row key={`heading-index-${idx}`}>{line}</Row>
                                ))}
                            </Paragraph>
                        </Typography>

                        <Row gutter={24}>
                            <Col span={24} className="mb5">
                                <QuestionCircleOutlined
                                    style={{fontSize: '20px', color: '#444444'}}
                                    className={'mr10'}
                                />
                                <Link href={getDefinitionFormatChannelHelp()} target="_blank">
                                    {definitionsFormatChannel}
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="mb5">
                                <ActionIconDocumentTypeCellRenderer
                                    edit={onEdit}
                                    view={onView}
                                    onBulkEdit={editChannel}
                                />
                            </Col>
                        </Row>

                        <div style={{flex: 'auto', height: '250px', paddingTop: '0px'}}>
                            <AgGridReact
                                defaultColDef={{
                                    enableValue: true,
                                    sortable: true,
                                    resizable: true,
                                }}
                                rowData={summaryGridData}
                                columnDefs={SURVEY_DOCUMENT_TYPE_TABLE_HEADERS(intl)}
                                localeText={getAgGridLocalization(intl)}
                                sideBar={false}
                                rowClass="agGridRows"
                                suppressMenuHide={true}
                                rowSelection={'single'}
                                debug={false}
                                paginationPageSize={5}
                                suppressRowClickSelection={true}
                                onRowSelected={selectRow}
                                onGridReady={onSupplierDocumentTypeGridReady}
                            />
                        </div>
                    </Card>
                )}

                {showEditPanel && surveyDocumentTypeMainVisible === 'survey-document-type-main-visible' && (
                    <SurveySupplierDocumentTypeBulkUpdateForm
                        outgoingFormatData={outgoingFormatData}
                        paymentAdvanceFormatData={padFormatData}
                        incomingDocumentsFormatData={incomingFormatData}
                        key={'survey-bulk-inset-form'}
                        outgoingFormatOptions={outgoingFormatOptions}
                        paymentAdvanceFormatOptions={paymentAdviceFormatOptions}
                        incomingDocumentsFormatOptions={incomingDocumentsFormatOptions}
                        channelData={surveyChannels}
                        save={saveChannel}
                        incomingChannel={selectedSurvey?.incomingChannel}
                        incomingFormat={selectedSurvey?.incomingFormat}
                        outgoingFormat={selectedSurvey?.outgoingFormat}
                        outgoingChannel={selectedSurvey?.outgoingChannel}
                        paymentAdviceChannel={selectedSurvey?.paymentAdviceChannel}
                        paymentAdviceFormat={selectedSurvey?.paymentAdviceFormat}
                    />
                )}

                {surveyDocumentTypeMainVisible !== 'survey-document-type-main-visible' && (
                    <Card title="" size={'small'} className={`ml-10 ${surveyDocumentTypeMainDetailsVisible}`}>
                        <Row gutter={24}>
                            <Col span={10} className="mb5">
                                <QuestionCircleOutlined
                                    style={{fontSize: '20px', color: '#444444'}}
                                    className={'mr10'}
                                />
                                <Link href={getDefinitionFormatChannelHelp()} target="_blank">
                                    {definitionsFormatChannel}
                                </Link>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24} className="mb5">
                                <Tabs>
                                    <TabPane tab={incomingDocumentsTabTitle} key={'3'}>
                                        <SurveySupplierDocumentTypeTab
                                            back={backToMain}
                                            key={'INC-GRID-3'}
                                            next={props.next}
                                            selectedPartnerNumber={selectedPartnerNumber}
                                            selectedPartnerCompanyCode={selectedPartnerCompanyCode}
                                            channelData={surveyChannels?.filter(
                                                (item) => item.processCode === 'INC' || item.processCode === 'ECAP'
                                            )}
                                            surveyId={Number(localStorage.getItem(SELECTED_SURVEY_ID))}
                                            selectedTab={SUPPLIER_DOC_TYPES.INCOMING}
                                            onStatusUpdate={props.onStatusUpdate}
                                            stateContainer={props.stateContainer}
                                            includedSurveys={includedSurveyIds}
                                        />
                                    </TabPane>
                                    <TabPane tab={outgoingDocumentsTabTitle} key={'1'}>
                                        <SurveySupplierDocumentTypeTab
                                            back={backToMain}
                                            key={'OUT-GRID-3'}
                                            next={toPaymentAdvice}
                                            selectedPartnerNumber={selectedPartnerNumber}
                                            selectedPartnerCompanyCode={selectedPartnerCompanyCode}
                                            channelData={surveyChannels?.filter(
                                                (item) =>
                                                    item.processCode !== 'INC' &&
                                                    item.processCode !== 'PAD' &&
                                                    item.processCode !== 'ECAP'
                                            )}
                                            surveyId={Number(localStorage.getItem(SELECTED_SURVEY_ID))}
                                            selectedTab={SUPPLIER_DOC_TYPES.OUTGOING}
                                            onStatusUpdate={props.onStatusUpdate}
                                            stateContainer={props.stateContainer}
                                            includedSurveys={includedSurveyIds}
                                        />
                                    </TabPane>
                                    <TabPane tab={paymentAdvicesTabTitle} key={'2'}>
                                        <SurveySupplierDocumentTypeTab
                                            key={'PAD-GRID-2'}
                                            back={backToMain}
                                            next={toIncomingDocuments()}
                                            selectedPartnerNumber={selectedPartnerNumber}
                                            selectedPartnerCompanyCode={selectedPartnerCompanyCode}
                                            channelData={surveyChannels?.filter((item) => item.processCode === 'PAD')}
                                            surveyId={Number(localStorage.getItem(SELECTED_SURVEY_ID))}
                                            selectedTab={SUPPLIER_DOC_TYPES.PAYMENT_ADVICE}
                                            onStatusUpdate={props.onStatusUpdate}
                                            stateContainer={props.stateContainer}
                                            includedSurveys={includedSurveyIds}
                                        />
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </Card>
                )}
                {/* </Spin> */}
            </ConfigProvider>
        </>
    );
};
;

export default SurveySuppliersDocumentType;