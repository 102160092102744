import { after, cloneDeepWith, isArray, isObject, omitBy } from "lodash";
import { IntlShape } from "react-intl/src/types";
import { FormInstance } from "antd";
import dayjs from "dayjs";

import DOMPurify from "dompurify";

function omitDeep(collection: Object | [], omitFn: (val, key) => Boolean) {
  return cloneDeepWith(collection, function customizer(v) {
    return isArray(v)
      ? v.map((e) => (isObject(e) ? cloneDeepWith(e, customizer) : e))
      : isObject(v)
        ? cloneDeepWith(omitBy(v, omitFn), after(2, customizer))
        : undefined;
  });
}

export {
  omitDeep
};

export const getI18n = (id: string, defaultMessage: string, intl: IntlShape) => {  
  if (!intl) {
    return '';
  }

  if(id === null || id === undefined){
    console.error(`getI18n error - id is null:: ${defaultMessage}`); 
  }
  return intl.formatMessage({
    id: id ?? "no-id-supplied", defaultMessage
  });
};

export const readNestedFormValue = (form:FormInstance<any>, nestedProp:string)=> {
  const nestedProperties = nestedProp.split('.');
  let fieldValues = form.getFieldsValue();
  let result = undefined;
  for (let i = 0; i < nestedProperties.length; i++) {
    result = fieldValues[nestedProperties[i]];
    if (!result) {
      break;
    }
    if (typeof result === "object") {
      fieldValues = result;
    }
  }

  return result;
}

/**
 * Generate a nested value, and save it in FormInstance.
 * @param nestedProp
 * @param nestedValue
 */
export const createdNestedValue = (nestedProp:string, nestedValue:any)=> {
  const nestedProperties = nestedProp.split('.');
  const value = {};
  let previous = value;
  for (let i = 0; i < nestedProperties.length; i++) {
    if (i < nestedProperties.length-1) {
      previous = {};
      value[nestedProperties[i]] = previous;
    }
    else {
      previous[nestedProperties[i]] = nestedValue;
    }
  }

  return value;
};

export function isNotNull(val: any) {
  return val !== undefined && val !== null;
}


export const isExternalUser = (entitlements: string[]) => { 
  return !entitlements?.includes("user@internal");  //Rather do negative test IF NOT INTERNAL - so if internal does not exists - assume external. will fix any issue if no entitlements exist.
}

export function peek<T>(val:T) {
  console.log('array item peek',val);
  return val;
}

export function renderPeek(val:any) {
  console.log('render peek',val);
  return true;
}

export function groupByKeyMapper(arr:any[], keyMapper:(item:any)=>string, valueMapper?:(item:any)=>any):Record<string, any[]> {
  return arr.reduce((result, arrayItem) => {
    const key = keyMapper(arrayItem);
    if (!result[key]) {
      result[key] = [];
    }

    const value = valueMapper ? valueMapper(arrayItem) : arrayItem;

    result[key].push(value);

    return result;
  }, {});
}

export type CustomReducer = {
  reducer: (result,ithItem)=>any;
  initial:any;
}

export function groupByKeyMapperCustomerReducer(
    arr:any[],
    keyMapper:(item:any)=>string,
    customReducer:CustomReducer,
    valueMapper?:(item:any)=>any) {

  const original = groupByKeyMapper(arr,keyMapper,valueMapper);
  const newMap = {};

  for (const key in original) {
    const values = original[key];
    newMap[key] = values.reduce(customReducer.reducer,customReducer.initial);
  }

  return newMap;
}

export function roundNumber(numberToRound:number, precision:number) {
  if (precision===0) {
    return `${Math.round(numberToRound)}`;
  }

  if (numberToRound!==undefined && numberToRound!==null) {
    return (Math.round(numberToRound*Math.pow(10,precision))/Math.pow(10,precision)).toFixed(precision)
  }
  return '';
}

export function getMinDate(firstDate:dayjs.Dayjs, secondDate:dayjs.Dayjs) {
  if (firstDate && secondDate) {
    if (secondDate.isBefore(firstDate)) {
      return secondDate;
    }
  }

  return firstDate;
}

export function getMaxDate(firstDate:dayjs.Dayjs, secondDate:dayjs.Dayjs) {
  if (firstDate && secondDate) {
    if (secondDate.isAfter(firstDate)) {
      return secondDate;
    }
  }

  return firstDate;
}

export function removeScriptsFromText(text:string) {
  return DOMPurify.sanitize(text, {FORBID_TAGS:['style']});
}

/*
The method below converts confluence markup for bolding, italizing, underlining text and specifying links into html.
* */
export function interpretConfluenceMarkup(originalText: string): string {
  let text = originalText;
  // Define regular expressions for different markup types
  const boldRegex = /\*([^*]+)\*/g;
  const italicRegex = /_([^_]+)_/g;
  const underlineRegex = /\+([^+]+)\+/g;
  const linkRegex = /\[([^|]+)\|([^\]]+)\]/g;

  // Replace bold markup
  text = text.replace(boldRegex, (match, p1) => `<strong>${p1}</strong>`);

  // Replace italic markup
  text = text.replace(italicRegex, (match, p1) => `<em>${p1}</em>`);

  // Replace underline markup
  text = text.replace(underlineRegex, (match, p1) => `<u>${p1}</u>`);

  // Replace link markup
  text = text.replace(linkRegex, (match, p1, p2) => `<a class="edp-link" href="${p2}" target="_blank">${p1}</a>`);

  return text;
}

