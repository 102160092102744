import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ProLayout, ProSettings } from '@ant-design/pro-layout';
import { useIntl } from 'react-intl';

import { Link, Outlet, useNavigate } from 'react-router-dom';
import './index.css';
import sideMenu from '../config/layoutMenuSettings';
import { IAppComponentProps } from '../components';

import { QuestionCircleOutlined } from '@ant-design/icons';

import check from '../components/Authorized/CheckPermissions';

import { entitlementExists } from '../main/entitlementService';

import { ProConfigProvider } from '@ant-design/pro-components';
import { Button, ConfigProvider, Tooltip } from 'antd';
import styles from '../components/GlobalHeader/index.less';
import NoticeIconView from '../components/GlobalHeader/NoticeIconView';
import AvatarDropdown from '../components/GlobalHeader/AvatarDropdown';
import PortalRoutes from './PortalRoutes';
import Banner from "./Banner";


const MainLayout: FunctionComponent<IAppComponentProps> = (props) => {
    const intl = useIntl();
    const navigate = useNavigate();

    const currentUser = props.currentUser;
    const distinctEntitlements = props.distinctEntitlements;
    const entitlementPartner = props.entitlementPartner;
    const [pathname, setPathname] = useState("/welcome");
    const [collapsed, setCollapsed] = useState(false);

    const [settings, setSetting] = useState<Partial<ProSettings> | undefined>({
        fixSiderbar: true,
        layout: 'mix',
        splitMenus: false,
    })


   // const isProduction = process.env.NODE_ENV !== 'development';
    const isProduction = process.env.REACT_APP_EINV_PORTAL_ENV !== 'INT';

    const actionRef = useRef<{
        reload: () => void;
    }>();

    const handleFAQClicked = () => {
        navigate("/help-faq-user-guides");
    };

    /**
     * filter menuData
     */
    const getSubMenu = item => {
        // doc: add hideChildrenInMenu
        if (item.children && !item.hideChildrenInMenu && item.children.some(child => child.name)) {
            return {
                ...item,
                children: filterMenuData(item.children) // eslint-disable-line
            };
        }
        return item;
    };

    const filterMenuData = menuData => {
        if (!menuData) {
            return [];
        }
        return menuData
            .filter(item => item.name && !item.hideInMenu)
            .map(item => check(item.authority, getSubMenu(item), undefined))
            .filter(item => item);
    };


    function copy(o) {
        return Object.assign({}, o)
    }

    //filters sidemenu based on entitlements - only entitled menu items will remain.
    const filterSideMenuOnEntitlements = () => {
        const resultRoutes = sideMenu(intl).route.routes.map(copy).filter(function f(o) {

            if (o.routes) {//if menu has routes, filter them using this same filter
                o.routes = o.routes.map(copy).filter(f);
                //here we can check if o.routes has any items, and if empty return false - this will remove top level menu's if no children exist
                if (o.routes.length === 0) {
                    return false;
                }
            }

            if (o.entitlement === undefined) {//if no entitlement filter is set - just return true
                return true;
            }

            if (entitlementExists(o.entitlement, props.distinctEntitlements)) {//if entitlement is set - check it against the distinct system entitlements.
                return true;
            }

        })
        const filteredResult: any = {}
        filteredResult.location = sideMenu(intl).location;
        filteredResult.route = {};
        filteredResult.route.routes = resultRoutes;
        return filteredResult;
    }


    const layoutProps = {
        ...filterSideMenuOnEntitlements(),
        location: {
            pathname
        },
        navTheme: "light",
        collapsed,
        collapsedButtonRender: false,
        menuItemRender: (item, dom) => (

            <a
                onClick={() => {
                    setPathname(item.path);
                }}
            >
                {dom}
            </a>

        )
    };

    const setPath = (name) => {
        setPathname(name);
    };


    useEffect(() => {
        let username = localStorage.getItem("username");
        if (!username || username === 'undefined') {
            username = props.currentUser?.uid;
            if (username && username !== 'undefined') {
                localStorage.setItem("username", username);
            } else {
                const token = localStorage.getItem("id_token");
                if (token) {
                    try {
                        const data = JSON.parse(atob(token.split('.')[1]));
                        username = data['sub'];
                        if (username) {
                            localStorage.setItem("username", username);
                        }
                    } catch (e) {
                        console.error('Oops', e);
                    }
                }
            }
        }

    }, []);

    if (typeof document === 'undefined') {
        return <div/>;
    }

    return (
        <>

            <div
                id="edp-pro-layout"
                style={{
                    height: '100vh'
                }}
            >
                <ProConfigProvider hashed={false}>
                    <ConfigProvider
                        getTargetContainer={() => {
                            return document.getElementById('edp-pro-layout') || document.body;
                        }}
                    >
                        <ProLayout
                            key={"edp-pro-layout-key"}
                            prefixCls="my-prefix"
                            pageTitleRender={false}
                            {...filterSideMenuOnEntitlements()}
                            location={{
                                pathname,
                            }}

                            headerTitleRender={(_, __, ___) => {
                                const defaultDom = (
                                    <div className="logo img">
                                        <img className="main-logo" src="./../images/bmw-group-logo_white.png"
                                             alt="BMW Logo"/>
                                    </div>
                                );
                                if (typeof window === 'undefined') return defaultDom;
                                if (document.body.clientWidth < 1400) {
                                    return defaultDom;
                                }
                                if (___.isMobile) return defaultDom;
                                return (
                                    <>
                                        {defaultDom}
                                    </>
                                );
                            }}

                            actionsRender={(props) => {
                                return [
                                    <div key={"environment-ribbon"}
                                         style={{display: "flex", paddingRight: "20px"}}>
                                        <Button hidden={isProduction} danger={true} type="dashed">Integration</Button>
                                    </div>,
                                    <div key={"rightcontents-faq-tooltip-key"}
                                         style={{display: "flex", paddingRight: "20px"}}>
                                        <Tooltip title={intl.formatMessage({
                                            id: "rightcontents-faq-tooltip",
                                            defaultMessage: "Frequently Asked Questions"
                                        })}
                                        >
                                            <a
                                                style={{
                                                    color: "inherit"
                                                }}
                                                className={styles.action}
                                            >
                                                <QuestionCircleOutlined onClick={handleFAQClicked}/>
                                            </a>
                                        </Tooltip>
                                    </div>,
                                    <div key={"rightcontents-notifications-tooltip-key"}
                                         style={{display: "flex", paddingRight: "20px"}}>
                                        <Tooltip title={intl.formatMessage({
                                            id: "rightcontents-notifications-tooltip",
                                            defaultMessage: "Notifications"
                                        })}>
                                            <NoticeIconView currentUser={currentUser} intl={intl}
                                                            distinctEntitlements={distinctEntitlements}/>
                                        </Tooltip>
                                    </div>,
                                    <div key={"avatar-drop-down-key"}
                                         style={{display: "unset", paddingRight: "20px", alignContent: "right"}}>
                                        <AvatarDropdown currentUser={currentUser} menu={true} intl={intl}
                                                        distinctEntitlements={distinctEntitlements}
                                                        entitlementPartner={entitlementPartner}/>

                                    </div>

                                ];
                            }}
                            menuItemRender={(item, dom) => (
                                <Link to={item.path}
                                    onClick={() => {
                                        setPathname(item.path || '/welcome');
                                    }}
                                >
                                    {dom}
                                </Link>
                            )}

                            menu={{
                                collapsedShowGroupTitle: true,
                            }}

                            {...settings}
                        >
                            {currentUser !== undefined ? <Banner intl={intl} currentUser={currentUser}/> : null}
                            <PortalRoutes distinctEntitlements={distinctEntitlements} intl={intl} currentUser={currentUser} />
                            <Outlet/>
                        </ProLayout>
                    </ConfigProvider>
                </ProConfigProvider>
            </div>

        </>
    );
};

export default MainLayout;



