import React from 'react';
import { IntlShape } from 'react-intl';
import { getI18n } from '../../../../utils/Utils';
import { Button, Col, Form, FormInstance, Input, Popover } from 'antd';
import { ClearOutlined, ReloadOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { AuditDateFromToSelection } from '../../entitlement_maintenance/DateUtils';
import { getDateFormatOrDefault } from '../../../../pages/users/UserFormatPreferenceServices';
import {
    DropDownSelectionMode,
    Initialization,
    LabelLocation,
} from '../../../../pages/info-portal/InfoPortalInterfaces';
import { IAppComponentProps } from 'src/components';
import CompanyCodeDropDown from '../../entitlement_maintenance/CompanyCodeDropDown';
import { DropDownSelectType } from '../../entitlement_maintenance/EntitlementInterfaces';
import {
    BusinessAreaListSelection,
    descriptionFilter,
} from '../../../../pages/info-portal/components/InfoPortalDropdowns';
import { ICellRendererParams } from 'ag-grid-community';
export function EditButton(intl: IntlShape, onEdit: any, width?: number) {
    return {
        headerName: getI18n('entitlements-grid-header-edit-action', 'Edit', intl),
        filter: false,
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
            onClick: onEdit,
            label: 'Edit',
            type: 'primary',
        },
        width: 100 | width,
    };
}

export function DeleteButton(intl: IntlShape, onDelete: any, width?: number) {
    return {
        headerName: getI18n('entitlements-grid-header-delete-action', 'Delete', intl),
        filter: false,
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
            onClick: onDelete,
            label: 'Delete',
            type: 'delete',
        },
        width: 110 | width,
    };
}

export function BusinessAreaIdField(intl: IntlShape, width?: number) {
    return {
        headerName: getI18n('master-data-business-area-id-grid-header-name', 'Business Area Id', intl),
        field: 'businessAreaId',
        colId: 'Business_Area_Id',
        width: 200 | width,
        filter: 'agMultiColumnFilter',
    };
}

export function CompanyCodeField(intl: IntlShape, width?: number) {
    return {
        headerName: getI18n('master-data-company-code-grid-header-name', 'Company Code', intl),
        field: 'companyCode',
        colId: 'Company_Code',
        width: 300 | width,
        filter: 'agMultiColumnFilter',
    };
}

export function CompanyNameField(intl: IntlShape, width?: number) {
    return {
        headerName: getI18n('master-data-company-name-grid-header-name', 'Company Name', intl),
        field: 'companyName',
        colId: 'Company_Name',
        width: 300 | width,
        filter: 'agMultiColumnFilter',
    };
}

export function CompanyCodeFormField(
    intl: IntlShape,
    handleInputChange?: any,
    required?: boolean,
    span?: number,
    readOnly?: boolean
) {
    return (
        <Col span={4 | span}>
            <Form.Item
                name={'companyCode'}
                key={'companyCode'}
                rules={[
                    {
                        required: !required,
                        message: getI18n('master-data-company-code-required-error', 'Company Code is required', intl),
                    },
                ]}
                label={getI18n('master-data-company-code-grid-header-name', 'Company Code', intl)}
            >
                <Input
                    type="text"
                    onChange={handleInputChange}
                    placeholder={getI18n('master-data-company-code-placeholder', 'Enter company Code', intl)}
                    readOnly={readOnly}
                />
            </Form.Item>
        </Col>
    );
}

export function CompanyCodeDropDownField(
    props: IAppComponentProps,
    span?: number,
    onFieldChange?: any,
    dropDownType?: DropDownSelectType
) {
    return (
        <Col span={4 | span}>
            <CompanyCodeDropDown
                intl={props.intl}
                currentUser={props.currentUser}
                distinctEntitlements={props.distinctEntitlements}
                selectType={dropDownType || DropDownSelectType.Single}
                onSelect={onFieldChange}
                required={true}
            />
        </Col>
    );
}

const updateInitState = (property: string, value: boolean) => {};

export function BusinessAreaCodeDropDownField(props: IAppComponentProps, form: FormInstance<any>, span?: number) {
    const init: Initialization = {
        beginInitialize(): string {
            const property = 'businessAreaCode';
            updateInitState(property, true);
            return property;
        },
        finishInitialize(initializationId: string): void {
            console.log('Finished waiting for property to load', initializationId, new Date());
            updateInitState(initializationId, false);
        },
    };
    return (
        <Col span={4 | span}>
            <BusinessAreaListSelection
                selectionMode={DropDownSelectionMode.SINGLE}
                initialValue={null}
                filterOptions={descriptionFilter}
                currentUser={props.currentUser}
                intl={props.intl}
                distinctEntitlements={props.distinctEntitlements}
                initialization={init}
                form={form}
            />
        </Col>
    );
}


export enum agGridFilterTypes {
    'agTextColumnFilter',
    'agNumberColumnFilter',
    'agDateColumnFilter',
    'agSetColumnFilter',
    'agMultiColumnFilter'
}


export enum agGridCellRendererTypes {
    'uppercase',
    'group'
}

export function uppercaseCellRenderer(params: ICellRendererParams, mode: agGridCellRendererTypes) {
    if (mode === agGridCellRendererTypes.uppercase) {
        return params.value ? params.value.toUpperCase() : '';
    } else if (mode === agGridCellRendererTypes.group) {
        return 'agGroupCellRenderer';
    }
    return params.value;
  }
export function GenericGridField(
    intl: IntlShape,
    field: string,
    colId: string,
    filter: agGridFilterTypes,
    headerName?: string,
    width?: number,
    mode?: agGridCellRendererTypes
) {
    return {
        headerName: getI18n(`master-data-${colId}-grid-header-name`, headerName || colId, intl),
        field,
        colId,
        width: 300 | width,
        filter,
        cellRenderer:  (params) => uppercaseCellRenderer(params,mode)
    };
}

export function BusinessAreaCodeField(intl: IntlShape, width?: number) {
    return {
        headerName: getI18n('master-data-area-business-area-code-grid-header-name', 'Business Area Code', intl),
        field: 'businessAreaCode',
        colId: 'Business_Area_Code',
        width: 300 | width,
        filter: 'agMultiColumnFilter',
    };
}

export function TextInputFormField(
    intl: IntlShape,
    componentName: string,
    headerName: string,
    required?: boolean,
    span?: number,
    handleInputChange?: any
) {
    return (
        <Col span={4 | span}>
            <Form.Item
                name={componentName}
                key={componentName}
                rules={[
                    {
                        required: !required,
                        message: getI18n(
                            `master-data-${componentName}-required-error`,
                            `${headerName} is required`,
                            intl
                        ),
                    },
                ]}
                label={getI18n(`master-data-area-${componentName}-grid-header-name`, headerName, intl)}
            >
                <Input
                    type="text"
                    onChange={handleInputChange}
                    placeholder={getI18n(`master-data-${componentName}-placeholder`, `Enter ${headerName}`, intl)}
                    readOnly={required}
                />
            </Form.Item>
        </Col>
    );
}

export function BusinessAreasCodeFormField(
    intl: IntlShape,
    required?: boolean,
    span?: number,
    handleInputChange?: any
) {
    return (
        <Col span={4 | span}>
            <Form.Item
                name={'businessAreaCode'}
                key={'businessAreaCode'}
                rules={[
                    {
                        required: !required,
                        message: getI18n(
                            'master-data-business-area-code-required-error',
                            'Business Area Code is required',
                            intl
                        ),
                    },
                ]}
                label={getI18n('master-data-area-business-area-code-grid-header-name', 'Business Area Code', intl)}
            >
                <Input
                    type="text"
                    onChange={handleInputChange}
                    placeholder={getI18n(
                        'master-data-business-area-code-placeholder',
                        'Enter Business Area Code',
                        intl
                    )}
                    readOnly={required}
                />
            </Form.Item>
        </Col>
    );
}

export function BusinessAreaNameField(intl: IntlShape, width?: number) {
    return {
        headerName: getI18n('master-data-area-business-area-name-grid-header-name', 'Business Area Name', intl),
        field: 'businessAreaName',
        colId: 'Business_Area_Name',
        width: 300 | width,
        filter: 'agMultiColumnFilter',
    };
}

export function BusinessAreaNameFormField(intl: IntlShape, required?: boolean, span?: number, handleInputChange?: any) {
    return (
        <Col span={4 | span}>
            <Form.Item
                name={'businessAreaName'}
                key={'businessAreaName'}
                rules={[
                    {
                        required: !required,
                        message: getI18n(
                            'master-data-business-area-name-required-error',
                            'Business Area Name is required',
                            intl
                        ),
                    },
                ]}
                label={getI18n('master-data-area-business-area-name-grid-header-name', 'Business Area Name', intl)}
            >
                <Input
                    type="text"
                    onChange={handleInputChange}
                    placeholder={getI18n(
                        'master-data-business-area-name-placeholder',
                        'Enter Business Area Name',
                        intl
                    )}
                    readOnly={required}
                />
            </Form.Item>
        </Col>
    );
}

export function PhraseIdField(intl: IntlShape, width?: number) {
    return {
        headerName: getI18n('master-data-area-phrase-Id-grid-header-name', 'Phrase Id', intl),
        field: 'phraseId',
        colId: 'Phrase_Id',
        width: 300 | width,
        filter: 'agMultiColumnFilter',
    };
}

export function PhraseIdFormField(
    intl: IntlShape,
    required?: boolean,
    readOnly?: boolean,
    span?: number,
    handleInputChange?: any
) {
    return (
        <Col span={4}>
            <Form.Item
                name={'phraseId'}
                key={'phraseId'}
                rules={[
                    {
                        required: !required,
                        message: getI18n('master-data-phrase-id-required-error', 'Phrase Id is required', intl),
                    },
                ]}
                label={getI18n('master-data-area-phrase-Id-grid-header-name', 'Phrase Id', intl)}
            >
                <Input
                    type="text"
                    onChange={handleInputChange}
                    placeholder={getI18n('master-data-phrase-id-placeholder', 'Enter Phrase Id', intl)}
                    readOnly={readOnly}
                />
            </Form.Item>
        </Col>
    );
}

export function InsertUserField(intl: IntlShape, width?: number) {
    return {
        headerName: getI18n('master-data-header-insert-user', 'Insert User', intl),
        field: 'insertUser',
        colId: 'Insert_User',
        width: 150 | width,
        filter: 'agMultiColumnFilter',
        cellRenderer: (params) => (params.value ? params.value.toUpperCase() : ''),
    };
}

export function InsertUserFormField(intl: IntlShape, required?: boolean, span?: number, handleInputChange?: any) {
    return (
        <Col span={4 | span}>
            <Form.Item
                name={'insertUser'}
                key={'insertUser'}
                rules={[
                    {
                        required: !required,
                        message: getI18n(
                            'master-data-header-insert-user-required-error',
                            'Insert User is required field',
                            intl
                        ),
                    },
                ]}
                label={getI18n('master-data-header-insert-user-label', 'Insert User', intl)}
            >
                <Input
                    type="text"
                    onChange={handleInputChange}
                    placeholder={getI18n('master-data-insert-user-placeholder', 'Insert User', intl)}
                    readOnly={required}
                />
            </Form.Item>
        </Col>
    );
}

export function InsertTimeField(intl: IntlShape, width?: number) {
    return {
        headerName: getI18n('master-data-header-insert-time', 'Insert Time', intl),
        field: 'insertTime',
        colId: 'Insert_Time',
        width: 200 | width,
        filter: 'agDateColumnFilter',
    };
}

export function UpdateUserField(intl: IntlShape, width?: number) {
    return {
        headerName: getI18n('master-data-header-update-user', 'Update User', intl),
        field: 'updateUser',
        colId: 'Update_User',
        width: 150 | width,
        filter: 'agMultiColumnFilter',
        cellRenderer: (params) => (params.value ? params.value.toUpperCase() : ''),
    };
}

export function UpdateUserFormField(intl: IntlShape, required?: boolean, span?: number, handleInputChange?: any) {
    return (
        <Col span={4 | span}>
            <Form.Item
                name={'updateUser'}
                key={'updateUser'}
                rules={[
                    {
                        required: !required,
                        message: getI18n(
                            'master-data-header-update-user-required-error',
                            'Update User is required field',
                            intl
                        ),
                    },
                ]}
                label={getI18n('master-data-header-update-user-label', 'Update User', intl)}
            >
                <Input
                    type="text"
                    onChange={handleInputChange}
                    placeholder={getI18n('master-data-update-user-placeholder', 'Update User', intl)}
                    readOnly={required}
                />
            </Form.Item>
        </Col>
    );
}

export function UpdateTimeField(intl: IntlShape, width?: number) {
    return {
        headerName: getI18n('master-data-header-update-time', 'Update Time', intl),
        field: 'updateTime',
        colId: 'Update_Time',
        width: 200,
        filter: 'agDateColumnFilter',
    };
}

export function VolumeFieldField(intl: IntlShape, width?: number) {
    return {
        headerName: getI18n('master-data-header-volume', '#Times used', intl),
        field: 'volume',
        colId: 'volume',
        width: 150 | width,
        filter: 'agNumberColumnFilter',
    };
}

export function AuditIdGroupingField(intl: IntlShape, width?: number) {
    return {
        headerName: getI18n('master-data-header-audit-id', 'Audit Id', intl),
        field: 'auditId',
        colId: 'Audit_Id',
        width: 100 | width,
        filter: 'agMultiColumnFilter',
        cellRenderer: 'agGroupCellRenderer',
    };
}

export function AuditIdField(intl: IntlShape, width?: number) {
    return {
        headerName: getI18n('master-data-header-audit-id', 'Audit Id', intl),
        field: 'auditId',
        colId: 'Audit_Id',
        width: 100 | width,
        filter: 'agMultiColumnFilter',
    };
}

export function AuditDateFromToSelectionField(
    props: IAppComponentProps,
    form: FormInstance<any>,
    vertical?: boolean,
    span?: number
) {
    return (
        <Col span={4 | span}>
            <AuditDateFromToSelection
                dateFormatter={getDateFormatOrDefault(props.currentUser)}
                labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                displayColon={!vertical}
                currentUser={props.currentUser}
                intl={props.intl}
                distinctEntitlements={props.distinctEntitlements}
                form={form}
                initialization={null}
            />
        </Col>
    );
}
export function GridRowSaveButton(intl: IntlShape, saveData: any, span?: number) {
    return (
        <Col span={2 | span}>
            <Button type={'primary'} onClick={saveData} icon={<SaveOutlined />}>
                {getI18n('entitlements-grid-header-save-action', 'Save', intl)}
            </Button>
        </Col>
    );
}

export function RefreshButton(intl: IntlShape, refreshData: any, span?: number) {
    return (
        <Col span={3 | span}>
            <Button type="default" icon={<ReloadOutlined />} onClick={refreshData}>
                Refresh
            </Button>
        </Col>
    );
}

export function SearchButton(intl: IntlShape, handleFormSearch: any, span?: number) {
    return (
        <Col span={1 | span}>
            <Button type="primary" icon={<SearchOutlined />} onClick={handleFormSearch} className={'mr5'}>
                {getI18n('master-data-header-search-button', 'Search', intl)}
            </Button>
        </Col>
    );
}

export function ClearFiltersButton(intl: IntlShape, clearFilters: any, span?: number) {
    return (
        <Col span={1 | span}>
            <Button type="default" icon={<ClearOutlined />} onClick={clearFilters}>
                {getI18n('master-data-header-clear-filters-button', 'Clear Filters', intl)}
            </Button>
        </Col>
    );
}

export function SubmitButton(intl: IntlShape, submit: any, span?: number, updateMode?: boolean) {
    return (
        <Col span={1 | span}>
            <Button type="primary" onClick={submit}>
                {updateMode
                    ? getI18n('master-data-update-data-button', 'Update', intl)
                    : getI18n('master-data-add-data-button', 'Add', intl)}
            </Button>
        </Col>
    );
}

export function CancelUpdateButton(intl: IntlShape, cancelUpdate: any, span?: number, updateMode?: boolean) {
    return (
        <Col span={1 | span}>
            <Button type="primary" onClick={cancelUpdate}>
                {updateMode
                    ? getI18n('master-data-cancel-update-data-button', 'Cancel', intl)
                    : getI18n('master-data-clear-update-data-button', 'Clear', intl)}
            </Button>
        </Col>
    );
}
