import {gql} from "@apollo/client";

export const ATTACH_SUPPORTING_DOCUMENT = gql`
    mutation AttachSupportingDocumentResponse($blobFile: BlobFileInput!) {
        uploadEcapDocument(blobFile: $blobFile)
        {
            blobId
            downloadLink
            fileName
            mimeType
            requiresAuthentication
            rolesAllowed
            insertUser
            insertTime
            updateUser
            updateTime
        }
    }
`

export const ATTACH_BLOB = gql`
    mutation AssociateWithBlob($blobInfo: BlobInfo!) {
        associateWithBlob(blobInfo: $blobInfo)
        {
            attachmentId
            blobId
            blobPath
            fileName
            documentId
            attachmentType
            sizeInKB
        }
    }
`

export const DELETE_SUPPORTING_DOCUMENT = gql`
    mutation DeleteSupportingDocument($attachmentId: String!) {
        deleteSupportingDocument(attachmentId: $attachmentId) {
            statusCode
        }
    }
`;

export const UPDATE_ECAP_DOCUMENT_RECORD = gql`
    mutation updateDocumentRecord($documentRecord: DocumentRecordInput!) {
        updateDocumentRecord(documentRecord: $documentRecord)
        {
            document {
                id
                documentTypeId
                documentStatusId
                companyPartnerId
                companyCode
                partnerVatCodeId
                bmwIsRetailOutlet
                bmwBusinessAreaId
                bmwBusinessUnitId
                bmwContactName
                bmwContactDepartment
                bmwContactDepartment
                bmwContactTelephone
                bmwContactTelephone
                bmwContactEmail
                currencyCodeId
                localCurrencyCodeId
                exchangeRate
                exchangeRateDate
                documentNumber
                documentDate
                orderNumber
                deliveryNumber
                deliveryDate
                documentFormatId
                processCodeId
                originalInvoiceNumber
                agreementReference
                headerText
                footerText
                grossAmount
                localCurrencyGrossAmount
                localCurrencyTaxAmount
                localCurrencyTaxableAmount
                paymentTerms
                bmwGroupPaymentRequest
                companyVatCodeId
                isTemplateDocument
                bmwCompanyCity
                bmwCompanyCountryCode
                bmwCompanyPostalCode
                bmwCompanyStreet1
                bmwCompanyStreet2
                bmwCompanyTaxId
                isOrderNumberNotRequired
            }
            shippingInformation {
                ecDocumentShippingInfoId
                shipFromCity
                shipFromContact
                shipFromCountryCode
                shipFromCountry
                shipFromDepartment
                shipFromPostalCode
                shipFromRegionCode
                shipFromRegion
                shipFromStreet
                shipToCity
                shipToContact
                shipToCountryCode
                shipToCountry
                shipToDepartment
                shipToPostalCode
                shipToRegionCode
                shipToRegion
                shipToStreet
                shipFromName
                shipToName
            }
            supplierDetails {
                documentPartnerProfileId
                bankAccountNumber
                bankBranchCode
                bankIban
                bankSwiftBicNumber
                placeOfRegistration
                managingDirector
                legalFormTypeId
                liquidationRemark
                inLiquidation
                hasFiscalRepresentative
                chairmanOfSupervisoryBoard
                companyRegistrationNumber
                companyRegistryData
                contactDepartment
                contactEmail
                contactName
                contactTelephone
                fiscalRepresentativeAddressCity
                fiscalRepresentativeAddressPostalCode
                fiscalRepresentativeAddressCountryCode
                fiscalRepresentativeAddressCountry
                fiscalRepresentativeAddressRegionCode
                fiscalRepresentativeAddressRegion
                fiscalRepresentativeAddressStreet
                fiscalRepresentativeName
                fiscalRepresentativeVatId
            }
            lineItems {
                ecDocumentDetailId
                ecDocumentId
                lineNo
                deliveryDate
                deliveryNo
                quantity
                grossAmount
                itemDescription
                netAmount
                orderNumber
                taxAmount
                taxRate
                unitPrice
            }
            agreementHistory {
                id
                agreementAcceptedDate
                companyPartnerId
            }
            documentTemplate {
                id
                templateName
            }
        }
    }
`


export const MUTATE_ECAP_DOCUMENT_RECORD = gql`
    mutation mutateDocumentRecord($documentRecord: DocumentRecordInput!) {
        mutateDocumentRecord(documentRecord: $documentRecord)
        {
            document {
                id
                documentTypeId
                documentStatusId
                companyPartnerId
                companyCode
                partnerVatCodeId
                bmwIsRetailOutlet
                bmwBusinessAreaId
                bmwBusinessUnitId
                bmwContactName
                bmwContactDepartment
                bmwContactDepartment
                bmwContactTelephone
                bmwContactTelephone
                bmwContactEmail
                currencyCodeId
                localCurrencyCodeId
                exchangeRate
                exchangeRateDate
                documentNumber
                documentDate
                orderNumber
                deliveryNumber
                deliveryDate
                documentFormatId
                processCodeId
                originalInvoiceNumber
                agreementReference
                headerText
                footerText
                grossAmount
                netAmount
                taxableAmount
                taxAmount
                localCurrencyGrossAmount
                localCurrencyTaxAmount
                localCurrencyTaxableAmount
                localCurrencyNetAmount
                paymentTerms
                bmwGroupPaymentRequest
                companyVatCodeId
                isTemplateDocument
                bmwCompanyTaxId
                bmwCompanyCity
                bmwCompanyCountryCode
                bmwCompanyPostalCode
                bmwCompanyStreet1
                bmwCompanyStreet2
                isOrderNumberNotRequired
            }
            shippingInformation {
                ecDocumentShippingInfoId
                shipFromCity
                shipFromContact
                shipFromCountryCode
                shipFromCountry
                shipFromDepartment
                shipFromPostalCode
                shipFromRegionCode
                shipFromRegion
                shipFromStreet
                shipToCity
                shipToContact
                shipToCountryCode
                shipToCountry
                shipToDepartment
                shipToPostalCode
                shipToRegionCode
                shipToRegion
                shipToStreet
                shipToName
                shipFromName
            }
            supplierDetails {
                documentPartnerProfileId
                bankAccountNumber
                bankBranchCode
                bankIban
                bankSwiftBicNumber
                placeOfRegistration
                managingDirector
                legalFormTypeId
                liquidationRemark
                inLiquidation
                hasFiscalRepresentative
                chairmanOfSupervisoryBoard
                companyRegistrationNumber
                companyRegistryData
                contactDepartment
                contactEmail
                contactName
                contactTelephone
                fiscalRepresentativeAddressCity
                fiscalRepresentativeAddressCountryCode
                fiscalRepresentativeAddressCountry
                fiscalRepresentativeAddressPostalCode
                fiscalRepresentativeAddressRegionCode
                fiscalRepresentativeAddressRegion
                fiscalRepresentativeAddressStreet
                fiscalRepresentativeName
                fiscalRepresentativeVatId
                partnerVatCode
                partnerVatCountryCode
                companyVatCode
                companyVatCountryCode
                partnerNo
                partnerName
                systemCode
                street1
                street2
                postalCode
                regionCode
                city
                countryCode
                partnerTaxId
                companyCode
                companyName
            }
            lineItems {
                ecDocumentDetailId
                ecDocumentId
                lineNo
                deliveryDate
                deliveryNo
                quantity
                grossAmount
                itemDescription
                netAmount
                orderNumber
                taxAmount
                taxRate
                unitPrice
            }
            agreementHistory {
                id
                agreementAcceptedDate
                companyPartnerId
            }
            documentTemplate {
                id
                templateName
            }
        }
    }
`;


export const UPDATE_ECAP_DOCUMENT_STATUS = gql`
    mutation updateEcapDocumentStatus($documentId: Float!, $documentStatusId: Float!) {
        updateEcapDocumentStatus(documentId: $documentId, documentStatusId: $documentStatusId)
        {
            id
                documentTypeId
                documentStatusId
                companyPartnerId
                companyCode
                partnerVatCodeId
                bmwIsRetailOutlet
                bmwBusinessAreaId
                bmwBusinessUnitId
                bmwContactName
                bmwContactDepartment
                bmwContactDepartment
                bmwContactTelephone
                bmwContactTelephone
                bmwContactEmail
                currencyCodeId
                localCurrencyCodeId
                exchangeRate
                exchangeRateDate
                documentNumber
                documentDate
                orderNumber
                deliveryNumber
                deliveryDate
                documentFormatId
                processCodeId
                originalInvoiceNumber
                agreementReference
                headerText
                footerText
                grossAmount
                localCurrencyGrossAmount
                localCurrencyTaxAmount
                localCurrencyTaxableAmount
                paymentTerms
                bmwGroupPaymentRequest
                companyVatCodeId
                isTemplateDocument
                bmwCompanyCity
                bmwCompanyCountryCode
                bmwCompanyPostalCode
                bmwCompanyStreet1
                bmwCompanyStreet2
                bmwCompanyTaxId
                isOrderNumberNotRequired
        }
    }
`;

export const DELETE_DOCUMENT_BY_BLOB_ID = gql`
    mutation DeleteBlob($blobId: Float!, $appName:String!){
        deleteBlobFile(blobId : $blobId, appName:$appName)
        {
            blobId
            status
        }
    }
`;