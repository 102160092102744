import React, { useState, useEffect, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { PortalFormItem } from '../../../../../components/FormUtils';
import { Button, Card, Col, Collapse, Form, Input, Menu, notification, Row, Select, Spin } from 'antd';
import { GridApi } from 'ag-grid-community';
import { useAppDispatch, useAppSelector } from '../../../../../main/hooks';
import { getAgGridLocalization, PersonalizeGridView } from '../../../../../pages/info-portal/components/grid/GridUtil';
import { AUDIT_DATA_SEARCH_FORM_FIELDS, AUDIT_TABLE_DETAIL_HEADERS, AUDIT_TABLE_MASTER_HEADERS, MASTER_DATA_AUDIT_BUTTONS_TOP, MASTER_DATA_AUDIT_TABLE_GRID } from '../../Common/EditFormData';
import { IAppComponentProps } from '../../../../../components';
import { ClearOutlined, DownOutlined, ReloadOutlined, SaveOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { updateAuditFilters } from '../../Redux/IStoredMasterDataSlice';
import { AuditDateFromToSelection } from '../../../../../pages/dashboard/entitlement_maintenance/DateUtils';
import { getDateFormatOrDefault } from '../../../../../pages/users/UserFormatPreferenceServices';
import { LabelLocation } from '../../../../../pages/info-portal/InfoPortalInterfaces';
import CompanyCodeDropDown from '../../../../../pages/dashboard/entitlement_maintenance/CompanyCodeDropDown';
import { DropDownSelectType } from '../../../../../pages/dashboard/entitlement_maintenance/EntitlementInterfaces';

const BusinessAreaAuditViewForm: React.FC<IAppComponentProps> = (props) => {
    const [form] = Form.useForm();
    const [searchFilter, setSearchFilter] = useState({});
    const [detailGridApi, setDetailGridApi] = useState<GridApi>(undefined);
    const [masterGridApi, setMasterGridApi] = useState<GridApi>(undefined);
    const [selectedDetailRow, setSelectedDetailRow] = useState(null);
    const [selectedMasterRow, setSelectedMasterRow] = useState(null);
    const [vertical, _] = useState<boolean>(false);
    const [entitlementGroupSelected, setGroupSelected] = useState<string[]>([]);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [entitlementAuditReport, setAuditReport] = useState([]);
    const auditReportState = useAppSelector((state) => state.masterDataReducer);
    const dispatch = useAppDispatch();

    const masterGridView = useRef<PersonalizeGridView>(
        new PersonalizeGridView(
            'master_data_ba_maintenance_audit_ag_grid_personalized_master_view',
            AUDIT_TABLE_MASTER_HEADERS(props.intl)
        )
    );

    const detailGridView = useRef<PersonalizeGridView>(
        new PersonalizeGridView(
            'master_data_ba_maintenance_audit_ag_grid_personalized_detail_view',
            AUDIT_TABLE_DETAIL_HEADERS(props.intl)
        )
    );

    const onMasterGridReady = (params) => {
        setMasterGridApi(params.api);
        if (masterGridView.current.hasPersonalizedView) {
            masterGridView.current.initPersonalizedView(params.api);
        }
        // else
        // {
        //     params.api.sizeColumnsToFit();
        // }
    };

    const onDetailGridReady = (params) => {
        setDetailGridApi(params.api);
        if (detailGridView.current.hasPersonalizedView) {
            detailGridView.current.initPersonalizedView(params.api);
        }
    };

    const handleFormSearch = (values) => {
        console.log('Form search Values:', form.getFieldsValue());
        // Handle form search logic here
        setSearchFilter({
            ...form.getFieldsValue(),
            auditTimeFrom: form.getFieldValue('auditDate')?.at(0),
            auditTimeTo: form.getFieldValue('auditDate')?.at(1),
        });
    };

    const onSave = (_: any) => {
        masterGridView?.current?.savePersonalizedView();
        detailGridView?.current?.savePersonalizedView();
        notification.info({ message: props.intl.formatMessage({ id: 'report-result-preferences-saved' }) });
    };

    const onApplyPerson = (_: any) => {
        masterGridView?.current?.applyPersonalizedView();
        detailGridView?.current?.applyPersonalizedView();
    };

    const onReset = (_: any) => {
        masterGridApi?.setFilterModel(null);
        detailGridApi?.setFilterModel(null);
        masterGridView?.current?.reset();
        detailGridView?.current?.reset();
    };

    const downIcon = () => <DownOutlined />;

    const menu = (
        <Menu>
            <Menu.Item
                key="3"
                disabled={
                    !masterGridView.current.hasPersonalizedView() && !detailGridView.current.hasPersonalizedView()
                }
            >
                <UserOutlined onClick={onApplyPerson} />
                <a data-testid={'info-portal-personal'} onClick={onApplyPerson}>
                    {props.intl.formatMessage({ id: 'info-portal-personal', defaultMessage: 'Personal' })}
                </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="4">
                <SaveOutlined onClick={onSave} />

                <a data-testid={'info-portal-save'} onClick={onSave}>
                    {props.intl.formatMessage({ id: 'info-portal-save', defaultMessage: 'Save' })}
                </a>
            </Menu.Item>
        </Menu>
    );

    //TODO If you require the search fields to persist then create REDUX reducer for it
    const onFieldChange = (e) => {
        dispatch(updateAuditFilters(form));
    };

    //TODO replace the query with your query that returns the Audit master data
    // const {
    //     data: auditReportRowData,
    //     loading: auditReportLoading,
    //     error: auditReportError,
    //     refetch: auditRefresh,
    // } = useGetAuditReportQuery({
    //     variables: {
    //         entitlementFilter: searchFilter,
    //     },
    // });

    const selectMasterRow = () => {
        console.log('Selected Master Row:');
        console.dir(masterGridApi?.getSelectedRows());
        const selectedRows = masterGridApi?.getSelectedRows();
        if (selectedRows.length > 0) {
            setSelectedMasterRow(selectedRows[0]);
        } else {
            setSelectedMasterRow(null);
        }
    };

    const selectDetailRow = () => {
        console.log('Selected Detail Row:');
        console.dir(detailGridApi?.getSelectedRows());
        const selectedRows = detailGridApi?.getSelectedRows();
        if (selectedRows.length > 0) {
            setSelectedDetailRow(selectedRows[0]);
        } else {
            setSelectedDetailRow(null);
        }
    };

    const detailGridOptions = useMemo(
        () => ({
            rowSelection: 'single',
            suppressRowClickSelection: true,
            enableRangeSelection: false,
            pagination: true,
            rowClass: 'agGridRows',
            paginationAutoPageSize: true,
            onRowSelected: selectDetailRow,
            onGridReady: onDetailGridReady,
            columnDefs: detailGridView.current.getView(),
            api: detailGridApi,
            defaultColDef: {
                sortable: true,
                flex: 1,
                resizable: true,
            },
        }),
        []
    );

    const detailCellRendererParams = useMemo(
        () => ({
            detailGridOptions,
            getDetailRowData: (params) =>
                params.successCallback(params.data.details === null ? [] : params.data.details),
        }),
        []
    );

    //TODO uncomment this after the query has be added
    // if (auditReportError) {
    //     message.error(`Error! ${auditReportError.message}`);
    //     console.log(`error: ${auditReportError.message}`);
    // }

    //TODO uncomment this after the query has be added
    React.useEffect(() => {
        // if (!auditReportLoading && auditReportRowData) {
        //     setAuditReport(auditReportRowData.getAuditReport);
        // }
        console.dir(auditReportState?.masterDataSchema);
        if (auditReportState?.masterDataSchema?.formFieldFilter) {
            form.setFieldsValue(auditReportState?.masterDataSchema?.formFieldFilter);
        }
    }, [auditReportState]);        
    // }, [auditReportLoading, auditReportRowData]);

    //TODO uncomment this after the query has be added
    const refreshData = () => {
    //     setRefreshLoading(true);
    //     auditRefresh()
    //         .then(({ data }) => {
    //             setAuditReport(data.getAuditReport);
    //             if (masterGridApi) {
    //                 masterGridApi.setRowData(data.getAuditReport);
    //             }
    //             setRefreshLoading(false);
    //         })
    //         .catch(() => {
    //             setRefreshLoading(false);
    //         });
    };

        const clearFilters = () => {
            form.resetFields();
            dispatch(updateAuditFilters(null));
        };

    return (
         //TODO uncomment this after the query has be added
        // <Spin spinning={auditReportLoading || refreshLoading}>
         //TODO remove this after the query has be added
        <Spin spinning={ refreshLoading}>
            <Card>
                <Form form={form} layout="vertical">
                    {MASTER_DATA_AUDIT_BUTTONS_TOP(props.intl, handleFormSearch, clearFilters, refreshData)}
                    {AUDIT_DATA_SEARCH_FORM_FIELDS(props,vertical,form, onFieldChange, false)}
                </Form>
                {MASTER_DATA_AUDIT_TABLE_GRID(masterGridView,masterGridApi,entitlementAuditReport,onMasterGridReady, selectMasterRow,detailCellRendererParams,props)}
            </Card>
        </Spin>
    );
};


export default BusinessAreaAuditViewForm;