import React from "react";
import { Button } from "antd";

const ButtonRenderer = (props) => {
    const handleClick = () => {
        props.onClick(props);
    };

    return (
        <Button onClick={handleClick} className={`btn btn-${props.type}`}>
            {props.label}
        </Button>
    );
};

export const ButtonRendererComponents = {
    buttonRenderer: ButtonRenderer,
};

export const GetYellowRowStyle = (params) => {
    if (params.data.updateAction) {
        return { backgroundColor: '#ffeb3b' }; // Yellow background color
    }
    return null;
};

export const convertRawDataToObject = (tableName, rawData) => {
    const [rawRowData]  = rawData.getTableData.filter((tableData) => tableData.tableName === tableName);
    // console.dir(rawRowData);
    const newData =  [];
    //take each row from rawRowData.rows and convert into react array object
    rawRowData.rows.forEach((record) => {
        const newRecord = {};
        record.row.columns.forEach((column) => {
            //make sure column names conforms to java variable conventions
            const camelCaseColumnName = column.columnName
                .replace(/_([a-z])/g, (g) => g[1].toUpperCase())
                .replace(/_/g, '')
                .replace(/^[A-Z]/, (g) => g.toLowerCase());
            newRecord[camelCaseColumnName] = column.columnValue;
        });
        newData.push(newRecord);
    });
    // console.log("new data");
    // console.dir(newData);
    return newData;
}
