import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTableMetaDataQueryVariables = Types.Exact<{
  tableNames: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type GetTableMetaDataQuery = { __typename?: 'Query', getTableMetaData: Array<{ __typename?: 'TableMetaData', tableName: string, columnNames: Array<{ __typename?: 'TableColumnMetaData', rowId: number, columnName: string, dataType: string, IS_NULLABLE: boolean, columnDefault?: string | null, ordinalPosition: number, characterMaximumLength?: number | null, characterOctetLength?: number | null, numericPrecision?: number | null, numericPrecisionRadix?: number | null, numericScale?: number | null, datetimePrecision?: number | null }> }> };

export type GetTableDataQueryVariables = Types.Exact<{
  tableNames: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type GetTableDataQuery = { __typename?: 'Query', getTableData: Array<{ __typename?: 'TableDataResponse', tableName: string, rows: Array<{ __typename?: 'TableRow', row: { __typename?: 'TableRowColumns', columns: Array<{ __typename?: 'TableRowColumn', columnName: string, columnValue?: string | null }> } }> }> };


export const GetTableMetaDataDocument = gql`
    query GetTableMetaData($tableNames: [String!]!) {
  getTableMetaData(tableNames: $tableNames) {
    tableName
    columnNames {
      rowId
      columnName
      dataType
      IS_NULLABLE
      columnDefault
      ordinalPosition
      characterMaximumLength
      characterOctetLength
      numericPrecision
      numericPrecisionRadix
      numericScale
      datetimePrecision
    }
  }
}
    `;

/**
 * __useGetTableMetaDataQuery__
 *
 * To run a query within a React component, call `useGetTableMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableMetaDataQuery({
 *   variables: {
 *      tableNames: // value for 'tableNames'
 *   },
 * });
 */
export function useGetTableMetaDataQuery(baseOptions: Apollo.QueryHookOptions<GetTableMetaDataQuery, GetTableMetaDataQueryVariables> & ({ variables: GetTableMetaDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTableMetaDataQuery, GetTableMetaDataQueryVariables>(GetTableMetaDataDocument, options);
      }
export function useGetTableMetaDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTableMetaDataQuery, GetTableMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTableMetaDataQuery, GetTableMetaDataQueryVariables>(GetTableMetaDataDocument, options);
        }
export function useGetTableMetaDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTableMetaDataQuery, GetTableMetaDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTableMetaDataQuery, GetTableMetaDataQueryVariables>(GetTableMetaDataDocument, options);
        }
export type GetTableMetaDataQueryHookResult = ReturnType<typeof useGetTableMetaDataQuery>;
export type GetTableMetaDataLazyQueryHookResult = ReturnType<typeof useGetTableMetaDataLazyQuery>;
export type GetTableMetaDataSuspenseQueryHookResult = ReturnType<typeof useGetTableMetaDataSuspenseQuery>;
export type GetTableMetaDataQueryResult = Apollo.QueryResult<GetTableMetaDataQuery, GetTableMetaDataQueryVariables>;
export const GetTableDataDocument = gql`
    query GetTableData($tableNames: [String!]!) {
  getTableData(tableNames: $tableNames) {
    tableName
    rows {
      row {
        columns {
          columnName
          columnValue
        }
      }
    }
  }
}
    `;

/**
 * __useGetTableDataQuery__
 *
 * To run a query within a React component, call `useGetTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableDataQuery({
 *   variables: {
 *      tableNames: // value for 'tableNames'
 *   },
 * });
 */
export function useGetTableDataQuery(baseOptions: Apollo.QueryHookOptions<GetTableDataQuery, GetTableDataQueryVariables> & ({ variables: GetTableDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTableDataQuery, GetTableDataQueryVariables>(GetTableDataDocument, options);
      }
export function useGetTableDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTableDataQuery, GetTableDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTableDataQuery, GetTableDataQueryVariables>(GetTableDataDocument, options);
        }
export function useGetTableDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTableDataQuery, GetTableDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTableDataQuery, GetTableDataQueryVariables>(GetTableDataDocument, options);
        }
export type GetTableDataQueryHookResult = ReturnType<typeof useGetTableDataQuery>;
export type GetTableDataLazyQueryHookResult = ReturnType<typeof useGetTableDataLazyQuery>;
export type GetTableDataSuspenseQueryHookResult = ReturnType<typeof useGetTableDataSuspenseQuery>;
export type GetTableDataQueryResult = Apollo.QueryResult<GetTableDataQuery, GetTableDataQueryVariables>;