import React, { useEffect, useState } from 'react';
import {
    DropDownSelectionMode,
    ExternalStateUpdateCallback,
    InfoPortalAgGridColumnDef,
    InfoPortalColumnDef,
    InfoPortalGridService,
    InfoPortalReport,
    InfoPortalSearchProperties,
    IValidator,
    LabelLocation,
    OnChangeListener,
    ReportViewConfig,
    stringToDayjs_yyyy_MM_dd,
    utcDateToFormatTo_yyyy_MM_dd_string,
    Validator,
} from '../../../InfoPortalInterfaces';
import { BMWInvoiceNumber, PartnerInvoiceNumber, PurchaseOrderNumber } from '../../InputWithOperator';
import { Card, FormInstance } from 'antd';
import {
    CompanySelection,
    descriptionFilter,
    InvoiceStatusSelection,
    PartnerSelection,
} from '../../InfoPortalDropdowns';
import styles from '../../styling/infoportal.module.css';
import { DocumentDateFromToSelection } from '../../FromToDateSelection';
import { RuleSet } from '../../../../e-cap/util/RuleResultCache';
import { IntlShape } from 'react-intl/src/types';
import { retrieveNR3Report } from '../../../services/ReportSearch';
import { PortalUser } from '../../../../../components';
import {
    convertToUserSpecifiedNumericFormat,
    dateComparatorUsingUserPreferences,
    getDateFormatOrDefault,
    numericComparatorUsingUserPreferences,
} from '../../../../users/UserFormatPreferenceServices';
import {
    filterParamAmountFormattedAsStringUsingPreferences,
    filterParamWithUserDateFormatComparator
} from "../../grid/GridUtil";
import Keybinding from '../../../../../components/Keybinding';

function validatator(form: FormInstance<any>): Validator {
    const validator: IValidator = {
        fetchRule(fieldName: string): RuleSet {
            const result = {
                required: false,
                picture: '',
                readOnly: false,
                hidden: false,
                formatMessage: null,
                infoMessage: null,
            };
            if (fieldName === 'company' || fieldName === 'partner' || fieldName === 'documentDate') {
                result.required = true;
            }
            return result;
        },
    };
    return new Validator(validator);
}

function SelfBilledInvoiceSimpleSearch({
                                           currentUser,
                                           intl,
                                           reportEntitlement,
                                           distinctEntitlements,
                                           initialization,
                                           form,
                                           initialValuesCache,
                                           rerenderDependentFields
                                       }: InfoPortalSearchProperties) {
    const [vertical, setVertical] = useState<boolean>(false);

    const validator = validatator(form);
    const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

    const companySelectionChange: OnChangeListener<string[]> = {
        performAction(companyIds: string[]) {
            const newVal = form.getFieldValue("partner") || [];
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    };

    useEffect(() => {
        if (rerenderDependentFields) {
            const companyIds = form.getFieldValue("company");
            const newVal = form.getFieldValue("partner");
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    });

    return <>
        <Card size={"small"} style={{backgroundColor: "#f1f3f5"}}>
            <div className={styles.selfBilledSimpleSearchContainer}>
                <div className={styles.singleColumnGrid}>
                    <CompanySelection
                        selectionMode={DropDownSelectionMode.MULTIPLE}
                        currentUser={currentUser}
                        initialValue={initialValuesCache?.getValue("company")}
                        intl={intl}
                        validator={validator}
                        displayColon={!vertical}
                        distinctEntitlements={distinctEntitlements}
                        initialization={initialization} form={form}
                        filterOptions={descriptionFilter}
                        onSelectionChange={companySelectionChange}
                        companyEntitlement={reportEntitlement}/>
                    <PartnerSelection
                        selectionMode={DropDownSelectionMode.MULTIPLE}
                        currentUser={currentUser} intl={intl}
                        validator={validator}
                        labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                        displayColon={!vertical}
                        initialValue={initialValuesCache?.getValue("partner")}
                        distinctEntitlements={distinctEntitlements}
                        initialization={initialization} form={form}
                        filterOptions={descriptionFilter}
                        companyIds={initialValuesCache?.getValue("company")}
                        externalStateUpdater={partnerSelectionUpdate}
                        partnerEntitlement={reportEntitlement}/>
                    <PartnerInvoiceNumber
                        currentUser={currentUser}
                        validator={validator}
                        labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                        displayColon={!vertical}
                        initialInputValue={initialValuesCache?.getValue("partnerInvoiceNumber")}
                        initialSelectedOperator={initialValuesCache?.getValueOrDefault("partnerInvoiceOperator", "CP")}
                        intl={intl}
                        distinctEntitlements={distinctEntitlements}
                        initialization={initialization}
                        form={form}/>
                    <DocumentDateFromToSelection
                        validator={validator}
                        labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                        displayColon={!vertical}
                        dateFormatter={getDateFormatOrDefault(currentUser)}
                        initialValue={initialValuesCache?.getValue("documentDate", stringToDayjs_yyyy_MM_dd)}
                        currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                        initialization={initialization} form={form}/>
                </div>

                <div className={styles.singleColumnGrid}>
                    <InvoiceStatusSelection
                        currentUser={currentUser}
                        intl={intl}
                        validator={validator}
                        displayColon={!vertical}
                        customDescription={(item)=>`${item.id} - ${item.description}`}
                        sortByDescription={true}
                        initialValue={initialValuesCache?.getValue("invoiceStatus")}
                        distinctEntitlements={distinctEntitlements}
                        initialization={initialization}
                        form={form} reportName={"nr3"}/>
                    <BMWInvoiceNumber
                        validator={validator}
                        labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                        displayColon={!vertical}
                        initialInputValue={initialValuesCache?.getValue("bmwInvoiceNumber")}
                        initialSelectedOperator={initialValuesCache?.getValueOrDefault("bmwInvoiceNumberOperator", "CP")}
                        currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                        initialization={initialization} form={form}/>
                    <PurchaseOrderNumber
                        validator={validator}
                        labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                        displayColon={!vertical}
                        currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                        initialInputValue={initialValuesCache?.getValue("purchaseOrderNumber")}
                        initialSelectedOperator={initialValuesCache?.getValueOrDefault("purchaseOrderNumberOperator", "CP")}
                        initialization={initialization} form={form}/>
                </div>
            </div>
            <Keybinding target={this}
                        onKey={ (e) => { if(e.key === 'Enter'){form.submit()} }} type='keyup'/>
        </Card>
    </>;
}

export class NR3AgGridColumnDef implements InfoPortalAgGridColumnDef {
    getDefaultColumnDefinitions(intl: IntlShape, user:PortalUser,distinctEntitlements: string[]): InfoPortalColumnDef[] {
        return [
            {field: "compcode", headerName: intl.formatMessage({"id": "grid-heading-company"})},
            {field: "partnerno", headerName: intl.formatMessage({"id": "grid-heading-partnerNumber"})},
            {field: "partnerinvno", headerName: intl.formatMessage({"id": "grid-heading-partnerInvoiceNumber"})},
            {field: "bmwdocno", headerName: intl.formatMessage({"id": "grid-heading-bmwInvoiceNumber"})},
            {
                field: "docdate",
                headerName: intl.formatMessage({"id": "grid-heading-documentDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.docdate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {
                field: "gross",
                headerName: intl.formatMessage({"id": "grid-heading-invoiceAmount"}),
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.gross),
                filter: "agTextColumnFilter",
                filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {field: "doccurrency", headerName: intl.formatMessage({"id": "grid-heading-currency"})},
            {field: "invstat", headerName: intl.formatMessage({"id": "grid-heading-invoiceStatus"})},
            {
                field: "duedate",
                headerName: intl.formatMessage({"id": "grid-heading-dueDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.duedate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {field: "contactap", headerName: intl.formatMessage({"id": "grid-heading-contactAccountsPayable"})},
            {
                field: "totpaymamnt",
                headerName: intl.formatMessage({"id": "info-portal-payment-amount"}),
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.totpaymamnt),
                filter: "agTextColumnFilter",
                filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {field: "paymentnumber", headerName: intl.formatMessage({"id": "info-portal-payment-advice-number"})},
            {
                field: "paymentdate",
                headerName: intl.formatMessage({"id": "grid-heading-paymentDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.paymentdate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
        ];
    }

    getShortViewColumns(): string[] {
        return [];
    }

    getLineItemColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
        return [
            {field: "purchaseorderitem", headerName: intl.formatMessage({"id": "grid-heading-purchaseOrderItem"})},
            {field: "purchaseorderno", headerName: intl.formatMessage({"id": "grid-heading-purchaseOrderNumber"})},
            {
                field: "netamount",
                headerName: intl.formatMessage({"id": "grid-heading-netAmount"}),
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.netamount),
                filter: "agTextColumnFilter",
                filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {field: "currency", headerName: intl.formatMessage({"id": "grid-heading-currency"})},
        ];
    }
}

export class NR3Report implements InfoPortalReport {
    private _config = new NR3AgGridColumnDef();

    getColumnsDefinition(): InfoPortalAgGridColumnDef {
        return this._config;
    }

    performSearch(form: FormInstance, currentUser: any, distinctEntitlements: string[]): Promise<InfoPortalGridService> {
        return retrieveNR3Report(form).then(result =>
            new InfoPortalGridService(
                new ReportViewConfig("NR3", this._config), result, distinctEntitlements));
    }

    hasExpertSearch(): boolean {
        return false;
    }

    renderExpertSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element {
        return null;
    }

    renderSimpleSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element {
        return <SelfBilledInvoiceSimpleSearch initialization={initialization}
                                              currentUser={currentUser}
                                              intl={intl}
                                              form={form}
                                              distinctEntitlements={distinctEntitlements}
                                              rerenderDependentFields={rerenderDependentFields}
                                              initialValuesCache={initialValuesCache}
                                              reportEntitlement={this.getEntitlement()}/>;
    }

    getEntitlement(): string {
        return "display_nr@reports";
    }

    getReportName(): string {
        return "NR3";
    }
}