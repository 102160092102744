import React from 'react';
import { ViewAuditTableInputProps } from './types';


const ViewAuditTrailTableComponent: React.FC<ViewAuditTableInputProps> = (props) => {
    return (
        <div>
            <h3>{`Edit Master Data for ${props.tableName}`}</h3>
            {props.auditForm}
        </div>
    );
};

export default ViewAuditTrailTableComponent;