import React from 'react';
import { EditTableInputProps } from './types';


const EditTableComponent: React.FC<EditTableInputProps> = (props) => {

    return (
        <div>
            <h3>{`Edit Master Data for ${props.tableName}`}</h3>
            {props.editForm}
        </div>
    );
};

export default EditTableComponent;