import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {
    Alert,
    Badge, Button,
    Col,
    ConfigProvider,
    Dropdown,
    Form, Menu,
    Modal, notification,
    Row,
    Space,
    Spin,
    Tabs,
    Tooltip,
    Typography
} from 'antd';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { PageContainer } from '@ant-design/pro-layout';
import styles from '../../../../styles/scss/portal.scss';
import { useIntl } from 'react-intl';
import { useLazyQuery } from '@apollo/client';
import enUS from 'antd/lib/locale/en_US';
import { getI18n, isExternalUser } from '../../../../utils/Utils';
import { FailureDownloadDocsResp, IAppComponentProps } from '../../../../components';
import { getTemplateDocument } from './TemplateDocumentService';
import { AgGridReact } from 'ag-grid-react';
import { GridApi } from 'ag-grid-community';
import { isTemplateDocumentLoading, setTotalTemplateDocument } from '../../IStoredTemplateDocumentLoadingSlice';
import { useAppDispatch, useAppSelector } from '../../../../main/hooks';
import deDe from 'antd/lib/locale/de_DE';
import { ECAP_USER_ENTITLEMENT_BY_USERID } from '../../../../main/queries';
import { getEcapUserEntitlementResultByUserId } from '../../../../main/__generated__/getEcapUserEntitlementResultByUserId';
import { CustomRender } from '../../../../components/CustomRender';
import TemplateDocumentSearchForm from './TemplateListSearchForm';
import { MYDOCS_TABLE_HEADERS_TEMPLATES } from './templateDocumentUtils';
import { getDocumentTemplateDropdown } from '../__generated__/getDocumentTemplateDropdown';
import { MY_DOCS_TEMPLATE_DROPDOWN } from '../queries';
import {
    DownloadOutlined,
    DownOutlined,
    EyeOutlined,
    ReloadOutlined,
    SaveOutlined,
    UserOutlined
} from "@ant-design/icons";
import moduleStyles from "../../../documents/styling/document.module.css";
import {PersonalizeGridView} from "../../../info-portal/components/grid/GridUtil";

const TemplateDocumentList: FunctionComponent<IAppComponentProps> = (props) => {
    const dispatch = useAppDispatch();
    const currentUser = props.currentUser;
    const [_form] = Form.useForm();
    const [docGridApi, setDocGridApi] = useState<GridApi>(undefined);
    const [docData, setDocData] = useState(undefined);
    const [_docCount, setDocCount] = useState(0);
    const [_formDataValues, setFormDataValues] = useState(undefined);
    const [failureDocuments, _setFailureDocuments] = useState<FailureDownloadDocsResp[]>(undefined);
    const [_partnerNos, setPartnerNos] = useState<string[]>(undefined);
    const [_flag,setFlag] = useState<boolean>(false);
    

    const { TabPane } = Tabs;
    const sizes: React.CSSProperties = {
        height: '100%',
        width: '98%',
    };

    const intl = useIntl();

    const { loading, total } = useAppSelector((state) => state.templateDocumentLoading);
    const [getUserEntitlement, {}] = useLazyQuery<getEcapUserEntitlementResultByUserId>(
        ECAP_USER_ENTITLEMENT_BY_USERID,
        {
            variables: { userId: props.currentUser.uid, authentication: localStorage.getItem('id_token') },
            fetchPolicy: 'cache-first',
        }
    );

    const gridView = useRef<PersonalizeGridView>(
        new PersonalizeGridView(
            'ecap_template_ag_grid_personalized_view',
            MYDOCS_TABLE_HEADERS_TEMPLATES(
                intl,
                props.distinctEntitlements,
                props.currentUser
            )));

    const onDocGridReady = (params) => {
        setDocGridApi(params.api);
        gridView.current.initPersonalizedView(params.api);
    };

    const onExport = () => {
        const selectedRows = docGridApi.getSelectedRows();

        if (selectedRows.length === 0) {
            //console.log("Error")
            Modal.warning({
                title: getI18n('Warning-message', 'Export Document(s)', intl),
                content: getI18n(
                    'Warning-export-message',
                    'You have not selected any items to be exported. Please select an item first.',
                    intl
                ),
            });
        } else {
            docGridApi.exportDataAsExcel({
                onlySelectedAllPages: false,
                onlySelected: true,
                fileName: 'Template_Document_Export',
            });
        }
    };

    const reload = ()=>setFlag(prevState=>!prevState);

    const onSave = (_: any) => {
        gridView.current.savePersonalizedView();
        reload();
        notification.info({ message: intl.formatMessage({ id: 'report-result-preferences-saved' }) });
    };

    const onApplyPerson = (_: any) => {
        gridView.current.applyPersonalizedView();
    };

    const onReset = (_: any) => {
        docGridApi.setFilterModel(null);
        gridView.current.reset();
    };

    const downIcon = () => <DownOutlined />;

    const menu = (
        <Menu>
            <Menu.Item key="3" disabled={!gridView.current.hasPersonalizedView()}>
                <UserOutlined onClick={onApplyPerson} />
                <a data-testid={'info-portal-personal'} onClick={onApplyPerson}>
                    {intl.formatMessage({ id: 'info-portal-personal', defaultMessage: 'Personal' })}
                </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="4">
                <SaveOutlined onClick={onSave} />

                <a data-testid={'info-portal-save'} onClick={onSave}>
                    {intl.formatMessage({ id: 'info-portal-save', defaultMessage: 'Save' })}
                </a>
            </Menu.Item>
        </Menu>
    );

    const onSearch = (values) => {
        dispatch(isTemplateDocumentLoading(true));
        setFormDataValues(values);

        const templateListInput = {
            templateNameId: values.templateNameId ? parseInt(values.templateNameId) : undefined,
            bmwCompanyId: values.bmwCompanyId ? parseInt(values.bmwCompanyId) : undefined,
            documentTypeId: values.documentTypeId ? parseInt(values.documentTypeId) : undefined,
            partnerNo: values.partnerNo ? values.partnerNo : undefined,
            myTemplates: values.myTemplates,
        };
        localStorage.setItem('templateListInput', JSON.stringify(templateListInput));
        Promise.resolve(getTemplateDocument(currentUser, templateListInput, intl))
            .then((data) => {
                setDocCount(data.getTemplateDocumentList.length);
                dispatch(setTotalTemplateDocument(data?.getTemplateDocumentList.length));
                setDocData(data.getTemplateDocumentList);
                dispatch(isTemplateDocumentLoading(false));
            })
            .catch((err) => {
                dispatch(isTemplateDocumentLoading(false));
                console.error(err);
            });
    };


    useEffect(() => {
        if (isExternalUser(props.distinctEntitlements)) {
            dispatch(isTemplateDocumentLoading(true));
            const partnerNos: string[] = [];
            getUserEntitlement()
                .then((response) => {
                    return new Promise<string[]>((resolve) => {
                        const userEntitlement = response.data?.getEcapUserEntitlementResultByUserId;
                        userEntitlement.partners.map((item) => {
                            partnerNos.push(item.partnerNo);
                        });
                        setPartnerNos(partnerNos);
                        resolve(partnerNos);
                        dispatch(isTemplateDocumentLoading(false));
                    });
                })
                .catch(() => {
                    dispatch(isTemplateDocumentLoading(false));
                });
        }
    }, [props.currentUser]);

    return (
        <>
            <ConfigProvider locale={localStorage.getItem('locale') === 'en' ? enUS : deDe}>
                <PageContainer
                    content={''}
                    extraContent={''}
                    className={styles.users}
                    style={{ background: 'white' }}
                    title={false}
                >
                    <Spin spinning={loading} size={'large'}>
                        <div style={{ ...sizes, minHeight: '200px' }} className="pl15 ag-theme-balham">
                            <Col md={24} sm={12} xs={24}>
                                <Tabs defaultActiveKey={'1'} className="users-tabs">
                                    <TabPane
                                        tab={getI18n('ecap-template-document-title', 'Template Documents', intl)}
                                        key="1"
                                    >
                                        <Typography.Title level={5} className={'mb10'}>
                                            {getI18n('e-cap-capture-template-title-massage', '', intl)}
                                        </Typography.Title>
                                        <TemplateDocumentSearchForm
                                            onSearch={onSearch}
                                            currentUser={currentUser}
                                            intl={props.intl}
                                            distinctEntitlements={props.distinctEntitlements}
                                        />
                                        {failureDocuments && (
                                            <Row gutter={24}>
                                                <Col span={24} className="mb5 mt5">
                                                    {failureDocuments.map((x, i) => (
                                                        <Alert
                                                            key={i}
                                                            message={`${x.errorMessage}`}
                                                            type="error"
                                                            showIcon={true}
                                                            closable={true}
                                                        />
                                                    ))}
                                                </Col>
                                            </Row>
                                        )}

                                        <Col md={24} sm={12} xs={24}>
                                            <Tabs defaultActiveKey={'1'}>
                                                <TabPane
                                                    tab={getI18n(
                                                        'ecap-template-document-title',
                                                        'Template Documents',
                                                        intl
                                                    )}
                                                    key="1"
                                                >
                                                    <div style={{flex: 'auto', height: '500px'}}>
                                                        <div style={{marginBottom: "15px"}}>
                                                            <label>
                                                                {getI18n(
                                                                    'ecap-templates-template-count',
                                                                    'Number Of Entries',
                                                                    intl
                                                                )}
                                                            </label>
                                                            <Space>
                                                                <Badge
                                                                    className="site-badge-count-109"
                                                                    count={total}
                                                                    style={{backgroundColor: '#52c41a'}}
                                                                    title={getI18n(
                                                                        'ecap-templates-template-count',
                                                                        'Number Of Entries',
                                                                        intl
                                                                    )}
                                                                    overflowCount={999999}
                                                                />
                                                            </Space>
                                                        </div>

                                                        <div style={{marginBottom: "15px"}}>
                                                            <div style={{display: "inline-block"}}>
                                                                <Dropdown.Button
                                                                    style={{marginRight: "5px"}}
                                                                    data-testid={'view-result-customization'}
                                                                    overlay={menu} trigger={['click']}
                                                                    icon={downIcon()} size={'middle'}>
                                                                    <EyeOutlined/>
                                                                    {intl.formatMessage({
                                                                        id: 'info-portal-view',
                                                                        defaultMessage: 'View',
                                                                    })}
                                                                </Dropdown.Button>
                                                            </div>

                                                            <Tooltip
                                                                title={intl.formatMessage({id: 'reset-filter'})}>
                                                                <Button
                                                                    style={{marginRight: "5px"}}
                                                                    data-testid={'view-result-customization-reset'}
                                                                    onClick={onReset} size={'middle'}
                                                                    icon={<ReloadOutlined
                                                                        className={moduleStyles.simpleFlipRefresh}/>}>
                                                                    {intl.formatMessage({id: 'info-portal-reset'})}
                                                                </Button>
                                                            </Tooltip>

                                                            <Tooltip
                                                                title={intl.formatMessage({id: 'export-to-excel'})}>
                                                                <Button data-testid={'result-export'}
                                                                        onClick={onExport}
                                                                        icon={<DownloadOutlined/>}
                                                                        size={'middle'}>
                                                                    {intl.formatMessage({id: 'export'})}
                                                                </Button>
                                                            </Tooltip>
                                                        </div>

                                                        <AgGridReact
                                                            defaultColDef={{
                                                                enableValue: true,
                                                                sortable: true,
                                                                resizable: true,
                                                            }}
                                                            api={docGridApi}
                                                            columnDefs={MYDOCS_TABLE_HEADERS_TEMPLATES(
                                                                intl,
                                                                props.distinctEntitlements,
                                                                props.currentUser
                                                            )}
                                                            rowData={docData}
                                                            sideBar={false}
                                                            rowClass="agGridRows"
                                                            suppressMenuHide={true}
                                                            debug={false}
                                                            onGridReady={onDocGridReady}
                                                            rowSelection={'multiple'}
                                                            pagination={true}
                                                            paginationPageSize={15}
                                                            suppressRowClickSelection={true}
                                                            enableBrowserTooltips={true}
                                                            data-testid="documents-test-id"
                                                        />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </Col>
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </div>
                    </Spin>
                </PageContainer>
            </ConfigProvider>
        </>
    );
};
export default TemplateDocumentList;
