import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, from, split } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { clearStorage, generateNonce, getLastDocumentReferrer, getParameterByName, getWeEAMUrl, isValidReferrer } from "../main/OauthUtils";
import GraphQLQueryLogger from "../interceptors/RequestLogger";

const TIME_OUT_LINK = 800_000;

// Dynamically set URLs
const GRAPHQL_HTTP_URL = `${process.env.REACT_APP_GRAPHQL_PROTOCOL}://${process.env.REACT_APP_GRAPHQL_SERVER}`;
const GRAPHQL_WS_URL = `${process.env.REACT_APP_GRAPHQL_WS_URL}`;

function fetchWithTimeout(uri: RequestInfo | URL, options: RequestInit = {}): Promise<Response> {
    return new Promise<Response>((resolve, reject) => { // ✅ Explicitly return a Promise<Response>
        const timer = setTimeout(() => {
            reject(new Error('Request timed out.'));
        }, TIME_OUT_LINK);

        fetch(uri, options)
            .then((response) => {
                clearTimeout(timer);
                resolve(response);
            })
            .catch((err) => {
                clearTimeout(timer);
                reject(err);
            });
    });
}

// Create HTTP link
const httpLink = new HttpLink({ uri: GRAPHQL_HTTP_URL,
    fetch: (uri, options) => {
        return fetchWithTimeout(uri, options);
    }
});

// Create WebSocket link (only for subscriptions)
const wsLink = new WebSocketLink(
    new SubscriptionClient(GRAPHQL_WS_URL, { reconnect: true })
);

// Middleware for Authorization
const authMiddleware = new ApolloLink((operation, forward) => {
    const accessToken = localStorage.getItem("access_token");
    const idToken = localStorage.getItem("id_token");
    const username = localStorage.getItem("username");
    const userId = localStorage.getItem("userId");

    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            authorization: accessToken ? `Bearer ${accessToken}` : null,
            username,
            userId,
            token: accessToken,
            idToken,
            authentication: idToken ? `Bearer ${idToken}` : null,
        }
    }));

    new GraphQLQueryLogger(username).logGraphQL(operation);
    return forward(operation);
});



// Error handling
const authorizationErrorLink = onError(({ graphQLErrors, networkError }) => {
    let had401AuthError = false;
    let had403AuthError = false;

    if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
            console.error(`[GraphQL error]: ${message}`);
            if (message.includes("401")) had401AuthError = true;
            if (message.includes("403")) had403AuthError = true;
        });
    }

    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
    }

    if (had401AuthError) {
        const validReferrer = isValidReferrer(getLastDocumentReferrer());
        const realm = getParameterByName("realm");

        if (validReferrer || realm) {
            getWeEAMUrl().then((url) => {
                window.location.assign(`${url}&nonce=${generateNonce("xxx")}`);
            });
        } else {
            console.error("Token expired - Logging out");
            if (window.location.pathname !== "/logout") {
                clearStorage();
                sessionStorage.setItem("sessionExpired", "true");
                window.location.href = "/logout";
            }
        }
    }

    if (had403AuthError) {
        console.error("FORBIDDEN - User authenticated but not allowed access.");
    }
});

// Use split to choose between WebSocket and HTTP
const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
);

// Apollo Client instance
const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([authorizationErrorLink, authMiddleware, splitLink]),
});

export default client;
