import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { IAppComponentProps } from '../../../components';
import { SELECT_OPTION_QUERY } from '../../users/queries';
import { selectOption, selectOptionVariables } from '../../users/__generated__/selectOption';
import { PortalFormItemSelect } from '../../../components/FormUtils';
import { DropDownSelectType } from './EntitlementInterfaces';
import { Spin } from 'antd';

type AppProps = {
    selectType: DropDownSelectType;
    onSelect?: any;
    required?: boolean;
} & IAppComponentProps;

type SelectDropDown = {
    getSelectDropDown: any[];
};

const key = 'companyCode';

const CompanyCodeDropDown: React.FunctionComponent<AppProps> = (props) => {
    const [companyCodeList, setCompanyCodeList] = useState<SelectDropDown>({ getSelectDropDown: [] });

    const { data, loading, error } = useQuery<selectOption, selectOptionVariables>(SELECT_OPTION_QUERY, {
        fetchPolicy: 'cache-first',
        skip: !props.currentUser,
        variables: { select: 'COMPANY', username: props.currentUser?.username },
    });

    if (data?.getSelectDropDown && !loading && !error && companyCodeList.getSelectDropDown.length === 0) {
        //add All to drop down list
        companyCodeList.getSelectDropDown.push({ id: 'ANY', value: 'ANY', name: 'ANY', __typename: 'SelectDropdowns' });
        companyCodeList.getSelectDropDown.push(...data.getSelectDropDown);
        setCompanyCodeList(companyCodeList);
    }

    const onSelect = (value) => {
      if(props?.onSelect) props.onSelect(key, value);
    };

    return (
            <Spin spinning={loading}>
                <PortalFormItemSelect
                    type={props.selectType}
                    label={props.intl.formatMessage({ id: 'management-companycode-label', defaultMessage: 'Company Code' })}
                    name={'companyCode'}
                    placeHolder={props.intl.formatMessage({
                        id: 'management-companycode-placeholder',
                        defaultMessage: 'Company Code',
                    })}
                    selectOption={companyCodeList}
                    onSelect={onSelect}
                    required={props.required || true}
                />
            </Spin>
    );
};

export default CompanyCodeDropDown;
