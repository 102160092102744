import React, { FunctionComponent, useEffect, useState } from 'react';
import { IAppComponentProps } from '../../../../components';
import enUS from 'antd/lib/locale/en_US';
import deDe from 'antd/lib/locale/de_DE';
import {
    Badge,
    Button,
    Card,
    Checkbox,
    ConfigProvider,
    message,
    Modal,
    Radio,
    Select,
    Space,
    Spin,
    Tabs,
    Tooltip,
} from 'antd';
import { getAllSapAdminConfig, updateSapAdminConfig } from '../../services/ReportConfigurationService';
import {
    SapAdminConfig_SapSystemUpdate,
    SapAdminConfig_SystemStatus,
} from '../../graphql/__generated__/SapAdminConfig';
import styles from '../styling/infoportal.module.css';
import { getI18n } from '../../../../utils/Utils';
import Input from 'antd/lib/input/Input';
import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { IntlShape } from 'react-intl/src/types';
import { getSystemCodes } from '../../services/Selectors';
import Paragraph from 'antd/es/typography/Paragraph';
import TextArea from "antd/lib/input/TextArea";
import {v4 as uuidv4} from 'uuid';

type ConfigEditParams = {
    currentUser: any;
    distinctEntitlements: string[];
    selectedConfig: SapAdminConfig_SapSystemUpdate;
    intl: IntlShape;
};

const validateUrl = (url:string)=> {
    const regex = new RegExp(`^http(s)?(://)(.+)(:[\\d]+)?[^/]$`, 'i');
    return regex.test(url);
};

const validatePath = (path:string)=> {
  const regex = RegExp('^([/])([a-z0-9A-Z])(.)+');
  return regex.test(path);
};

const DisplayAdd = ({ currentUser, distinctEntitlements, selectedConfig, intl }: ConfigEditParams) => {
    const [systemsList, setSystemsList] = useState<string[]>([]);
    const [system, setSystem] = useState<string>(null);
    const [url, setUrl] = useState<string>(null);
    const [path, setPath] = useState<string>(null);
    const [userName, setUserName] = useState<string>(null);
    const [secretPropertyKey, setSecretPropertyKey] = useState<string>(null);
    const [description, setDescription] = useState<string>(null);
    const [addEndpointKey, setAddEndpointKey] = useState<string>(uuidv4());

    useEffect(() => {
        getSystemCodes(currentUser.id, distinctEntitlements).then((result) => {
            setSystemsList(result.map((item) => item.description));
        });
    }, []);

    const addToConfig = (_e: any) => {
        let failedValidation = false;
        if (!validateUrl(url)) {
            message.error('The url is invalid.');
            failedValidation = true;
        }
        if (!validatePath(path)) {
            message.error('The path is invalid.');
            failedValidation = true;
        }
        if (!system) {
            message.error('Please select a system');
            failedValidation = true;
        }
        if (!userName) {
            message.error('The userName is invalid.');
            failedValidation = true;
        }
        if (!secretPropertyKey) {
            message.error('The secret property is invalid.');
            failedValidation = true;
        }
        if (!description) {
            message.error('The description is invalid.');
            failedValidation = true;
        }

        if (!failedValidation) {
            const newSapSystem: SapAdminConfig_SystemStatus = {
                url,
                path,
                userName,
                secretPlaceholder: secretPropertyKey,
                systemName: system,
                active: false,
                filerResultsRule: null,
                id: null,
                placeHolderConfigured: false,
                reachable: false,
                description,
            };

            console.log(newSapSystem);

            selectedConfig.systemStatusList.push(newSapSystem);

            message.info('Remember to save to persist new sap config or changes will be lost');
            setUrl(null);
            setPath(null);
            setUserName(null);
            setSecretPropertyKey(null);
            setDescription(null);
            setSystem(null);
            setAddEndpointKey(uuidv4());
        }
    };

    return (
        <div key={addEndpointKey}>
            <div className={styles.reportConfigGrid}>
                <Paragraph>URL</Paragraph>
                <Tooltip
                    title={
                        "Please make sure that the url starts with http or https and doesn't end with a forward slash"
                    }
                >
                    <Input
                        type="text"
                        placeholder="https://example.com"
                        allowClear={true}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                </Tooltip>
            </div>
            <div className={styles.reportConfigGrid}>
                <Paragraph>Path</Paragraph>
                <Tooltip title={'Please make sure that the path starts with a forward slash'}>
                    <Input
                        type="text"
                        placeholder="/sap/systems/path"
                        allowClear={true}
                        onChange={(e) => setPath(e.target.value)}
                    />
                </Tooltip>
            </div>
            <div className={styles.reportConfigGrid}>
                <Paragraph>Username</Paragraph>
                <Tooltip title={'Username'}>
                    <Input
                        type="text"
                        placeholder="username"
                        allowClear={true}
                        onChange={(e) => setUserName(e.target.value)}
                    />
                </Tooltip>
            </div>
            <div className={styles.reportConfigGrid}>
                <Paragraph>Secret property key</Paragraph>
                <Tooltip title={'Secret property key'}>
                    <Input
                        type="text"
                        placeholder="secret property key"
                        allowClear={true}
                        onChange={(e) => setSecretPropertyKey(e.target.value)}
                    />
                </Tooltip>
            </div>
            <div className={styles.reportConfigGrid}>
                <Paragraph>System Code</Paragraph>

                <Select style={{ width: '100%' }} allowClear={true} onChange={(e) => setSystem(e)}>
                    {systemsList.map((systemCode, index) => (
                        <Select.Option key={`system-code-${index}`} value={systemCode}>
                            {systemCode}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div className={styles.reportConfigGrid}>
                <Paragraph>Description</Paragraph>
                <Tooltip
                    title={
                        "Enter the description of the endpoint"
                    }
                >
                    <TextArea
                        rows={2}
                        placeholder="Description of the new endpoint"
                        allowClear={true}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Tooltip>
            </div>

            <Button type={'primary'} style={{ width: '80px' }} onClick={(e) => addToConfig(e)}>
                {intl.formatMessage({ id: 'add' })}
            </Button>
        </div>
    );
};

const DisplayEdit = ({ currentUser, distinctEntitlements, selectedConfig, intl }: ConfigEditParams) => {
    const companyCodes =
        '07,A1,A1D,A5,A6,A7,B1,B1A,B1B,B1D,B7,B9,BA,BB,BE,BJ,BJ-1,BN,BY,C1,C5,C6,C6D,C7,C9,CN01,CS,CU,CZ,D0,D1,D2,D3,D3-1,D3-2,D3-3,D5,D9,D9-3,D9D,DD,DF,DI,DP,DPD,DQ,DR,DRD,DS,DSA,DSD,DZ,E0,E1-1,E1-2,E4,E4A,E6,E6-2,E6D,E7,E7-2,E7D,E8,E9,E9D,EH,EI,EJ,EK,EM,ET,EZ,F0,F1,F1A,F1D,F4,F4-2,F4D,F6,F6D,F7,F8,F9,F9-1,F9-3,FE,FF,FZ,G0,G8,GFCC,GJ,GX,H1,H2,H2A,H3,H5,H8,H8-1,H9,H9D,I8,L3,L8,L8-1,L8-2,L8-3,L8-4,L8-5,L8-6,L8RO,LG,LO,LY,M1,M2,M8,MC10,MD,MH,MJ,ML,MP,MU,N6,NI,NXD,O0,O7,OD,OE,OG,OM,P3,PB,PC,Q0,QE,QG,RK,S1,S3,S3A,S5,S6,S7,S9,SA,SC,SE,SFZA,SG,T1,T1D,T2,T2D,TB,U0,UC,UG,UH,UI,V1,ZZ'.split(
            ','
        );

    const [systemsList, setSystemsList] = useState<string[]>([]);
    const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
    const [logSys, setLogSys] = useState<string>('');
    const [selectedEndpoint, setSelectedEndpoint] = useState<SapAdminConfig_SystemStatus>(null);
    const [_flag, setFlag] = useState<boolean>(false);

    useEffect(() => {
        getSystemCodes(currentUser.id, distinctEntitlements).then((result) => {
            setSystemsList(result.map((item) => item.description));
        });
    }, []);

    const reload = () => setFlag((prevState) => !prevState);

    const addNew = (_e) => {
        if (!logSys || !selectedCompanies?.length) {
            message.warning(intl.formatMessage({ id: 'edp-report-filter-config-warning' }));
            return;
        }

        try {
            const allRules = selectedEndpoint.filerResultsRule ? JSON.parse(selectedEndpoint.filerResultsRule) : [];
            const newRule = {
                logSystem: logSys,
                companyCodes: selectedCompanies,
            };

            allRules.push(newRule);

            selectedEndpoint.filerResultsRule = JSON.stringify(allRules);
            console.log('Saving configuration....', allRules);
            reload();
        } catch (error) {
            console.error(error);
        }
    };

    const removeConfig = (logSys: string) => {
        try {
            const config: LogSystemCompanyCodes[] = JSON.parse(selectedEndpoint.filerResultsRule);
            const newConfig = config.filter((item) => item.logSystem !== logSys);

            if (newConfig.length) {
                selectedEndpoint.filerResultsRule = JSON.stringify(newConfig);
            } else {
                selectedEndpoint.filerResultsRule = null;
            }

            console.log('Updated rules', selectedEndpoint.filerResultsRule);
            reload();
        } catch (error) {
            console.error(`Something is wrong with the existing config ${selectedEndpoint?.filerResultsRule}`, error);
        }
    };

    const fetchCurrentFilterConfiguration = (): LogSystemCompanyCodes[] => {
        if (selectedEndpoint?.filerResultsRule) {
            try {
                return JSON.parse(selectedEndpoint.filerResultsRule);
            } catch (error) {
                console.error(
                    `Something is wrong with the existing config ${selectedEndpoint?.filerResultsRule}`,
                    error
                );
            }
        }

        return [];
    };

    const onEndpointSelectionChange = (event) => {
        selectedConfig.systemStatusList.forEach((urlEndpoint) => {
            if (`${urlEndpoint.url}/${urlEndpoint.path}` === event.target.value) {
                setSelectedEndpoint(urlEndpoint);
            }
        });
    };

    type LogSystemCompanyCodes = {
        logSystem: string;
        companyCodes: string[];
    };

    return (
        <div>
            <Radio.Group onChange={onEndpointSelectionChange}>
                {selectedConfig.systemStatusList.map((endpoint) => (
                    <Radio key={`filter-${endpoint.url}/${endpoint.path}`} value={`${endpoint.url}/${endpoint.path}`}>
                        {`${endpoint.url}/${endpoint.path} (${endpoint.systemName})`}
                    </Radio>
                ))}
            </Radio.Group>

            <div hidden={!selectedEndpoint} style={{ borderTop: '1px solid #dddddd', marginTop: '10px' }}>
                {selectedEndpoint && (
                    <div>
                        <div>
                            <h4>Current Properties</h4>

                            <Paragraph>Please be careful when modifying existing properties.</Paragraph>

                            <div className={styles.reportConfigGrid}>
                                <Paragraph>URL</Paragraph>
                                <Tooltip
                                    title={
                                        "Please make sure that the url starts with http or https and doesn't end with a forward slash"
                                    }
                                >
                                    <Input
                                        key={`${selectedEndpoint?.id}-edit-url`}
                                        type="text"
                                        placeholder="https://example.com"
                                        allowClear={true}
                                        defaultValue={selectedEndpoint.url}
                                        onChange={(e) => (selectedEndpoint.url = e.target.value)}
                                    />
                                </Tooltip>
                            </div>
                            <div className={styles.reportConfigGrid}>
                                <Paragraph>Path</Paragraph>
                                <Tooltip title={'Please make sure that the path starts with a forward slash'}>
                                    <Input
                                        key={`${selectedEndpoint?.id}-edit-path`}
                                        type="text"
                                        placeholder="/sap/systems/path"
                                        allowClear={true}
                                        defaultValue={selectedEndpoint.path}
                                        onChange={(e) => (selectedEndpoint.path = e.target.value)}
                                    />
                                </Tooltip>
                            </div>
                            <div className={styles.reportConfigGrid}>
                                <Paragraph>User</Paragraph>
                                <Tooltip title={'Username'}>
                                    <Input
                                        key={`${selectedEndpoint?.id}-edit-username`}
                                        type="text"
                                        placeholder="username"
                                        allowClear={true}
                                        defaultValue={selectedEndpoint.userName}
                                        onChange={(e) => (selectedEndpoint.userName = e.target.value)}
                                    />
                                </Tooltip>
                            </div>
                            <div className={styles.reportConfigGrid}>
                                <Paragraph>Secret property</Paragraph>
                                <Tooltip title={'Secret property key'}>
                                    <Input
                                        key={`${selectedEndpoint?.id}-edit-secretkey`}
                                        type="text"
                                        placeholder="secret property key"
                                        allowClear={true}
                                        defaultValue={selectedEndpoint.secretPlaceholder}
                                        onChange={(e) => (selectedEndpoint.secretPlaceholder = e.target.value)}
                                    />
                                </Tooltip>
                            </div>
                            <div className={styles.reportConfigGrid}>
                                <Paragraph>System Code</Paragraph>

                                <Select
                                    style={{ width: '100%' }}
                                    allowClear={true}
                                    key={`${selectedEndpoint?.id}-edit-system-name`}
                                    onChange={(e) => (selectedEndpoint.systemName = e)}
                                    defaultValue={selectedEndpoint.systemName}
                                >
                                    {systemsList.map((systemCode, index) => (
                                        <Select.Option key={`system-code-${index}`} value={systemCode}>
                                            {systemCode}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>

                            <div className={styles.reportConfigGrid}>
                                <Paragraph>Description</Paragraph>
                                <Tooltip
                                    title={
                                        "Enter the description of the endpoint"
                                    }
                                >
                                    <TextArea
                                        key={`${selectedEndpoint?.id}-edit-text-description`}
                                        rows={2}
                                        defaultValue={selectedEndpoint.description}
                                        placeholder="Description of the new endpoint"
                                        allowClear={true}
                                        onChange={(e) => selectedEndpoint.description = e.target.value}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div hidden={!selectedEndpoint?.filerResultsRule} key={`${selectedEndpoint?.id}-endpoint-filters`}>
                            <Paragraph>Current row filters:</Paragraph>

                            {fetchCurrentFilterConfiguration().map((filterRule) => (
                                <div key={`badge-${filterRule.logSystem} (${filterRule.companyCodes.join(',')})`}>
                                    <Badge
                                        color={'geekblue'}
                                        text={`${filterRule.logSystem} (${filterRule.companyCodes.join(',')})`}
                                    />

                                    <DeleteOutlined
                                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                                        onClick={(_) => removeConfig(filterRule.logSystem)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <h4>Add New row filter</h4>

                <div className={styles.reportConfigGrid}>
                    <Paragraph>Log System</Paragraph>
                    <Tooltip title={'The log system name, or use the wildcard * for all log systems'}>
                        <Input
                            key={`${selectedEndpoint?.id}-edit-log-system-name`}
                            placeholder={'Enter the log system name'}
                            onChange={(e) => setLogSys(e.target.value)}
                            allowClear={true}
                        />
                    </Tooltip>
                </div>

                <div className={styles.reportConfigGrid}>
                    <Paragraph>Company Codes</Paragraph>
                    <Tooltip title={'You can select multiple company codes'}>
                        <Select
                            key={`${selectedEndpoint?.id}-edit-selected-company`}
                            mode={'multiple'}
                            onChange={(e) => setSelectedCompanies(e)}
                            style={{ width: '100%', margin: '10px 0px' }}
                            allowClear={true}
                        >
                            {companyCodes.map((companyCode) => (
                                <Select.Option key={`filter-company-code-${companyCode}`} value={companyCode}>
                                    {companyCode}
                                </Select.Option>
                            ))}
                        </Select>
                    </Tooltip>
                </div>

                <div style={{ borderBottom: '1px solid #dddddd', marginBottom: '10px', paddingBottom: '10px' }}>
                    <Button type={'primary'} style={{ width: '80px' }} onClick={(e) => addNew(e)}>
                        {intl.formatMessage({ id: 'add' })}
                    </Button>
                </div>
            </div>
        </div>
    );
};

const ReportingConfiguration: FunctionComponent<IAppComponentProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedConfig, setSelectedConfig] = useState<SapAdminConfig_SapSystemUpdate>(null);
    const [sapConfigurationList, setSapConfigurationList] = useState<SapAdminConfig_SapSystemUpdate[]>();

    useEffect(() => {
        setLoading(true);

        getAllSapAdminConfig()
            .then((data: SapAdminConfig_SapSystemUpdate[]) => {
                data.forEach((item) => {
                    if (item.reportName === 'sr3') {
                        setSelectedConfig(item);
                    }
                });
                setSapConfigurationList(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const onSelectionChecked = (val, system: SapAdminConfig_SystemStatus) => {
        selectedConfig.systemStatusList.forEach((item) => {
            if (item.url === system.url && item.path === system.path) {
                item.active = val.target.checked;
            }
        });
    };

    const selectReport = (event) => {
        sapConfigurationList.forEach((item) => {
            if (item.reportName === event.target.value) {
                setSelectedConfig(item);
            }
        });
    };

    const submitData = () => {
        console.log(selectedConfig);

        let failedValidation = false;

        for (let i = 0;i<selectedConfig.systemStatusList.length;i++) {
            const ithConfig = selectedConfig.systemStatusList[i];

            if (!validateUrl(ithConfig.url)) {
                message.error(`The url at index ${i} is not valid`);
                failedValidation = true;
            }
            if (!validatePath(ithConfig.path)) {
                message.error(`The path at index ${i} is not valid`);
                failedValidation = true;
            }
            if (!ithConfig.systemName) {
                message.error(`Please select system at index ${i}`);
                failedValidation = true;
            }
            if (!ithConfig.userName) {
                message.error(`The username for index ${i} is invalid`);
                failedValidation = true;
            }
            if (!ithConfig.secretPlaceholder) {
                message.error(`The secretPlaceholder for index ${i} is invalid`);
                failedValidation = true;
            }

            if (failedValidation) {
                return;
            }
        }

        setLoading(true);
        updateSapAdminConfig(selectedConfig)
            .then((_) => getAllSapAdminConfig())
            .then((data: SapAdminConfig_SapSystemUpdate[]) => {
                data.forEach((item) => {
                    if (item.reportName === selectedConfig.reportName) {
                        setSelectedConfig(item);
                    }
                });
                setSapConfigurationList(data);
                Modal.success({
                    title: getI18n('Download Success', 'Success', props.intl),
                    content: getI18n('Download Success', 'Success', props.intl),
                    okText: 'OK',
                });
            })
            .catch((error) => {
                console.error(error);
                Modal.warning({
                    title: getI18n('error-occurred-while-performing-save', 'Error occurred while saving', props.intl),
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <ConfigProvider locale={localStorage.getItem('locale') === 'en' ? enUS : deDe}>
            <Spin size={'large'} spinning={loading}>
                <Card size={'small'} style={{ backgroundColor: '#f1f3f5' }}>
                    <h3>{props.intl.formatMessage({ id: 'report-admin-menu-label' })}</h3>
                    <div className={styles.selfBilledSimpleSearchContainer} style={{ marginLeft: '10px' }}>
                        <div hidden={!sapConfigurationList?.length}>
                            <h4>{props.intl.formatMessage({ id: 'cor-report-select' })}</h4>
                            <Radio.Group onChange={selectReport} defaultValue={'sr3'}>
                                <Space direction={'vertical'}>
                                    {sapConfigurationList?.map((config) => (
                                        <Radio
                                            data-testid={`${config.reportName}-config`}
                                            className="info-radio"
                                            key={`${config.reportName}`}
                                            value={config.reportName}
                                        >
                                            {`${config.reportName}-${config.description}`}
                                        </Radio>
                                    ))}
                                </Space>
                            </Radio.Group>
                        </div>

                        <div hidden={!selectedConfig}>
                            <Tabs>
                                <Tabs.TabPane
                                    tab={props.intl.formatMessage({ id: 'enable-disable' })}
                                    key={'enable-disable'}
                                >
                                    {selectedConfig?.systemStatusList.map((system, idx) => (
                                        <div key={`url-${idx}-for-${selectedConfig.reportName}`}>
                                            <Tooltip title={`(${system.userName}, ${system.secretPlaceholder})`}>
                                                <Checkbox
                                                    key={`checkbox-${idx}-for-${selectedConfig.reportName}`}
                                                    defaultChecked={system.active}
                                                    onChange={(e) => onSelectionChecked(e, system)}
                                                >
                                                    {`${system.url}${system.path} (${system.systemName})`}
                                                </Checkbox>

                                                <div style={{ marginLeft: '5px', display: 'inline-block' }}>
                                                    <Tooltip title={`Could reach host: ${system.reachable}. Property ${system.secretPlaceholder} was found ${system.placeHolderConfigured}`}>
                                                        {system.reachable && system.placeHolderConfigured ? <CheckOutlined style={{cursor:'pointer'}} /> : <CloseOutlined style={{cursor:'pointer'}}/>}
                                                    </Tooltip>
                                                </div>

                                            </Tooltip>
                                        </div>
                                    ))}
                                </Tabs.TabPane>
                                <Tabs.TabPane
                                    tab={props.intl.formatMessage({ id: 'draft-document-row-options-edit' })}
                                    key="edit-config"
                                >
                                    <DisplayEdit
                                        key={`${selectedConfig?.reportName}-edit-config`}
                                        currentUser={props.currentUser}
                                        selectedConfig={selectedConfig}
                                        intl={props.intl}
                                        distinctEntitlements={props.distinctEntitlements}
                                    />
                                </Tabs.TabPane>

                                <Tabs.TabPane tab={props.intl.formatMessage({ id: 'add' })} key="add-config">
                                    <DisplayAdd
                                        key={`${selectedConfig?.reportName}-add-config`}
                                        currentUser={props.currentUser}
                                        selectedConfig={selectedConfig}
                                        intl={props.intl}
                                        distinctEntitlements={props.distinctEntitlements}
                                    />
                                </Tabs.TabPane>
                            </Tabs>

                            <Button
                                type={'primary'}
                                onClick={submitData}
                                style={{ marginTop: '20px', width: '80px' }}
                                hidden={!selectedConfig}
                            >
                                {props.intl.formatMessage({ id: 'survey-main-general-save-button' })}
                            </Button>
                        </div>
                    </div>
                </Card>
            </Spin>
        </ConfigProvider>
    );
};

export default ReportingConfiguration;