import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDefaultProcessCodesQueryVariables = Types.Exact<{
  partnerType: Types.Scalars['String']['input'];
  companyCode: Types.Scalars['String']['input'];
}>;


export type GetDefaultProcessCodesQuery = { __typename?: 'Query', getDefaultProcessCodes?: Array<{ __typename?: 'DocumentProcessCode', processCodeId?: string | null, processCode?: string | null, processCodeName?: string | null, format?: string | null, channel?: string | null, updatedFormat?: string | null, updatedChannel?: string | null, defaultAlternative?: string | null, direction?: string | null, formatOptions?: Array<string> | null }> | null };

export type GetProcessCodeNameQueryVariables = Types.Exact<{
  processCodes: Array<Types.SurveyDocumentProcessChannelCodeInput> | Types.SurveyDocumentProcessChannelCodeInput;
}>;


export type GetProcessCodeNameQuery = { __typename?: 'Query', getProcessCodeName?: Array<{ __typename?: 'SurveyDocumentProcessChannelCodeResponse', processCodeId?: number | null, processCode?: string | null, processCodeName?: string | null, phraseId?: string | null, format?: string | null, channel?: string | null, updatedFormat?: string | null, updatedChannel?: string | null, defaultAlternative?: string | null, direction?: string | null }> | null };

export type GetDefaultChannelFormatQueryVariables = Types.Exact<{
  partnerType: Types.Scalars['String']['input'];
  companyCode: Types.Scalars['String']['input'];
  processCode: Types.Scalars['String']['input'];
  direction: Types.Scalars['String']['input'];
}>;


export type GetDefaultChannelFormatQuery = { __typename?: 'Query', getDefaultChannelFormat?: { __typename?: 'DocumentFormatChannelData', partnerType?: string | null, companyCode?: string | null, processCode?: string | null, direction?: string | null, format?: Array<{ __typename?: 'DocumentFormat', formatName?: string | null, channel?: Array<{ __typename?: 'ChannelFormat', channelName?: string | null, defaultAlternative?: string | null, provider?: string | null }> | null }> | null } | null };

export type InsertSurveyChannelsMutationVariables = Types.Exact<{
  includedSurveyIds: Types.Scalars['String']['input'];
  partnerType: Types.Scalars['String']['input'];
  surveyChannels: Array<Types.SurveyChannelInput> | Types.SurveyChannelInput;
}>;


export type InsertSurveyChannelsMutation = { __typename?: 'Mutation', insertSurveyChannels?: Array<{ __typename?: 'SurveyChannelResponse', surveyId?: number | null, surveyChannelId?: number | null, processCodeId?: number | null, documentType?: string | null, format?: string | null, channel?: string | null, defaultFormat?: string | null, defaultChannel?: string | null, channelTypeId?: number | null, formatId?: number | null, documentTypeId?: number | null, channelDefault?: boolean | null, acceptedDate?: string | null, processCodeName?: string | null, processCode?: string | null, provider?: string | null }> | null };


export const GetDefaultProcessCodesDocument = gql`
    query getDefaultProcessCodes($partnerType: String!, $companyCode: String!) {
  getDefaultProcessCodes(partnerType: $partnerType, companyCode: $companyCode) {
    processCodeId
    processCode
    processCodeName
    format
    channel
    updatedFormat
    updatedChannel
    defaultAlternative
    direction
    formatOptions
  }
}
    `;

/**
 * __useGetDefaultProcessCodesQuery__
 *
 * To run a query within a React component, call `useGetDefaultProcessCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultProcessCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultProcessCodesQuery({
 *   variables: {
 *      partnerType: // value for 'partnerType'
 *      companyCode: // value for 'companyCode'
 *   },
 * });
 */
export function useGetDefaultProcessCodesQuery(baseOptions: Apollo.QueryHookOptions<GetDefaultProcessCodesQuery, GetDefaultProcessCodesQueryVariables> & ({ variables: GetDefaultProcessCodesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultProcessCodesQuery, GetDefaultProcessCodesQueryVariables>(GetDefaultProcessCodesDocument, options);
      }
export function useGetDefaultProcessCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultProcessCodesQuery, GetDefaultProcessCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultProcessCodesQuery, GetDefaultProcessCodesQueryVariables>(GetDefaultProcessCodesDocument, options);
        }
export function useGetDefaultProcessCodesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDefaultProcessCodesQuery, GetDefaultProcessCodesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDefaultProcessCodesQuery, GetDefaultProcessCodesQueryVariables>(GetDefaultProcessCodesDocument, options);
        }
export type GetDefaultProcessCodesQueryHookResult = ReturnType<typeof useGetDefaultProcessCodesQuery>;
export type GetDefaultProcessCodesLazyQueryHookResult = ReturnType<typeof useGetDefaultProcessCodesLazyQuery>;
export type GetDefaultProcessCodesSuspenseQueryHookResult = ReturnType<typeof useGetDefaultProcessCodesSuspenseQuery>;
export type GetDefaultProcessCodesQueryResult = Apollo.QueryResult<GetDefaultProcessCodesQuery, GetDefaultProcessCodesQueryVariables>;
export const GetProcessCodeNameDocument = gql`
    query getProcessCodeName($processCodes: [SurveyDocumentProcessChannelCodeInput!]!) {
  getProcessCodeName(processCodes: $processCodes) {
    processCodeId
    processCode
    processCodeName
    phraseId
    format
    channel
    updatedFormat
    updatedChannel
    defaultAlternative
    direction
  }
}
    `;

/**
 * __useGetProcessCodeNameQuery__
 *
 * To run a query within a React component, call `useGetProcessCodeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProcessCodeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProcessCodeNameQuery({
 *   variables: {
 *      processCodes: // value for 'processCodes'
 *   },
 * });
 */
export function useGetProcessCodeNameQuery(baseOptions: Apollo.QueryHookOptions<GetProcessCodeNameQuery, GetProcessCodeNameQueryVariables> & ({ variables: GetProcessCodeNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProcessCodeNameQuery, GetProcessCodeNameQueryVariables>(GetProcessCodeNameDocument, options);
      }
export function useGetProcessCodeNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProcessCodeNameQuery, GetProcessCodeNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProcessCodeNameQuery, GetProcessCodeNameQueryVariables>(GetProcessCodeNameDocument, options);
        }
export function useGetProcessCodeNameSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProcessCodeNameQuery, GetProcessCodeNameQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProcessCodeNameQuery, GetProcessCodeNameQueryVariables>(GetProcessCodeNameDocument, options);
        }
export type GetProcessCodeNameQueryHookResult = ReturnType<typeof useGetProcessCodeNameQuery>;
export type GetProcessCodeNameLazyQueryHookResult = ReturnType<typeof useGetProcessCodeNameLazyQuery>;
export type GetProcessCodeNameSuspenseQueryHookResult = ReturnType<typeof useGetProcessCodeNameSuspenseQuery>;
export type GetProcessCodeNameQueryResult = Apollo.QueryResult<GetProcessCodeNameQuery, GetProcessCodeNameQueryVariables>;
export const GetDefaultChannelFormatDocument = gql`
    query getDefaultChannelFormat($partnerType: String!, $companyCode: String!, $processCode: String!, $direction: String!) {
  getDefaultChannelFormat(
    partnerType: $partnerType
    companyCode: $companyCode
    processCode: $processCode
    direction: $direction
  ) {
    partnerType
    companyCode
    processCode
    direction
    format {
      formatName
      channel {
        channelName
        defaultAlternative
        provider
      }
    }
  }
}
    `;

/**
 * __useGetDefaultChannelFormatQuery__
 *
 * To run a query within a React component, call `useGetDefaultChannelFormatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultChannelFormatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultChannelFormatQuery({
 *   variables: {
 *      partnerType: // value for 'partnerType'
 *      companyCode: // value for 'companyCode'
 *      processCode: // value for 'processCode'
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useGetDefaultChannelFormatQuery(baseOptions: Apollo.QueryHookOptions<GetDefaultChannelFormatQuery, GetDefaultChannelFormatQueryVariables> & ({ variables: GetDefaultChannelFormatQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultChannelFormatQuery, GetDefaultChannelFormatQueryVariables>(GetDefaultChannelFormatDocument, options);
      }
export function useGetDefaultChannelFormatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultChannelFormatQuery, GetDefaultChannelFormatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultChannelFormatQuery, GetDefaultChannelFormatQueryVariables>(GetDefaultChannelFormatDocument, options);
        }
export function useGetDefaultChannelFormatSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDefaultChannelFormatQuery, GetDefaultChannelFormatQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDefaultChannelFormatQuery, GetDefaultChannelFormatQueryVariables>(GetDefaultChannelFormatDocument, options);
        }
export type GetDefaultChannelFormatQueryHookResult = ReturnType<typeof useGetDefaultChannelFormatQuery>;
export type GetDefaultChannelFormatLazyQueryHookResult = ReturnType<typeof useGetDefaultChannelFormatLazyQuery>;
export type GetDefaultChannelFormatSuspenseQueryHookResult = ReturnType<typeof useGetDefaultChannelFormatSuspenseQuery>;
export type GetDefaultChannelFormatQueryResult = Apollo.QueryResult<GetDefaultChannelFormatQuery, GetDefaultChannelFormatQueryVariables>;
export const InsertSurveyChannelsDocument = gql`
    mutation insertSurveyChannels($includedSurveyIds: String!, $partnerType: String!, $surveyChannels: [SurveyChannelInput!]!) {
  insertSurveyChannels(
    includedSurveyIds: $includedSurveyIds
    partnerType: $partnerType
    surveyChannels: $surveyChannels
  ) {
    surveyId
    surveyChannelId
    processCodeId
    documentType
    format
    channel
    defaultFormat
    defaultChannel
    channelTypeId
    formatId
    documentTypeId
    channelDefault
    acceptedDate
    processCodeName
    processCode
    provider
  }
}
    `;
export type InsertSurveyChannelsMutationFn = Apollo.MutationFunction<InsertSurveyChannelsMutation, InsertSurveyChannelsMutationVariables>;

/**
 * __useInsertSurveyChannelsMutation__
 *
 * To run a mutation, you first call `useInsertSurveyChannelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSurveyChannelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSurveyChannelsMutation, { data, loading, error }] = useInsertSurveyChannelsMutation({
 *   variables: {
 *      includedSurveyIds: // value for 'includedSurveyIds'
 *      partnerType: // value for 'partnerType'
 *      surveyChannels: // value for 'surveyChannels'
 *   },
 * });
 */
export function useInsertSurveyChannelsMutation(baseOptions?: Apollo.MutationHookOptions<InsertSurveyChannelsMutation, InsertSurveyChannelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertSurveyChannelsMutation, InsertSurveyChannelsMutationVariables>(InsertSurveyChannelsDocument, options);
      }
export type InsertSurveyChannelsMutationHookResult = ReturnType<typeof useInsertSurveyChannelsMutation>;
export type InsertSurveyChannelsMutationResult = Apollo.MutationResult<InsertSurveyChannelsMutation>;
export type InsertSurveyChannelsMutationOptions = Apollo.BaseMutationOptions<InsertSurveyChannelsMutation, InsertSurveyChannelsMutationVariables>;