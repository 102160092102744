import { gql } from "@apollo/client";


export const ALL_SURVEY_OVERVIEW_FOR_LOGGED_IN_USER_QUERY = gql`
    query getSurveyList ($surveyOverviewFilter: SurveyOverviewFilter!, $pageNumber: Float!,  $pageSize: Float!){
        getSurveyList (surveyOverviewFilter : $surveyOverviewFilter
        , pageNumber : $pageNumber,  pageSize : $pageSize){
            rowNo
            assignedPartnerUser
            assignedPartnerUserId
            categoryDescription
            categoryPhraseId
            companyCode
            partnerName
            partnerNo
            surveySentDate
            insertTime
            updateTime
            surveyId
            includedSurveyIds
            includedPartnerIds
            surveyStartDate
            surveyStatus
            surveyStatusId
            surveyStatusPhraseId
            groupId
            agreementType
            agreementTypeId
            systemCode
            categoryCode
            
        }
    }
`;


export const QUERY_SURVEY_MANAGEMENT_DATA = gql`
    query getSurveyManagementList($surveyCriteriaType:SurveyCriteriaType!){
        getSurveyManagementList(surveyCriteriaType:$surveyCriteriaType) {
            partnerNo
            partnerName
            companyCode
            email
            surveyStatus
            surveyStatusPhraseId
            surveyId
            groupId
            assignedPartnerUser
            categoryDescription
            categoryPhraseId
            surveySentDate
            insertTime
            updateTime
            masterDataStatusPhraseId
            surveyVolume
            partnerVolume
            includedSurveyIds
            includedPartnerIds
        }
    }
`;


export const QUERY_SURVEY_CONNECTIVITY_AS2 = gql`
    query getSurveyConnectivityAs2($groupId: String!){
        getSurveyConnectivityAs2(groupId:$groupId) {
            as2Environment
            as2Server
            as2ServerComment
            as2ServerPort
            as2ServerPortComment
            as2Id
            as2MdnUrl
            as2MdnUrlComment
            as2IdComment
            as2CertAvailable
            as2CertAvailableComment
            as2EDIqualifier
            as2EDIqualifierComment
            as2Software
            as2SoftwareComment
            as2SoftwareVersion
            as2SoftwareVersionComment
            as2MessageFormatType
            as2MessageFormatTypeComment
            as2EncryptionAlgorithm
            as2EncryptionAlgorithmComment
            as2SigningAlgorithm
            as2SigningAlgorithmComment
            as2MessageToBeExchanged
            as2MessageToBeExchangedComment
            as2SigningCapability
            as2SigningCapabilityComment
            as2ReceiptCapability
            as2ReceiptCapabilityComment
            as2MDNSigningAlgorithm
            as2MDNSigningAlgorithmComment
        }
    }
`;

export const QUERY_SURVEY_CONTACT_BY_TYPE = gql`
    query getSurveyContactByType($groupId: String!, $contactTypeId: Float!) {
        getSurveyContactByType(groupId: $groupId, contactTypeId: $contactTypeId)
        {
            surveyContactId
            surveyId
            contactTypeId
            contactFirstName
            contactLastName
            contactNumber
            contactEmailAddres
            contactComment
            insertUser
            insertTime
            updateUser
            updateTime
            groupId
        }

    }
`

export const QUERY_SURVEY_CONNECTIVITY_SMTP_BY_SURVEY = gql`
    query getSurveyConnectivitySmtpBySurvey($groupId: String!) {
        getSurveyConnectivitySmtpBySurvey(groupId: $groupId)
        {
            surveyConnectivityId
            surveyId
            environment
            partnerInboundMessageSubject
            partnerInboundMessageTo
            partnerInboundMessageCC
            partnerInboundMessageDataFileInEmailBody
            partnerInboundAttachmentFilenameRequirements
            partnerInboundEmailBodyText
            partnerInboundMaximumEmailSizeMegaBytes
            partnerOutboundMessageFrom
            partnerOutboundMessageSubject
            partnerOutboundMessageDataFileInEmailBody
            partnerInboundMessageSubjectComment
            partnerInboundMessageToComment
            partnerInboundMessageCCComment
            partnerInboundMessageDataFileInEmailBodyComment
            partnerInboundAttachmentFilenameRequirementsComment
            partnerInboundEmailBodyTextComment
            partnerInboundMaximumEmailSizeMegaBytesComment
            partnerOutboundMessageFromComment
            partnerOutboundMessageSubjectComment
            partnerOutboundMessageDataFileInEmailBodyComment
            additionalInfo
            insertUser
            insertTime
            updateUser
            updateTime
            groupId
            dcInvoiceCompanyEmailAddress
            dcInvoiceCompanyEmailAddressComment
            dcPaymentAdviceCompanyEmailAddress
            dcPaymentAdviceCompanyEmailAddressComment
        }

    }
`

export const QUERY_SURVEY_CONNECTIVITY_OFTP2_BY_SURVEY = gql`
    query getSurveyConnectivityOftp2BySurvey($groupId: String!) {
        getSurveyConnectivityOftp2BySurvey(groupId: $groupId)
        {
            additionalInfo
            certificateAvailable
            certificateAvailableComment
            environment
            fileExchangeMode
            fileExchangeModeComment
            groupId
            insertTime
            insertUser
            partnerPullAdvancedCompression
            partnerPullAdvancedCompressionComment
            partnerPullDataFormat
            partnerPullDataFormatComment
            partnerPullEERP
            partnerPullEERPComment
            partnerPullEnryptOrSign
            partnerPullEnryptOrSignComment
            partnerPullEnryptionAlgorithm
            partnerPullEnryptionAlgorithmComment
            partnerPullFixedLength
            partnerPullFixedLengthComment
            partnerPullSFIDDSNVFN
            partnerPullSFIDDSNVFNComment
            partnerPushDataEncoding
            partnerPushDataEncodingComment
            partnerPushFileTypes
            partnerPushFileTypesComment
            partnerPushNonEDIAPRF
            partnerPushNonEDIAPRFComment
            partnerPushNonEDIOriginator
            partnerPushNonEDIOriginatorComment
            partnerPushNonEDIRecipient
            partnerPushNonEDIRecipientComment
            partnerPushVFN
            partnerPushVFNComment
            passwordAvailable
            passwordAvailableComment
            providerPullEnrypted
            providerPullEnryptedComment
            providerPullFrequency
            providerPullFrequencyComment
            providerPullSigning
            providerPullSigningComment
            providerPushAdvancedCompression
            providerPushAdvancedCompressionComment
            providerPushDataEncoding
            providerPushDataEncodingComments
            providerPushDataFormat
            providerPushDataFormatComment
            providerPushEERP
            providerPushEERPComment
            providerPushEnryptOrSign
            providerPushEnryptOrSignComment
            providerPushEnryptionAlgorithm
            providerPushEnryptionAlgorithmComment
            providerPushFixedLength
            providerPushFixedLengthComment
            providerPushSFIDDSNVFN
            providerPushSFIDDSNVFNComment
            providerPushSFIDDestination
            providerPushSFIDDestinationComment
            providerPushSigningAlgorithm
            providerPushSigningAlgorithmComment
            sSIDCode
            sSIDCodeComment
            securityPreference
            securityPreferenceComment
            serverAddress
            serverAddressComment
            serverPort
            serverPortComment
            sfidCode
            sfidCodeComment
            surveyConnectivityId
            surveyId
            updateTime
            updateUser
            useCryptographicAuthentication
            useCryptographicAuthenticationComment
            useTLS
            useTLSComment
        }

    }
`

export const SURVEY_STATUS_LIST_QUERY = gql`
    query getSurveyStatusList{
        getSurveyStatusList{
            id
            surveyStatus
            phraseId
        }
    }
`;

export const SURVEY_TESTFILE_REPORT_QUERY = gql`
    query getConnectivityTestFileReport($groupId: String!) {
        getConnectivityTestFileReport(groupId: $groupId)
        {
            rowId
            processCode
            processCodeName
            documentFormat
            currentVersionNumber
            processCodeId
            documentFormatId
            testFileId
            surveyId
            groupId
            versionNumber
            fileBlobId
            fileStatus
            fileError
            submittedForValidation
            validationReference
            validationStatus
            validationPassed
            validationResultBlobId
            surveyValidationStatusDescr
            validationStatusPhraseId
            insertTime
            subList {
                rowId
                processCode
                processCodeName
                documentFormat
                currentVersionNumber
                processCodeId
                documentFormatId
                testFileId
                surveyId
                groupId
                versionNumber
                fileBlobId
                fileStatus
                fileError
                submittedForValidation
                validationReference
                validationStatus
                validationPassed
                validationResultBlobId
                surveyValidationStatusDescr
                validationStatusPhraseId
                insertTime
            }
        }
    }
`;

export const QUERY_SURVEY_NOTIFICATIONS = gql`
    query getNotifications {
        getNotifications
        {
            notificationId
            notificationType
            menu
            audience
            templateData
            templateText
            linkText
            linkAddress
            insertTime
            insertUser
            readDate
            readFlag
            phraseId

        }
    }
`;

export const QUERY_GET_ALL_NOTIFICATIONS_DATA = gql`
    query getAllNotificationsInTable {
        getAllNotificationsInTable
        {
            notificationId
            activeFromDate
            activeToDate
            entitlements
            notificationType
            linkText
            linkAddress
        }
    }
`;

export const QUERY_SURVEY_NOTIFICATION_COUNT = gql`
    query getNotificationCount {
        getNotificationCount
        {
            notificationCount
        }
    }
`;

export const QUERY_GET_BANNER_DETAILS = gql`
    query getBannerDetailsData {
        getBannerDetailsData
        {
            bannerHeadingEn
            bannerHeadingDe
            bannerMessageEn
            bannerMessageDe
            messageType
            startTime
            endTime
        }
    }
`;

export const QUERY_GET_ALL_BANNER_DATA = gql`
    query getAllBanners {
        getAllBanners
        {
            bannerId
            bannerHeadingEn
            bannerHeadingDe
            bannerMessageEn
            bannerMessageDe
            messageType
            startTime
            endTime
            bannerShowStart
            bannerShowEnd
        }
    }
`;

export const QUERY_SURVEY_GOLIVE = gql`
    query getGoLiveDates($groupId: String!) {
        getGoLiveDates(groupId: $groupId)
        {
            groupId
            submissionDate
            provisionalGoLiveDate
            confirmedGoLiveDate
        }
    }
`;

export const QUERY_PEPPOL_DETAILS = gql`
    query getConnectivityPeppolDetailsForSurvey($groupId: String!) {
        getConnectivityPeppolDetailsForSurvey(groupId: $groupId)
        {
            environment
            groupId
            insertTime
            insertUser
            receivingPeppolId
            receivingPeppolIdComment
            sendingPeppolId
            sendingPeppolIdComment
            surveyConnectivityId
            surveyID
            updateTime
            updateUser
        }
    }
`;


export const ALL_SURVEY_CHANNEL_SURVEY_ID = gql`
    query Portal_getSurveyChannels($surveyId: Float!, $partnerType: String!, $companyCode: String!) {
        Portal_getSurveyChannels(surveyId: $surveyId, partnerType:$partnerType, companyCode: $companyCode){
            surveyId
            surveyChannelId
            processCodeId
            documentType
            format
            channel
            defaultFormat
            defaultChannel
            channelTypeId
            formatId
            documentTypeId
            channelDefault
            acceptedDate
            processCodeName
            processCode
            documentFormatPhraseId
            processCodePhraseId
            formatOptions
            provider

        }
    }
`;

export const SELECT_OPTION_ENTITLEMENT_QUERY = gql`
    query getNotificationEntitlementsDropdown{
        getNotificationEntitlementsDropdown{
            entitlement
            description
        }
    }
`;

export const SELECT_OPTION_TEMPLATE_NAME_QUERY = gql`
    query getNotificationTemplateNamesDropDown{
        getNotificationTemplateNamesDropDown{
            messageTemplateId
            templateName
        }
    }
`;

export const SELECT_OPTION_TEMPLATE_TEXT_QUERY = gql`
    query getNotificationTemplateText($messageTemplateId: String!) {
        getNotificationTemplateText(messageTemplateId: $messageTemplateId) {
            templateTextEn
            templateTextDe
        }
    }
`;
