import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {IAppComponentProps} from "../../../components";
import enUS from "antd/lib/locale/en_US";
import deDe from "antd/lib/locale/de_DE";
import {ConfigProvider, Spin, Tabs} from "antd";
import {InfoPortalTabConfig, Initialization} from "../InfoPortalInterfaces";
import {v4 as uuidv4} from 'uuid';
import {entitlementExists} from "../../../main/entitlementService";
import {InternalReportsTab, KPITab, RejectionLetterTrackingTab} from "./tabs/InternalReportsTab";
import { PageHeader } from '@ant-design/pro-layout';

const InternalReports: FunctionComponent<IAppComponentProps> = (props) => {
    const DEFAULT_REPORT =
        props.intl.formatMessage({'id': 'internal-reports-basic-title'});

    const [selectedTab, setSelectedTab] = useState<string>("item-1")
    const [heading, setHeading] = useState<string>(DEFAULT_REPORT);

    const rejectionLetterTab = useRef<InfoPortalTabConfig>(new RejectionLetterTrackingTab());
    const kpiTab = useRef<InfoPortalTabConfig>(new KPITab());

    const [initState, setState] = useState<any>({});
    const isLoading = () => Object.keys(initState).map(key => initState[key] === true).reduce((prev, curr) => prev || curr, false);

    const updateInitState =
      (property: string, state: boolean) => setState(currState => {
          const newState = { ...currState };
          newState[property] = state;
          return newState;
      });

    const stateInit: Initialization = {
        beginInitialize(): string {
            const randomProp = uuidv4();
            updateInitState(randomProp, true);
            return randomProp;
        }, finishInitialize(initializationId: string): void {
            updateInitState(initializationId,false);
        }
    };

    const onTabSelectionChange = (key: string) => {
        console.debug(`${selectedTab}->${key}`);
        setSelectedTab(key);
    };

    const reportSearchChange = (isExpert: boolean, reportName: string)=> {
        if (!isExpert) {
            setHeading(DEFAULT_REPORT);
        }
        else {
            setHeading(`${props.intl.formatMessage({id:'info-portal-expert-search'})} - ${reportName}`);
        }
    };

    type ReportTab = {
        shouldRender:boolean;
        component: JSX.Element;
    }

    const tabs:ReportTab[] = [
        {
            shouldRender: entitlementExists(rejectionLetterTab.current.getEntitlement(),props.distinctEntitlements),
            component:<Tabs.TabPane
                tab={props.intl.formatMessage({
                    'id': 'rejection-letter-tracking-tab'})} key="item-1">
                <InternalReportsTab
                    intl={props.intl}
                    distinctEntitlements={props.distinctEntitlements}
                    currentUser={props.currentUser}
                    initialization={stateInit}
                    infoPortalTab={rejectionLetterTab.current}
                    onReportAndSearchTypeChange={reportSearchChange}/>
            </Tabs.TabPane>
        },
        {
            shouldRender:true,
            component:<Tabs.TabPane tab={props.intl.formatMessage({
                'id': 'kpi-usage',
            })} key="item-2">
                <InternalReportsTab
                    intl={props.intl}
                    distinctEntitlements={props.distinctEntitlements}
                    currentUser={props.currentUser}
                    initialization={stateInit}
                    infoPortalTab={kpiTab.current}
                    onReportAndSearchTypeChange={reportSearchChange}/>
            </Tabs.TabPane>
        },
    ];

    useEffect(()=> {
        function checkIfContentEditable(node: HTMLElement | null) {
            if (node === null) {
                return false;
            }

            if (node.getAttribute('contenteditable') === 'true') {
                return true;
            }

            return checkIfContentEditable(node.parentElement);
        }

        const TARGETS_BLACKLIST = ['textarea', 'input', 'select'];

        const enterKeyEvent =   (e: KeyboardEvent) => {

            const target = e.target as HTMLElement | null;

            if (target) {
                let canDispatch = true;

                if (
                    TARGETS_BLACKLIST.indexOf(target.tagName.toLowerCase()) > -1
                ) {
                    if (target.tagName.toLowerCase() === 'input' && !target.classList.contains('ant-radio-input')) {
                        canDispatch = false;
                    }
                }

                if (checkIfContentEditable(target)) {
                    canDispatch = false;
                }

                if (canDispatch && e.key === 'Enter') {
                    const selectorString = `.ant-tabs-tabpane-active [data-testid="info-portal-search-button"]`;
                    const searchButton = document.querySelector(selectorString);
                    if (searchButton) {
                        console.debug('Button found', searchButton);
                        // @ts-ignore
                        searchButton.click();
                    }
                }
            }
        };

        console.log('Registering key up event');
        const target = document;
        target.addEventListener('keyup', enterKeyEvent);
        return () => {
            console.log('Unregistering key up event');
            target.removeEventListener('keyup', enterKeyEvent);
        };
    },[]);

    return <>
        <ConfigProvider locale={localStorage.getItem("locale") === "en" ? enUS : deDe}>
            <Spin size={"large"} spinning={isLoading()}>
                <div>
                    <PageHeader
                        className="site-page-header menu-item-capitalize"
                        title={heading}
                    />
                    <Tabs onChange={onTabSelectionChange}>
                        {
                            tabs.filter(theTab => theTab.shouldRender).map(theTab => theTab.component)
                        }
                    </Tabs>
                </div>
            </Spin>
        </ConfigProvider>
    </>
};

export default InternalReports;