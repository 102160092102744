import React, {useEffect, useState} from 'react';
import {
    DropDownSelectionMode,
    ExternalStateUpdateCallback,
    InfoPortalAgGridColumnDef,
    InfoPortalColumnDef,
    InfoPortalGridService,
    InfoPortalReport,
    InfoPortalSearchProperties,
    IValidator,
    LabelLocation,
    nonePresent,
    OnChangeListener,
    ReportViewConfig,
    stringToDayjs_yyyy_MM_dd,
    utcDateToFormatTo_yyyy_MM_dd_string,
    Validator,
} from '../../../InfoPortalInterfaces';
import {Card, FormInstance, Typography} from 'antd';
import {RuleSet} from '../../../../e-cap/util/RuleResultCache';
import {IntlShape} from 'react-intl/src/types';
import {retrieveLY1Report} from '../../../services/ReportSearch';
import styles from '../../styling/infoportal.module.css';
import {
    CompanySelection,
    descriptionFilter,
    InvoiceStatusSelection,
    PartnerSelection,
} from '../../InfoPortalDropdowns';
import {BMWInvoiceNumber, PartnerInvoiceNumber, PaymentAdviceNumber, PaymentAmount} from '../../InputWithOperator';
import {DocumentDateFromToSelection, PaymentFromToSelection} from '../../FromToDateSelection';
import {PortalUser} from '../../../../../components';
import {
    convertToUserSpecifiedNumericFormat,
    dateComparatorUsingUserPreferences,
    getDateFormatOrDefault,
    numericComparatorUsingUserPreferences,
} from '../../../../users/UserFormatPreferenceServices';
import {
    filterParamAmountFormattedAsStringUsingPreferences,
    filterParamWithUserDateFormatComparator
} from "../../grid/GridUtil";
import Keybinding from '../../../../../components/Keybinding';



function validatator(form: FormInstance<any>): Validator {
    const validator: IValidator = {
        fetchRule(fieldName: string): RuleSet {
            const result = {
                required: false,
                picture: '',
                readOnly: false,
                hidden: false,
                formatMessage: null,
                infoMessage: null,
            };
            if (fieldName === 'company') {
                result.required = true;
            }
            if (fieldName === 'partner') {
                result.required = true;
            }

            return result;
        },
    };
    return new Validator(validator);
}

function InvoicesSimpleSearch({currentUser, intl, distinctEntitlements, reportEntitlement,initialization, form, initialValuesCache,rerenderDependentFields}: InfoPortalSearchProperties) {
    const [vertical, setVertical] = useState<boolean>(false);
    const validator = validatator(form);
    const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

    const { Text } = Typography;

    const companySelectionChange: OnChangeListener<string[]> = {
        performAction(companyIds: string[]) {
            const newVal = form.getFieldValue("partner") || [];
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    };

    useEffect(() => {
        if (rerenderDependentFields) {
            const companyIds = form.getFieldValue("company");
            const newVal = form.getFieldValue("partner");
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    });

    return <Card size={"small"} style={{backgroundColor: "#f1f3f5"}}>
        <div className={styles.selfBilledSimpleSearchContainer} style={{marginLeft: "10px"}}>
            <div className={styles.singleColumnGrid}>
                <CompanySelection
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    currentUser={currentUser}
                    initialValue={initialValuesCache?.getValue("company")}
                    intl={intl}
                    validator={validator}
                    filterOptions={descriptionFilter}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}
                    onSelectionChange={companySelectionChange}
                    companyEntitlement={reportEntitlement}/>
                <PartnerSelection
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    currentUser={currentUser} intl={intl}
                    validator={validator}
                    initialValue={initialValuesCache?.getValue("partner")}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}
                    filterOptions={descriptionFilter}
                    companyIds={initialValuesCache?.getValue("company")}
                    externalStateUpdater={partnerSelectionUpdate}
                    partnerEntitlement={reportEntitlement}/>
                <PartnerInvoiceNumber
                    currentUser={currentUser}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    validator={validator}
                    initialInputValue={initialValuesCache?.getValue("partnerInvoiceNumber")}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("partnerInvoiceOperator", "CP")}
                    intl={intl}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization}
                    form={form}/>
                <InvoiceStatusSelection
                    currentUser={currentUser}
                    selectionMode={DropDownSelectionMode.SINGLE}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    intl={intl}
                    validator={validator}
                    customDescription={(item)=>`${item.id} - ${item.description}`}
                    sortByDescription={true}
                    initialValue={initialValuesCache?.getValue("invoiceStatus")}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization}
                    form={form} reportName={"ly1"}/>
                <DocumentDateFromToSelection
                    validator={validator}
                    dateFormatter={getDateFormatOrDefault(currentUser)}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    initialValue={initialValuesCache?.getValue("documentDate", stringToDayjs_yyyy_MM_dd)}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}/>
            </div>

            <div className={styles.singleColumnGrid}>

                <PaymentAmount
                    currentUser={currentUser}
                    isNumeric={true}
                    numDecimals={2}
                    validator={validator}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    initialInputValue={initialValuesCache?.getValue("paymentAmount")}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("paymentAmountOperator", "EQ")}
                    intl={intl}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization}
                    form={form} />
                <BMWInvoiceNumber
                    initialInputValue={initialValuesCache?.getValue("bmwInvoiceNumber")}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("bmwInvoiceNumberOperator", "CP")}
                    currentUser={currentUser}
                    validator={validator}
                    intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}/>

                <PaymentAdviceNumber
                    currentUser={currentUser}
                    validator={validator}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    initialInputValue={initialValuesCache?.getValue("paymentAdviceNumber")}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("paymentAdviceNumberOperator", "CP")}
                    intl={intl}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization}
                    form={form} />

                <PaymentFromToSelection
                    validator={validator}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    dateFormatter={getDateFormatOrDefault(currentUser)}
                    initialValue={initialValuesCache?.getValue("paymentDate", stringToDayjs_yyyy_MM_dd)}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />

            </div>
        </div>

        <div style={{marginLeft: "10px"}}>

            <div style={{marginTop:"30px"}}>
                <ul>
                    <li><Text>{intl.formatMessage({id:'ly1-extra-info-1'})}</Text></li>
                    <li><Text>{intl.formatMessage({id:'ly1-extra-info-2'})}</Text></li>
                    <li><Text>{intl.formatMessage({id:'ly1-extra-info-3'})}</Text></li>
                </ul>
            </div>
            <div>&nbsp;</div>
        </div>
        <Keybinding target={this}
                    onKey={ (e) => { if(e.key === 'Enter'){form.submit()} }} type='keyup'/>
    </Card>;
}

export class LY1AgGridColumnDef implements InfoPortalAgGridColumnDef {
    getLineItemColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
        return [];
    }

    getDefaultColumnDefinitions(intl: IntlShape,user:PortalUser,distinctEntitlements:string[]): InfoPortalColumnDef[] {
        return [
            {field: "compcode", headerName: intl.formatMessage({"id": "grid-heading-company"})},
            {field: "partnerno", headerName: intl.formatMessage({"id": "grid-heading-partnerNumber"})},
            {field: "extinvoice", headerName: intl.formatMessage({"id": "grid-heading-partnerInvoiceNumber"})},
            {field: "bmwdocno", headerName: intl.formatMessage({"id": "grid-heading-bmwInvoiceNumber"})},
            {
                field: "documentdate",
                headerName: intl.formatMessage({"id": "grid-heading-documentDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.documentdate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {
                field: "gross",
                headerName: intl.formatMessage({"id": "grid-heading-totalAmount"}),
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.gross),
                filter: "agTextColumnFilter",
                filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {field: "doccurrency", headerName: intl.formatMessage({"id": "grid-heading-currency"})},
            {field: "invoicestatus", headerName: intl.formatMessage({"id": "grid-heading-invoiceStatus"})},
            {
                field: "duedate",
                headerName: intl.formatMessage({"id": "grid-heading-dueDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.duedate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {field: "contactap", headerName: intl.formatMessage({"id": "grid-heading-contactAccountsPayable"})},
            {
                field: "totpaymamnt",
                headerName: intl.formatMessage({"id": "info-portal-payment-amount"}),
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.totpaymamnt),
                filter: "agTextColumnFilter",
                filterParams:filterParamAmountFormattedAsStringUsingPreferences(user, intl),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {field: "paymadviceno", headerName: intl.formatMessage({"id": "info-portal-payment-advice-number"})},
            {
                field: "paymdate",
                headerName: intl.formatMessage({"id": "grid-heading-paymentDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.paymdate,getDateFormatOrDefault(user)),
                filterParams:filterParamWithUserDateFormatComparator(user),
                filter:'agDateColumnFilter',
                comparator: dateComparatorUsingUserPreferences(user)
            },
        ]
    }

    getShortViewColumns(): string[] {
        return []
    }
}

export class LY1Report implements InfoPortalReport {
    private _config = new LY1AgGridColumnDef();

    getColumnsDefinition(): InfoPortalAgGridColumnDef {
        return this._config;
    }

    performSearch(form:FormInstance,currentUser:any, distinctEntitlements:string[]):Promise<InfoPortalGridService> {
        return retrieveLY1Report(form).then(result=>
            new InfoPortalGridService(
                new ReportViewConfig('LY1',this._config), result, distinctEntitlements));
    }

    presearch(form: FormInstance<any>, intl: IntlShape): { result: "continue" | "abort-search"; description?: string } {
        if (nonePresent(form, ["documentDate", "paymentAdviceNumber", "paymentAmount"])) {
            return {
                result: "abort-search",
                description: intl.formatMessage({ id: "ly1-presearch-please-select-either" })
            };
        }

        return { result: "continue" };
    }

    hasExpertSearch(): boolean {
        return false;
    }

    renderExpertSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache
                       }: InfoPortalSearchProperties): JSX.Element {
        return null;
    }

    renderSimpleSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element {
        return <InvoicesSimpleSearch initialization={initialization}
                                     currentUser={currentUser}
                                     intl={intl}
                                     form={form}
                                     distinctEntitlements={distinctEntitlements}
                                     rerenderDependentFields={rerenderDependentFields}
                                     initialValuesCache={initialValuesCache} reportEntitlement={this.getEntitlement()}/>;
    }

    getEntitlement(): string {
        return "display_ly@reports";
    }

    getReportName(): string {
        return "LY1";
    }
}