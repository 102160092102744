import React from 'react';
import { Tabs } from 'antd';
import EditTableComponent from '../Common/EditTableComponent';
import { EditViewTableInputProps } from '../Common/types';
import ViewAuditTrailTableComponent from '../Common/ViewAuditTrailTableComponent';

const MaintainTableTabComponent: React.FC<EditViewTableInputProps> = (props) => {


    const tabItems = [
        {
            key: '1',
            label: `Edit ${props.tableTabName}`,
            children: <EditTableComponent intl={props.intl} system={props.system} tableName={props.tableTabName} editForm={props.editForm} />,
        },
        {
            key: '2',
            label: `Audit Trail for table ${props.tableTabName}`,
            children: <ViewAuditTrailTableComponent intl={props.intl}  system={props.system} tableName={props.tableTabName} auditForm={props.auditForm}/>,
        }
    ];

    return (
        <div>
            <h1>{`Edit/View ${props.system} Master Data for ${props.tableTabName}`}</h1>
            <Tabs defaultActiveKey="1" items={tabItems} />
        </div>
    );
};

export default MaintainTableTabComponent;