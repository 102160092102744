import React, { FunctionComponent, useEffect, useState } from "react";
import { Input, Select, Button, Alert, Card, Col, Row, Tabs, Typography, message, Form } from "antd";
import { TextAreaRef } from "antd/lib/input/TextArea";
import "./../../Welcome.css";
import { IAppComponentProps } from "../../../components";
import { useIntl } from "react-intl";
import { PortalFormItem } from "../../../components/FormUtils";
import { createNotificationTemplateData } from "../../notifications/NotificationService";
import { NotificationTemplateInput } from "__generated__/globalTypes";
import { getI18n } from "../../../utils/Utils";

const { TextArea } = Input

const validateString = (englishNotification: String, germanNotification: String) => {

  const noOfOpeningCurlyBrackets1 = (englishNotification.match(/[{]/gi) || []).length;
  const noOfClosingCurlyBrackets1 = (englishNotification.match(/[}]/gi) || []).length;
  let bracketDifferenceEnglish = 0;

  const noOfOpeningCurlyBrackets2 = (germanNotification.match(/[{]/gi) || []).length;
  const noOfClosingCurlyBrackets2 = (germanNotification.match(/[}]/gi) || []).length;
  let bracketDifferenceGerman = 0;

  let bracketMessage = '';
  if (noOfOpeningCurlyBrackets1 > noOfClosingCurlyBrackets1 || noOfOpeningCurlyBrackets2 > noOfClosingCurlyBrackets2) {
    bracketDifferenceEnglish = noOfOpeningCurlyBrackets1 - noOfClosingCurlyBrackets1;
    if (noOfOpeningCurlyBrackets1 > noOfClosingCurlyBrackets1) {
      bracketMessage = `You have ${bracketDifferenceEnglish} more opening bracket(s) ({) than closing bracket(s) (}) in English Text Area`;
    }

    bracketDifferenceGerman = noOfOpeningCurlyBrackets2 - noOfClosingCurlyBrackets2;
    if (noOfOpeningCurlyBrackets2 > noOfClosingCurlyBrackets2) {
      bracketMessage = `You have ${bracketDifferenceGerman} more opening bracket(s) ({) than closing bracket(s) (}) in German Text Area.`;
    }
  } else if (noOfClosingCurlyBrackets1 > noOfOpeningCurlyBrackets1 || noOfClosingCurlyBrackets2 > noOfOpeningCurlyBrackets2) {
    bracketDifferenceEnglish = noOfClosingCurlyBrackets1 - noOfOpeningCurlyBrackets1;

    if (noOfClosingCurlyBrackets1 > noOfOpeningCurlyBrackets1) {
      bracketMessage = `You have  ${bracketDifferenceEnglish} more closing bracket(s) (}) than opening bracket(s) ({) in English Text Area`;
    }

    // for germanNotification
    bracketDifferenceGerman = noOfClosingCurlyBrackets2 - noOfOpeningCurlyBrackets2;
    if (noOfClosingCurlyBrackets2 > noOfOpeningCurlyBrackets2) {
      bracketMessage = `You have  ${bracketDifferenceGerman} more closing bracket(s) (}) than opening bracket(s) ({) in German Text Area.`;
    }
  } else {
  }
};

const NotificationsTemplate: FunctionComponent<IAppComponentProps> = (props) => {
  const [form] = Form.useForm();
  const currentUser = props.currentUser;
  const distinctEntitlements = props.distinctEntitlements;
  const { TabPane } = Tabs;
  const sizes: React.CSSProperties = {
    height: "150%",
    width: "98%"
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [selectOptions, setSelectOption] = useState("true");
  const intl = useIntl();
  const { Paragraph } = Typography; 
  const [inputTemplateName, setInputTemplateName] = React.useState('');
  const [englishNotificationInput, setEnglishNotificationInput] = React.useState('');
  const [germanNotificationInput, setGermanNotificationInput] = React.useState('');
  const [typeAlert, setTypeAlert] = React.useState<"error" | "success" | "info" | "warning" | undefined>(undefined);
  const [alert, setAlert] = React.useState<string>('');
  const [notificationTemplateData, setClearNotificationTemplateData] = React.useState<any>('')
  const [select1, setSelect1] = React.useState('');
  const [select2, setSelect2] = React.useState('');
  const textarea1Ref = React.useRef<TextAreaRef | null>(null);
  const textarea2Ref = React.useRef<TextAreaRef | null>(null);
  const headerParagraphLine1 = getI18n(
    'notification-template-message-headerText-line1',
    `Custom variables are added with brackets '{}', the variable name will be added in Notification Templates
    and the value is added in the 'Add Notification' section. Example: Dear {user_name} the system wil be down 
    from {start_time}' to {end_time}. \n The {user_name} variable is a keyword variable that will return the logged in user's name`, intl
  );
  const validateString = (englishNotification: String, germanNotification: String) => {


    console.log(englishNotification);
    console.log(germanNotification);

    const noOfOpeningCurlyBrackets1 = (englishNotification.match(/[{]/gi) || []).length;
    const noOfClosingCurlyBrackets1 = (englishNotification.match(/[}]/gi) || []).length;
    let bracketDifference1 = 0;

    const noOfOpeningCurlyBrackets2 = (germanNotification.match(/[{]/gi) || []).length;
    const noOfClosingCurlyBrackets2 = (germanNotification.match(/[}]/gi) || []).length;
    let bracketDifference2 = 0;

    let alertMsg = '';
    if (noOfOpeningCurlyBrackets1 > noOfClosingCurlyBrackets1 || noOfOpeningCurlyBrackets2 > noOfClosingCurlyBrackets2) {
      // for str 1
      bracketDifference1 = noOfOpeningCurlyBrackets1 - noOfClosingCurlyBrackets1;
      if (noOfOpeningCurlyBrackets1 > noOfClosingCurlyBrackets1) {
        alertMsg = `You have ${bracketDifference1} more opening bracket(s) than closing bracket(s) in English Text Area`;
      }
      setAlert(alertMsg);
      setTypeAlert('error');
      bracketDifference2 = noOfOpeningCurlyBrackets2 - noOfClosingCurlyBrackets2;
      if (noOfOpeningCurlyBrackets2 > noOfClosingCurlyBrackets2) {
        alertMsg = `You have  ${bracketDifference2} more openning bracket(s) than closing bracket(s) in German Text Area.`;
      }
      setAlert(alertMsg);
      setTypeAlert('error');

    } else if (noOfClosingCurlyBrackets1 > noOfOpeningCurlyBrackets1 || noOfClosingCurlyBrackets2 > noOfOpeningCurlyBrackets2) {
      bracketDifference1 = noOfClosingCurlyBrackets1 - noOfOpeningCurlyBrackets1;

      if (noOfClosingCurlyBrackets1 > noOfOpeningCurlyBrackets1) {
        alertMsg = `You have ${bracketDifference1} more closing bracket(s) than opening bracket(s) in English Text Area.`;
      }

      bracketDifference2 = noOfClosingCurlyBrackets2 - noOfOpeningCurlyBrackets2;
      if (noOfClosingCurlyBrackets2 > noOfOpeningCurlyBrackets2) {
        alertMsg = `You have ${bracketDifference2} more closing bracket(s) than opening bracket(s) in German Text Area.`;
      }
      setAlert(alertMsg);
      setTypeAlert('error');
    } else {
      setAlert("Submitted Succesfully");
      setTypeAlert('success');
    }
  };

  useEffect(() => {
    if (typeAlert) {
      setTimeout(() => {
        setTypeAlert(undefined);
      }, 9000);
    }
  }, [typeAlert])
  const onInsertVariable = (isFor) => {
    if (isFor === 'englishNotificationInput') {
      const textarea = textarea1Ref?.current?.resizableTextArea?.textArea;
      const val = textarea.value;
      const start = textarea.selectionStart
      const end = textarea.selectionEnd;
      textarea.value = `${val.slice(0, start)}{${select1}}${val.slice(end)}`;
      setEnglishNotificationInput(textarea.value);
      textarea.focus();
      const caretPos = start + select1.length + 5;
      textarea.setSelectionRange(caretPos, caretPos);
    } else if (isFor === 'germanNotificationInput') {
      const textarea = textarea2Ref?.current?.resizableTextArea?.textArea;
      const val = textarea.value;
      const start = textarea.selectionStart
      const end = textarea.selectionEnd;
      textarea.value = `${val.slice(0, start)}{${select2}}${val.slice(end)}`;
      textarea.focus();
      setGermanNotificationInput(textarea.value)
      const caretPos = start + select2.length + 5;
      textarea.setSelectionRange(caretPos, caretPos);
    }
  };

  const setClearnotificationTemplateData = () => {
    delete notificationTemplateData.templateName;
    delete notificationTemplateData.templateText;
  };

  const onSubmit = () => {
    if (inputTemplateName === '') {
      setAlert("Template name cannot be empty");
      setTypeAlert('error');
      return
    }
    setLoading(true);
    validateString(englishNotificationInput, germanNotificationInput);

    const notificationTemplateTextStr = `{language, select, en{${englishNotificationInput}} de{${germanNotificationInput}} other{${englishNotificationInput}}}`
    const notificationTemplateNameStr = inputTemplateName

  

    const notificationTemplateData: NotificationTemplateInput = {
      templateName: notificationTemplateNameStr,
      templateText: notificationTemplateTextStr
    };

    console.log(`${notificationTemplateTextStr} and ${notificationTemplateData}`);
    console.log(notificationTemplateData);


    Promise.resolve(createNotificationTemplateData(notificationTemplateData))
      .then(() => {
        setClearNotificationTemplateData(notificationTemplateData)
        message.success(getI18n(
          "notifications-template-create-data-success",
          "Notifications template created successfully", intl
        ));
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        message.error(getI18n(
           "notifications-template-create-data-failure",
          "Notifications Template Data create failed", intl));
      });
  };


  return (
  <>
    <Form size={"middle"} layout={"vertical"} form={form}>
      <Row gutter={24} style={{ alignContent: "flex-end", marginTop: "50" }} />
      <Card>
        <Row gutter={24}>
          <PortalFormItem
            required={true}
            label={getI18n(
               'notification-template-label-placeholder',
               'Template Name', intl)}
            onChange={({ target }) => setInputTemplateName(target.value)}
            name={'templateName'}
            placeHolder={getI18n(
               'notification-template-label-placeholder',
               'Template Name', intl)}
               infoOutlined={getI18n("notification-templates-template-name-outlined-info",
               "The following naming conventions can be used for creatting Template names. S - Survey, E - eCap, F - Freight, R - Reports. T - Technical. e.g. T-Downtime1", intl)}
          />
        </Row>
      </Card>

      <Card title={getI18n(
        'notification-template-text-english-german-title',
        'Template Text for English and German', intl
      )}>

        <Typography>
      <Paragraph>
        <Row>
          {headerParagraphLine1}
        </Row>
      </Paragraph>
    </Typography>

        <Row>
          <TextArea
            placeholder={getI18n(
              'notification-template-label-placeholder-english-text',
              'English Text', intl)}
            ref={textarea1Ref}
            onChange={({ target }) => setEnglishNotificationInput(target.value)}
            value={englishNotificationInput}
            style={{ width: 500, height: 100 }} allowClear={true} />

          <TextArea
            placeholder={getI18n(
              'notification-template-label-placeholder-german-text',
              'German Text', intl)}
            ref={textarea2Ref}
            onChange={({ target }) => setGermanNotificationInput(target.value)}
            value={germanNotificationInput}
            style={{ width: 500, height: 100, marginLeft: 50 }} allowClear={true} />
        </Row>
        <Row>
          <Col>
            <Select
              defaultValue={select1}
              value={select1}
              size="middle"
              style={{ width: 220, marginTop: 20 }}
              onChange={(value) => setSelect1(value)}
              options={[
                {
                  value: ' ',
                  label: 'custom_variable',
                },
                {
                  value: 'user_name',
                  label: 'user_name',
                },
                {
                  value: 'downtime_start_time',
                  label: 'downtime_start_time',
                },
                {
                  value: 'downtime_end_time',
                  label: 'downtime_end_time',
                }

              ]}
            />
          </Col>

          <Col>
            <Button type="primary"
              onClick={() => onInsertVariable('englishNotificationInput')}
              style={{ marginTop: 20 }}
            >
              {getI18n(
                "notification-template-insert-variable-button",  
                 "Insert Variable", intl
              )}
            </Button>
          </Col>
          <Col />
          <Col >
           <Select
              defaultValue={select1}
              value={select2}
             size="middle"
             style={{ width: 220, marginLeft: 200, marginTop: 20 }}
             onChange={(value) => setSelect2(value)}
             options={[
              {
                 value: ' ',
                 label: 'custom_variable ',
               },
               {
                value: 'user_name',
                label: 'user_name',
              },
               {
                 value: 'downtime_start_time',
                 label: 'downtime_start_time',
                },
                {
                  value: 'downtime_end_time',
                  label: 'downtime_end_time',
                }
              ]}
           />
          </Col>

          <Col >
           <Button type="primary"
             style={{ marginTop: 20 }}
             onClick={() => onInsertVariable('germanNotificationInput')}
           >
             {getI18n(
               "notification-template-insert-variable-button", 
                "Insert Variable", intl
             )}
           </Button>
          </Col>
        </Row>
      </Card>
      <Card>
        <Row  >
          <Col span={5} >
            <Button onClick={onSubmit}
              type="primary"
              size="middle"
            >
              {getI18n(
                 "create-notification-template-button",
                 "Create Notification Template", intl
              )}
            </Button>
          </Col>
        </Row>
      </Card>
      {typeAlert && <Alert message={alert} type={typeAlert} showIcon={true} />}
    </Form>
  </>);
}

export default NotificationsTemplate;
